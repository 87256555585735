import React from "react";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  Card,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  ButtonIcon,
} from "ui-kit-ck-consultant";

import { getInt, getPrice } from "../../../../utils/general";

import ModalFees from "../../../../components/folder/administrative/ModalFees";

import AuthContext from "../../../../context/AuthContext";

export default class Fees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalFees: false,
      idxModalFees: null,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate() {
    this.initData();
  }

  initData = () => {
    if (!this.props.data.length) {
      let tmpData = this.props.data;

      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Préparation dossier",
        quantity: 0,
        type: "hrs",
        price: 100,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Préparation convocation",
        quantity: 0,
        type: "hrs",
        price: 100,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Recommandé AR",
        quantity: 0,
        type: "rar",
        price: 6.2,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Expertise / préliminaire / contradictoire 1",
        quantity: 0,
        type: "hrs",
        price: 100,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Frais kilométrique A/R",
        quantity: 0,
        type: "kms",
        price: 1.3,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Expertise / préliminaire / contradictoire 2",
        quantity: 0,
        type: "hrs",
        price: 100,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Compte rendu",
        quantity: 0,
        type: "kms",
        price: 100,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Rédaction réclamation",
        quantity: 0,
        type: "hrs",
        price: 100,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Recommandé AR",
        quantity: 0,
        type: "rar",
        price: 6.2,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Protocole accord",
        quantity: 0,
        type: "-",
        price: 100,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Étude technique / recherche",
        quantity: 0,
        type: "hrs",
        price: 100,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Rédaction rapport",
        quantity: 0,
        type: "hrs",
        price: 100,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Analyse huile",
        quantity: 0,
        type: "-",
        price: 0,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Kit prélèvement",
        quantity: 0,
        type: "-",
        price: 0,
      });
      tmpData.push({
        date: moment().format("YYYY-MM-DD"),
        object: "Frais envoi",
        quantity: 0,
        type: "hrs",
        price: 0,
      });

      this.setData(tmpData);
    }
  };

  setData = (data, callback = () => {}) => {
    this.props.setData("dataFees", data, callback);
  };

  render() {
    let total = 0;

    this.props.data.forEach((element) => {
      total += parseFloat(element.quantity) * parseFloat(element.price);
    });

    return (
      <>
        <Card
          className="mt-10 mt-20-xl"
          title="Honoraires"
          disabled={this.context.isLock}
        >
          <Table>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Objet</Th>
                <Th>Quantité</Th>
                <Th>Type</Th>
                <Th>Tarif unitaire</Th>
                <Th>Total honoraires</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.data.map((element, idx) => (
                <Tr key={idx}>
                  <Td>{moment(element.date).format("DD/MM/YYYY")}</Td>
                  <Td>{element.object}</Td>
                  <Td>{getInt(element.quantity)}</Td>
                  <Td>{element.type}</Td>
                  <Td>{getPrice(element.price)}</Td>
                  <Td>
                    {getPrice(
                      parseFloat(element.quantity) * parseFloat(element.price)
                    )}
                  </Td>
                  <Td>
                    <ButtonIcon
                      small
                      className="blue"
                      onClick={() =>
                        this.setState({
                          isModalFees: true,
                          idxModalFees: idx,
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </ButtonIcon>
                    <ButtonIcon
                      small
                      className="red"
                      onClick={() => {
                        let tmpData = this.props.data;
                        tmpData.splice(idx, 1);
                        this.setData(tmpData);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </ButtonIcon>
                  </Td>
                </Tr>
              ))}
              <Tr>
                <Td
                  colSpan="6"
                  className="text-right bg-fourth-color text-bold"
                >
                  Total HT
                </Td>
                <Td className="text-right bg-fourth-color text-bold">
                  {getPrice(total)}
                </Td>
              </Tr>
              <Tr>
                <Td
                  colSpan="6"
                  className="text-right bg-fourth-color text-bold"
                >
                  Total TVA (20%)
                </Td>
                <Td className="text-right bg-fourth-color text-bold">
                  {getPrice(total * 0.2)}
                </Td>
              </Tr>
              <Tr>
                <Td
                  colSpan="6"
                  className="text-right bg-fourth-color text-bold"
                >
                  Total TTC
                </Td>
                <Td className="text-right bg-fourth-color text-bold">
                  {getPrice(total + total * 0.2)}
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <div className="d-flex mt-10">
            <ButtonIcon
              isBackground
              className="ml-auto bg-blue"
              onClick={() => this.setState({ isModalFees: true })}
            >
              <FontAwesomeIcon icon={faPlus} />
            </ButtonIcon>
          </div>
        </Card>
        <ModalFees
          show={this.state.isModalFees}
          idx={this.state.idxModalFees}
          data={
            this.state.idxModalFees !== null
              ? this.props.data[this.state.idxModalFees]
              : null
          }
          onClose={() =>
            this.setState({
              isModalFees: false,
              idxModalFees: null,
            })
          }
          addFees={(data, callback) =>
            this.setState(
              {
                isModalFees: false,
              },
              () => {
                let tmpData = this.props.data;
                tmpData.push(data);
                this.setData(tmpData, callback);
              }
            )
          }
          updateFees={(idx, data, callback) =>
            this.setState(
              {
                isModalFees: false,
                idxModalFees: null,
              },
              () => {
                let tmpData = this.props.data;
                tmpData[idx] = data;
                this.setData(tmpData, callback);
              }
            )
          }
        />
      </>
    );
  }
}
