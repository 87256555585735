import React from "react";

import {
  faArrowLeft,
  faArrowRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import {
  Modal,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  FormRadio,
  Button,
  FormInput,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
} from "ui-kit-ck-consultant";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ModalMaileva extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isAddressValidation: false,
      currentLine: 0,
      radioData: [],
      data: [],
    };
  }

  componentDidMount() {
    this.setState({ data: this.props.partiesData });
  }

  handleChange(party, idx, sendOption) {
    const updatedRadioData = [...this.state.radioData];
    const existingIndex = this.state.radioData.findIndex(
      (data) => data.email === party.email && data.radioIdx === idx
    );

    if (existingIndex !== -1) {
      updatedRadioData[existingIndex].sendOption = sendOption;
    } else {
      updatedRadioData.push({
        sendOption,
        ...party,
        radioIdx: idx,
      });
    }

    this.setState({ radioData: updatedRadioData });
  }

  handleInputChange = (key, idx, value) => {
    const newData = [...this.state.radioData];
    newData[idx][key] = value;
    this.setState({ radioData: newData });
  };

  render() {
    const { radioData, isAddressValidation, currentLine, data } = this.state;

    return (
      <Modal
        title={
          isAddressValidation
            ? "Validation des adresses"
            : "Préparation courrier"
        }
        show={this.props.show}
        large
        onClose={this.props.onClose}
      >
        {!isAddressValidation ? (
          <>
            <Table>
              <Thead>
                <Tr>
                  <Th>Destinataire</Th>
                  <Th>Code postale</Th>
                  <Th>Ville</Th>
                  <Th>Adresse</Th>
                  <Th>Par courrier simple</Th>
                  <Th>Par courrier recommandé</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map((party, idx) => {
                  const existingIndex = radioData.findIndex(
                    (data) =>
                      data.email === party.email && data.radioIdx === idx
                  );

                  return (
                    <Tr key={idx}>
                      <Td>{`${party.kind} ${party.name} (${party.quality})`}</Td>
                      <Td>{party.zipCode}</Td>
                      <Td>{party.city}</Td>
                      <Td>{party.address}</Td>
                      <Td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FormRadio
                            value={"simplePostal"}
                            checked={
                              existingIndex !== -1 &&
                              radioData[existingIndex].sendOption ===
                                "simplePostal"
                            }
                            onChange={({ target }) => {
                              this.handleChange(party, idx, target.value);
                            }}
                          />
                        </div>
                      </Td>
                      <Td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FormRadio
                            value={"registeredPostal"}
                            checked={
                              existingIndex !== -1 &&
                              radioData[existingIndex].sendOption ===
                                "registeredPostal"
                            }
                            onChange={({ target }) => {
                              this.handleChange(party, idx, target.value);
                            }}
                          />
                        </div>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                disabled={radioData.length !== data.length}
                text={"Suivant"}
                className="mt-10 w-auto"
                onClick={() => {
                  this.setState({ isAddressValidation: true });
                }}
              >
                <FontAwesomeIcon className="white" icon={faArrowRight} />
              </Button>
            </div>
          </>
        ) : (
          <div className="d-flex flex-column" style={{ gap: 10 }}>
            <TabsCustom>
              <TabsCustomHeader noCard>
                {radioData.map((party, idx) => (
                  <TabsCustomMenu
                    key={idx}
                    title={`${party.kind} ${party.name} (${party.quality})`}
                    active={currentLine === idx}
                    onClick={(e) => {
                      this.setState({ currentLine: idx });
                    }}
                  />
                ))}
              </TabsCustomHeader>
              <TabsCustomBody noCard>
                <div className="d-flex flex-column w-100" style={{ gap: 10 }}>
                  <FormInput
                    title="Adresse"
                    name="addresse"
                    value={radioData[currentLine].address}
                    onChange={(e) =>
                      this.handleInputChange(
                        "address",
                        currentLine,
                        e.target.value
                      )
                    }
                  />
                  <FormInput
                    title="Adresse 2"
                    name="address2"
                    value={radioData[currentLine].address2}
                    onChange={(e) =>
                      this.handleInputChange(
                        "address2",
                        currentLine,
                        e.target.value
                      )
                    }
                  />

                  <FormInput
                    title="Code postal"
                    name="zipCode"
                    value={radioData[currentLine].zipCode}
                    onChange={(e) =>
                      this.handleInputChange(
                        "zipCode",
                        currentLine,
                        e.target.value
                      )
                    }
                  />
                  <FormInput
                    title="Ville"
                    name="city"
                    value={radioData[currentLine].city}
                    onChange={(e) =>
                      this.handleInputChange(
                        "city",
                        currentLine,
                        e.target.value
                      )
                    }
                  />
                </div>
              </TabsCustomBody>
            </TabsCustom>
            <div style={{ display: "flex", justifyContent: "end", gap: 5 }}>
              <Button
                disabled={radioData.length !== data.length}
                text={"Retour"}
                className="mt-10 w-auto"
                onClick={() => {
                  this.setState({ isAddressValidation: false });
                }}
              >
                <FontAwesomeIcon className="white" icon={faArrowLeft} />
              </Button>
              <Button
                disabled={radioData.length !== data.length}
                text={"Valider"}
                className="mt-10 w-auto"
                onClick={() => {
                  this.props.onValidate(
                    radioData.map(({ radioIdx, ...rest }) => rest),
                    (res) => {
                      if (res) {
                        this.setState({ radioData: [] });
                      }
                    }
                  );
                }}
              >
                <FontAwesomeIcon className="white" icon={faCheck} />
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
