import React from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  FormInput,
  FormSelect,
  Modal,
  Loader,
} from "ui-kit-ck-consultant";
import { Col, Row } from "react-flexbox-grid";

import { addBlankFolder } from "../../requests/home";

import AuthContext from "../../context/AuthContext";

export default class ModalAddBlankFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      reference: "",
      type: "DEFAULT",
      company: "DEFAULT",
      nameClient: "",
      nameThird: "",
      licensePlate: "",
      brand: "",
      model: "",
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  addBlankFolder = () => {
    if (
      this.state.reference &&
      this.state.type !== "DEFAULT" &&
      this.state.company !== "DEFAULT" &&
      this.state.nameClient &&
      this.state.nameThird &&
      this.state.licensePlate &&
      this.state.brand &&
      this.state.model
    ) {
      const folders = this.props.folders.filter(
        (element) => element.reference === this.state.reference
      );

      if (!folders.length) {
        this.setState({ isLoad: true }, () => {
          addBlankFolder(
            {
              reference: this.state.reference,
              type: this.state.type,
              company: this.state.company,
              nameClient: this.state.nameClient,
              nameThird: this.state.nameThird,
              licensePlate: this.state.licensePlate,
              brand: this.state.brand,
              model: this.state.model,
            },
            (result) => {
              if (result.success) {
                this.setState(
                  {
                    isLoad: false,
                    reference: "",
                    type: "DEFAULT",
                    company: "DEFAULT",
                    nameClient: "",
                    nameThird: "",
                    licensePlate: "",
                    brand: "",
                    model: "",
                  },
                  () => {
                    document.location.hash = `/folder/${result.token}`;
                  }
                );
              } else {
                this.setState(
                  {
                    isLoad: false,
                  },
                  () => window.alert("Une erreur est survenue")
                );
              }
            }
          );
        });
      } else {
        document.location.hash = `/folder/${folders[0].token}`;
      }
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  render() {
    return (
      <>
        <Modal
          title="Nouveau dossier vierge"
          show={this.props.show}
          onClose={this.props.onClose}
          large
          isMove
        >
          <Row>
            <Col xs={12} md={4}>
              <FormInput
                title="N° dossier"
                className="mb-10"
                name="reference"
                value={this.state.reference}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormSelect
                title="Type de dossier"
                className="mb-10"
                init="Veuillez sélectionner un type de dossier"
                options={this.context.types}
                name="type"
                value={this.state.type}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormSelect
                title="Compagnie"
                className="mb-10"
                init="Veuillez sélectionner une compagnie"
                options={this.context.companies}
                name="company"
                value={this.state.company}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                title="Nom du lésé"
                className="mb-10"
                name="nameClient"
                value={this.state.nameClient}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                title="Nom du tiers"
                className="mb-10"
                name="nameThird"
                value={this.state.nameThird}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                title="Immatriculation"
                className="mb-10"
                name="licensePlate"
                value={this.state.licensePlate}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                title="Marque"
                className="mb-10"
                name="brand"
                value={this.state.brand}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput
                title="Modèle"
                className="mb-10"
                name="model"
                value={this.state.model}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          <Button
            className="w-auto ml-auto"
            text="Créer"
            onClick={this.addBlankFolder}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
