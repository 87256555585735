import React from "react";
import {
  TabsEdit,
  TabsEditHeader,
  TabsEditBody,
  TabsEditMenu,
  TabsEditItem,
} from "ui-kit-ck-consultant";

export default class ExpertisesReports extends React.Component {
  render() {
    return (
      <>
        <TabsEdit className="mt-10 mt-20-xl mb-0">
          <TabsEditHeader onClickAdd={this.props.onClickAdd}>
            {this.props.data.map((element, idx) => {
              let count = this.props.data.filter(
                (elementCount, idxCount) =>
                  elementCount.name === element.name && idx >= idxCount
              ).length;

              return (
                <TabsEditMenu
                  key={idx}
                  title={`${element.name} n°${count}${
                    element.isLock
                      ? this.context.isReport
                        ? " (Clôturé)"
                        : " (Clôturée)"
                      : ""
                  }`}
                  active={this.props.tabsId === idx}
                  onClick={() => {
                    this.props.onClick(idx);
                  }}
                />
              );
            })}
          </TabsEditHeader>
          <TabsEditBody noCard>
            <TabsEditItem active={true}>{this.props.children}</TabsEditItem>
          </TabsEditBody>
        </TabsEdit>
      </>
    );
  }
}
