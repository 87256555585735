import React, { createRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Signature, Modal, Button, FormCheckbox } from "ui-kit-ck-consultant";
import SignatureCanvas from "react-signature-canvas";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class Signatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 200,
      width: 200,
      isModalSignature: false,
      modalSignatureKey: null,
    };

    this.signatureRef = createRef();
    this.displayName = "Signatures";
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.isModalSignature &&
      prevState.isModalSignature !== this.state.isModalSignature
    ) {
      this.setSizeSignature();
    }
  }

  setSizeSignature = () => {
    if (
      this.signatureRef.current &&
      this.state.width !== this.signatureRef.current.getCanvas().offsetWidth
    ) {
      this.setState({
        width: this.signatureRef.current.getCanvas().offsetWidth,
      });
    }
  };

  handleClearSignatureClick = () => {
    this.signatureRef.current.clear();
  };

  handleValidSignatureClick = () => {
    let image = this.signatureRef.current.toDataURL("image/png");

    let tmpData = this.props.data;
    tmpData[this.state.modalSignatureKey] = image;

    this.setState({ isModalSignature: false, modalSignatureKey: null }, () => {
      this.setData(tmpData);
    });
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise(
        `signatures${this.props.extra}`,
        tmpData
      );
    } else {
      this.context.updateDataReport(`signatures${this.props.extra}`, tmpData);
    }
  };

  render() {
    return (
      <>
        <CustomCard
          title={this.props.title || "Signatures"}
          name={this.displayName}
        >
          <Row>
            {Object.keys(this.context.parties).map((key) => {
              const currentCheckBox =
                Array.isArray(this.props.dataRetrieveSignature) &&
                this.props.dataRetrieveSignature &&
                this.props.dataRetrieveSignature.find((el) => el.key === key);

              if (this.props.dataAttendees[key] === 1) {
                return (
                  <Col key={key} xs={12} md={6}>
                    <h3>
                      {this.context.parties[key].kind}{" "}
                      {this.context.parties[key].name}
                      {" - "}
                      {this.context.parties[key].quality === "Autre"
                        ? this.context.parties[key].otherQuality
                        : this.context.parties[key].quality}
                    </h3>
                    {this.props.showCheckBox && (
                      <FormCheckbox
                        className="mb-10"
                        name={"retrieveSignature"}
                        checked={
                          currentCheckBox ? currentCheckBox.value : false
                        }
                        text={"Récupérer la signature"}
                        onChange={(e) => {
                          if (!this.props.signaturesStart[key]) {
                            window.alert(
                              "Veuillez remplir la section signature en haut de la page."
                            );
                          } else {
                            let tmpData = this.props.data;
                            const value = { key, value: e.target.checked };
                            tmpData[key] = e.target.checked
                              ? this.props.signaturesStart[key]
                              : "";

                            this.props.updateRetrieveSignature(value);
                            this.setData(tmpData);
                          }
                        }}
                      />
                    )}
                    <Signature
                      disabled={this.props.disabled}
                      onAdd={() => {
                        if (
                          this.context.parties[key].name ===
                            this.context.name &&
                          this.context.signature
                        ) {
                          let tmpData = this.props.data;
                          tmpData[key] = this.context.signature;

                          this.setData(tmpData);
                        } else {
                          this.setState({
                            isModalSignature: true,
                            modalSignatureKey: key,
                          });
                        }
                      }}
                      onRemove={() => {
                        let tmpData = this.props.data;
                        delete tmpData[key];

                        this.setData(tmpData);
                      }}
                      signature={this.props.data[key]}
                    />
                  </Col>
                );
              } else {
                return null;
              }
            })}
          </Row>
        </CustomCard>
        <Modal
          show={this.state.isModalSignature}
          onClose={() => {
            this.setState({
              isModalSignature: false,
              modalSignatureKey: null,
            });
          }}
          isMove
        >
          <SignatureCanvas
            ref={this.signatureRef}
            penColor="black"
            canvasProps={{
              height: this.state.height,
              width: this.state.width,
              style: {
                border: "var(--gray) dashed 2px",
                borderRadius: "15px",
              },
              className: "sigCanvas mb-10",
            }}
          />
          <Button
            text="Rénitialiser"
            className="m-0 mb-10 outline"
            onClick={this.handleClearSignatureClick}
          />
          <Button
            text="Valider"
            className="m-0"
            onClick={this.handleValidSignatureClick}
          />
        </Modal>
      </>
    );
  }
}
