import React from "react";

import CardEditor from "../CardEditor";


export default class Responsibilities extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "Responsibilities";
  }

  render() {
    const defaultData = `<p>Détailler les responsabilités de chacune des parties</p>`;

    return (
      <CardEditor
        {...this.props}
        name={this.displayName}
        title="Responsabilité des parties"
        dataName="responsibilities"
        default={defaultData}
      />
    );
  }
}
