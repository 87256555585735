import axiosConfig from "../utils/axiosConfig";
import { getAll, insertAll } from "../db/folder";

const getFolders = (data, callback) => {
  if (navigator.onLine) {
    axiosConfig("home/get-folders", {
      data,
    })
      .then((res) => {
        insertAll(res.data.data.folders, "homeFolders", () => {
          callback(res.data);
        });
      })
      .catch(() => callback({ success: false }));
  } else {
    getAll("homeFolders", (resulLocal) => {
      callback({
        success: resulLocal.success,
        data: {
          folders: resulLocal.data,
        },
      });
    });
  }
};

const countFolders = (callback) => {
  if (navigator.onLine) {
    axiosConfig("home/count-folders")
      .then((res) => {
        insertAll([res.data.data], "homeCountFolders", () => {
          callback(res.data);
        });
      })
      .catch(() => callback({ success: false }));
  } else {
    getAll("homeCountFolders", (resulLocal) => {
      if (resulLocal.success) {
        callback({ success: true, data: resulLocal[0] });
      } else {
        callback({ success: false, data: {} });
      }
    });
  }
};

const addFolder = (data, callback) => {
  axiosConfig("home/add-folder", {
    data: data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const addBlankFolder = (data, callback) => {
  axiosConfig("home/add-blank-folder", {
    data: data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const deleteFolder = (data, callback) => {
  axiosConfig("home/delete-folder", {
    data: data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const lockFolder = (data, callback) => {
  axiosConfig("home/lock-folder", {
    data: data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const unlockFolder = (data, callback) => {
  axiosConfig("home/unlock-folder", {
    data: data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export {
  getFolders,
  addFolder,
  addBlankFolder,
  deleteFolder,
  countFolders,
  lockFolder,
  unlockFolder,
};
