import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { FormCheckbox, FormInput } from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import { getParty } from "../../../../utils/general";

import AuthContext from "../../../../context/AuthContext";

export default class Beneficiary extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "Beneficiary";
  }
  static contextType = AuthContext;

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = checked;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    this.context.updateDataReport("beneficiary", tmpData);
  };

  getData = () => {
    const opponent = getParty(this.context.parties, "Adverse");
    const lese = getParty(this.context.parties, "Lésé");

    return (
      <Row>
        <Col xs={12}>
          <FormCheckbox
            className="mb-10"
            text={`Le tiers - ${opponent.kind} ${opponent.name}`}
            name="q1"
            checked={this.props.data.q1}
            onChange={this.handleCheckboxChange}
          />
        </Col>
        <Col xs={12}>
          <FormCheckbox
            className="mb-10"
            text={`L'assuré - ${lese.kind} ${lese.name}`}
            name="q2"
            checked={this.props.data.q2}
            onChange={this.handleCheckboxChange}
          />
        </Col>
        <Col xs={12}>
          <FormCheckbox
            className="mb-10"
            text="Autre, préciser ses coordonnées"
            name="q3"
            checked={this.props.data.q3}
            onChange={this.handleCheckboxChange}
          />
          {this.props.data.q3 ? (
            <FormInput
              className="mb-10"
              name="nameOther"
              placeholder="Coordonnées"
              value={this.props.data.nameOther}
              onChange={({ target }) => {
                let tmpData = this.props.data;
                tmpData[target.name] = target.value;

                this.setData(tmpData);
              }}
            />
          ) : null}
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <CustomCard
        title="Bénéficiaire présumé de l'indemnité"
        name={this.displayName}
      >
        {this.getData()}
      </CustomCard>
    );
  }
}
