import React from "react";
import {
  FormInput,
  Switch,
  FormRadio,
  FormCheckbox,
  FormTextarea,
} from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";


export default class Observations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsId: null,
      date: "",
      hour: "",
      type: "",
      isLoad: false,
    };
    this.displayName = "Observations";
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = value;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    this.context.updateDataReport("observations", tmpData);
  };

  getSwitch = (text, name, textInput = null, textDate = null) => (
    <>
      <div className="d-flex mb-20">
        <p className="mt-auto mb-auto mr-10">{text}</p>
        <Switch
          className="mt-auto mb-auto"
          value1="Non"
          value2="Oui"
          color1="#f2711c"
          color2="#026fc2"
          idxActive={
            this.props.data.hasOwnProperty(`switch_${name}`)
              ? this.props.data[`switch_${name}`]
              : 0
          }
          onChange={(type) => {
            let tmpData = this.props.data;
            tmpData[`switch_${name}`] = type;
            this.setData(tmpData);
          }}
        />
        {textInput &&
        this.props.data.hasOwnProperty(`switch_${name}`) &&
        this.props.data[`switch_${name}`] ? (
          <div className="d-flex">
            <FormInput
              className="mt-auto mb-auto ml-10"
              placeholder={textInput}
              name={`text_${name}`}
              value={this.props.data[`text_${name}`] || ""}
              onChange={this.handleInputChange}
            />
            <span className="nowrap mt-auto mb-auto ml-10">
              € HT /{" "}
              {(parseFloat(this.props.data[`text_${name}`] || 0) * 1.2).toFixed(
                2
              )}{" "}
              € TTC
            </span>
          </div>
        ) : null}
      </div>
      <div className="d-flex mb-20 mt-auto">
        {textDate &&
        this.props.data.hasOwnProperty(`switch_${name}`) &&
        this.props.data[`switch_${name}`] ? (
          <div className="d-flex">
            <FormInput
              type="date"
              className="mt-auto mb-auto ml-10"
              title={textDate}
              name={`text2_${name}`}
              value={this.props.data[`text2_${name}`] || ""}
              onChange={this.handleInputChange}
            />
          </div>
        ) : null}
      </div>
    </>
  );

  render() {
    return (
      <CustomCard title="Observations" name={this.displayName}>
        <>
          <div className="mb-20 d-flex">
            <p className="mt-auto mb-auto">
              La demande formulée par l’assuré est :
            </p>
            <FormRadio
              className="ml-10"
              checked={this.props.data.requestType === 1}
              onChange={() => {
                let tmpData = this.props.data;
                tmpData["requestType"] = 1;
                this.setData(tmpData);
              }}
              text="Fondée"
            />
            <FormRadio
              className="ml-10"
              checked={this.props.data.requestType === 2}
              onChange={() => {
                let tmpData = this.props.data;
                tmpData["requestType"] = 2;
                this.setData(tmpData);
              }}
              text="Particulièrement fondée"
            />
            <FormRadio
              className="ml-10"
              checked={this.props.data.requestType === 3}
              onChange={() => {
                let tmpData = this.props.data;
                tmpData["requestType"] = 3;
                this.setData(tmpData);
              }}
              text="Infondée"
            />
            <FormRadio
              className="ml-10"
              checked={this.props.data.requestType === 4}
              onChange={() => {
                let tmpData = this.props.data;
                tmpData["requestType"] = 4;
                this.setData(tmpData);
              }}
              text="Partiellement fondée"
            />
          </div>
          {this.getSwitch(
            "Une expertise contradictoire est-elle techniquement justifiée : ",
            "justified"
          )}
          {this.getSwitch("Le véhicule est immobilisé : ", "immobilized")}
          {this.getSwitch(
            "Frais de gardiennage : ",
            "storageCosts",
            "0",
            "À partir du",
            true
          )}
          {this.getSwitch(
            "Un transfert du véhicule est il nécessaire : ",
            "transfer"
          )}
          {this.getSwitch(
            "Un démontage plus complet est il nécessaire : ",
            "completeDisassembly"
          )}
          {this.getSwitch(
            "Frais de démontage pour examen complémentaire : ",
            "dismantlingCosts",
            "Montant à prévoir"
          )}
          <div>
            <p>Parties à mettre en cause :</p>
            {this.context.partiesOptions
              .filter(
                (element) =>
                  ![
                    "Lésé",
                    "Mandant",
                    "Expert",
                    "Gestionnaire",
                    "Assureur",
                  ].includes(this.context.parties[element.value].quality)
              )
              .map((party, idx) => (
                <div key={idx} className="mb-10">
                  <FormCheckbox
                    text={party.text}
                    className="mr-5"
                    checked={
                      this.props.data.hasOwnProperty("parties") &&
                      this.props.data.parties.hasOwnProperty(party.value)
                    }
                    onChange={() => {
                      let tmpData = this.props.data;

                      if (!tmpData.hasOwnProperty("parties")) {
                        tmpData.parties = {};
                      }

                      if (tmpData.parties.hasOwnProperty(party.value)) {
                        delete tmpData.parties[party.value];
                      } else {
                        tmpData.parties[party.value] = "";
                      }

                      this.setData(tmpData);
                    }}
                  />
                  {this.props.data.hasOwnProperty("parties") &&
                  this.props.data.parties.hasOwnProperty(party.value) ? (
                    <FormTextarea
                      value={
                        this.props.data.parties &&
                        this.props.data.parties[party.value]
                          ? this.props.data.parties[party.value]
                          : ""
                      }
                      onChange={(e) => {
                        const value = e.target.value;
                        let tmpData = this.props.data;

                        tmpData.parties[party.value] = value;

                        this.setData(tmpData);
                      }}
                    />
                  ) : null}
                </div>
              ))}
          </div>
          <FormTextarea
            className="mt-10"
            title="Enjeux du dossier"
            value={this.props.data.comment}
            onChange={({ target }) => {
              let tmpData = this.props.data;
              tmpData.comment = target.value;
              this.setData(tmpData);
            }}
          />
        </>
      </CustomCard>
    );
  }
}
