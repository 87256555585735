import React from "react";
import {
  FormInput,
  FormSelect,
  ButtonIcon,
  FormCheckbox,
  TabsEdit,
  TabsEditHeader,
  TabsCustomHeader,
  TabsEditBody,
  TabsEditMenu,
  TabsEditItem,
  Button,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";
import {
  faEye,
  faEyeSlash,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Histovec from "../Histovec";

import CustomCard from "../../../../components/folder/general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

import MaintenancePlan from "./MaintenancePlan";

export default class VehicleIdentification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsId: 0,
    };
    this.displayName = "VehicleIdentification";
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.context.dataAdministrative;
    tmpData["dataVehicleIdentification"][this.state.tabsId][name] = value;

    this.context.updateDataFolder("dataAdministrative", tmpData, () => {});
  };

  addVehicle = () => {
    let tmpData = this.context.dataAdministrative;
    tmpData["dataVehicleIdentification"][
      tmpData["dataVehicleIdentification"].length
    ] = {};

    this.context.updateDataFolder("dataAdministrative", tmpData, () => {});
  };

  getFuel = (fuel) => {
    if (fuel === "ES") {
      return "Essence";
    } else if (fuel === "GO") {
      return "Diesel";
    } else if (fuel === "HY") {
      return "Hybride";
    } else if (fuel === "EL") {
      return "Électrique";
    } else {
      return "Sans énergie";
    }
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("vehicleIdentification", tmpData);
    } else {
      this.context.updateDataReport("vehicleIdentification", tmpData);
    }
  };

  setMaintenancePlan = (tmpData) => {
    let dataAdministrative = this.context.dataAdministrative;

    dataAdministrative.dataVehicleIdentification[this.state.tabsId] = tmpData;
    this.context.updateDataFolder(
      "dataAdministrative",
      dataAdministrative,
      () => {}
    );
  };

  render() {
    const dataVehicleIdentification =
      this.context.dataAdministrative.dataVehicleIdentification;
    const currentVehicle = dataVehicleIdentification[this.state.tabsId];

    return (
      <>
        <TabsEdit className="mt-10 mt-20-xl mb-0">
          {this.context.isLock ? (
            <TabsCustomHeader
              onClickAdd={() => this.addVehicle()}
              className="mb-0"
            >
              {dataVehicleIdentification.map((element, idx) => {
                return (
                  <TabsEditMenu
                    key={idx}
                    title={`Véhicule n°${idx + 1}`}
                    active={this.state.tabsId === idx}
                    onClick={() => this.setState({ tabsId: idx })}
                  />
                );
              })}
            </TabsCustomHeader>
          ) : (
            <TabsEditHeader
              onClickAdd={() => this.addVehicle()}
              className="mb-0"
            >
              {dataVehicleIdentification.map((element, idx) => {
                return (
                  <TabsEditMenu
                    key={idx}
                    title={`Véhicule n°${idx + 1}`}
                    active={this.state.tabsId === idx}
                    disabled={this.context.isLock}
                    onClick={() => this.setState({ tabsId: idx })}
                  />
                );
              })}
            </TabsEditHeader>
          )}
          <TabsEditBody noCard>
            <TabsEditItem active={true}>
              <CustomCard
                title="Identification du véhicule"
                name={this.displayName}
                action={
                  <ButtonIcon
                    className="blue mr-10 p-0"
                    small
                    onClick={() => {
                      window.confirmCustom(
                        "Êtes-vous sûr de synchroniser le bloc avec Alpha Expert (risque d'écrasement des données) ?",
                        (result) => {
                          if (result) {
                            let tmpData = this.context.dataAdministrative;

                            tmpData["dataVehicleIdentification"][0] = {
                              licensePlate:
                                this.context.interfaceVehicule["Immat"],
                              VIN: this.context.interfaceVehicule["VIN"],
                              dateTechnicalControl: moment(
                                this.context.interfaceVehicule["DateDernierCT"]
                              ).format("YYYY-MM-DD"),
                              brand:
                                this.context.interfaceVehicule["Constructeur"],
                              model: this.context.interfaceVehicule["Modele"],
                              year: moment(
                                this.context.interfaceVehicule["Date1MEC"]
                              ).format("YYYY"),
                              circulationDate: moment(
                                this.context.interfaceVehicule["Date1MEC"]
                              ).format("YYYY-MM-DD"),
                              certificateDate: moment(
                                this.context.interfaceVehicule[
                                  "DateCertificatImmat"
                                ]
                              ).format("YYYY-MM-DD"),
                              fuel: this.getFuel(
                                this.context.interfaceVehicule["Energie"]
                              ),
                              body: this.context.interfaceVehicule[
                                "Carrosserie"
                              ],
                              color: this.context.interfaceVehicule["Couleur"],
                              formula:
                                this.context.interfaceVehicule[
                                  "Numero_Formule"
                                ],
                            };

                            this.context.updateDataFolder(
                              "dataAdministrative",
                              tmpData,
                              () => {}
                            );
                          }
                        }
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </ButtonIcon>
                }
              >
                <Row>
                  <Col xs={12} md={6} lg={4}>
                    <div className="d-flex">
                      <FormInput
                        className="mb-10 w-100"
                        name="licensePlate"
                        value={currentVehicle.licensePlate || ""}
                        title="Immatriculation"
                        onChange={this.handleInputChange}
                      />
                      <FormCheckbox
                        className="mt-auto mb-20 ml-10 nowrap"
                        text="Non concerné"
                        checked={!!currentVehicle.checkboxLicensePlate}
                        onChange={() => {
                          let tmpData = this.context.dataAdministrative;

                          tmpData["dataVehicleIdentification"][
                            this.state.tabsId
                          ].checkboxLicensePlate =
                            !currentVehicle.checkboxLicensePlate;

                          this.context.updateDataFolder(
                            "dataAdministrative",
                            tmpData,
                            () => {}
                          );
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <FormInput
                      className="mb-10"
                      name="VIN"
                      value={currentVehicle.VIN || ""}
                      title="Numéro de série"
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <div className="d-flex">
                      <FormInput
                        type="date"
                        className="mb-10 w-100"
                        name="dateTechnicalControl"
                        value={currentVehicle.dateTechnicalControl || ""}
                        title="Date dernier CT"
                        onChange={this.handleInputChange}
                      />
                      <FormCheckbox
                        className="mt-auto mb-20 ml-10 nowrap"
                        text="Non concerné"
                        checked={!!currentVehicle.checkboxTechnicalControl}
                        onChange={() => {
                          let tmpData = this.context.dataAdministrative;

                          tmpData["dataVehicleIdentification"][
                            this.state.tabsId
                          ].checkboxTechnicalControl =
                            !currentVehicle.checkboxTechnicalControl;

                          this.context.updateDataFolder(
                            "dataAdministrative",
                            tmpData,
                            () => {}
                          );
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <FormInput
                      className="mb-10"
                      name="brand"
                      value={currentVehicle.brand || ""}
                      title="Marque"
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <FormInput
                      className="mb-10"
                      name="model"
                      value={currentVehicle.model || ""}
                      title="Modèle"
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <FormInput
                      className="mb-10"
                      name="year"
                      value={currentVehicle.year || ""}
                      type="year"
                      title="Année"
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <FormInput
                      name="circulationDate"
                      value={currentVehicle.circulationDate || ""}
                      className="mb-10"
                      type="date"
                      title="Date mise en circulation"
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <FormInput
                      className="mb-10"
                      name="certificateDate"
                      value={currentVehicle.certificateDate || ""}
                      type="date"
                      title="Date certificat"
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <FormSelect
                      className="mb-10"
                      name="fuel"
                      value={currentVehicle.fuel}
                      title="Énergie"
                      init={"Veuillez sélectionner un d'énergie"}
                      ignore={true}
                      options={[
                        { value: "Essence", text: "Essence" },
                        { value: "Diesel", text: "Diesel" },
                        { value: "Électrique", text: "Électrique" },
                        { value: "Hybride", text: "Hybride" },
                        { value: "GPL", text: "GPL" },
                        { value: "Sans énergie", text: "Sans énergie" },
                      ]}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <FormSelect
                      className="mb-10"
                      name="gearbox"
                      value={currentVehicle.gearbox}
                      title="Boîte de vitesse"
                      init={"Veuillez sélectionner un type de boîte de vitesse"}
                      ignore={true}
                      options={[
                        { value: "Manuelle", text: "Manuelle" },
                        { value: "Automatique", text: "Automatique" },
                      ]}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <FormSelect
                      className="mb-10"
                      name="body"
                      value={currentVehicle.body}
                      title="Carrosserie"
                      init={"Veuillez sélectionner un type de carrosserie"}
                      ignore={true}
                      options={[
                        { value: "berline", text: "Berline" },
                        { value: "monospace", text: "Monospace" },
                        { value: "pickup", text: "Pickup" },
                        { value: "coupe", text: "Coupé" },
                        { value: "citadine", text: "Citadine" },
                        { value: "4x4", text: "4x4" },
                        {
                          value: "voiture sans permis",
                          text: "Voiture sans permis",
                        },
                        { value: "voituresociete", text: "Voiture societé" },
                        { value: "minibus", text: "Minibus" },
                        { value: "cabriolet", text: "Cabriolet" },
                        { value: "break", text: "Break" },
                        { value: "caravane", text: "Caravane" },
                        { value: "camping-car", text: "Camping-Car" },
                        { value: "Cyclomoteur", text: "Cyclomoteur" },
                        {
                          value: "Motocycle < à 125 cm3",
                          text: "Motocycle < à 125 cm3",
                        },
                        {
                          value: "Motocycle > à 125 cm3",
                          text: "Motocycle > à 125 cm3",
                        },
                        { value: "Quad", text: "Quad" },
                        { value: "Fourgon", text: "Fourgon" },
                        { value: "Camion", text: "Camion" },
                        { value: "Tracteur Routier", text: "Tracteur Routier" },
                        { value: "Remorque", text: "Remorque" },
                        { value: "Semi-remorque", text: "Semi-remorque" },
                        {
                          value: "Tracteur Agricole",
                          text: "Tracteur Agricole",
                        },
                        {
                          value: "Moissonneuse-Batteuse",
                          text: "Moissonneuse-Batteuse",
                        },
                        { value: "Télescopique", text: "Télescopique" },
                        {
                          value: "Machine à Vendanger",
                          text: "Machine à Vendanger",
                        },
                        {
                          value: "Matériel Agricole non immatriculé",
                          text: "Matériel Agricole non immatriculé",
                        },
                        { value: "Vélo", text: "Vélo" },
                        {
                          value: "Vélo à Assistance Electrique",
                          text: "Vélo à Assistance Electrique",
                        },
                        { value: "autre", text: "Autre" },
                      ]}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <FormInput
                      className="mb-10"
                      name="color"
                      value={currentVehicle.color || ""}
                      title="Couleur"
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    lg={
                      this.context.isExpertise || this.context.isReport ? 3 : 4
                    }
                  >
                    <FormInput
                      className="mb-10"
                      name="motorType"
                      value={currentVehicle.motorType || ""}
                      title="Type moteur"
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    lg={
                      this.context.isExpertise || this.context.isReport ? 3 : 4
                    }
                  >
                    <FormInput
                      className="mb-10"
                      name="formula"
                      value={currentVehicle.formula || ""}
                      title="N° de formule"
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    lg={
                      this.context.isExpertise || this.context.isReport ? 3 : 4
                    }
                    className="d-flex"
                  >
                    <Button
                      outline
                      className="mt-auto mb-10"
                      red
                      text="Supprimer"
                      disabled={!this.state.tabsId}
                      onClick={() => {
                        window.confirmCustom(
                          "Êtes-vous sûr de vouloir supprimer ce véhicule ?",
                          (result) => {
                            if (result) {
                              const tabsId = this.state.tabsId;
                              let tmpData = this.context.dataAdministrative;

                              if (
                                tabsId ===
                                tmpData["dataVehicleIdentification"].length - 1
                              ) {
                                this.setState({
                                  tabsId: tabsId - 1,
                                });
                              }

                              tmpData["dataVehicleIdentification"].splice(
                                tabsId,
                                1
                              );

                              this.context.updateDataFolder(
                                "dataAdministrative",
                                tmpData,
                                () => {}
                              );
                            }
                          }
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Col>
                  {this.context.isExpertise || this.context.isReport ? (
                    <Col xs={12} md={6} lg={3} className="d-flex">
                      <Button
                        outline
                        className="mt-auto mb-10"
                        orange
                        text={
                          this.props.data[this.state.tabsId]
                            ? "Afficher"
                            : "Masquer"
                        }
                        onClick={() => {
                          let tmpData = this.props.data;

                          tmpData[this.state.tabsId] =
                            !tmpData[this.state.tabsId];

                          this.setData(tmpData);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            this.props.data[this.state.tabsId]
                              ? faEye
                              : faEyeSlash
                          }
                        />
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              </CustomCard>
            </TabsEditItem>
          </TabsEditBody>
        </TabsEdit>
        <MaintenancePlan
          data={currentVehicle.maintenancePlan}
          name={`maintenancePlan_${this.state.tabsId}`}
          setData={this.setMaintenancePlan}
          currentVehicleId={this.state.tabsId}
        />
        {this.context.isReport || this.context.isExpertise ? null : (
          <Histovec dataRecallFacts={this.props.dataRecallFacts} />
        )}
      </>
    );
  }
}
