import axiosConfig from "../utils/axiosConfig";

const sendMail = (data, callback) => {
  axiosConfig("folder/mail/send-mail", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getTemplateMail = (typeDocument, typeParty, data, callback) => {
  axiosConfig("folder/mail/get-template-mail", {
    data: {
      typeDocument,
      typeParty,
      data,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => {
      callback({ success: false });
    });
};

const documentToPdfRequest = (data, callback) => {
  axiosConfig("folder/mail/document-to-pdf", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => {
      callback({ success: false });
    });
};

const sendMails = (
  pdf,
  recipients,
  others,
  subject,
  folderId,
  type,
  message,
  token,
  callback,
  attachments
) => {
  axiosConfig("folder/mail/send-mails", {
    data: {
      pdf,
      recipients,
      others,
      subject,
      folderId,
      type,
      message,
      token,
      attachments,
    },
  })
    .then((res) => {
      callback({ success: true });
    })
    .catch((err) => {
      callback({ success: false });
    });
};

const electronicSignature = (data, callback) => {
  axiosConfig("folder/mail/electronic-signature", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => {
      callback({ success: false });
    });
};

export {
  sendMail,
  getTemplateMail,
  documentToPdfRequest,
  sendMails,
  electronicSignature,
};
