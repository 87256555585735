import React from "react";

import CardEditor from "../CardEditor";

import { getParty } from "../../../../utils/general";

import AuthContext from "../../../../context/AuthContext";


export default class NatureOfMission extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "NatureOfMission";
  }

  static contextType = AuthContext;

  render() {
    const insured = getParty(this.context.parties, "Lésé");

    const defaultData = `<p>Vous nous avez sollicité en qualité d'assureur responsabilité civile professionnelles des établissements ${insured.kind} ${insured.name}, ${insured.address} ${insured.address2}, ${insured.zipCode} ${insured.city} à l'occasion d’un litige susceptible d’être en lien avec son intervention.</p>`;

    return (
      <CardEditor
        {...this.props}
        name={this.displayName}
        title="Nature de la mission"
        dataName="natureOfMission"
        default={defaultData}
      />
    );
  }
}
