import moment from "moment";

import axiosConfig from "../utils/axiosConfig";
import { insertFolderAction, getDefault } from "./general";

import {
  getAllFolder,
  getFolder as getLocalFolder,
  insertAllFolder,
  insertFolder as insertLocalFolder,
  updateFolder as updateLocalFolder,
  insertActionsTmp,
  getActionsTmp,
  deleteActionsTmp,
} from "../db/folder";
import { checkFolderSync } from "../utils/general";

const getFolder = (data, callback) => {
  getLocalFolder(data.token, (resultLocal) => {
    if (navigator.onLine) {
      axiosConfig("folder/get-folder", {
        data,
      })
        .then((res) => {
          if (res.data.success) {
            // check if local exist and is most recent
            if (resultLocal.data) {
              if (data.isSoftware) {
                res.data.data.files.forEach((file) => {
                  if (!file.base64) {
                    resultLocal.data.files.forEach((fileLocal) => {
                      if (fileLocal.path === file.path && fileLocal.base64) {
                        file.base64 = fileLocal.base64;
                      }
                    });
                  }
                });
              }
            }

            checkFolderSync(res.data.data, (resultCheck) => {
              if (resultCheck.isLocal) {
                Object.keys(resultCheck.data).forEach((key) => {
                  if (resultCheck.data[key] === "local") {
                    res.data.data[key] = resultLocal.data[key];
                    res.data.data.dataOther[`lastDate_${key}`] =
                      resultLocal.data.dataOther[`lastDate_${key}`];
                  }
                });

                updateFolder(
                  res.data.data.token,
                  {
                    ...res.data.data,
                    files: undefined,
                  },
                  () => {
                    insertLocalFolder(res.data.data, () => {
                      callback(res.data);
                    });
                  }
                );
              } else {
                insertLocalFolder(res.data.data, () => {
                  callback(res.data);
                });
              }
            });
          } else {
            callback(res.data);
          }
        })
        .catch(() => {
          callback({ success: false });
        });
    } else {
      callback(resultLocal);
    }
  });
};

const updateDataFolder = (
  folder,
  name,
  data,
  other,
  callback,
  action,
  keyData
) => {
  const dateUpdate = moment().format("YYYY-MM-DD HH:mm:ss");

  if (window.electronAPI && window.electronAPI.saveLogUpdateFolder) {
    window.electronAPI.saveLogUpdateFolder(
      `${folder}-${name}-${moment().format("YYYY_MM_DD_HH_mm_ss")}`,
      JSON.stringify({ folder, name, data, other })
    );
  }

  updateLocalFolder(folder, name, data, other, dateUpdate, (result) => {
    if (navigator.onLine) {
      axiosConfig("folder/update-data-folder", {
        data: { folder, data, other, name, dateUpdate },
      })
        .then((res) => {
          if (res.data.success && action) {
            insertFolderAction(folder, action, keyData, () => {
              if (res.data.success) {
                getFolderActions({ folder }, (result) => {
                  callback({ ...res.data, actions: result.data });
                });
              } else {
                callback(res.data);
              }
            });
          } else {
            callback(res.data);
          }
        })
        .catch(() => callback({ success: false }));
    } else {
      if (result.success && action) {
        insertActionsTmp(folder, action, keyData, () => {
          callback(result);
        });
      } else {
        callback(result);
      }
    }
  });
};

const updateFolder = (token, folder, callback) => {
  axiosConfig("folder/update-folder", {
    data: { token, data: folder },
  })
    .then((res) => {
      getActionsTmp(folder, (resultActions) => {
        if (resultActions.success) {
          Promise.all(
            resultActions.data.map((action) => {
              return new Promise((resolve) => {
                insertFolderAction(
                  folder,
                  action.action,
                  action.keyData,
                  () => {
                    resolve();
                  },
                  action.date
                );
              });
            })
          ).then(() => deleteActionsTmp(folder, () => callback(res.data)));
        } else {
          callback(res.data);
        }
      });
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getFolderActions = (data, callback) => {
  axiosConfig("folder/get-folder-actions", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateFolderState = (stateId, folderId, callback) => {
  axiosConfig("folder/update-folder-state", {
    data: { stateId, folderId },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => callback({ success: false }));
};

const getFolderState = (folderId, callback) => {
  axiosConfig("folder/get-folder-state", {
    data: folderId,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      callback({ success: false });
    });
};

// START - FOLDER CALL
const insertFolderCall = (data, callback) => {
  axiosConfig("folder/insert-folder-call", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateFolderCall = (data, callback) => {
  axiosConfig("folder/update-folder-call", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const deleteFolderCall = (data, callback) => {
  axiosConfig("folder/delete-folder-call", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

// END - FOLDER CALL

// START - FOLDER APPOINTMENT
const insertFolderAppointment = (data, callback) => {
  axiosConfig("folder/insert-folder-appointment", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateFolderAppointment = (data, callback) => {
  axiosConfig("folder/update-folder-appointment", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const deleteFolderAppointment = (data, callback) => {
  axiosConfig("folder/delete-folder-appointment", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

// END - FOLDER APPOINTMENT

// START - FOLDER DOCUMENT
const getFolderDocument = (data, callback) => {
  axiosConfig("folder/get-folder-document", {
    data,
  })
    .then((result) => {
      callback(result.data);
    })
    .catch((err) => {
      callback({ success: false });
    });
};
// END - FOLDER DOCUMENT

const getReportHistovec = (data, callback) => {
  axiosConfig("folder/get-report-histovec", {
    data,
  })
    .then((result) => {
      callback(result.data);
    })
    .catch((err) => {
      callback({ success: false });
    });
};

const getPieces = (data, callback) => {
  axiosConfig("folder/get-pieces", {
    data,
  })
    .then((result) => {
      callback(result.data);
    })
    .catch((err) => {
      callback({ success: false, errror: err });
    });
};

// START - FOLDER TECHNICAL ADVICE \\
const insertFolderTechnicalAdvice = (data, callback) => {
  axiosConfig("folder/insert-folder-technical-advice", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateFolderTechnicalAdvice = (data, callback) => {
  axiosConfig("folder/update-folder-technical-advice", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const deleteFolderTechnicalAdvice = (data, callback) => {
  axiosConfig("folder/delete-folder-technical-advice", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getOptions = (
  callback,
  data = {},
  functions = [
    { name: "actions", url: "/folder/get-folder-actions" },
    { name: "calls", url: "/folder/get-folder-calls" },
    { name: "appointments", url: "/folder/get-folder-appointments" },
    { name: "technicalAdvices", url: "/folder/get-folder-technical-advice" },
  ]
) => {
  Promise.all(
    functions.map((element) => {
      return new Promise((resolve) => {
        if (navigator.onLine) {
          getDefault(
            element.url,
            (result) => {
              if (result.success) {
                insertAllFolder(data.folder, result.data, element.name, () => {
                  resolve({ name: element.name, data: result.data });
                });
              } else {
                resolve({ name: element.name, data: [] });
              }
            },
            data
          );
        } else {
          getAllFolder(data.folder, element.name, (resultLocal) => {
            if (resultLocal.success) {
              resolve({ name: element.name, data: resultLocal.data });
            } else {
              resolve({ name: element.name, data: [] });
            }
          });
        }
      });
    })
  ).then((result) => {
    let data = {};

    result.forEach((element) => {
      data[element.name] = element.data;
    });

    callback({
      success: true,
      data,
    });
  });
};

const getParties = (callback) => {
  axiosConfig("folder/get-parties")
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const deleteParty = (data, callback) => {
  axiosConfig("folder/delete-party", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const addParty = (data, callback) => {
  axiosConfig("folder/add-party", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateParty = (data, callback) => {
  axiosConfig("folder/update-party", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateCompany = (data, callback) => {
  axiosConfig("folder/update-company", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getRecallFacts = (data, callback) => {
  axiosConfig("folder/get-folder-recall-facts", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const recallFactsPDF = (data, callback) => {
  axiosConfig("folder/get-recall-facts-pdf", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};
// END  - FOLDER TECHNICAL ADVICE \\

const getDataSaveMessage = (data, callback) => {
  axiosConfig("folder/get-data-save-message", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateSaveMessage = (data, callback) => {
  axiosConfig("folder/update-data-save-message", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export {
  getFolder,
  updateDataFolder,
  updateFolder,
  updateFolderState,
  insertFolderCall,
  updateFolderCall,
  deleteFolderCall,
  insertFolderAppointment,
  updateFolderAppointment,
  deleteFolderAppointment,
  getFolderDocument,
  getReportHistovec,
  getPieces,
  getFolderState,
  insertFolderTechnicalAdvice,
  updateFolderTechnicalAdvice,
  deleteFolderTechnicalAdvice,
  getOptions,
  getParties,
  addParty,
  updateParty,
  updateCompany,
  getRecallFacts,
  deleteParty,
  recallFactsPDF,
  getDataSaveMessage,
  updateSaveMessage,
};
