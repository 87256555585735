import React from "react";
import { Card, FormSelect } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import AuthContext from "../../../../context/AuthContext";

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mission_agencyId: "",
      mission_agency: "",
      mission_dateMission: "",
      mission_dateSinister: "",
      mission_typeMission: "",
      mission_referenceSinister: "",
      mission_reference: "",
      mission_expert: "",
      mission_assistant: "",
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (e) => {
    const { value, name } = e.target;

    this.setState(
      {
        [name]: value,
      },
      this.setData
    );
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.getData();
    }
  }

  getData = () => {
    let tmpState = this.state;

    Object.keys(this.state).forEach((key) => {
      const keySplit = key.split("_");
      if (
        this.props.data.hasOwnProperty(keySplit[0]) &&
        this.props.data[keySplit[0]].hasOwnProperty(keySplit[1])
      ) {
        tmpState[key] = this.props.data[keySplit[0]][keySplit[1]];
      }
    });

    this.setState(tmpState);
  };

  setData = () => {
    let tmpData = this.props.data;

    Object.keys(this.state).forEach((key) => {
      const keySplit = key.split("_");
      if (
        this.props.data.hasOwnProperty(keySplit[0]) &&
        this.props.data[keySplit[0]].hasOwnProperty(keySplit[1])
      ) {
        tmpData[keySplit[0]][keySplit[1]] = this.state[key];
      }
    });

    this.props.setData("dataAdministrativeInformation", tmpData);
  };

  render() {
    return (
      <Card
        className="mt-10 mt-20-xl"
        title="Utilisateurs"
        disabled={this.context.isLock}
      >
        <Row>
          <Col xs={12} md={6} lg={4}>
            <FormSelect
              name="mission_expert"
              value={this.state.mission_expert}
              className="mb-10"
              title="Expert"
              ignore={true}
              init="Veuillez sélectionner un expert"
              options={this.context.users
                .filter((user) => user.type === "expert")
                .map((user) => ({
                  value: user.id,
                  text: user.name,
                }))}
              onChange={this.handleSelectChange}
            />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <FormSelect
              name="mission_assistant"
              value={this.state.mission_assistant}
              className="mb-10"
              title="Assistant(e)"
              ignore={true}
              init="Veuillez sélectionner un(e) assistant(e)"
              options={this.context.users
                .filter((user) => user.type === "assistant")
                .map((user) => ({
                  value: user.id,
                  text: user.name,
                }))}
              onChange={this.handleSelectChange}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
