import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { FormCheckbox } from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class Conditions extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "Conditions";
  }

  static contextType = AuthContext;

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = checked;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    this.context.updateDataReport("conditions", tmpData);
  };

  getData = () => {
    return (
      <Row>
        <Col xs={12}>
          <FormCheckbox
            className="mb-10"
            text="Hors Taxe H.T"
            name="q1"
            checked={this.props.data.q1}
            onChange={this.handleCheckboxChange}
          />
        </Col>
        <Col xs={12}>
          <FormCheckbox
            className="mb-10"
            text="Toutes taxes comprises T.T.C"
            name="q2"
            checked={this.props.data.q2}
            onChange={this.handleCheckboxChange}
          />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <CustomCard
        title="Conditions présumées de réglement"
        name={this.displayName}
      >
        {this.getData()}
      </CustomCard>
    );
  }
}
