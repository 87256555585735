import React from "react";
import moment from "moment";
import {
  faCheck,
  faPen,
  faTrash,
  faMagnifyingGlass,
  faPlus,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-flexbox-grid";
import {
  Button,
  ButtonIcon,
  FormInput,
  Loader,
  FullModal,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Pagination,
} from "ui-kit-ck-consultant";

import Lexical from "../../lexical/Lexical";

import {
  insertFolderTechnicalAdvice,
  updateFolderTechnicalAdvice,
  deleteFolderTechnicalAdvice,
} from "../../requests/folder";

import AuthContext from "../../context/AuthContext";

export default class ModalTechnicalAdvice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      id: null,
      reference: "",
      brand: "",
      model: "",
      motor: "",
      date: "",
      text: "",
      editorState: "",
      filterReference: "",
      filterDate: "",
      filterBrand: "",
      filterModel: "",
      filterMotor: "",
      filter: false,
      selectedField: "",
      filteredList: [],
      activePage: 1,
      pageLength: 10,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    this.setState(
      {
        filterBrand:
          this.context.dataAdministrative.dataVehicleIdentification[0].brand,
        filterModel:
          this.context.dataAdministrative.dataVehicleIdentification[0].model,
        filterMotor:
          this.context.dataAdministrative.dataVehicleIdentification[0]
            .motorType,
      },
      () => this.filterTechnicalAdvices()
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && this.props.show !== prevProps.show) {
      this.setState(
        {
          filterBrand:
            this.context.dataAdministrative.dataVehicleIdentification[0].brand,
          filterModel:
            this.context.dataAdministrative.dataVehicleIdentification[0].model,
          filterMotor:
            this.context.dataAdministrative.dataVehicleIdentification[0]
              .motorType,
        },
        () => this.filterTechnicalAdvices()
      );
    } else if (
      JSON.stringify(prevProps.technicalAdvices) !==
      JSON.stringify(this.props.technicalAdvices)
    ) {
      this.filterTechnicalAdvices();
    }
  }

  insertFolderTechnicalAdvice = () => {
    if (
      this.state.reference &&
      this.state.date &&
      this.state.brand &&
      this.state.model
    ) {
      this.setState(
        {
          isLoad: true,
          reference: this.context.reference,
        },
        () => {
          insertFolderTechnicalAdvice(
            {
              folder: this.context.folderId,
              reference: this.state.reference,
              date: this.state.date,
              brand: this.state.brand,
              model: this.state.model,
              motor: this.state.motor,
              text: this.state.editorState,
            },
            (result) => {
              if (result.success) {
                this.setState(
                  {
                    isLoad: false,
                    reference: this.context.reference,
                    date: moment().format("YYYY-MM-DD"),
                    brand: "",
                    model: "",
                    motor: "",
                    editorState: "",
                  },
                  () => {}
                );
              } else {
                this.setState({ isLoad: false }, () => {
                  window.alert("Une erreur est survenue");
                });
              }
            }
          );
        }
      );
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  updateFolderTechnicalAdvice = () => {
    if (
      this.state.reference &&
      this.state.date &&
      this.state.brand &&
      this.state.model
    ) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          updateFolderTechnicalAdvice(
            {
              folder: this.context.folderId,
              id: this.state.id,
              reference: this.state.reference,
              date: this.state.date,
              brand: this.state.brand,
              model: this.state.model,
              motor: this.state.motor,
              text: this.state.editorState,
            },
            (result) => {
              if (result.success) {
                this.setState(
                  {
                    isLoad: false,
                    folder: this.context.folderId,
                    id: null,
                    reference: this.context.reference,
                    date: "",
                    brand: "",
                    model: "",
                    motor: "",
                    text: "",
                  },
                  () => {}
                );
              } else {
                this.setState({ isLoad: false }, () => {
                  window.alert("Une erreur est survenue");
                });
              }
            }
          );
        }
      );
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  deleteFolderTechnicalAdvice = (technicalAdvice) => {
    window.confirmCustom(
      "Êtes-vous sûr de vouloir supprimer cet avis technique ?",
      (result) => {
        if (result) {
          this.setState(
            {
              isLoad: true,
            },
            () => {
              deleteFolderTechnicalAdvice(
                {
                  technicalAdvice,
                },
                (result) => {
                  if (result) {
                    this.setState({ isLoad: false }, () =>
                      this.props.getTechnicalAdvice()
                    );
                  } else {
                    this.setState({ isLoad: false }, () => {
                      window.alert("Une erreur est survenue");
                    });
                  }
                }
              );
            }
          );
        }
      }
    );
  };

  filterTechnicalAdvices = () => {
    let items = this.props.technicalAdvices;

    const {
      filterBrand,
      filterReference,
      filterDate,
      filterModel,
      filterMotor,
    } = this.state;

    const filteredData =
      filterReference || filterDate || filterBrand || filterModel || filterMotor
        ? items.filter(
            (item) =>
              (!filterReference ||
                item.reference
                  .toLowerCase()
                  .includes(filterReference.toLowerCase())) &&
              (!filterDate ||
                item.date
                  .toLowerCase()
                  .includes(moment(filterDate).format("DD/MM/YYYY"))) &&
              (!filterBrand ||
                item.brand.toLowerCase().includes(filterBrand.toLowerCase())) &&
              (!filterModel ||
                item.model.toLowerCase().includes(filterModel.toLowerCase())) &&
              (!filterMotor ||
                item.motor.toLowerCase().includes(filterMotor.toLowerCase()))
          )
        : items;

    this.setState({ filteredList: filteredData, filter: true });
  };

  render() {
    return (
      <>
        <FullModal
          show={this.props.show}
          onClose={() => {
            this.setState(
              {
                isLoad: false,
                id: null,
                reference: this.context.reference,
                brand: "",
                model: "",
                motor: "",
                date: "",
                text: "",
                filter: "",
                selectedField: "",
                filteredList: [],
              },
              this.props.onClose
            );
          }}
          noPadding
        >
          <Row className="h-100 pl-10 pr-10">
            {!this.props.modalTechnicalAdviceIsReport ? (
              <Col
                xs={12}
                md={3}
                className="h-100 d-flex flex-column bg-blue-extra-light pt-20 pb-20 overflow-y-auto"
              >
                <h2 className="mb-20 mt-0">Ecrire un avis technique</h2>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormInput
                      name="reference"
                      value={this.state.reference}
                      className="mb-10"
                      title="Numéro du dossier"
                      onChange={this.handleInputChange}
                      required
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormInput
                      name="date"
                      value={this.state.date}
                      className="mb-10"
                      type="date"
                      title="Date de l'avis"
                      onChange={this.handleInputChange}
                      required
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormInput
                      name="brand"
                      value={this.state.brand}
                      className="mb-10"
                      title="Marque du véhicule"
                      onChange={this.handleInputChange}
                      required
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormInput
                      name="model"
                      value={this.state.model}
                      className="mb-10"
                      title="Modèle du véhicule"
                      onChange={this.handleInputChange}
                      required
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormInput
                      name="motor"
                      value={this.state.motor}
                      className="mb-10"
                      title="Type de moteur"
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col xs={12} className="h-100">
                    <span
                      style={{
                        display: "flex",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginBottom: "8px",
                      }}
                    >
                      Avis technique
                    </span>
                    <Lexical
                      value={this.state.editorState}
                      onChange={(editorState) => this.setState({ editorState })}
                    />
                  </Col>
                </Row>
                <div className="d-flex mt-auto">
                  <Button
                    className="w-auto ml-auto"
                    text={this.state.id ? "Mettre à jour" : "Valider"}
                    onClick={
                      this.state.id
                        ? this.updateFolderTechnicalAdvice
                        : this.insertFolderTechnicalAdvice
                    }
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                </div>
              </Col>
            ) : null}
            <Col
              xs={12}
              md={this.props.modalTechnicalAdviceIsReport ? 12 : 9}
              className="pt-20 pb-20 h-100 overflow-y-auto"
            >
              <h2 className="mb-20 mt-0">Historique des avis techniques</h2>
              <Row>
                <Col md={4} lg={2}>
                  <FormInput
                    name="filterReference"
                    value={this.state.filterReference}
                    className="mb-10"
                    title="Numéro du dossier"
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={4} lg={2}>
                  <FormInput
                    name="filterBrand"
                    value={this.state.filterBrand}
                    className="mb-10"
                    title="Marque"
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={4} lg={2}>
                  <FormInput
                    name="filterModel"
                    value={this.state.filterModel}
                    className="mb-10"
                    title="Modèle"
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={4} lg={2}>
                  <FormInput
                    name="filterMotor"
                    value={this.state.filterMotor}
                    className="mb-10"
                    title="Type de moteur"
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={4} lg={2} className="d-flex">
                  <Button
                    className="mt-auto mb-10"
                    text="Rechercher"
                    onClick={(e) => {
                      e.preventDefault();
                      this.filterTechnicalAdvices();
                    }}
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </Button>
                </Col>
              </Row>
              <Table>
                <Thead>
                  <Tr>
                    <Th className="pl-10 pr-10">Référence</Th>
                    <Th className="pl-10 pr-10">Date</Th>
                    <Th className="pl-10 pr-10">Marque</Th>
                    <Th className="pl-10 pr-10">Modèle</Th>
                    <Th className="pl-10 pr-10">Type de moteur</Th>
                    <Th className="pl-10 pr-10">Avis</Th>
                    <Th className="pl-10 pr-10">Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {(this.state.filter
                    ? this.state.filteredList
                    : this.props.technicalAdvices
                  )
                    .slice(
                      (this.state.activePage - 1) * this.state.pageLength,
                      this.state.activePage * this.state.pageLength
                    )
                    .map((element, idx) => (
                      <Tr key={idx}>
                        <Td className="pl-10 pr-10">{element.reference}</Td>
                        <Td className="pl-10 pr-10">{element.date}</Td>
                        <Td className="pl-10 pr-10">{element.brand}</Td>
                        <Td className="pl-10 pr-10">{element.model}</Td>
                        <Td className="pl-10 pr-10">{element.motor}</Td>
                        <Td className="pl-10 pr-10">
                          <p
                            dangerouslySetInnerHTML={{ __html: element.text }}
                          />
                        </Td>
                        <Td className="ml-10 mr-10 p-0 m-0">
                          <div className="m-auto d-flex flex-column">
                            {!this.props.modalTechnicalAdviceIsReport ? (
                              <ButtonIcon
                                className="blue"
                                small
                                onClick={() =>
                                  this.setState({
                                    id: element.id,
                                    reference: element.reference,
                                    date: moment(
                                      element.date,
                                      "DD/MM/YYYY"
                                    ).format("YYYY-MM-DD"),
                                    brand: element.brand,
                                    model: element.model,
                                    motor: element.motor,
                                    editorState: element.text,
                                  })
                                }
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </ButtonIcon>
                            ) : null}
                            {this.props.modalTechnicalAdviceIsReport ? (
                              <ButtonIcon
                                className="blue"
                                small
                                onClick={() => {
                                  this.props.modalTechnicalAdviceCallback(
                                    element.text
                                  );
                                  this.props.onClose();
                                }}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </ButtonIcon>
                            ) : null}
                            {!this.props.modalTechnicalAdviceIsReport ? (
                              <ButtonIcon
                                className="red"
                                small
                                onClick={() => {
                                  this.deleteFolderTechnicalAdvice(element.id);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </ButtonIcon>
                            ) : null}
                          </div>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.pageLength}
                totalItemsCount={
                  (this.state.filter
                    ? this.state.filteredList
                    : this.props.technicalAdvices
                  ).length
                }
                pageRangeDisplayed={4}
                onChange={(activePage) => {
                  this.setState({ activePage: activePage });
                }}
                firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
                lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
                prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
                nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
              />
            </Col>
          </Row>
        </FullModal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
