import React from "react";

import CardEditor from "../CardEditor";
import { Button, FormTextarea, Switch } from "ui-kit-ck-consultant";

import AuthContext from "../../../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default class Conclusion extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "Conclusion";
  }

  static contextType = AuthContext;

  componentDidMount() {
    let tmpData = this.props.data;
    if (tmpData.isLimit !== this.props.isLimit) {
      tmpData.isLimit = this.props.isLimit;
      this.setData(tmpData);
    }
  }

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("conclusion", tmpData);
    } else {
      this.context.updateDataReport("conclusion", tmpData);
    }
  };

  render() {
    const defaultData = `<p>Résumé succint des responsabilités et la situation de l’affaire au jour du dépôt 

    Le véhicule présente des dommages au niveau de					 caractérisés par 
    Le réparateur à commis une faute
    
    Comme décrit ci-dessus, la cause de l’avarie est consécutive à
    
    Il existe un lien de causalité entre l’intervention du réparateur et les dommages relevés contradictoirement.
    
    Vu l’ensemble des constatations réalisées contradictoirement sur le véhicule,
    Vu les caractéristiques des dommages,
    
    Nous estimons que
    
    Le véhicule a été réparé. Les travaux ont été pris en charge par
    Le véhicule est économiquement non réparable, il a été cédé en l’état par son propriétaire 
    </p>`;

    return (
      <CardEditor
        {...this.props}
        name={this.displayName}
        title="Conclusion"
        dataName="conclusion"
        before={
          this.props.isLimit ? null : (
            <>
              <div className="d-flex">
                <Button
                  text={
                    this.props.data[`hide_amicable`]
                      ? "Afficher l'accord amiable"
                      : "Masquer l'accord amiable"
                  }
                  className="w-auto mb-10"
                  onClick={() => {
                    let tmpData = this.props.data;
                    tmpData[`hide_amicable`] = tmpData[`hide_amicable`]
                      ? false
                      : true;
                    this.setData(tmpData);
                  }}
                >
                  <FontAwesomeIcon
                    icon={this.props.data[`hide_amicable`] ? faEye : faEyeSlash}
                  />
                </Button>
              </div>
              {this.props.data[`hide_amicable`] ? null : (
                <>
                  <div className="mb-20 d-flex">
                    <p className="mt-auto mb-auto mr-10 nowrap">
                      Avez-vous trouvé un accord amiable ?
                    </p>
                    <Switch
                      className="mt-auto mb-auto"
                      value1="Non"
                      value2="Oui"
                      color1="#f2711c"
                      color2="#026fc2"
                      idxActive={this.props.data["switch_amicable"] || 0}
                      onChange={(type) => {
                        let tmpData = this.props.data;
                        tmpData[`switch_amicable`] = type;
                        this.setData(tmpData);
                      }}
                    />
                  </div>
                  {this.props.data["switch_amicable"] ? (
                    <div className="mb-20 d-flex">
                      <p className="mt-auto mb-auto mr-10 nowrap">
                        Avez-vous signé un protocole d'accord transactionnel ?
                      </p>
                      <Switch
                        className="mt-auto mb-auto"
                        value1="Non"
                        value2="Oui"
                        color1="#f2711c"
                        color2="#026fc2"
                        idxActive={this.props.data["switch_deal"] || 0}
                        onChange={(type) => {
                          let tmpData = this.props.data;
                          tmpData[`switch_deal`] = type;
                          this.setData(tmpData);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="mb-20 d-flex">
                      <FormTextarea
                        value={this.props.data[`text_amicable`] || ""}
                        title="Pourquoi l'accord n'a pas été trouvé ?"
                        onChange={(e) => {
                          let tmpData = this.props.data;
                          tmpData["text_amicable"] = e.target.value;

                          this.setData(tmpData);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )
        }
        subDataName="text"
        default={defaultData}
      />
    );
  }
}
