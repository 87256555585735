import React from "react";
import { FormSelect, ButtonIcon } from "ui-kit-ck-consultant";
import { Col } from "react-flexbox-grid";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AuthContext from "../../../context/AuthContext";

export default class PartyTop extends React.Component {
  static contextType = AuthContext;

  render() {
    const parties = this.context.parties;
    const token = this.props.parties[this.props.idx].token;

    let party = {};
    if (token && parties[token]) {
      party = parties[token];
    }

    return (
      <Col xs={12} md={6} lg={4}>
        <div className="d-flex">
          <p>
            <b>{party.party}</b>
          </p>
          <ButtonIcon
            className="red ml-auto"
            small
            onClick={() => {
              window.confirmCustom(
                "Êtes-vous sûr de vouloir supprime cette partie ?",
                (result) => {
                  if (result) {
                    this.props.onClickDeleteParty();
                  }
                }
              );
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonIcon>
        </div>
        <FormSelect
          className="mb-10"
          value={token}
          ignore={true}
          options={[
            {
              text: "Veuillez sélectionner une partie",
              value: "DEFAULT",
              disabled: true,
            },
            ...this.context.partiesOptions,
          ]}
          onChange={(e) => {
            this.props.onChange(e.target.value);
          }}
        />
        <p className="mt-10 mb-10">
          E-mail : <b>{party.email}</b>
          {party.society ? (
            <>
              <br />
              Société : <b>{party.society}</b>
            </>
          ) : null}
          <br />
          Téléphone fixe : <b>{party.phone}</b>
          <br />
          Téléphone portable : <b>{party.cellphone}</b>
          <br />
          Adresse : <b>{party.address}</b>
          <br />
          {party.address2 ? (
            <>
              Adresse 2 : <b>{party.address2}</b>
            </>
          ) : null}
          <br />
          Code postal : <b>{party.zipCode}</b>
          <br />
          Ville : <b>{party.city}</b>
        </p>
      </Col>
    );
  }
}
