import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSync,
  faExclamation,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

export default class Save extends React.Component {
  checkStatusSave = () => {
    if (this.props.save === 1) {
      return (
        <div
          className={"m-auto ml-10 d-flex orange bg-orange-light"}
          style={{
            transition: "1s",
            padding: "0.5rem",
            borderRadius: "10px",
          }}
        >
          <FontAwesomeIcon
            icon={faSync}
            style={{ fontSize: "16px" }}
            className="rotate"
          />
          <span className="pl-10 m-auto nowrap" style={{ fontSize: "12px" }}>
            En cours de sauvegarde
          </span>
        </div>
      );
    } else if (this.props.save === 2) {
      return (
        <div
          className={"m-auto ml-10 d-flex orange bg-red-light"}
          style={{
            transition: "1s",
            padding: "0.5rem",
            borderRadius: "10px",
          }}
        >
          <FontAwesomeIcon
            icon={faSync}
            style={{ fontSize: "16px" }}
            className="rotate"
          />
          <span className="pl-10 m-auto nowrap" style={{ fontSize: "12px" }}>
            En cours de sauvegarde 
          </span>
        </div>
      );
    } else if (this.props.save === 3) {
      return (
        <div
          className={"m-auto ml-10 d-flex green bg-green-light"}
          style={{
            transition: "1s",
            padding: "0.5rem",
            borderRadius: "10px",
          }}
        >
          <FontAwesomeIcon icon={faCheck} style={{ fontSize: "16px" }} />
          <span className="pl-10 m-auto nowrap" style={{ fontSize: "12px" }}>
            Sauvegardé
          </span>
        </div>
      );
    } else if (this.props.save === 4) {
      return (
        <div
          className={"m-auto ml-10 d-flex red bg-red-light"}
          style={{
            transition: "1s",
            padding: "0.5rem",
            borderRadius: "10px",
          }}
        >
          <FontAwesomeIcon icon={faExclamation} style={{ fontSize: "16px" }} />
          <span className="pl-10 m-auto nowrap" style={{ fontSize: "12px" }}>
            Erreur
          </span>
        </div>
      );
    } else {
      return (
        <div
          className={"m-auto ml-10 d-flex blue bg-blue-light"}
          style={{
            transition: "1s",
            padding: "0.5rem",
            borderRadius: "10px",
          }}
        >
          <FontAwesomeIcon icon={faCheck} style={{ fontSize: "16px" }} />
          <span className="pl-10 m-auto nowrap" style={{ fontSize: "12px" }}>
            Non modifié
          </span>
        </div>
      );
    }
  };

  render() {
    return this.checkStatusSave();
  }
}
