import React from "react";
import { Row, Col } from "react-flexbox-grid";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  FormInput,
  FormCheckbox,
  FormTextarea,
  Switch,
  FormSelect,
} from "ui-kit-ck-consultant";
import {
  faPlus,
  faTrash,
  faCaretUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DefaultDamageAssessment from "./DefaultDamageAssessment";

import AuthContext from "../../../../context/AuthContext";

import { getPrice } from "../../../../utils/general";

export default class RCdamageAssessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      tabsId: 0,
    };
    this.displayName = "RCdamageAssessment";
  }

  static contextType = AuthContext;

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    let tmpData = this.props.data;
    tmpData[this.state.tabsId][name] = checked;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("RCdamageAssessment", tmpData);
    } else if (this.context.updateDataReport) {
      this.context.updateDataReport("RCdamageAssessment", tmpData);
    }

    let tmpDataEvaluation = this.props.dataEvaluation;
    tmpDataEvaluation["RCdamageAssessment"] = tmpData;
    this.context.updateDataFolder("dataEvaluation", tmpDataEvaluation);
  };

  handleInputChange = (type, name, value, idx = null, isForce = true) => {
    let tmpData = this.props.data;

    if (!tmpData[this.state.tabsId].hasOwnProperty(type)) {
      tmpData[this.state.tabsId][type] = {};
    }

    if (isForce && typeof value === "string") {
      value = value.replace(",", ".");
    }

    if (type) {
      if (idx !== null) {
        tmpData[this.state.tabsId][type][idx][name] = value;
      } else {
        tmpData[this.state.tabsId][type][name] = value;
      }
    } else {
      tmpData[this.state.tabsId][name] = value;
    }
    this.setData(tmpData);
  };

  onAddLineClick = (type) => {
    let tmpData = this.props.data;

    if (!tmpData[this.state.tabsId].hasOwnProperty(type)) {
      tmpData[this.state.tabsId][type] = [];
    }

    tmpData[this.state.tabsId][type].push({ price: 0, quantity: 1, vat: 20 });
    this.setData(tmpData);
  };

  getVat = (price, vat = 0) => {
    let priceVat = 0;

    if (price) {
      priceVat = (price * vat) / 100;
    }
    return priceVat;
  };

  getValue = (quantity = 0, price, dilapidated = 0, discount = 0, vat = 0) => {
    let total = 0;

    let priceDilapidated = 0;
    let priceDiscount = 0;
    let priceVat = 0;

    if (price) {
      price = price * quantity;

      if (dilapidated) {
        priceDilapidated = (price * dilapidated) / 100;
      }

      if (discount) {
        priceDiscount = (price * discount) / 100;
      }

      total = price - priceDilapidated - priceDiscount;

      if (vat) {
        priceVat = (total * vat) / 100;
      }
    }

    return total + priceVat;
  };

  getTotalValue = (data, isVat = false) => {
    let total = 0;
    if (data) {
      data.forEach((element) => {
        total += this.getValue(
          element.quantity,
          element.price,
          element.dilapidated,
          element.discount,
          isVat ? element.vat : 0
        );
      });
    }
    return total;
  };

  changePosition = (key, idx) => {
    let tmpData = this.props.data;
    let currentData = tmpData[this.state.tabsId] || {};

    return (
      <Td>
        <div
          className="d-flex"
          style={{
            flexDirection: "column",
          }}
        >
          {idx !== 0 ? (
            <ButtonIcon
              small
              className="blue m-auto mb-0 p-0"
              onClick={() => {
                let tmpCurrentData = currentData;

                const oldLine = tmpCurrentData[key][idx - 1];

                tmpCurrentData[key][idx - 1] = tmpCurrentData[key][idx];

                tmpCurrentData[key][idx] = oldLine;

                this.setData(tmpCurrentData);
              }}
            >
              <FontAwesomeIcon icon={faCaretUp} />
            </ButtonIcon>
          ) : null}
          {idx !== currentData[key].length - 1 ? (
            <ButtonIcon
              small
              className="blue m-auto mt-0 p-0"
              onClick={() => {
                let tmpCurrentData = currentData;

                const oldLine = tmpCurrentData[key][idx + 1];

                tmpCurrentData[key][idx + 1] = tmpCurrentData[key][idx];

                tmpCurrentData[key][idx] = oldLine;

                this.setData(tmpCurrentData);
              }}
            >
              <FontAwesomeIcon icon={faCaretDown} />
            </ButtonIcon>
          ) : null}
        </div>
      </Td>
    );
  };

  getData = () => {
    let tmpData = this.props.data;
    let currentData = tmpData[this.state.tabsId] || {};

    //complexe
    const tmpDataPieces = currentData.pieces ? currentData.pieces : [];
    const tmpDataHours = currentData.hours ? currentData.hours : [];
    const tmpDataGeneral = currentData.general || {};

    let { pourcRemisePiece, pourcRemiseMo, discount } = tmpDataGeneral;
    pourcRemisePiece = pourcRemisePiece || 0;
    pourcRemiseMo = pourcRemiseMo || 0;
    discount = discount || 0;

    const totalPieces = this.getTotalValue(tmpDataPieces);
    const totalPiecesVAT = this.getTotalValue(tmpDataPieces, true);
    const totalPiecesHT = totalPieces - totalPieces * (pourcRemisePiece / 100);
    const totalPiecesWithDiscount =
      totalPiecesVAT - totalPiecesVAT * (pourcRemisePiece / 100);

    const totalHours = this.getTotalValue(tmpDataHours);
    const totalHoursVAT = this.getTotalValue(tmpDataHours, true);
    const totalHoursHT = totalHours - totalHours * (pourcRemiseMo / 100);
    const totalMoWithDiscount =
      totalHoursVAT - totalHoursVAT * (pourcRemiseMo / 100);
    const totalBeforeDiscount = totalPiecesHT + totalHoursHT;

    const totalDiscountTTC = totalPiecesWithDiscount + totalMoWithDiscount;

    const totalDiscountHT =
      (totalBeforeDiscount -
        (totalBeforeDiscount -
          totalBeforeDiscount * (parseInt(discount) / 100))) *
      -1;

    const discountTTC =
      totalDiscountTTC - totalDiscountTTC * (parseInt(discount) / 100);

    const discountTva =
      (totalDiscountTTC -
        discountTTC -
        (totalBeforeDiscount -
          (totalBeforeDiscount -
            totalBeforeDiscount * (parseInt(discount) / 100)))) *
      -1;

    const totalTVA =
      totalMoWithDiscount -
      totalHoursHT +
      (totalMoWithDiscount - totalHoursHT) +
      discountTva;

    const totalHT = totalPiecesHT + totalHoursHT + totalDiscountHT;

    return (
      <>
        {!this.props.isLight ? (
          <>
            <Row className="mb-20">
              <Col xs={12}>
                <h3>Evaluation fondée sur devis/facture communiqué par :</h3>
                <FormCheckbox
                  className="mb-10"
                  text="le tiers lésé"
                  name="q1"
                  checked={currentData.q1}
                  onChange={this.handleCheckboxChange}
                />
              </Col>
              <Col xs={12}>
                <FormCheckbox
                  className="mb-10"
                  text="l'assuré"
                  name="q2"
                  checked={currentData.q2}
                  onChange={this.handleCheckboxChange}
                />
              </Col>
              <Col xs={12}>
                <FormCheckbox
                  className="mb-10"
                  text="Autre préciser ses coordonées :"
                  name="q3"
                  checked={currentData.q3}
                  onChange={this.handleCheckboxChange}
                />
              </Col>
              {currentData && currentData.q3 ? (
                <Col xs={4}>
                  <FormInput
                    className="mb-10"
                    title="Coordonnées:"
                    name="otherCoordinates"
                    value={currentData.otherCoordinates}
                    onChange={({ target }) => {
                      this.handleInputChange(
                        null,
                        "otherCoordinates",
                        target.value
                      );
                    }}
                  />
                </Col>
              ) : null}
            </Row>
            <Row className="mb-20">
              <Col xs={6}>
                <Switch
                  className="mb-10"
                  title="Si évaluation et remise en état effectuées par l'assuré, ce chiffrage est fondé sur les tarifs des fournisseurs de l'assuré"
                  value1="oui"
                  value2="non"
                  color1="#026fc2"
                  color2="#f2711c"
                  idxActive={
                    currentData.hasOwnProperty("switchDamageAssessment")
                      ? currentData["switchDamageAssessment"]
                      : 0
                  }
                  onChange={(type) => {
                    let tmpData = currentData;
                    tmpData["switchDamageAssessment"] = type;
                    this.setData(tmpData);
                  }}
                />
                {currentData.switchDamageAssessment === 1 ? (
                  <>
                    <FormInput
                      className="mb-10"
                      title="Si non, pourquoi ? :"
                      name="ifNoWhy"
                      value={currentData.ifNoWhy}
                      onChange={({ target }) => {
                        this.handleInputChange(null, "ifNoWhy", target.value);
                      }}
                    />
                    <FormInput
                      type="number"
                      className="mb-10"
                      title="A défaut, quel est l'abattement forfaitaire à retenir ?"
                      name="flatRateAllowance"
                      value={currentData.flatRateAllowance}
                      onChange={({ target }) => {
                        this.handleInputChange(
                          null,
                          "flatRateAllowance",
                          target.value
                        );
                      }}
                    />
                  </>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <FormInput
                  className="mb-20"
                  type="number"
                  title="Valeur du véhicule avant sinistre :"
                  name="valueBeforeSinister"
                  value={currentData.valueBeforeSinister}
                  onChange={({ target }) => {
                    this.handleInputChange(
                      null,
                      "valueBeforeSinister",
                      target.value
                    );
                  }}
                >
                  €
                </FormInput>
              </Col>
              <Col xs={12} lg={4}>
                <FormInput
                  className="mb-20"
                  type="number"
                  title="Valeur du véhicule après sinistre :"
                  name="valueAfterSinister"
                  value={currentData.valueAfterSinister}
                  onChange={({ target }) => {
                    this.handleInputChange(
                      null,
                      "valueAfterSinister",
                      target.value
                    );
                  }}
                >
                  €
                </FormInput>
              </Col>
              <Col xs={12}>
                <FormTextarea
                  className="mb-20"
                  title="Observations"
                  name="textBeforeSinister"
                  value={currentData.textBeforeSinister}
                  onChange={({ target }) => {
                    this.handleInputChange(
                      null,
                      "textBeforeSinister",
                      target.value,
                      null,
                      false
                    );
                  }}
                />
              </Col>
            </Row>
          </>
        ) : null}

        {!currentData.isHide ? (
          <>
            <Row>
              <Col xs={12} md={6}>
                <Switch
                  className="mb-10"
                  title="Affichage du chiffrage de l'ensemble des travaux à réaliser :"
                  value1="Simple"
                  value2="Complexe"
                  color1="#026fc2"
                  color2="#f2711c"
                  idxActive={
                    currentData.hasOwnProperty("switchDisplayArray")
                      ? currentData["switchDisplayArray"]
                      : 0
                  }
                  onChange={(type) => {
                    let tmpData = this.props.data;
                    tmpData[this.state.tabsId]["switchDisplayArray"] = type;
                    this.setData(tmpData);
                  }}
                />
              </Col>
            </Row>
            {currentData.switchDisplayArray === 1 ? (
              <>
                <Table className="mb-20">
                  <Thead>
                    <Tr>
                      <Th colSpan="13">Détail des pièces</Th>
                    </Tr>
                    <Tr>
                      <Th></Th>
                      <Th className="nowrap p-10">Nbe</Th>
                      <Th className="nowrap p-10">Libellé</Th>
                      <Th className="nowrap p-10">Réf. constructeur</Th>
                      <Th className="nowrap p-10">Opération</Th>
                      <Th className="nowrap p-10">P</Th>
                      <Th className="nowrap p-10">Prix unit HT</Th>
                      <Th className="nowrap p-10">% vétusté</Th>
                      <Th className="nowrap p-10">% remise</Th>
                      <Th className="nowrap p-10">% TVA</Th>
                      <Th className="nowrap p-10">Montant HT</Th>
                      <Th className="nowrap p-10">Montant TTC</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tmpDataPieces.map((element, idx) => (
                      <Tr key={idx}>
                        {this.changePosition("pieces", idx)}
                        <Td className="p-10">
                          <FormInput
                            type="number"
                            styleInput={{
                              border: "none",
                              padding: "0 10px",
                              fontSize: "13px",
                              height: "36px",
                            }}
                            style={{
                              width: "50px",
                            }}
                            value={element["quantity"]}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "pieces",
                                "quantity",
                                target.value,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Td className="p-10 w-100">
                          <FormInput
                            styleInput={{
                              border: "none",
                              padding: "0 10px",
                              fontSize: "13px",
                              height: "36px",
                            }}
                            value={element["name"]}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "pieces",
                                "name",
                                target.value,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Td className="p-10">
                          <FormInput
                            styleInput={{
                              border: "none",
                              padding: "0 10px",
                              fontSize: "13px",
                              height: "36px",
                            }}
                            value={element["reference"]}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "pieces",
                                "reference",
                                target.value,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Td className="p-10">
                          <FormSelect
                            ignore={true}
                            options={[
                              {
                                text: "",
                                value: "DEFAULT",
                                disabled: true,
                              },
                              { text: "E", value: "E" },
                              { text: "R", value: "R" },
                              { text: "RP", value: "RP" },
                              { text: "PP", value: "PP" },
                              { text: "S", value: "S" },
                              { text: "EP", value: "EP" },
                              { text: "L", value: "L" },
                              { text: "C", value: "C" },
                              { text: "R", value: "R" },
                              { text: "N", value: "N" },
                              { text: "RM", value: "RM" },
                              { text: "EM", value: "EM" },
                              { text: "CB", value: "CB" },
                              { text: "D", value: "D" },
                              { text: "A", value: "A" },
                            ]}
                            styleInput={{
                              border: "none",
                              padding: "0 10px",
                              fontSize: "13px",
                              height: "36px",
                            }}
                            style={{
                              width: "120px",
                            }}
                            value={element["operation"]}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "pieces",
                                "operation",
                                target.value,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Td className="p-10 text-center">
                          <FormCheckbox
                            checked={element.painted}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "pieces",
                                "painted",
                                target.checked,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Td className="p-10">
                          <FormInput
                            type="number"
                            styleInput={{
                              border: "none",
                              padding: "0 10px",
                              fontSize: "13px",
                              height: "36px",
                            }}
                            style={{
                              width: "120px",
                            }}
                            value={element["price"]}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "pieces",
                                "price",
                                target.value,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Td className="p-10">
                          <FormInput
                            type="number"
                            styleInput={{
                              border: "none",
                              padding: "0 10px",
                              width: "56px",
                              fontSize: "13px",
                              height: "36px",
                            }}
                            value={element["dilapidated"]}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "pieces",
                                "dilapidated",
                                target.value,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Td className="p-10">
                          <FormInput
                            type="number"
                            styleInput={{
                              border: "none",
                              padding: "0 10px",
                              width: "56px",
                              fontSize: "13px",
                              height: "36px",
                            }}
                            value={element["discount"]}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "pieces",
                                "discount",
                                target.value,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Td className="p-10">
                          <FormInput
                            type="number"
                            styleInput={{
                              border: "none",
                              padding: "0 10px",
                              width: "56px",
                              fontSize: "13px",
                              height: "36px",
                            }}
                            value={element["vat"]}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "pieces",
                                "vat",
                                target.value,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Th className="p-10">
                          {getPrice(
                            this.getValue(
                              element.quantity,
                              element.price,
                              element.dilapidated,
                              element.discount
                            )
                          )}
                        </Th>
                        <Th className="p-10">
                          {getPrice(
                            this.getValue(
                              element.quantity,
                              element.price,
                              element.dilapidated,
                              element.discount,
                              element.vat
                            )
                          )}
                        </Th>
                        <Td className="text-center">
                          <ButtonIcon className="red" small>
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => {
                                window.confirmCustom(
                                  "Etes vous sûre de vouloir supprimer la ligne?",
                                  (result) => {
                                    if (result) {
                                      let tmpData = this.props.data;
                                      tmpData[this.state.tabsId].pieces.splice(
                                        idx,
                                        1
                                      );
                                      this.setData(tmpData);
                                    }
                                  }
                                );
                              }}
                            />
                          </ButtonIcon>
                        </Td>
                      </Tr>
                    ))}
                    <Tr>
                      <Td colSpan="10" hide></Td>
                      <Th radiusBottomLeft>{getPrice(totalPieces)}</Th>
                      <Th>{getPrice(totalPiecesVAT)}</Th>
                      <Th>
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={(e) => {
                            e.preventDefault();
                            this.onAddLineClick("pieces");
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </ButtonIcon>
                      </Th>
                    </Tr>
                  </Tbody>
                </Table>
                {/* // ---- HOURS ---- \\ */}
                <Table className="mb-20">
                  <Thead>
                    <Tr>
                      <Th colSpan="9">Détail des opérations</Th>
                    </Tr>
                    <Tr>
                      <Th></Th>
                      <Th>Type</Th>
                      <Th>Sous-type</Th>
                      <Th>Heure</Th>
                      <Th>Tarif horaire</Th>
                      <Th>% TVA</Th>
                      <Th>Montant HT</Th>
                      <Th>Montant TTC</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tmpDataHours.map((element, idx) => (
                      <Tr key={idx}>
                        {this.changePosition("hours", idx)}
                        <Td>
                          <FormSelect
                            ignore={true}
                            options={[
                              {
                                text: "",
                                value: "DEFAULT",
                                disabled: true,
                              },
                              { text: "T1", value: "T1" },
                              { text: "T2", value: "T2" },
                              { text: "T3", value: "T3" },
                              { text: "M1", value: "M1" },
                              { text: "M2", value: "M2" },
                              { text: "M3", value: "M3" },
                              { text: "P1", value: "P1" },
                              { text: "P2", value: "P2" },
                              { text: "DSP", value: "DSP" },
                              { text: "Ing. 1", value: "Ing. 1" },
                              { text: "Ing. 2", value: "Ing. 2" },
                            ]}
                            styleInput={{
                              border: "none",
                              fontSize: "13px",
                              height: "36px",
                              width: "80px",
                            }}
                            value={element["type"]}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "hours",
                                "type",
                                target.value,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Td>
                          {element["type"] &&
                          element["type"].includes("Ing.") ? (
                            <FormSelect
                              ignore={true}
                              options={[
                                {
                                  text: "",
                                  value: "DEFAULT",
                                  disabled: true,
                                },
                                { text: "Opaque", value: "Opaque" },
                                {
                                  text: "Opaque Vernis",
                                  value: "Opaque Vernis",
                                },
                                { text: "Métal", value: "Métal" },
                                { text: "Métal vernis", value: "Métal vernis" },
                                { text: "Nacré vernis", value: "Nacré vernis" },
                                { text: "Autre", value: "Autre" },
                              ]}
                              styleInput={{
                                border: "none",
                                fontSize: "13px",
                                height: "36px",
                                width: "120px",
                              }}
                              value={element["subType"]}
                              onChange={({ target }) =>
                                this.handleInputChange(
                                  "hours",
                                  "subType",
                                  target.value,
                                  idx
                                )
                              }
                            />
                          ) : null}
                        </Td>
                        <Td>
                          <FormInput
                            type="number"
                            styleInput={{
                              border: "none",
                              fontSize: "13px",
                              height: "36px",
                            }}
                            value={element["quantity"]}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "hours",
                                "quantity",
                                target.value,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Td>
                          <FormInput
                            type="number"
                            styleInput={{
                              border: "none",
                              fontSize: "13px",
                              height: "36px",
                            }}
                            value={element["price"]}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "hours",
                                "price",
                                target.value,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Td>
                          <FormInput
                            type="number"
                            styleInput={{
                              border: "none",
                              fontSize: "13px",
                              height: "36px",
                            }}
                            value={element["vat"]}
                            onChange={({ target }) =>
                              this.handleInputChange(
                                "hours",
                                "vat",
                                target.value,
                                idx
                              )
                            }
                          />
                        </Td>
                        <Th>
                          {getPrice(
                            this.getValue(element.quantity, element.price)
                          )}
                        </Th>
                        <Th>
                          {getPrice(
                            this.getValue(
                              element.quantity,
                              element.price,
                              0,
                              0,
                              element.vat
                            )
                          )}
                        </Th>
                        <Td className="text-center">
                          <ButtonIcon className="red" small>
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => {
                                window.confirmCustom(
                                  "Ëtes vous sûre de vouloir supprimer cette ligne ? ",
                                  (result) => {
                                    if (result) {
                                      let tmpData = this.props.data;
                                      tmpData[this.state.tabsId].hours.splice(
                                        idx,
                                        1
                                      );
                                      this.setData(tmpData);
                                    }
                                  }
                                );
                              }}
                            />
                          </ButtonIcon>
                        </Td>
                      </Tr>
                    ))}
                    <Tr>
                      <Td colSpan="6" hide></Td>
                      <Th radiusBottomLeft>{getPrice(totalHours)}</Th>
                      <Th>{getPrice(totalHoursVAT)}</Th>
                      <Th>
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={(e) => {
                            e.preventDefault();
                            this.onAddLineClick("hours");
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </ButtonIcon>
                      </Th>
                    </Tr>
                  </Tbody>
                </Table>
                {/* // ---- total complexe ---- \\ */}
                <Table className="mb-20">
                  <Thead>
                    <Tr>
                      <Th colSpan="6">Total général</Th>
                    </Tr>
                    <Tr>
                      <Th className="nowrap p-10">Libellé</Th>
                      <Th className="nowrap p-10">Montant HT avant remise</Th>
                      <Th className="nowrap p-10">% REMISE</Th>
                      <Th className="nowrap p-10">Montant HT</Th>
                      <Th className="nowrap p-10">Montant TVA</Th>
                      <Th className="nowrap p-10">Montant TTC</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td className="w-100">Pièces</Td>
                      <Td>{getPrice(totalPieces)}</Td>
                      <Td>
                        <FormInput
                          type="number"
                          styleInput={{
                            border: "none",
                            padding: "0 10px",
                            width: "56px",
                            fontSize: "13px",
                            height: "36px",
                          }}
                          value={pourcRemisePiece}
                          onChange={({ target }) =>
                            this.handleInputChange(
                              "general",
                              "pourcRemisePiece",
                              target.value
                            )
                          }
                        />
                      </Td>
                      <Td>{getPrice(totalPiecesHT)}</Td>
                      <Td>{getPrice(totalMoWithDiscount - totalHoursHT)}</Td>
                      <Td>{getPrice(totalPiecesWithDiscount)}</Td>
                    </Tr>
                    <Tr>
                      <Td className="w-100">Main d'œuvre </Td>
                      <Td>{getPrice(totalHours)}</Td>
                      <Td>
                        <FormInput
                          type="number"
                          styleInput={{
                            border: "none",
                            padding: "0 10px",
                            width: "56px",
                            fontSize: "13px",
                            height: "36px",
                          }}
                          value={pourcRemiseMo}
                          onChange={({ target }) =>
                            this.handleInputChange(
                              "general",
                              "pourcRemiseMo",
                              target.value
                            )
                          }
                        />
                      </Td>
                      <Td>{getPrice(totalHoursHT)}</Td>
                      <Td>{getPrice(totalMoWithDiscount - totalHoursHT)}</Td>
                      <Td>{getPrice(totalMoWithDiscount)}</Td>
                    </Tr>
                    <Tr>
                      <Td className="w-100">Remise/Total </Td>
                      <Td>{getPrice(totalPiecesHT + totalHoursHT)}</Td>
                      <Td>
                        <FormInput
                          type="number"
                          styleInput={{
                            border: "none",
                            padding: "0 10px",
                            width: "56px",
                            fontSize: "13px",
                            height: "36px",
                          }}
                          value={discount}
                          onChange={({ target }) =>
                            this.handleInputChange(
                              "general",
                              "discount",
                              target.value
                            )
                          }
                        />
                      </Td>
                      <Td>{getPrice(totalDiscountHT)}</Td>
                      <Td>{getPrice(discountTva)}</Td>
                      <Td>{getPrice((totalDiscountTTC - discountTTC) * -1)}</Td>
                    </Tr>
                    <Tr className="w-100">
                      <Th>Total</Th>
                      <Th></Th>
                      <Th></Th>
                      <Th>{getPrice(totalHT)}</Th>
                      <Th>{getPrice(totalTVA)}</Th>
                      <Th>{getPrice(discountTTC)}</Th>
                    </Tr>
                  </Tbody>
                </Table>
              </>
            ) : (
              this.getTable(
                "A. Ensemble des travaux à réaliser :",
                "piecesSimple"
              )
            )}
            {this.getTable("B. Cause ou mal façon :", "causeOrWrongWay")}
            {this.getTable(
              "C. Travaux à l'origine à refaire :",
              "originalWork"
            )}
            {this.getTable(
              "D. Travaux imputables à l'entretien :",
              "dueToMaintenance"
            )}

            {!this.props.isLight ? (
              <Col xs={4}>
                <FormInput
                  className="mb-20"
                  type="date"
                  title="Ce poste a fait l'objet d'un accord avec le tiers et son expert en date du :"
                  name="agreementDate"
                  value={currentData.agreementDate}
                  onChange={({ target }) => {
                    this.handleInputChange(null, "agreementDate", target.value);
                  }}
                />
              </Col>
            ) : null}

            <h3 className="mt-20">E. Conséquences dommageables :</h3>
            <Table>
              <Thead>
                <Tr>
                  <Th>Tableaux</Th>
                  <Th>Nom</Th>
                  <Th>Montant HT</Th>
                  <Th>Montant TVA</Th>
                  <Th>Montant TTC</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentData.switchDisplayArray === 1
                  ? this.getLineSummary(
                      "A",
                      "Ensemble des travaux à réaliser",
                      [],
                      "black",
                      totalHT,
                      discountTTC
                    )
                  : this.getLineSummary(
                      "A",
                      "Ensemble des travaux à réaliser",
                      ["piecesSimple"],
                      "black"
                    )}
                {this.getLineSummary("B", "Cause ou mal façon", [
                  "causeOrWrongWay",
                ])}
                {this.getLineSummary("C", "Travaux à l'origine à refaire", [
                  "originalWork",
                ])}
                {this.getLineSummary("D", "Travaux imputables à l'entretien", [
                  "dueToMaintenance",
                ])}
                {this.getLineSummary(
                  "E",
                  "Conséquences dommageables",
                  [
                    ...(currentData.switchDisplayArray === 1
                      ? []
                      : ["piecesSimple"]),
                  ],
                  "green",
                  currentData.switchDisplayArray === 1
                    ? totalHT -
                        this.getTotalValue(
                          this.props.data[this.state.tabsId]["causeOrWrongWay"]
                        ) -
                        this.getTotalValue(
                          this.props.data[this.state.tabsId]["originalWork"]
                        ) -
                        this.getTotalValue(
                          this.props.data[this.state.tabsId]["dueToMaintenance"]
                        )
                    : 0,
                  currentData.switchDisplayArray === 1
                    ? discountTTC -
                        this.getTotalValue(
                          this.props.data[this.state.tabsId]["causeOrWrongWay"],
                          true
                        ) -
                        this.getTotalValue(
                          this.props.data[this.state.tabsId]["originalWork"],
                          true
                        ) -
                        this.getTotalValue(
                          this.props.data[this.state.tabsId][
                            "dueToMaintenance"
                          ],
                          true
                        )
                    : 0
                )}
              </Tbody>
            </Table>
          </>
        ) : null}
      </>
    );
  };

  getTable = (title, name) => {
    let tmpData = this.props.data[this.state.tabsId][name];

    const price = this.getTotalValue(tmpData);
    const priceVat = this.getTotalValue(tmpData, true);

    return (
      <>
        <h3>{title}</h3>
        <Table className="mb-20">
          <Thead>
            <Tr>
              <Th></Th>
              <Th className="nowrap p-10">Désignation</Th>
              <Th className="nowrap p-10">% TVA</Th>
              <Th className="nowrap p-10">Montant HT</Th>
              <Th className="nowrap p-10">Montant TVA</Th>
              <Th className="nowrap p-10">Montant TTC</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {tmpData
              ? tmpData.map((element, idx) => (
                  <Tr key={idx}>
                    {this.changePosition(name, idx)}
                    <Td className="p-10 w-100">
                      <FormInput
                        styleInput={{
                          border: "none",
                          padding: "0 10px",
                          fontSize: "13px",
                          height: "36px",
                        }}
                        value={element["name"]}
                        onChange={({ target }) =>
                          this.handleInputChange(
                            name,
                            "name",
                            target.value,
                            idx
                          )
                        }
                      />
                    </Td>
                    <Td className="p-10">
                      <FormInput
                        type="number"
                        styleInput={{
                          border: "none",
                          padding: "0 10px",
                          fontSize: "13px",
                          height: "36px",
                        }}
                        style={{
                          width: "120px",
                        }}
                        value={element["vat"]}
                        onChange={({ target }) =>
                          this.handleInputChange(name, "vat", target.value, idx)
                        }
                      />
                    </Td>
                    <Td className="p-10">
                      <FormInput
                        type="number"
                        styleInput={{
                          border: "none",
                          padding: "0 10px",
                          fontSize: "13px",
                          height: "36px",
                        }}
                        style={{
                          width: "120px",
                        }}
                        value={element["price"]}
                        onChange={({ target }) =>
                          this.handleInputChange(
                            name,
                            "price",
                            target.value,
                            idx
                          )
                        }
                      />
                    </Td>
                    <Td className="p-10 text-center">
                      {getPrice(this.getVat(element.price, element.vat))}
                    </Td>
                    <Td className="p-10 text-center">
                      {getPrice(
                        this.getValue(1, element.price, 0, 0, element.vat)
                      )}
                    </Td>
                    <Td className="text-center">
                      <ButtonIcon className="red" small>
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => {
                            window.confirmCustom(
                              "Etes vous sûre de vouloir supprimer la ligne?",
                              (result) => {
                                if (result) {
                                  let tmpData = this.props.data;
                                  tmpData[this.state.tabsId][name].splice(
                                    idx,
                                    1
                                  );
                                  this.setData(tmpData);
                                }
                              }
                            );
                          }}
                        />
                      </ButtonIcon>
                    </Td>
                  </Tr>
                ))
              : null}
            <Tr>
              <Th colSpan="3" hide></Th>
              <Th radiusBottomLeft>{getPrice(price)}</Th>
              <Th>{getPrice(priceVat - price)}</Th>
              <Th>{getPrice(priceVat)}</Th>
              <Th>
                <ButtonIcon
                  small
                  className="blue"
                  onClick={(e) => {
                    e.preventDefault();
                    this.onAddLineClick(name);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </ButtonIcon>
              </Th>
            </Tr>
          </Tbody>
        </Table>
      </>
    );
  };

  getLineSummary = (
    letter,
    title,
    names,
    color = "red",
    forcePrice = 0,
    forcePriceVat = 0
  ) => {
    let price = forcePrice;
    let priceVat = forcePriceVat;
    const isSimpleE = letter === "E" && names.includes("piecesSimple");

    const toRemoveHT =
      (this.getTotalValue(
        this.props.data[this.state.tabsId]["causeOrWrongWay"]
      ) +
        this.getTotalValue(this.props.data[this.state.tabsId]["originalWork"]) +
        this.getTotalValue(
          this.props.data[this.state.tabsId]["dueToMaintenance"]
        )) *
      -1;

    const toRemoveTTC =
      (this.getTotalValue(
        this.props.data[this.state.tabsId]["causeOrWrongWay"],
        true
      ) +
        this.getTotalValue(
          this.props.data[this.state.tabsId]["originalWork"],
          true
        ) +
        this.getTotalValue(
          this.props.data[this.state.tabsId]["dueToMaintenance"],
          true
        )) *
      -1;

    names.forEach((name) => {
      let tmpData = this.props.data[this.state.tabsId][name];
      if (letter === "E" && name !== "piecesSimple") {
        price -= this.getTotalValue(tmpData);
        priceVat -= this.getTotalValue(tmpData, true);
      } else {
        price += this.getTotalValue(tmpData);
        priceVat += this.getTotalValue(tmpData, true);
      }
    });

    return (
      <Tr>
        <Td>{letter}</Td>
        <Td>{title}</Td>
        <Td className={`p-10 text-center ${color}`}>
          {isSimpleE ? getPrice(price + toRemoveHT) : getPrice(price)}
        </Td>
        <Td className={`p-10 text-center ${color}`}>
          {isSimpleE
            ? getPrice(priceVat + toRemoveTTC - (price + toRemoveHT))
            : getPrice(priceVat - price)}
        </Td>
        <Td className={`p-10 text-center ${color}`}>
          {isSimpleE ? getPrice(priceVat + toRemoveTTC) : getPrice(priceVat)}
        </Td>
      </Tr>
    );
  };

  render() {
    return (
      <>
        <DefaultDamageAssessment
          {...this.props}
          setData={this.setData}
          isRc={true}
          displayName={this.displayName}
          onTabsChange={(tabsId) => this.setState({ tabsId })}
        >
          {this.getData()}
        </DefaultDamageAssessment>
      </>
    );
  }
}
