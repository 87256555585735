import React from "react";
import {
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Loader,
} from "ui-kit-ck-consultant";
import moment from "moment";

import Protocol from "./complaints-protocol/Protocol";
import Template from "./file-preparation/Template";
import ModalMaileva from "./file-preparation/ModalMaileva";

import { getTemplateMail, sendMail } from "../../../requests/mail";
import { sendMaileva } from "../../../requests/maileva";

import AuthContext from "../../../context/AuthContext";

export default class ProtocolComplaints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      tabsIdArray: ["Réclamation / Proposition", "Protocole"],
      tabsId: 0,
    };
  }

  static contextType = AuthContext;

  setData = (
    name,
    data,
    callback = () => {},
    action = null,
    keyData = null
  ) => {
    let tmpData = this.props.dataProtocolComplaints;

    tmpData[name] = data;

    this.context.updateDataFolder(
      "dataProtocolComplaints",
      tmpData,
      callback,
      action,
      keyData
    );
  };

  addMessagePostal = (
    recipient,
    recipients,
    object,
    message,
    body,
    files,
    name,
    actionType,
    keyData,
    isPreview = false,
    callback
  ) => {
    const parties = this.context.parties;

    let recipientsEmail = recipients.map((key) => parties[key]);

    if (!isPreview) {
      this.setState({
        showModalMailEva: true,
        partiesData: [parties[recipient], ...recipientsEmail],
        templateData: {
          object,
          message,
          body,
          files,
          name,
          actionType,
          keyData,
          isPreview,
          data: this.context.getDataTemplate(recipient),
        },
        callbackOnValidate: callback,
      });
    } else {
      this.setState({ isLoad: true }, () => {
        const { folderId } = this.context;

        sendMaileva(
          {
            to: [],
            templateData: {
              object,
              message,
              body,
              files,
              name,
              actionType,
              keyData,
              isPreview,
              data: this.context.getDataTemplate(recipient),
            },
            folderId,
          },
          (res) => {
            this.setState({ isLoad: false }, () => {
              if (res.success) {
                callback(res);
              }
            });
          }
        );
      });
    }
  };

  addMessageMail = (
    recipient,
    recipients,
    emailRecipients,
    object,
    message,
    body,
    files,
    name,
    actionType,
    keyData,
    isPreview = false,
    callback
  ) => {
    if (recipient && object && message) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          const parties = this.context.parties;

          let recipientsEmail = recipients.map((key) => parties[key].email);
          emailRecipients = emailRecipients.split(";");
          if (!emailRecipients.length) {
            emailRecipients = [];
          }

          sendMail(
            {
              quality: parties[recipient].quality,
              folder: this.context.folderId,
              to: [
                parties[recipient].email,
                ...recipientsEmail,
                ...emailRecipients,
              ],
              subject: object,
              message,
              body,
              files,
              type: actionType,
              dataTemplate: this.context.getDataTemplate(recipient),
              isPreview,
            },
            (result) => {
              this.setState({ isLoad: false }, () => {
                if (result.success) {
                  if (!isPreview) {
                    let tmpData = this.getData(name);
                    tmpData.push({
                      dateInsert: moment().format("YYYY-MM-DD HH:mm"),
                      recipient,
                      recipients,
                      emailRecipients,
                      object,
                      message,
                      files,
                      filename: result.data.filename,
                      messageHTML: result.data.messageHTML,
                      keyData,
                    });
                    this.setData(
                      name,
                      tmpData,
                      () => {
                        callback({ success: true });
                      },
                      actionType,
                      keyData
                    );
                  } else {
                    callback(result);
                  }
                } else {
                  window.alert("Une erreur est survenue");
                  callback({ success: false });
                }
              });
            }
          );
        }
      );
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  componentDidUpdate() {
    if (this.context.isDisconnected && this.state.tabsId === 0) {
      this.setState({ tabsId: 1 });
    }
  }

  componentDidMount() {
    if (this.context.isDisconnected && this.state.tabsId === 0) {
      this.setState({ tabsId: 1 });
    }
  }

  getTemplateMail = (typeDocument, typeParty, data, callback) => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        getTemplateMail(typeDocument, typeParty, data, (result) => {
          this.setState({ isLoad: false }, () => callback(result));
        });
      }
    );
  };

  getData = (key) => {
    return this.props.dataProtocolComplaints.hasOwnProperty(key)
      ? this.props.dataProtocolComplaints[key]
      : key === "dataProtocol"
      ? {}
      : [];
  };

  onValidate = (to, callback) => {
    const { templateData } = this.state;
    const { folderId } = this.context;
    this.setState({ isLoad: true }, () => {
      sendMaileva({ to, templateData, folderId }, (res) => {
        if (res.success) {
          this.context.updateMailevaData(res.data || [], () => {
            if (this.state.callbackOnValidate) {
              this.state.callbackOnValidate(res);
            }

            this.setState(
              {
                isLoad: false,
                showModalMailEva: false,
                callbackOnValidate: null,
                templateData: null,
                partiesData: [],
              },
              () => {
                callback(res.success);
              }
            );
          });
        } else {
          this.setState({ isLoad: false }, () => {
            window.alert("Une erreur est survenue");
          });
        }
      });
    });
  };

  render() {
    return (
      <>
        <TabsCustom className="mt-10 mt-20-xl">
          <TabsCustomHeader className="m-0">
            {this.state.tabsIdArray.map((element, key) => (
              <TabsCustomMenu
                key={key}
                title={element}
                disabled={this.context.isDisconnected && key === 0}
                active={this.state.tabsId === key}
                onClick={(e) => {
                  if (this.context.isDisconnected && key === 0) {
                  } else {
                    this.setState({
                      tabsId: key,
                    });
                  }
                }}
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsId === 0}>
              <Template
                title="Courrier"
                historyTitle="Historique des réclamations / propositions"
                name="dataComplaints"
                message="Bonjour, <br/><br/> Vous trouverez ci-joint notre courrier concernant le dossier référencé ci-dessus.<br/><br/> Cordialement,<br/>[replace]"
                data={this.getData("dataComplaints")}
                tmpData={this.getData("tmp_dataComplaints")}
                getTemplateMail={this.getTemplateMail}
                addMessage={this.addMessageMail}
                addMessagePostal={this.addMessagePostal}
                parties={[{ party: "Adverse" }]}
                setData={this.setData}
              />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsId === 1}>
              <Protocol
                dataRecallFacts={this.props.dataRecallFacts}
                setData={this.setData}
                data={this.getData("dataProtocol")}
                dataExpertise={this.props.dataExpertise}
                type={"Protocol"}
              />
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
        {this.state.showModalMailEva && (
          <ModalMaileva
            show={this.state.showModalMailEva}
            partiesData={this.state.partiesData}
            onClose={() => this.setState({ showModalMailEva: false })}
            onValidate={this.onValidate}
          />
        )}
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
