import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowDown,
  faFileLines,
  faCloud,
  faDownload,
  faClose,
} from "@fortawesome/free-solid-svg-icons";

import { ButtonIcon, Loader } from "ui-kit-ck-consultant";

import moment from "moment";
import html2canvasInstance from "../../../utils/html2canvasInstance";

import "./viewMailBox.css";

import AuthContext from "../../../context/AuthContext";

export default class ViewMailBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
    };

    this.refModalContainer = React.createRef();
  }

  static contextType = AuthContext;

  componentDidUpdate() {
    if (this.props.show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }

  handlePrintClick() {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        html2canvasInstance(this.refModalContainer.current, (result) => {
          if (result.success === true) {
            const link = document.createElement("a");
            link.href = result.base64;
            // Nom temporaire
            link.download = `redaxion-${moment().format(
              "YYYY-MM-DD_HH-mm-ss"
            )}.jpeg`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
              isLoad: false,
            });
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                window.alert("Une erreur est survenue lors de l'impression");
              }
            );
          }
        });
      }
    );
  }

  render() {
    const { recipient, recipients, email } = this.props;

    if (!this.props.show) {
      return null;
    }
    return (
      <div className="capture-screen">
        <div ref={this.refModalContainer} className="modal-container">
          {this.state.isLoad ? null : (
            <>
              <div className="screen-shot-button">
                <ButtonIcon
                  small
                  className="blue"
                  onClick={() => this.handlePrintClick()}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </ButtonIcon>
              </div>
              <div className="close-button">
                <ButtonIcon
                  small
                  className="red"
                  onClick={() => this.props.onClose()}
                >
                  <FontAwesomeIcon icon={faClose} />
                </ButtonIcon>
              </div>
            </>
          )}

          <div className="header">
            <ButtonIcon style={{ color: "#605e5c" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </ButtonIcon>
            <h3>{this.props.object}</h3>
          </div>
          <div className="subheader">
            <div className="left-circle">
              <p className="circle">
                <span className="p-circle">GL</span>
              </p>
            </div>
            <div className="text-subheader">
              <div className="up">
                <p>
                  {this.props.sender.kind} {this.props.sender.name}
                </p>
                <div className="blue-arrows">
                  <ul>
                    <li>
                      <svg style={{ height: "25px", width: "15px" }}>
                        <path
                          fill="#0078d4"
                          d="M3.7 8l2.69 2.68a.5.5 0 01-.64.77l-.07-.06-3.56-3.57-.05-.07-.03-.06-.02-.06V7.6A.5.5 0 012 7.5v.07-.11l.02-.09.02-.06.04-.08.05-.07 3.55-3.55a.5.5 0 01.77.64l-.06.07L3.7 7H8c3.52 0 5.89 1.98 6 4.8v.2a.5.5 0 01-1 0c0-2.27-1.8-3.9-4.74-4H3.7l2.69 2.68L3.7 8z"
                        ></path>
                      </svg>
                    </li>
                    <li>
                      <svg style={{ height: "25px", width: "15px" }}>
                        <path
                          fill="#0078d4"
                          d="M 9.39 3.61 c 0.17 0.17 0.2 0.44 0.06 0.64 l -0.06 0.07 L 6.7 7 H 8 c 3.52 0 5.89 1.98 6 4.8 v 0.2 a 0.5 0.5 0 0 1 -1 0 c 0 -2.27 -1.8 -3.9 -4.74 -4 H 6.7 l 2.69 2.68 a 0.5 0.5 0 0 1 -0.64 0.77 l -0.07 -0.06 l -3.56 -3.57 l -0.05 -0.07 l -0.03 -0.06 l -0.02 -0.06 L 5 7.57 v -0.2 l 0.03 -0.06 l 0.04 -0.08 l 0.05 -0.07 l 3.55 -3.55 c 0.2 -0.2 0.51 -0.2 0.7 0 Z M 2.15 7.15 l 3.5 -3.5 a 0.5 0.5 0 0 1 0.76 0.63 l -0.06 0.07 L 3.21 7.5 l 3.14 3.15 a 0.5 0.5 0 0 1 -0.63 0.76 l -0.07 -0.06 l -3.5 -3.5 a 0.5 0.5 0 0 1 -0.06 -0.63 l 0.06 -0.07 l 3.5 -3.5 l -3.5 3.5 Z"
                        ></path>
                      </svg>
                    </li>
                    <li>
                      <svg style={{ height: "25px", width: "15px" }}>
                        <path
                          fill="#0078d4"
                          d="M 12.3 8 L 9.6 10.68 a 0.5 0.5 0 0 0 0.64 0.77 l 0.07 -0.06 l 3.56 -3.57 l 0.05 -0.07 l 0.03 -0.06 l 0.02 -0.06 V 7.6 A 0.5 0.5 0 0 0 14 7.5 v 0.07 v -0.11 l -0.02 -0.09 l -0.02 -0.06 l -0.04 -0.08 l -0.05 -0.07 l -3.55 -3.55 a 0.5 0.5 0 0 0 -0.77 0.64 l 0.06 0.07 L 12.3 7 H 8 c -3.52 0 -5.89 1.98 -6 4.8 v 0.2 a 0.5 0.5 0 0 0 1 0 c 0 -2.27 1.8 -3.9 4.74 -4 h 4.56 Z"
                        ></path>
                      </svg>
                    </li>
                    <li style={{ color: "#0078d4" }}>...</li>
                  </ul>
                </div>
              </div>
              <div className="down">
                <div className="recipient">
                  <p>
                    À{" "}
                    {this.context.parties[recipient]
                      ? this.context.parties[recipient].email
                      : email}{" "}
                    {recipients
                      ? recipients.map((recipient) => (
                          <Fragment key={recipient}>
                            &nbsp;/&nbsp;{this.context.parties[recipient].email}
                          </Fragment>
                        ))
                      : null}
                  </p>
                </div>
                <p>{moment(this.props.date).format("DD/MM/YYYY HH:mm")}</p>
              </div>
            </div>
          </div>
          <div className="files">
            {this.props.files.length
              ? this.props.files.map((element, idx) => (
                  <div className="file-item" key={idx}>
                    <div className="picture">
                      <ButtonIcon
                        style={{ color: "#605e5c", padding: "4px 12px" }}
                      >
                        <FontAwesomeIcon icon={faFileLines} />
                      </ButtonIcon>
                    </div>
                    <div className="file-text">
                      <p>{element.name.split("/").pop()}</p>
                    </div>
                  </div>
                ))
              : null}
          </div>
          <div className="sub-file">
            <ul>
              <li>
                <ButtonIcon
                  className="cloud"
                  style={{ color: "#0078d4", fontSize: "12px" }}
                >
                  <FontAwesomeIcon icon={faCloud} />
                </ButtonIcon>
                Tout enregistrer dans OneDrive
              </li>
              <li>
                <ButtonIcon style={{ color: "#0078d4", fontSize: "12px" }}>
                  <FontAwesomeIcon icon={faArrowDown} />
                </ButtonIcon>
                Télécharger tout
              </li>
            </ul>
          </div>
          <div
            className="text"
          >
            <div
              dangerouslySetInnerHTML={{ __html: this.props.message }}
            ></div>
          </div>
        </div>
        <Loader show={this.state.isLoad} />
      </div>
    );
  }
}
