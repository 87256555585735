import React from "react";
import { Card } from "ui-kit-ck-consultant";

import Table from "./Table";

import AuthContext from "../../context/AuthContext";

export default class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageLength: 10,
    };
  }

  static contextType = AuthContext;

  getColorLegend = (title, color) => {
    return (
      <div className="d-flex mb-10">
        <div
          style={{
            width: "60px",
            height: "20px",
            backgroundColor: `var(${color})`,
            borderRadius: "10px",
          }}
        ></div>
        <span className="ml-5 mt-auto mb-auto">{title}</span>
      </div>
    );
  };

  render() {
    return (
      <Card
        className="mt-10 mt-20-xl flex-column"
        classNameChildren="overflow-x-auto"
      >
        <Table
          states={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
          folders={this.props.folders}
          getFolders={this.props.getFolders}
        />
        <div className="mt-10" />
        {this.getColorLegend(
          "Verrouillé par un autre utilisateur",
          "--red-light"
        )}
        {this.getColorLegend("Sauvegardé localement", "--green-light")}
        {this.getColorLegend(
          "Modification serveur attente mise à jour locale",
          "--purple-light"
        )}
        {this.getColorLegend(
          "Modification locale attente mise à jour serveur",
          "--blue-light"
        )}
      </Card>
    );
  }
}
