import React from "react";
import { FormInput, Modal, Button } from "ui-kit-ck-consultant";

export default class ModalPlaceOfExpertise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "",
      zipCode: "",
      city: "",
      phone: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.setState({
        name: this.props.data.name || "",
        address: this.props.data.address || "",
        zipCode: this.props.data.zipCode || "",
        city: this.props.data.city || "",
        phone: this.props.data.phone || "",
      });
    }
  }

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  updatePlaceOfExpertise = () => {
    const tmpData = { ...this.state };

    this.props.updateData(tmpData);
  };

  render() {
    const { name, address, zipCode, city, phone } = this.state;

    return (
      <Modal
      closeMessage="Êtes-vous sûr de vouloir fermer cette fenêtre ?"
        title="Lieu d'expertise"
        show={this.props.show}
        onClose={this.props.onClose}
        isMove
      >
        <FormInput
          type="text"
          className="mb-10"
          name="name"
          value={name || " "}
          title="Raison Sociale"
          onChange={this.handleInputChange}
        />
        <FormInput
          type="text"
          className="mb-10"
          name="address"
          value={address || " "}
          title="Adresse"
          onChange={this.handleInputChange}
        />
        <FormInput
          type="text"
          className="mb-10"
          name="zipCode"
          value={zipCode}
          title="Code Postal"
          onChange={this.handleInputChange}
        />
        <FormInput
          type="text"
          className="mb-10"
          name="city"
          value={city}
          title="Ville"
          onChange={this.handleInputChange}
        />
        <FormInput
          type="text"
          className="mb-10"
          name="phone"
          value={phone}
          title="Téléphone"
          onChange={this.handleInputChange}
        />
        <div className="d-flex">
          <Button text={"Modifier"} onClick={this.updatePlaceOfExpertise} />
        </div>
      </Modal>
    );
  }
}
