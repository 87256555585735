import React, { Fragment } from "react";
import moment from "moment";
import { Row, Col } from "react-flexbox-grid";
import {
  Gauge,
  FormCheckbox,
  FormInput,
  Switch,
  ButtonIcon,
  FormTextarea,
  Button,
} from "ui-kit-ck-consultant";
import ModalLevelsWearName from "./modals/ModalLevelsWearName";
import {
  faCar,
  faEye,
  faEyeSlash,
  faOilCan,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getFileShorcut } from "../../../../utils/general";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";
import ModalAddNewGauge from "./modals/ModalAddNewGauge";
import ModalUpdateGaugeTitle from "./modals/ModalUpdateGaugeTitle";

export default class Vehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
      isModalNewGauge: false,
      isModalUpdateGaugetitle: false,
      currentGauge: "",
      newGaugeKey: "",
    };
    this.displayName = "Vehicle";
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.props.dataExpertiseConditions;
    tmpData[name] = value;

    if (name === "dateTechnicalControl") {
      let tmpDataAdministrative = this.context.dataAdministrative;

      if (
        !tmpDataAdministrative.dataVehicleIdentification.dateTechnicalControl[0]
      ) {
        tmpDataAdministrative.dataVehicleIdentification.dateTechnicalControl[0] =
          value;

        this.context.updateDataFolder(
          "dataAdministrative",
          tmpDataAdministrative
        );
      }
    }

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("expertiseConditions", tmpData);
    } else {
      this.context.updateDataReport("expertiseConditions", tmpData);
    }
  };

  setDataVehicle = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("vehicle", tmpData);
    } else {
      this.context.updateDataReport("vehicle", tmpData);
    }
  };

  handleGaugeChangeVE = (value, key) => {
    let tmpData = this.props.data;
    if (!tmpData[key]) {
      tmpData[key] = { value };
    }

    tmpData[key].value = value;

    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("vehicle", tmpData);
    } else {
      this.context.updateDataReport("vehicle", tmpData);
    }
  };

  handleGaugeChange = (value, idx, key) => {
    let tmpData = this.props.data;

    tmpData[key][idx].value = value;

    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("vehicle", tmpData);
    } else {
      this.context.updateDataReport("vehicle", tmpData);
    }
  };

  handleGaugeChangeDisplay = (idx, key) => {
    let tmpData = this.props.data;

    tmpData[key][idx].display = !tmpData[key][idx].display;

    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("vehicle", tmpData);
    } else {
      this.context.updateDataReport("vehicle", tmpData);
    }
  };

  getPicture = (name) => {
    let path = "";
    if (this.props.dataExpertiseConditions[name]) {
      const file = this.props.dataExpertiseConditions[name];
      path = getFileShorcut(this.context, file);
    }

    return (
      <div
        className="mb-10 opacity"
        style={{
          display: "flex",
          position: "relative",
          borderRadius: "15px",
          border: "dashed 1px var(--gray)",
          minHeight: "100px",
          width: "100%",
          cursor: "pointer",
          overflow: "hidden",
        }}
        onClick={() =>
          this.context.openModalLibrary((files) => {
            if (files.length) {
              let tmpData = this.props.dataExpertiseConditions;
              tmpData[name] = files[0];

              this.setData(tmpData);
            }
          })
        }
      >
        <div
          className="opacity"
          style={{
            display: "inline-flex",
            position: "absolute",
            right: 0,
            top: 0,
            width: "32px",
            height: "32px",
            backgroundColor: "var(--gray)",
            borderBottomLeftRadius: "15px",
            cursor: "pointer",
            zIndex: 10,
          }}
          onClick={(e) => {
            let tmpData = this.props.dataExpertiseConditions;
            delete tmpData[name];

            this.setData(tmpData);
            e.stopPropagation();
          }}
        >
          <FontAwesomeIcon
            icon={faTrash}
            className="red"
            style={{ margin: "auto" }}
          />
        </div>
        <span
          style={{
            position: "absolute",
            color: "var(--gray)",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          Cliquer pour ajouter une photo
        </span>
        {path ? (
          <img
            style={{
              zIndex: 9,
              maxWidth: "100%",
              margin: "auto",
            }}
            src={path}
            alt={name}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  getGaugePicture = (key, idx) => {
    let path = "";
    if (this.props.data[key] && this.props.data[key][idx].files) {
      const file = this.props.data[key][idx].files;
      path = getFileShorcut(this.context, file);
    }

    return (
      <div
        className="mb-10 opacity"
        style={{
          display: "flex",
          position: "relative",
          borderRadius: "15px",
          border: "dashed 1px var(--gray)",
          minHeight: "100px",
          width: "100%",
          cursor: "pointer",
          overflow: "hidden",
        }}
        onClick={() =>
          this.context.openModalLibrary((files) => {
            if (files.length) {
              let tmpData = this.props.data;
              tmpData[key][idx].files = files[0];

              this.setDataVehicle(tmpData);
            }
          })
        }
      >
        <div
          className="opacity"
          style={{
            display: "inline-flex",
            position: "absolute",
            right: 0,
            top: 0,
            width: "32px",
            height: "32px",
            backgroundColor: "var(--gray)",
            borderBottomLeftRadius: "15px",
            cursor: "pointer",
            zIndex: 10,
          }}
          onClick={(e) => {
            let tmpData = this.props.data;
            delete tmpData[key][idx].files;

            this.setDataVehicle(tmpData);
            e.stopPropagation();
          }}
        >
          <FontAwesomeIcon
            icon={faTrash}
            className="red"
            style={{ margin: "auto" }}
          />
        </div>
        <span
          style={{
            position: "absolute",
            color: "var(--gray)",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          Cliquer pour ajouter une photo
        </span>
        {path ? (
          <img
            style={{
              zIndex: 9,
              maxWidth: "100%",
              margin: "auto",
            }}
            src={path}
            alt={"name"}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  getGaugeCustom = (
    title,
    idx,
    onEdit = undefined,
    colors = undefined,
    key
  ) => {
    const gauge = this.props.data[key];

    return (
      <Fragment key={idx}>
        <div className="d-flex" style={{ justifyContent: "end" }}>
          <ButtonIcon
            className="black mt-auto mb-auto"
            small
            info="Afficher dans le PDF"
            onClick={() => this.handleGaugeChangeDisplay(idx, key)}
          >
            <FontAwesomeIcon icon={gauge[idx].display ? faEye : faEyeSlash} />
          </ButtonIcon>
        </div>
        <Gauge
          ignore={true}
          className="mb-10"
          title={title}
          min={colors ? undefined : 25}
          max={colors ? undefined : 75}
          colors={colors || ["#db2828", "#0c8540", "#db2828"]}
          step={5}
          noText={!colors}
          position={gauge[idx].value}
          onChange={(value) => this.handleGaugeChange(value, idx, key)}
          onEdit={onEdit}
        />
        {this.getGaugePicture(key, idx)}
        <FormTextarea
          placeholder="Commentaire"
          value={gauge[idx].comment || ""}
          onChange={({ target }) => {
            let tmpData = this.props.data;

            tmpData[key][idx].comment = target.value;

            this.setDataVehicle(tmpData);
          }}
        />
      </Fragment>
    );
  };

  getData = () => {
    const defaultGaugeObject = {
      tyres: [
        {
          key: "tyreFrontLeft",
          text: "Pneu avant gauche",
          value: 0,
          comment: "",
          files: "",
        },
        {
          key: "tyreFrontRight",
          text: "Pneu avant droit",
          value: 0,
          comment: "",
          files: "",
        },
        {
          key: "tyreRearLeft",
          text: "Pneu arrière gauche",
          value: 0,
          comment: "",
          files: "",
        },
        {
          key: "tyreRearRight",
          text: "Pneu arrière droit",
          value: 0,
          comment: "",
          files: "",
        },
      ],
      liquids: [
        {
          key: "motor",
          text: "Huile moteur",
          value: 0,
          comment: "",
          files: "",
        },
        {
          key: "cooling",
          text: "Liquide de refroidissement",
          value: 0,
          comment: "",
          files: "",
        },
        {
          key: "brake",
          text: "Liquide de freins",
          value: 0,
          comment: "",
          files: "",
        },
      ],
    };
    const { tyres, liquids } = Object.keys(this.props.data).length
      ? this.props.data
      : defaultGaugeObject;

    return (
      <>
        <Row>
          <Col xs={12} md={3}>
            <div className="d-flex flex-column mb-10">
              <span className="text-bold mb-5">Immatriculation</span>
              <span>
                {
                  this.context.dataAdministrative.dataVehicleIdentification[0]
                    .licensePlate
                }
              </span>
            </div>
            {this.context.dataAdministrative.dataVehicleIdentification[0]
              .checkboxLicensePlate ? (
              <p>Non concerné</p>
            ) : (
              <>
                <Switch
                  className="mb-10"
                  title="Conforme"
                  value1="Oui"
                  value2="Non"
                  color1="#026fc2"
                  color2="#f2711c"
                  idxActive={
                    this.props.dataExpertiseConditions.hasOwnProperty(
                      "switchLicensePlate"
                    )
                      ? this.props.dataExpertiseConditions["switchLicensePlate"]
                      : 0
                  }
                  onChange={(type) => {
                    let tmpData = this.props.dataExpertiseConditions;
                    tmpData["switchLicensePlate"] = type;

                    this.setData(tmpData);
                  }}
                />
                {this.props.dataExpertiseConditions["switchLicensePlate"] ===
                1 ? (
                  <FormInput
                    className="mb-10"
                    title="Correction Immatriculation"
                    name="correctLicensePlate"
                    value={
                      this.props.dataExpertiseConditions.correctLicensePlate
                    }
                    onChange={this.handleInputChange}
                  />
                ) : null}
                {this.getPicture("pictureLicensePlate")}
              </>
            )}
          </Col>
          <Col xs={12} md={3}>
            <div className="d-flex flex-column mb-10">
              <span className="text-bold mb-5">VIN</span>
              <span>
                {
                  this.context.dataAdministrative.dataVehicleIdentification[0]
                    .VIN
                }
              </span>
            </div>
            <Switch
              className="mb-10"
              title="Conforme"
              value1="Oui"
              value2="Non"
              color1="#026fc2"
              color2="#f2711c"
              idxActive={
                this.props.dataExpertiseConditions.hasOwnProperty("switchVIN")
                  ? this.props.dataExpertiseConditions["switchVIN"]
                  : 0
              }
              onChange={(type) => {
                let tmpData = this.props.dataExpertiseConditions;
                tmpData["switchVIN"] = type;

                this.setData(tmpData);
              }}
            />
            {this.props.dataExpertiseConditions["switchVIN"] === 1 ? (
              <FormInput
                className="mb-10"
                title="Correction VIN"
                name="correctVIN"
                value={this.props.dataExpertiseConditions.correctVIN}
                onChange={this.handleInputChange}
              />
            ) : null}
            {this.getPicture("pictureVIN")}
          </Col>
          <Col xs={12} md={3}>
            <div className="d-flex flex-column mb-10">
              <span className="text-bold mb-5">Contrôle technique</span>
              <span>
                {this.context.dataAdministrative.dataVehicleIdentification[0]
                  .dateTechnicalControl
                  ? moment(
                      this.context.dataAdministrative
                        .dataVehicleIdentification[0].dateTechnicalControl
                    ).format("DD/MM/YYYY")
                  : ""}
              </span>
            </div>
            {this.context.dataAdministrative.dataVehicleIdentification[0]
              .checkboxTechnicalControl ? (
              <p>Non concerné</p>
            ) : (
              <>
                <Switch
                  className="mb-10"
                  title="Conforme"
                  value1="Oui"
                  value2="Non"
                  color1="#026fc2"
                  color2="#f2711c"
                  idxActive={
                    this.props.dataExpertiseConditions.hasOwnProperty(
                      "switchTechnicalControl"
                    )
                      ? this.props.dataExpertiseConditions[
                          "switchTechnicalControl"
                        ]
                      : 0
                  }
                  onChange={(type) => {
                    let tmpData = this.props.dataExpertiseConditions;
                    tmpData["switchTechnicalControl"] = type;

                    this.setData(tmpData);
                  }}
                />
                {this.getPicture("pictureTechnicalControl")}
              </>
            )}
          </Col>
          <Col xs={12} md={3}>
            <Switch
              className="mb-10"
              title=""
              value1="Kilométrage"
              value2="Heures"
              color1="#026fc2"
              color2="#f2711c"
              idxActive={
                this.props.dataExpertiseConditions.hasOwnProperty(
                  "switchMileageHour"
                )
                  ? this.props.dataExpertiseConditions["switchMileageHour"]
                  : 0
              }
              onChange={(type) => {
                let tmpData = this.props.dataExpertiseConditions;
                tmpData["switchMileageHour"] = type;

                this.setData(tmpData);
              }}
            />

            {!this.props.dataExpertiseConditions["switchMileageHour"] ? (
              <>
                <div className="d-flex flex-column mb-10">
                  <FormInput
                    className="mb-10"
                    type="number"
                    title="Kilométrage"
                    name="mileage"
                    value={this.props.dataExpertiseConditions.mileage}
                    onChange={this.handleInputChange}
                  />
                  <FormCheckbox
                    className="ml-auto"
                    text="Kilométrage estimé"
                    checked={
                      this.props.dataExpertiseConditions.hasOwnProperty(
                        "checkboxMileage"
                      )
                        ? this.props.dataExpertiseConditions["checkboxMileage"]
                        : false
                    }
                    onChange={({ target }) => {
                      let tmpData = this.props.dataExpertiseConditions;
                      tmpData["checkboxMileage"] = target.checked;

                      this.setData(tmpData);
                    }}
                  />
                </div>
                {this.getPicture("pictureMileage")}
              </>
            ) : (
              <>
                <div className="d-flex flex-column mb-10">
                  <FormInput
                    className="mb-10"
                    type="number"
                    title="Heures"
                    name="hour"
                    value={this.props.dataExpertiseConditions.hour}
                    onChange={this.handleInputChange}
                  />
                  <FormCheckbox
                    className="ml-auto"
                    text="Heures estimées"
                    checked={
                      this.props.dataExpertiseConditions.hasOwnProperty(
                        "checkboxHour"
                      )
                        ? this.props.dataExpertiseConditions["checkboxHour"]
                        : false
                    }
                    onChange={({ target }) => {
                      let tmpData = this.props.dataExpertiseConditions;
                      tmpData["checkboxHour"] = target.checked;

                      this.setData(tmpData);
                    }}
                  />
                </div>
                {this.getPicture("pictureHour")}
              </>
            )}
          </Col>
          <Col xs={12}>{this.getPicture("pictureGeneral")}</Col>
          {["HY", "Hybride", "EL", "Électrique"].includes(
            this.context.dataAdministrative.dataVehicleIdentification[0].fuel
          ) ? (
            <>
              <Col xs={12}>
                <h3 className="mb-10">Véhicule électrique</h3>
              </Col>
              <Col xs={12} md={6}>
                <Gauge
                  ignore={true}
                  className="mb-10"
                  title={"SOH"}
                  colors={["#db2828", "#0c8540"]}
                  step={1}
                  position={
                    this.props.data.hasOwnProperty("SOH")
                      ? this.props.data["SOH"].value
                      : 0
                  }
                  onChange={(value) => {
                    this.handleGaugeChangeVE(value, "SOH");
                  }}
                />
                <FormCheckbox
                  className="mt-10"
                  text="Inconnu"
                  checked={
                    this.props.dataExpertiseConditions.hasOwnProperty(
                      "checkboxSOH"
                    )
                      ? this.props.dataExpertiseConditions["checkboxSOH"]
                      : false
                  }
                  onChange={({ target }) => {
                    let tmpData = this.props.dataExpertiseConditions;
                    tmpData["checkboxSOH"] = target.checked;

                    this.setData(tmpData);
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
                <Gauge
                  ignore={true}
                  className="mb-10"
                  title={"SOC"}
                  colors={["#db2828", "#0c8540"]}
                  step={1}
                  position={
                    this.props.data.hasOwnProperty("SOC")
                      ? this.props.data["SOC"].value
                      : 0
                  }
                  onChange={(value) => {
                    this.handleGaugeChangeVE(value, "SOC");
                  }}
                />
                <FormCheckbox
                  className="mt-10"
                  text="Inconnu"
                  checked={
                    this.props.dataExpertiseConditions.hasOwnProperty(
                      "checkboxSOC"
                    )
                      ? this.props.dataExpertiseConditions["checkboxSOC"]
                      : false
                  }
                  onChange={({ target }) => {
                    let tmpData = this.props.dataExpertiseConditions;
                    tmpData["checkboxSOC"] = target.checked;

                    this.setData(tmpData);
                  }}
                />
              </Col>
            </>
          ) : null}
          <Col xs={12}>
            <div className="d-flex">
              <h3 className="mb-0">Niveaux et usures</h3>
              <ButtonIcon
                className="black mt-auto mb-auto"
                small
                onClick={this.props.updateStatusCardLevelsWear}
              >
                <FontAwesomeIcon
                  icon={this.props.statusCardLevelsWear ? faEye : faEyeSlash}
                />
              </ButtonIcon>
            </div>
          </Col>
          {this.props.statusCardLevelsWear ? (
            <>
              <Col xs={12} md={6}>
                {liquids.map((liquid, idx) => {
                  return this.getGaugeCustom(
                    liquid.text,
                    idx,
                    () =>
                      this.setState({
                        currentGauge: idx,
                        newGaugeKey: "liquids",
                        isModalUpdateGaugetitle: true,
                      }),
                    undefined,
                    "liquids"
                  );
                })}
                <Button
                  outline
                  className="mb-10"
                  blue
                  text={"Ajouter un liquide"}
                  onClick={() => {
                    this.setState({
                      isModalNewGauge: true,
                      newGaugeKey: "liquids",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faOilCan} />
                </Button>
              </Col>
              <Col xs={12} md={6}>
                {tyres.map((gauge, idx) => {
                  return this.getGaugeCustom(
                    gauge.text,
                    idx,
                    () =>
                      this.setState({
                        currentGauge: idx,
                        newGaugeKey: "tyres",
                        isModalUpdateGaugetitle: true,
                      }),
                    ["#0c8540", "#db2828"],
                    "tyres"
                  );
                })}
                <Button
                  outline
                  className="mb-10"
                  blue
                  text={"Ajouter un pneumatique"}
                  onClick={() => {
                    this.setState({
                      isModalNewGauge: true,
                      newGaugeKey: "tyres",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCar} />
                </Button>
              </Col>
            </>
          ) : null}
        </Row>
        <ModalLevelsWearName
          show={this.state.isModal}
          data={
            this.props.data.hasOwnProperty("other")
              ? this.props.data["other"].name
              : ""
          }
          onUpdate={(name) => {
            this.setState({ isModal: false }, () => {
              let tmpData = this.props.data;
              if (!tmpData.hasOwnProperty("other")) {
                tmpData["other"] = {
                  name: undefined,
                  value: 0,
                };
              }
              tmpData["other"].name = name;

              this.setData(tmpData);
            });
          }}
          onClose={() => this.setState({ isModal: false })}
        />
        {this.state.isModalUpdateGaugetitle && (
          <ModalUpdateGaugeTitle
            show={this.state.isModalUpdateGaugetitle}
            data={
              this.props.data[this.state.newGaugeKey][this.state.currentGauge]
            }
            onClose={() => this.setState({ isModalUpdateGaugetitle: false })}
            onUpdate={(gaugeName) => {
              this.setState(
                {
                  isModalUpdateGaugetitle: false,
                },
                () => {
                  let tmpData = this.props.data;

                  tmpData[this.state.newGaugeKey][
                    this.state.currentGauge
                  ].text = gaugeName;

                  this.setData(tmpData);
                }
              );
            }}
          />
        )}
        {this.state.isModalNewGauge && (
          <ModalAddNewGauge
            show={this.state.isModalNewGauge}
            onClose={() => {
              this.setState({ isModalNewGauge: false });
            }}
            onUpdate={(gaugeName) => {
              const newKey = `${gaugeName}_${tyres.length - 1 + 1}`;
              this.setState(
                {
                  isModalNewGauge: false,
                },
                () => {
                  let tmpData = this.props.data;
                  const { newGaugeKey } = this.state;

                  const newObject = {
                    text: gaugeName,
                    key: newKey,
                    value: 0,
                    comment: "",
                    files: "",
                    display: true,
                  };

                  tmpData[newGaugeKey].push(newObject);

                  this.setData(tmpData);
                }
              );
            }}
          />
        )}
      </>
    );
  };

  render() {
    if (this.context.isReport && !this.props.isForceCard) {
      return this.getData();
    }

    return (
      <CustomCard title="Analyse physique véhicule" name={this.displayName}>
        {this.getData()}
      </CustomCard>
    );
  }
}
