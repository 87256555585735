import React from "react";

import RCdamageAssessment from "../../../components/folder/expertises-reports/report/RCdamageAssessment";
import DamageAssessment from "../../../components/folder/expertises-reports/report/DamageAssessment";

import { getData } from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";

export default class Evaluation extends React.Component {
  static contextType = AuthContext;

  render() {
    let tmpData = this.props.data;
    tmpData["RCdamageAssessment"] = getData(
      this.props.data,
      "RCdamageAssessment",
      "array"
    );
    tmpData["damageAssessment"] = getData(
      this.props.data,
      "damageAssessment",
      "array"
    );

    if (
      this.context.dataAdministrative.dataAdministrativeInformation.mission
        .typeMission === "2"
    ) {
      return (
        <RCdamageAssessment
          data={tmpData["RCdamageAssessment"]}
          dataEvaluation={tmpData}
          isLight
        />
      );
    } else {
      return (
        <DamageAssessment
          data={tmpData["damageAssessment"]}
          dataEvaluation={tmpData}
          isLight
        />
      );
    }
  }
}
