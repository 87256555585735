import React from "react";
import moment from "moment";

import ExpertisesReports from "../../../components/folder/expertises-reports/ExpertisesReports";
import Report from "../../../components/folder/expertises-reports/Report";
import ModalReport from "../../../components/folder/expertises-reports/report/ModalReport";

import AuthContext from "../../../context/AuthContext";
import { generateString, getParty } from "../../../utils/general";

export default class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsId: null,
      isModalReport: false,
      modalReportExpertises: [],
    };
  }

  static contextType = AuthContext;

  addReport = (type, idxExpertises) => {
    const expertParty = getParty(this.context.parties, "Expert", "Lésé");

    if (!expertParty.name) {
      alert("Veuillez ajouter un expert dans les intervenants");
      return false;
    }

    let tmpData = this.props.data;

    const expertises = this.props.dataExpertise.filter((expertise, idx) =>
      idxExpertises.includes(idx)
    );

    let storageCosts = "";

    expertises.forEach((experise) => {
      if (
        experise.expertiseConditions &&
        experise.expertiseConditions.text_storageCosts
      ) {
        storageCosts = experise.expertiseConditions.text_storageCosts;
      }
    });

    let expertValue = this.context.partiesOptions.filter(
      (element) =>
        ["Expert"].includes(element.quality) && element.party === "Lésé"
    );

    if (expertValue[0]) {
      const expert = expertValue[0].value;

      let expertSignature = {};
      if (expert && this.context.name === this.context.parties[expert].name) {
        expertSignature.base64 = this.context.signature;
      }

      let additionalDamage = [];

      expertises.forEach((expertise) => {
        if (
          expertise.expertiseConditions &&
          expertise.expertiseConditions.fees
        ) {
          additionalDamage = [
            ...additionalDamage,
            ...expertise.expertiseConditions.fees,
          ];
        }
      });

      const expertiseConditions =
        expertises[expertises.length - 1].expertiseConditions;
      const vehicle = expertises[expertises.length - 1].vehicle;
      const attendees = expertises[expertises.length - 1].attendees;

      let positionKnowParties = undefined;
      let positionKnowPartiesComment = undefined;
      if (["informationRc", "finalRc"].includes(type)) {
        positionKnowParties =
          expertises[expertises.length - 1].positionKnowParties;
        positionKnowPartiesComment =
          expertises[expertises.length - 1].positionKnowPartiesComment;
      }

      let rcAdditionalDamage = {};
      if (expertises[expertises.length - 1].expertiseConditions) {
        rcAdditionalDamage = {
          switch_storageCosts:
            expertises[expertises.length - 1].expertiseConditions
              .switch_storageCosts,
          text_storageCosts:
            expertises[expertises.length - 1].expertiseConditions
              .text_storageCosts,
        };
      }

      let damageAssessmentComment = "";
      if (expertises[expertises.length - 1].damageAssessmentComment) {
        damageAssessmentComment =
          expertises[expertises.length - 1].damageAssessmentComment;
      }

      if (storageCosts) {
        additionalDamage.push({
          value: "Frais de gardiennage",
          amount: storageCosts,
        });
      }

      let name = "";

      switch (type) {
        case "information":
          name = "Rapport d'information";
          break;
        case "final":
          name = "Rapport final";
          break;
        case "simplified":
          name = "Rapport simplifié";
          break;
        case "informationRc":
          name = "Rapport d'information Rc";
          break;
        case "finalRc":
          name = "Rapport final Rc";
          break;
        default:
          break;
      }

      if (this.company) {
        name = `${name} ${this.company}`;
      }

      const casualLink = tmpData.length
        ? tmpData[tmpData.length - 1].casualLink
        : "";
      const natureOfMission = tmpData.length
        ? tmpData[tmpData.length - 1].natureOfMission
        : "";
      const origines = tmpData.length
        ? tmpData[tmpData.length - 1].origines
        : "";
      const consequences = tmpData.length
        ? tmpData[tmpData.length - 1].consequences
        : "";
      const vehicleQuality = tmpData.length
        ? tmpData[tmpData.length - 1].vehicleQuality
        : null;
      const qualificationOfTheSinister = tmpData.length
        ? tmpData[tmpData.length - 1].qualificationOfTheSinister
        : null;
      const purposeMission = tmpData.length
        ? tmpData[tmpData.length - 1].purposeMission
        : null;

      let technicalAnalysis = undefined;
      let responsibilities = undefined;
      let conclusion = undefined;

      if (this.props.data.length) {
        const last = this.props.data[this.props.data.length - 1];
        technicalAnalysis = last.technicalAnalysis;
        responsibilities = last.responsibilities;
        conclusion = last.conclusion;
      }

      tmpData.push({
        token: generateString(),
        name,
        isLock: false,
        expertises,
        informations: {
          date: moment().format("YYYY-MM-DD"),
          hour: moment().format("HH:mm"),
          expertKind: this.context.parties[expert].kind,
          expertCodeVE: this.context.parties[expert].codeVE,
          expertName: this.context.parties[expert].name,
          expertCity: this.context.parties[expert].city,
          status: true,
          type,
        },
        technicalAnalysis,
        responsibilities,
        conclusion,
        casualLink,
        natureOfMission,
        origines,
        consequences,
        vehicleQuality,
        qualificationOfTheSinister,
        additionalDamage,
        expertiseConditions,
        vehicle,
        attendees,
        rcAdditionalDamage,
        positionKnowParties,
        positionKnowPartiesComment,
        purposeMission: purposeMission
          ? purposeMission
          : this.context.dataAdministrative &&
            this.context.dataAdministrative.dataPurposeMission
          ? this.context.dataAdministrative.dataPurposeMission
          : {},
        RCdamageAssessment: JSON.parse(
          JSON.stringify(
            this.props.dataEvaluation &&
              this.props.dataEvaluation.RCdamageAssessment
              ? this.props.dataEvaluation.RCdamageAssessment
              : [{}]
          )
        ),
        damageAssessment: JSON.parse(
          JSON.stringify(
            this.props.dataEvaluation &&
              this.props.dataEvaluation.damageAssessment
              ? this.props.dataEvaluation.damageAssessment
              : [{}]
          )
        ),
        damageAssessmentComment,
        recipients: [],
        statusCard: {},
        observations: {
          switch_storageCosts: storageCosts ? 1 : 0,
          text_storageCosts: storageCosts,
        },
        company: this.company,
        expertSignature,
      });

      this.context.updateDataFolder(
        "dataReports",
        tmpData,
        () =>
          this.setState({
            isModalReport: false,
            tabsId: this.props.data.length - 1,
          }),
        7,
        tmpData[tmpData.length - 1].token
      );
    } else {
      window.alert("L'expert du dossier n'est pas renseigné");
    }
  };

  deleteReport = () => {
    window.confirmCustom(
      "Êtes-vous sûr de vouloir supprimer ce rapport ?",
      (result) => {
        if (result) {
          let tmpData = this.props.data;

          tmpData.splice(this.state.tabsId, 1);

          this.context.updateDataFolder(
            "dataReports",
            tmpData,
            () => this.setState({ tabsId: null }),
            null,
            null
          );
        }
      }
    );
  };

  updateReport = (data, callback = () => {}, action, keyData) => {
    let tmpData = this.props.data;

    tmpData[this.state.tabsId] = data;

    this.context.updateDataFolder(
      "dataReports",
      tmpData,
      callback,
      action,
      keyData
    );
  };

  render() {
    const { dataIdentificationParties } = this.props.dataAdministrative;

    // CHECK FOR COMPANY
    let simplified = false;
    let assurancy = getParty(dataIdentificationParties, "Assureur");

    if (
      (assurancy.name && assurancy.name.toUpperCase().includes("MACIF")) ||
      this.context.companyName === "MACIF"
    ) {
      this.company = "macif";
      simplified = true;
    } else if (
      (assurancy.name && assurancy.name.toUpperCase().includes("COVEA")) ||
      this.context.companyName === "COVEA"
    ) {
      this.company = "covea";
      simplified = true;
    } else {
      simplified = false;
    }

    return (
      <>
        <ExpertisesReports
          onClickAdd={() =>
            this.setState({
              isModalReport: true,
            })
          }
          onClick={(tabsId) => this.setState({ tabsId })}
          onClickDelete={this.deleteReport}
          data={this.props.data}
          tabsId={this.state.tabsId}
          isReport={true}
        >
          {this.state.tabsId !== null &&
          this.props.data.hasOwnProperty(this.state.tabsId) ? (
            <Report
              key={this.state.tabsId}
              data={this.props.data[this.state.tabsId]}
              dataRecallFacts={this.props.dataRecallFacts}
              dataAdministrative={this.props.dataAdministrative}
              dataEvaluation={this.props.dataEvaluation}
              updateReport={this.updateReport}
              onClickDelete={this.deleteReport}
              type={this.context.type}
            />
          ) : (
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0 3px 6px 0 rgb(0 0 0 / 16%)",
              }}
              className="p-20 bg-gray text-center"
            >
              <span>Aucun rapport sélectionné</span>
            </div>
          )}
        </ExpertisesReports>
        <ModalReport
          show={this.state.isModalReport}
          dataExpertise={this.props.dataExpertise}
          addReport={this.addReport}
          onClose={() =>
            this.setState({
              isModalReport: false,
            })
          }
          isSimplified={simplified}
          company={this.company}
        />
      </>
    );
  }
}
