import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { FormInput, Switch } from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";


export default class RCadditionalDamage extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "RCadditionalDamage";
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = value;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    this.context.updateDataReport("rcAdditionalDamage", tmpData);
  };

  getSwitch = (title, name, isInput = true) => {
    return (
      <Col xs={12}>
        <div className="d-flex mb-10">
          <p className="mt-auto mb-auto mr-10">{title}</p>
          <Switch
            className="mt-auto mb-auto"
            value1="Non"
            value2="Oui"
            color1="#026fc2"
            color2="#f2711c"
            idxActive={
              this.props.data.hasOwnProperty(`switch_${name}`)
                ? this.props.data[`switch_${name}`]
                : 0
            }
            onChange={(type) => {
              let tmpData = this.props.data;
              tmpData[`switch_${name}`] = type;
              this.setData(tmpData);
            }}
          />
          {isInput && this.props.data[`switch_${name}`] === 1 ? (
            <>
              <FormInput
                className="mt-auto mb-auto ml-10"
                name={`text_${name}`}
                value={this.props.data[`text_${name}`]}
                onChange={this.handleInputChange}
              />
              <span className="nowrap mt-auto mb-auto ml-10">
                € HT /{" "}
                {(
                  parseFloat(this.props.data[`text_${name}`] || 0) * 1.2
                ).toFixed(2)}{" "}
                € TTC
              </span>
            </>
          ) : null}
        </div>
      </Col>
    );
  };

  getData = () => {
    return (
      <>
        <h3>Immobilisation du véhicule</h3>
        <Row>
          <Col xs={12} md={6}>
            <FormInput
              className="mb-10"
              type="number"
              title="A combien estimez-vous la durée de remise en état du véhicule ? (jour)"
              name="repair"
              value={this.props.repair}
              onChange={this.handleInputChange}
            />
          </Col>
          {this.getSwitch(
            "Le tiers a-t-il bénéficié d’un véhicule de prêt par le garage",
            "replacementVehicle",
            false
          )}
          {!this.props.data["switch_replacementVehicle"]
            ? this.getSwitch(
                "Dans la négative, le tiers a-t-il loué un véhicule de remplacement ",
                "elseReplacementVehicle",
                false
              )
            : null}
        </Row>
        <h3>Détail des frais</h3>
        <Row>
          {this.getSwitch(
            "Location véhicule remplacement",
            "replacementVehicleCost"
          )}
          {this.getSwitch("Montant dépannage", "breakdownAmount")}
          {this.getSwitch("Frais de gardiennage", "storageCosts")}
        </Row>
      </>
    );
  };

  render() {
    return (
      <CustomCard title="Frais annexes" name={this.displayName}>
        {this.getData()}
      </CustomCard>
    );
  }
}
