import React from "react";

import {
  Loader,
  FullModal,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  ButtonIcon,
  Pagination,
  FormSelect,
  FormInput,
} from "ui-kit-ck-consultant";

import AuthContext from "../../context/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faEye,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faFile,
} from "@fortawesome/free-solid-svg-icons";

import { Row, Col } from "react-flexbox-grid";

import { STATUS } from "../folder/file-preparation/HistoryPostalTable";

import PostalIcon from "../icons/PostalIcon";
import ModalPDF from "../folder/general/ModalPDF";

const TYPE = {
  dataAcknowledgmentReceipts: "Accusé de réception",
  dataRequestedDocuments: "Demande de documents",
  dataSummons: "Convocation",
  dataFreeEntry: "Saisie librie",
};

export default class ModalHistoryPostal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageLength: 10,
      statusArray: [],
      search: "",
      status: "",
      isLoad: false,
      depositProofData: "",
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.reformatStatus();
    if (this.props.folderReference) {
      this.setState(() => ({ search: this.props.folderReference }));
    }
  }

  filter(data) {
    const { search, status } = this.state;

    return data.filter((maileva) => {
      if (!maileva.reference.startsWith(search)) {
        return false;
      }

      if (status && status !== maileva.status) {
        return false;
      }

      return true;
    });
  }

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  reformatStatus() {
    for (const [key, value] of Object.entries(STATUS)) {
      this.setState((prev) => ({
        statusArray: [...prev.statusArray, { text: value, value: key }].sort(
          (a, b) =>
            a.text.localeCompare(b.text, "fr", {
              ignorePunctuation: true,
            })
        ),
      }));
    }
  }

  render() {
    const { search, statusArray, status } = this.state;
    const mailevaData = this.filter(this.props.mailevaData || []);

    return (
      <>
        <FullModal
          title="Historiques des courriers"
          show={this.props.show}
          onClose={this.props.onClose}
          isMove
        >
          <Row>
            <Col xs={12} md={3} lg={2}>
              <FormInput
                className="mb-10 mr-10"
                name="search"
                value={search}
                placeholder="N° dossier ou immatriculation"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={3} lg={2}>
              <FormSelect
                className="mb-10"
                name="status"
                value={status}
                ignore={true}
                init="Veuillez sélectionner un statut"
                options={statusArray}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          <Table style={{ overflow: "visible" }}>
            <Thead>
              <Tr>
                <Th>N° dossier</Th>
                <Th>Date d'envoi</Th>
                <Th>Dernière mise à jour</Th>
                <Th>Destinataires</Th>
                <Th>Adresse</Th>
                <Th>Statut</Th>
                <Th>Onglet Rédaxion</Th>
                <Th>Type d'envoi</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {mailevaData
                .slice(
                  (this.state.activePage - 1) * this.state.pageLength,
                  this.state.activePage * this.state.pageLength
                )
                .map((el, idx) => (
                  <Tr key={idx}>
                    <Td>{el.reference}</Td>
                    <Td>{el.insertDate}</Td>
                    <Td>{el.updateDate}</Td>
                    <Td>{el.recipient}</Td>
                    <Td>{el.address}</Td>
                    <Td>{STATUS[el.status]}</Td>
                    <Td>{TYPE[el.type]}</Td>
                    <Td className="text-center">
                      <PostalIcon type={el.typeSend} />
                    </Td>
                    <Td>
                      <ButtonIcon
                        small
                        className="blue"
                        onClick={() => {
                          let token = "";
                          if (this.context.isSoftware) {
                            token = `token/${window.localStorage.getItem(
                              "token"
                            )}/`;
                          }

                          const url = `${this.context.domain}/api/folder/get-document/${token}${el.filename}`;
                          window.open(url, "_blank");
                        }}
                        info="Visionner le courrier"
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </ButtonIcon>
                      {el.typeSend !== "simple " && el.status === "LP18" ? (
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() => this.handleGetDepositProofPDF(el)}
                          info="Visualisation preuve de dépôt"
                        >
                          <FontAwesomeIcon icon={faFile} />
                        </ButtonIcon>
                      ) : null}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.pageLength}
            totalItemsCount={mailevaData.length}
            pageRangeDisplayed={4}
            onChange={(activePage) => {
              this.setState({ activePage: activePage });
            }}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </FullModal>

        <Loader show={this.state.isLoad} />
        <ModalPDF
          name={this.state.depositProofData.name}
          base64={this.state.depositProofData.base64}
          show={this.state.depositProofData}
          onClose={() => {
            this.setState({ depositProofData: "" });
          }}
        />
      </>
    );
  }
}
