import React from "react";
import { faEyeSlash, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "react-flexbox-grid";
import { ButtonIcon, Status } from "ui-kit-ck-consultant";

import ModalIdentificationPartiesUser from "./ModalIdentificationPartiesUser";

import { writeLog } from "../../../requests/general";

import AuthContext from "../../../context/AuthContext";

export default class IdentificationPartiesUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
      idxModal: null,
    };
  }

  static contextType = AuthContext;

  setDataParties = (tmpData) => {
    if (this.context.updateDataReport) {
      this.context.updateDataReport("partiesReport", tmpData);
    }
  };

  render() {
    if (this.props.dataParties && this.props.dataParties[this.props.idx]) {
      return null;
    }

    let color = "";
    if (this.props.party === "Adverse") {
      color = "red bg-red-light";
    } else if (this.props.party === "Lésé") {
      color = "blue bg-blue-light";
    } else if (this.props.party === "Aucune") {
      color = "green bg-green-light";
    }

    return (
      <>
        <Col xs={12} md={6} lg={3} className="mb-20">
          <div
            style={{ borderRadius: "6px", overflow: "hidden" }}
            className="bg-gray-light p-10 h-100"
          >
            <div className="d-flex mb-10">
              <Status
                className={`mb-auto mr-auto ${color}`}
                text={
                  this.props.quality === "Autre"
                    ? this.props.otherQuality
                    : this.props.quality
                }
              />
              {this.props.data[this.props.idx].quality !== "Lésé" &&
              !this.props.dataParties ? (
                <ButtonIcon
                  className="ml-10 p-0"
                  small
                  onClick={() => {
                    window.confirmCustom(
                      "Êtes-vous sûr de vouloir supprimer cette partie ?",
                      (result) => {
                        if (result) {
                          writeLog(this.context.folderId, "delete-party", {
                            userId: this.context.id,
                            party: this.props.data[this.props.idx],
                          });
                          let tmpData = this.props.data;
                          delete tmpData[this.props.idx];
                          this.props.setData(tmpData);
                        }
                      }
                    );
                  }}
                >
                  <FontAwesomeIcon className="red" icon={faTrash} />
                </ButtonIcon>
              ) : null}
              {this.props.dataParties ? (
                <ButtonIcon
                  className="ml-10 p-0"
                  small
                  onClick={() => {
                    window.confirmCustom(
                      "Êtes-vous sûr de vouloir masquer cette personne présente ?",
                      (result) => {
                        if (result) {
                          let tmpData = this.props.dataParties;

                          tmpData[this.props.idx] = true;

                          this.setDataParties(tmpData);
                        }
                      }
                    );
                  }}
                >
                  <FontAwesomeIcon className="red" icon={faEyeSlash} />
                </ButtonIcon>
              ) : null}
              <ButtonIcon
                className="ml-10 p-0 "
                small
                onClick={() => {
                  this.setState({ isModal: true });
                }}
              >
                <FontAwesomeIcon className="blue" icon={faPen} />
              </ButtonIcon>
            </div>
            <p className="m-0 mb-5">
              <b>{this.props.name}</b>
            </p>
            <p className="m-0 mb-5 ft-14">{this.props.society}</p>
            <p className="m-0 mb-5 ft-14">{this.props.address}</p>
            <p className="m-0 mb-5 ft-14">{this.props.address2}</p>
            <p className="m-0 mb-5 ft-14">
              {this.props.zipCode} {this.props.city}
            </p>
            <p className="m-0 mb-5 ft-14">{this.props.email}</p>
            <p className="m-0 mb-5 ft-14">{this.props.phone}</p>
            <p className="m-0 mb-5 ft-14">{this.props.cellphone}</p>
          </div>
        </Col>
        <ModalIdentificationPartiesUser
          show={this.state.isModal}
          setData={this.props.setData}
          idx={this.props.idx}
          data={this.props.data}
          onClose={() => this.setState({ isModal: false })}
        />
      </>
    );
  }
}
