import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import DashboardLayout from "./layouts/Dashboard";
import DashboardFolderLayout from "./layouts/DashboardFolder";

// Route Views
import Home from "./views/dashboard/Home";
import Folder from "./views/dashboard/Folder";

const routes = [
  {
    path: "/",
    exact: true,
    layout: DashboardLayout,
    component: () => <Redirect to="/home" />,
  },
  {
    path: "/home",
    exact: true,
    layout: DashboardLayout,
    component: Home,
  },
  {
    path: "/folder/:token",
    exact: true,
    layout: DashboardFolderLayout,
    component: Folder,
  },
];

export default routes;
