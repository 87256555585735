import React from "react";

import CardEditor from "../CardEditor";

export default class InsuredActivity extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "InsuredActivity";
  }

  render() {
    const defaultData = `<div>
<p>Les conditions particulières ne nous ont pas été transmises et la réunion d'expertise n'a pas eu lieu dans les locaux de l'assuré. De ce fait, nous n'avons pas pu constater quelle était l'activité de l'assuré. Néanmoins, nous pouvons vous confirmer que l'intervention pour laquelle l'assuré est mis en cause est de l'ordre de la réparation et l'entretien mécanique OU carrosserie.</p>
<p>Les conditions particulières ne nous ont pas été transmises mais nos opérations d'expertise ont eu lieu dans les locaux de l'assuré. De ce fait, nous avons pu constater que son activité est de l'ordre de la réparation et l'entretien mécanique OU carrosserie.</p>
</div>`;

    return (
      <CardEditor
        {...this.props}
        name={this.displayName}
        title="Activité de l'assuré"
        dataName="insuredActivity"
        default={defaultData}
      />
    );
  }
}
