import React from "react";
import {
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
} from "ui-kit-ck-consultant";

import AdministrativeInformation from "./administrative/AdministrativeInformation";
import PurposeMission from "./administrative/PurposeMission";
import VehicleIdentification from "./administrative/VehicleIdentification";
import Users from "./administrative/Users";
import IdentificationParties from "./administrative/IdentificationParties";
import Fees from "./administrative/Fees";

import AuthContext from "../../../context/AuthContext";

export default class Administrative extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsIdArray: [
        { title: "Informations administratives" },
        { title: "Objet de la mission" },
        { title: "Identification véhicule" },
        { title: "Identification des parties" },
        { title: "Honoraires" },
      ],
      tabsId: 0,
    };
  }

  static contextType = AuthContext;

  setData = (name, data, callback = () => {}) => {
    let tmpData = this.props.dataAdministrative;
    tmpData[name] = data;
    this.context.updateDataFolder("dataAdministrative", tmpData, callback);
  };

  getData = (name, isArray = false) => {
    if (this.props.dataAdministrative.hasOwnProperty(name)) {
      return this.props.dataAdministrative[name];
    } else {
      return isArray ? [] : {};
    }
  };

  render() {
    return (
      <TabsCustom className="mt-10 mt-20-xl">
        <TabsCustomHeader className="m-0">
          {this.state.tabsIdArray.map((element, key) => (
            <TabsCustomMenu
              key={key}
              title={element.title}
              disabled={element.disabled}
              active={this.state.tabsId === key}
              onClick={(e) =>
                this.setState({
                  tabsId: key,
                })
              }
            />
          ))}
        </TabsCustomHeader>
        <TabsCustomBody noCard>
          <TabsCustomItem active={this.state.tabsId === 0}>
            <AdministrativeInformation
              data={this.getData("dataAdministrativeInformation")}
              setData={this.setData}
            />
          </TabsCustomItem>
          <TabsCustomItem active={this.state.tabsId === 1}>
            <PurposeMission
              data={this.getData("dataPurposeMission")}
              setData={this.setData}
            />
          </TabsCustomItem>
          <TabsCustomItem active={this.state.tabsId === 2}>
            <VehicleIdentification
              data={this.getData("dataVehicleIdentification", true)}
              dataRecallFacts={this.props.dataRecallFacts}
            />
          </TabsCustomItem>
          <TabsCustomItem active={this.state.tabsId === 3}>
            <Users
              data={this.getData("dataAdministrativeInformation")}
              setData={this.setData}
            />
            <IdentificationParties
              data={this.getData("dataIdentificationParties")}
              setData={this.setData}
            />
          </TabsCustomItem>
          <TabsCustomItem active={this.state.tabsId === 4}>
            <Fees
              data={this.getData("dataFees", true)}
              setData={this.setData}
            />
          </TabsCustomItem>
        </TabsCustomBody>
      </TabsCustom>
    );
  }
}
