import React from "react";
import { Card, Button } from "ui-kit-ck-consultant";

import AuthContext from "../../context/AuthContext";

import { getFolders, deleteFolder } from "../../db/folder";

export default class Memory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      foldersLocal: [],
      memoryUsage: 0,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    const humanReadableSize = (bytes) => {
      var thresh = 1024;
      if (Math.abs(bytes) < thresh) {
        return bytes + " B";
      }

      bytes = bytes / thresh / thresh;

      return parseInt(bytes);
    };

    (async () => {
      this.setState({
        memoryUsage: humanReadableSize(
          (await navigator.storage.estimate()).usage
        ),
      });
    })();

    getFolders((result) => {
      this.setState({
        foldersLocal: result.data,
      });
    });
  }

  render() {
    return (
      <Card className="mt-10 mt-20-xl flex-column">
        <div className="d-flex" style={{ gap: 10 }}>
          <p
            style={{ borderRadius: 8 }}
            className={`mt-auto mb-auto nowrap p-10 white ${
              this.state.memoryUsage > 15 ? "bg-orange" : "bg-green"
            } ${this.state.memoryUsage > 30 ? "bg-red" : ""}`}
          >
            {this.state.memoryUsage} Mo
          </p>
          <Button
            className="mt-auto mb-auto"
            orange
            text="Vider le cache"
            onClick={() => {
              window.confirmCustom(
                "Attention, cette action est irréversible, êtes-vous sûr de vouloir continuer",
                async (result) => {
                  if (result) {
                    window.localStorage.clear();
                    const req = indexedDB.deleteDatabase("RedaxionDB");
                    req.onsuccess = () => {
                      window.location.reload();
                    };
                  }
                }
              );
            }}
          />
          <Button
            className="mt-auto mb-auto"
            red
            text="Vider le cache des dossiers clôturés"
            onClick={() => {
              window.confirmCustom(
                "Attention, cette action est irréversible, êtes-vous sûr de vouloir continuer",
                async (result) => {
                  if (result) {
                    for (
                      let index = 0;
                      index < this.state.foldersLocal.length;
                      index++
                    ) {
                      Promise.all(
                        this.state.foldersLocal
                          .filter((folder) => folder.stateId === 8)
                          .map((folder) => {
                            return new Promise((resolve) => {
                              deleteFolder(folder.idLocal, () => {
                                resolve();
                              });
                            });
                          })
                      ).then(() => {
                        window.location.reload();
                      });
                    }
                  }
                }
              );
            }}
          />
        </div>
      </Card>
    );
  }
}
