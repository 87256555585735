import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import {
  faCamera,
  faUpload,
  faPen,
  faTrash,
  faTimes,
  faSave,
  faFile,
  faEye,
  faKeyboard,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomItem,
  ButtonIcon,
  DropzoneMultiple,
  Button,
  Loader,
  FormInput,
} from "ui-kit-ck-consultant";
import { RCamera } from "react-camera-media-stream";
import ImageEditor from "@toast-ui/react-image-editor";

import "tui-image-editor/dist/tui-image-editor.css";

import { addFile, renameFile } from "../../requests/folder/file";

import {
  generateString,
  resizeImage,
  getFileShorcut,
} from "../../utils/general";

import AuthContext from "../../context/AuthContext";

export default class ModalLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsIdArray: [
        { title: "Appareil photo" },
        { title: "Mon appareil" },
        { title: "Fichier(s) du dossier" },
      ],
      tabsId: 0,
      totalFile: 0,
      currentFile: 0,
      newFiles: [],
      isEdit: false,
      editImage: "",
      editFilename: "",
      scrollPosition: 0,
      isCamera: false,
      dropzoneToken: generateString(),
      idxActive: [],
      showEmbed: false,
      embedPath: "",
      customFilename: "",
      showRename: false,
      customFileFolderId: 0,
      customFileToken: "",
    };

    this.refImageEditor = createRef();
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show && this.props.show) {
      let idxActive = [];

      if (this.props.filenameEdit) {
        this.context.files.forEach((file, idx) => {
          if (
            file.filename === this.props.filenameEdit &&
            (!this.props.tokenEdit || file.token === this.props.tokenEdit)
          ) {
            idxActive.push(idx);
          }
        });

        if (idxActive.length) {
          this.setState({
            isEdit: true,
            editImage: getFileShorcut(
              this.context,
              this.context.files[idxActive[0]]
            ),
            editFilename: this.context.files[idxActive[0]].filename,
          });
        } else {
          this.onClose();
          window.alert("L'image n'est plus disponible");
        }
      }
    }
  }

  onClose = () => {
    this.setState(
      {
        idxActive: [],
      },
      this.props.onClose
    );
  };

  addFiles = () => {
    this.setState(
      {
        totalFile: this.state.newFiles.length,
      },
      () => {
        this.addFile();
      }
    );
  };

  addFile = () => {
    if (this.state.currentFile === this.state.totalFile) {
      const totalFile = this.state.totalFile;
      this.setState(
        {
          currentFile: 0,
          totalFile: 0,
          newFiles: [],
          dropzoneToken: generateString(),
        },
        () => {
          this.getFiles(totalFile);
        }
      );
    } else {
      const file = this.state.newFiles[this.state.currentFile];

      addFile(
        {
          reference: this.context.reference,
          ...file,
          filename: file.name,
          name: undefined,
        },
        (result) => {
          let tmpFiles = this.context.files;

          if (result.success) {
            tmpFiles = [
              {
                base64: file.base64,
                filename: file.name,
                extension: file.extension,
                mime: file.mime,
                token: result.data.token,
                id: result.data.id,
                path: result.data.path,
              },
              ...tmpFiles,
            ];
          }

          this.context.updateFiles(tmpFiles, () => {
            this.setState(
              {
                currentFile: this.state.currentFile + 1,
              },
              this.addFile
            );
          });
        }
      );
    }
  };

  getFiles = (countFile = 0) => {
    this.props.getFiles(() => {
      this.setState({ idxActive: [] }, () => {
        if (countFile) {
          let idxActive = [];
          for (let index = 0; index < countFile; index++) {
            idxActive.push(index);
          }
          this.setState(
            {
              idxActive,
            },
            this.setFiles
          );
        }
      });
    });
  };

  deleteFile = (folderId, token) => {
    this.props.deleteFile(folderId, token, () => {
      this.getFiles();
    });
  };

  setFiles = () => {
    const files = this.context.files.filter((file, idx) =>
      this.state.idxActive.includes(idx)
    );

    if (this.props.setFiles) {
      this.props.setFiles(files);
    }

    this.onClose();
  };

  render() {
    const imageExtensions = ["png", "jpeg", "jpg"];

    const myTheme = {
      // Theme object to extends default dark theme.
    };

    return (
      <>
        <Modal
          className="p-20 pt-0 overflow-hidden"
          classNameChildren="h-100"
          show={this.props.show}
          onClose={this.onClose}
          onValid={
            this.props.setFiles
              ? () => {
                  this.setFiles();
                }
              : null
          }
          large
          isMove
        >
          <TabsCustom className="overflowY-auto">
            <TabsCustomHeader className="pr-120" noCard>
              {this.state.tabsIdArray.map((element, key) => (
                <TabsCustomMenu
                  key={key}
                  title={element.title}
                  disabled={element.disabled}
                  active={this.state.tabsId === key}
                  onClick={(e) =>
                    this.setState({
                      tabsId: key,
                      newFiles: [],
                      dropzoneToken: generateString(),
                    })
                  }
                />
              ))}
            </TabsCustomHeader>
            <TabsCustomBody noCard className="h-100 overflowY-auto">
              <TabsCustomItem active={this.state.tabsId === 0}>
                <Button
                  text="Ouvril l'appareil photo"
                  className="m-auto mt-20 mb-20 w-auto"
                  onClick={() => {
                    this.setState({
                      scrollPosition: window.pageYOffset,
                      isCamera: true,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCamera} />
                </Button>
              </TabsCustomItem>
              <TabsCustomItem active={this.state.tabsId === 1}>
                <DropzoneMultiple
                  key={this.state.dropzoneToken}
                  extensions={["jpeg", "jpg", "png", "pdf"]}
                  onDrop={(files) => this.setState({ newFiles: files })}
                  maxImageSize={2000}
                />
                <div className="d-flex">
                  <Button
                    onClick={this.addFiles}
                    disabled={!this.state.newFiles.length}
                    className="w-auto ml-auto mt-10"
                    text={`Envoyer ${this.state.newFiles.length} fichiers`}
                  >
                    <FontAwesomeIcon icon={faUpload} />
                  </Button>
                </div>
              </TabsCustomItem>
              <TabsCustomItem
                active={this.state.tabsId === 2}
                className={`p-10`}
              >
                <Row>
                  {this.context.files.map((file, idx) => (
                    <Col
                      xs={12}
                      md={4}
                      lg={3}
                      key={`${file.token}`}
                      className="mb-10"
                    >
                      <div
                        style={{
                          position: "relative",
                          height: "256px",
                          backgroundColor: "#eeeeee",
                          borderRadius: "15px",
                          overflow: "hidden",
                          display: "flex",
                          WebkitUserSelect: "none",
                          MozUserSelect: "none",
                          msUserSelect: "none",
                          userSelect: "none",
                          transition: "0.3s",
                          cursor: "pointer",
                          opacity: this.state.idxActive.includes(idx) ? 0.4 : 1,
                        }}
                        onClick={() => {
                          let idxActive = this.state.idxActive;

                          if (idxActive.includes(idx)) {
                            idxActive.splice(idxActive.indexOf(idx), 1);
                          } else {
                            idxActive.push(idx);
                          }

                          this.setState({
                            idxActive,
                          });
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            display: "flex",
                            right: 0,
                            left: 0,
                            top: 0,
                            padding: "0 10px",
                            backgroundColor: "rgba(0,0,0,0.4)",
                            fontSize: "13px",
                          }}
                        >
                          <>
                            <ButtonIcon
                              onClick={() =>
                                this.setState({
                                  showEmbed: true,
                                  embedPath: getFileShorcut(this.context, file),
                                })
                              }
                              className="ml-auto blue"
                              info="Visionner"
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </ButtonIcon>
                            <ButtonIcon
                              small
                              className="blue"
                              info="Renommer"
                              onClick={() =>
                                this.setState({
                                  showRename: true,
                                  customFileFolderId: file.folderId,
                                  customFileToken: file.token,
                                  customFilename: file.filename,
                                })
                              }
                            >
                              <FontAwesomeIcon icon={faKeyboard} />
                            </ButtonIcon>
                            {imageExtensions.includes(file.extension) ? (
                              <ButtonIcon
                                small
                                className="blue"
                                onClick={() =>
                                  this.setState({
                                    isEdit: true,
                                    editImage: getFileShorcut(
                                      this.context,
                                      file
                                    ),
                                    editFilename: file.filename,
                                  })
                                }
                                info="Modifier"
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </ButtonIcon>
                            ) : null}
                            <ButtonIcon
                              small
                              className="red"
                              info="Supprimer"
                              onClick={() =>
                                window.confirmCustom(
                                  "Êtes-vous sûr de vouloir supprimer ce fichier ?",
                                  (result) => {
                                    if (result) {
                                      this.deleteFile(
                                        file.folderId,
                                        file.token
                                      );
                                    }
                                  }
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </ButtonIcon>
                          </>
                        </div>
                        {imageExtensions.includes(file.extension) ? (
                          <img
                            loading="lazy"
                            src={getFileShorcut(this.context, file, 320)}
                            alt="library"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              margin: "auto",
                            }}
                          />
                        ) : (
                          <div className="m-auto">
                            <FontAwesomeIcon
                              className="blue"
                              style={{ fontSize: "30px" }}
                              icon={faFile}
                            />
                          </div>
                        )}
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "rgba(0,0,0,0.4)",
                            fontSize: "13px",
                            color: "white",
                          }}
                        >
                          <span>{file.filename}</span>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </TabsCustomItem>
            </TabsCustomBody>
          </TabsCustom>
        </Modal>
        {this.state.isEdit ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 1000,
            }}
          >
            <ImageEditor
              ref={this.refImageEditor}
              includeUI={{
                loadImage: {
                  path: this.state.editImage,
                  name: this.state.editFilename,
                },
                theme: myTheme,
                menu: [
                  "crop",
                  "flip",
                  "rotate",
                  "draw",
                  "shape",
                  "icon",
                  "text",
                ],
                initMenu: "draw",
                menuBarPosition: "bottom",
              }}
              cssMaxHeight={window.innerHeight - 100}
              cssMaxWidth={window.innerWidth - 100}
              selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70,
              }}
              usageStatistics={true}
            />
            <div
              className="d-flex"
              style={{
                position: "absolute",
                right: "5px",
                top: "5px",
              }}
            >
              <Button
                red
                text="Fermer"
                onClick={() =>
                  this.setState({ isEdit: false }, () => {
                    if (this.props.filenameEdit) {
                      this.onClose();
                    }
                  })
                }
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
              <Button
                green
                className="ml-5"
                text="Enregistrer"
                onClick={() => {
                  const editorInstance =
                    this.refImageEditor.current.getInstance();
                  resizeImage(
                    editorInstance.toDataURL(),
                    "image/png",
                    (result) => {
                      this.setState(
                        {
                          isEdit: false,
                          newFiles: [
                            {
                              base64: result,
                              extension: ".png",
                              mime: "image/png",
                              name: `editor-${new Date().getTime()}.png`,
                            },
                          ],
                        },
                        this.addFiles
                      );
                    }
                  );
                }}
              >
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </div>
          </div>
        ) : null}
        {this.state.isCamera ? (
          <RCamera
            isConfirm={false}
            onTakePicture={(base64) => {
              const mime = base64.split(";")[0].split(":")[1];

              resizeImage(base64, mime, (result) => {
                this.setState(
                  {
                    newFiles: [
                      {
                        base64: result,
                        extension: mime.split("/")[1],
                        mime: mime,
                        name: `redaxion-${new Date().getTime()}.${
                          mime.split("/")[1]
                        }`,
                      },
                    ],
                  },
                  this.addFiles
                );
              });
            }}
            onClose={() =>
              this.setState({ isCamera: false }, () => {
                window.scrollTo(0, this.state.scrollPosition);
              })
            }
            isFullscreen={true}
            namePicture="redaxion"
            isTorch={true}
          />
        ) : null}
        <Loader
          show={this.state.currentFile < this.state.totalFile}
          message={`Importation en cours : ${this.state.currentFile}/${this.state.totalFile}`}
        />
        <Modal
          show={this.state.showEmbed}
          large
          onClose={() => this.setState({ showEmbed: false })}
          title="Visualisation"
        >
          <div>
            <embed
              src={this.state.embedPath}
              // type="application/pdf"
              width="700px"
              height="900px"
            />
          </div>
        </Modal>
        <Modal
          show={this.state.showRename}
          large
          onClose={() => this.setState({ showRename: false })}
          title="Renommer"
        >
          <FormInput
            title="Nom du fichier"
            value={this.state.customFilename}
            onChange={(e) => this.setState({ customFilename: e.target.value })}
          />
          <div
            className="d-flex mt-10"
            style={{ justifyContent: "end", gap: 10 }}
          >
            <Button
              className="w-auto"
              text="Annuler"
              red
              onClick={() => this.setState({ showRename: false })}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <Button
              className="w-auto"
              text="Valider"
              onClick={() =>
                renameFile(
                  {
                    folderId: this.state.customFileFolderId,
                    token: this.state.customFileToken,
                    name: this.state.customFilename,
                  },
                  () => {
                    this.setState({ showRename: false }, () => {
                      this.getFiles(this.state.totalFile);
                    });
                  }
                )
              }
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}
