import html2canvas from "html2canvas";

const html2canvasInstance = (ref, callback) => {
  html2canvas(ref, {
    backgroundColor: null,
    allowTaint: false,
    useCORS: true,
    scale: 2.2,
  }).then((canvas) => {
    callback({
      success: true,
      base64: canvas.toDataURL("image/jpeg", 0.9),
    });
  });
};

export default html2canvasInstance;
