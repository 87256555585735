import React from "react";
import { FormInput, Switch, FormRadio } from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class AdditionalInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.displayName = "AdditionalInformation";
  }

  static contextType = AuthContext;

  setData = (tmpData) => {
    this.context.updateDataReport("additionalInformation", tmpData);
  };

  getSwitch = (text, name) => {
    return (
      <div className="d-flex mb-20 mt-auto">
        <p className="mt-auto mb-auto mr-10 nowrap">{text}</p>
        <Switch
          className="mt-auto mb-auto"
          value1="Non"
          value2="Oui"
          color1="#f2711c"
          color2="#026fc2"
          idxActive={
            this.props.data.hasOwnProperty(`switch_${name}`)
              ? this.props.data[`switch_${name}`]
              : 0
          }
          onChange={(type) => {
            let tmpData = this.props.data;
            tmpData[`switch_${name}`] = type;
            this.setData(tmpData);
          }}
        />
      </div>
    );
  };

  getInput = (title, name, type = "") => {
    return (
      <div className="d-flex">
        <FormInput
          className="mb-20 w-auto"
          type={type}
          title={title}
          value={this.props.data[`input_${name}`] || ""}
          onChange={({ target }) => {
            let tmpData = this.props.data;
            tmpData[`input_${name}`] = target.value;
            this.setData(tmpData);
          }}
        />
      </div>
    );
  };

  getRadio = (title, texts, name) => {
    return (
      <div className="mb-20">
        <p className="m-0">
          <b>{title}</b>
        </p>
        {texts.map((text, idx) => (
          <FormRadio
            key={idx}
            text={text}
            className="mt-10"
            checked={this.props.data[`radio_${name}`] === text}
            onChange={() => {
              let tmpData = this.props.data;
              tmpData[`radio_${name}`] = text;
              this.setData(tmpData);
            }}
          />
        ))}
      </div>
    );
  };

  render() {
    return (
      <CustomCard title="Complément d'information" name={this.displayName}>
        {this.getSwitch("Véhicule immobilisé", "vehicle")}
        {this.getSwitch("Certificat d'immatriculation présenté", "certificate")}
        {this.getSwitch("Condition d'achat", "conditon")}
        {this.getInput("Date de la transaction", "transaction", "date")}
        {this.getRadio(
          "Transaction effectuée auprès",
          ["D’un particulier", "D’un professionnel"],
          "type"
        )}
        {this.getInput("Prix d'acquisition", "price")}
        {this.getInput("Valeur marché au jour de l'acquisition", "market")}
        {this.getInput("Kilométrage au moment de la transaction", "mileage")}
      </CustomCard>
    );
  }
}
