import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import "./index.css";
import "react-camera-media-stream/dist/index.css";
import "ui-kit-ck-consultant/dist/index.css";
import "ui-kit-ck-consultant/css/style.css";
import "./tui-color-picker.css";

console.log(
  `Name : ${process.env.REACT_APP_NAME}\nVersion : ${process.env.REACT_APP_VERSION}`
);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
