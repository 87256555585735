import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEye,
  faMailBulk,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "ui-kit-ck-consultant";

import ModalPDF from "../general/ModalPDF";

import AuthContext from "../../../context/AuthContext";

export default class BottomButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalPreview: false,
      base64Preview: "",
      titlePreview: "",
    };
  }

  static contextType = AuthContext;

  render() {
    return (
      <div style={{ overflowX: "scroll" }}>
        <div className="mt-10 mb-10">
          {this.props.files.map((file, idx) => (
            <div
              key={idx}
              className="mr-10"
              style={{
                display: "inline-flex",
                borderRadius: "6px",
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
                padding: "10px",
              }}
            >
              <span className="m-auto" style={{ fontSize: "12px" }}>
                {file.name}
              </span>
              <div
                className="m-auto ml-5 cursor-pointer"
                onClick={() => {
                  let tmpFiles = this.props.files;

                  tmpFiles.splice(idx, 1);

                  this.props.onFilesChange(tmpFiles);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex">
          <Button
            className="w-auto ml-10"
            text={"Sauvegarder"}
            onClick={() => this.props.saveMessage()}
          >
            <FontAwesomeIcon icon={faSave} />
          </Button>
          <Button
            className="w-auto ml-10"
            text={"Prévisualiser le pdf"}
            onClick={() =>
              this.props.addMessage(true, (result) => {
                this.setState({
                  isModalPreview: true,
                  base64Preview: result.data.attachments[0].content,
                  titlePreview: "previsualisation.pdf",
                });
              })
            }
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
          {this.props.canBeSendByLetter ? (
            <>
              <Button
                className="w-auto ml-10"
                text={"Prévisualiser le courrier"}
                disabled={
                  this.props.recipient &&
                  this.context.parties[this.props.recipient] &&
                  this.context.parties[this.props.recipient].address
                    ? false
                    : true
                }
                onClick={() =>
                  this.props.addMessagePostal(true, (result) => {
                    this.setState({
                      isModalPreview: true,
                      base64Preview: result.data,
                      titlePreview: "previsualisation.pdf",
                    });
                  })
                }
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
              <Button
                className="w-auto ml-10"
                text={"Envoyer par courrier"}
                disabled={
                  this.props.recipient &&
                  this.context.parties[this.props.recipient] &&
                  this.context.parties[this.props.recipient].address
                    ? false
                    : true
                }
                onClick={() => this.props.addMessagePostal(false)}
              >
                <FontAwesomeIcon icon={faMailBulk} />
              </Button>
            </>
          ) : null}
          <Button
            className="w-auto ml-10"
            text={"Envoyer par mail"}
            disabled={
              this.props.recipient &&
              this.context.parties[this.props.recipient] &&
              this.context.parties[this.props.recipient].email
                ? false
                : true
            }
            onClick={() => this.props.addMessage(false)}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
        <ModalPDF
          onClose={() =>
            this.setState({
              isModalPreview: false,
              base64Preview: "",
              titlePreview: "",
            })
          }
          show={this.state.isModalPreview}
          base64={this.state.base64Preview}
          name={this.state.titlePreview}
        />
      </div>
    );
  }
}
