import React from "react";
import moment from "moment";

import CardEditor from "../CardEditor";

import { getParty } from "../../../../utils/general";

import AuthContext from "../../../../context/AuthContext";

export default class ContradictoryExamination extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "ContradictoryExamination";
  }

  static contextType = AuthContext;

  render() {
    const garage = getParty(this.context.parties, "Réparateur");

    const defaultData = `Nous organisons une réunion d’examen contradictoire le ${moment(
      this.context.dateAppointment ? this.context.dateAppointment : null
    ).format("DD/MM/YYYY")} à ${
      this.context.hourAppointment || "8:00"
    } au garage ${garage.name} ${garage.address} ${garage.address2}, ${
      garage.zipCode
    } ${garage.city}`;

    return (
      <CardEditor
        {...this.props}
        name={this.displayName}
        title="Conclusion provisoire"
        dataName="contradictoryExamination"
        default={defaultData}
      />
    );
  }
}
