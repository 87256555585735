import React from "react";
import moment from "moment";
import { faCheck, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-flexbox-grid";
import {
  Button,
  FormInput,
  FormSelect,
  FormTextarea,
  FullModal,
  Switch,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Loader,
  ButtonIcon,
} from "ui-kit-ck-consultant";

import {
  insertFolderCall,
  updateFolderCall,
  deleteFolderCall,
} from "../../requests/folder";

import AuthContext from "../../context/AuthContext";

export default class ModalCall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      callId: null,
      date: moment().format("YYYY-MM-DD"),
      hour: moment().format("HH:mm"),
      type: 0,
      interlocutor: "",
      otherInterlocutor: "",
      detail: "",
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  insertFolderCall = () => {
    if (
      this.state.date &&
      this.state.hour &&
      this.state.interlocutor &&
      this.state.detail
    ) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          insertFolderCall(
            {
              folder: this.context.folderId,
              date: `${this.state.date} ${this.state.hour}`,
              interlocutor:
                this.state.interlocutor === "Autre"
                  ? this.state.otherInterlocutor
                  : this.state.interlocutor,
              detail: this.state.detail,
              type: this.state.type,
            },
            (result) => {
              if (result.success) {
                this.setState(
                  {
                    isLoad: false,
                    date: moment().format("YYYY-MM-DD"),
                    hour: moment().format("HH:mm"),
                    interlocutor: "",
                    otherInterlocutor: "",
                    detail: "",
                    type: 0,
                  },
                  () => {
                    this.props.getFolderActions();
                    this.props.getFolderCalls();
                  }
                );
              } else {
                this.setState({ isLoad: false }, () => {
                  window.alert("Une erreur est survenue");
                });
              }
            }
          );
        }
      );
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  updateFolderCall = () => {
    if (
      this.state.date &&
      this.state.hour &&
      this.state.interlocutor &&
      this.state.detail
    ) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          updateFolderCall(
            {
              folder: this.context.folderId,
              call: this.state.callId,
              date: `${this.state.date} ${this.state.hour}`,
              interlocutor:
                this.state.interlocutor === "Autre"
                  ? this.state.otherInterlocutor
                  : this.state.interlocutor,
              detail: this.state.detail,
              type: this.state.type,
            },
            (result) => {
              if (result.success) {
                this.setState(
                  {
                    isLoad: false,
                    callId: null,
                    date: moment().format("YYYY-MM-DD"),
                    hour: moment().format("HH:mm"),
                    interlocutor: "",
                    otherInterlocutor: "",
                    detail: "",
                    type: 0,
                  },
                  () => {
                    this.props.getFolderActions();
                    this.props.getFolderCalls();
                  }
                );
              } else {
                this.setState({ isLoad: false }, () => {
                  window.alert("Une erreur est survenue");
                });
              }
            }
          );
        }
      );
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  deleteFolderCall = (call) => {
    window.confirmCustom(
      "Êtes-vous sûr de vouloir supprimer cet appel ?",
      (result) => {
        if (result) {
          this.setState(
            {
              isLoad: true,
            },
            () => {
              deleteFolderCall(
                {
                  call,
                },
                (result) => {
                  if (result) {
                    this.setState({ isLoad: false }, () => {
                      this.props.getFolderActions();
                      this.props.getFolderCalls();
                    });
                  } else {
                    this.setState({ isLoad: false }, () => {
                      window.alert("Une erreur est survenue");
                    });
                  }
                }
              );
            }
          );
        }
      }
    );
  };

  checkIfExist = (value) => {
    if (this.context.parties.hasOwnProperty(value)) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <>
        <FullModal
          show={this.props.show}
          onClose={() => {
            this.setState(
              {
                isLoad: false,
                callId: null,
                date: "",
                hour: "",
                interlocutor: "",
                detail: "",
                type: 0,
              },
              this.props.onClose
            );
          }}
          noPadding
        >
          <Row className="h-100 w-100">
            <Col
              xs={12}
              md={4}
              className="h-100 d-flex flex-column bg-blue-extra-light p-40 overflow-y-auto"
            >
              <h2 className="mb-20 mt-0">Tracer un appel</h2>
              <Row>
                <Col xs={12} md={6}>
                  <FormInput
                    name="date"
                    value={this.state.date}
                    className="mb-10"
                    type="date"
                    title="Date de l'appel"
                    onChange={this.handleInputChange}
                    required
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FormInput
                    name="hour"
                    value={this.state.hour}
                    className="mb-10"
                    type="time"
                    title="Heure de l'appel"
                    onChange={this.handleInputChange}
                    required
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Switch
                    className="mb-10"
                    title="Type d'appel"
                    value1="Émis"
                    value2="Reçu"
                    color1="#026fc2"
                    color2="#026fc2"
                    onChange={(type) => this.setState({ type })}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FormSelect
                    name="interlocutor"
                    value={this.state.interlocutor}
                    className="mb-10"
                    title="Interlocuteur"
                    ignore={true}
                    init="Veuillez sélectionner un interlocuteur"
                    options={[
                      ...this.context.partiesOptions,
                      { value: "Autre", text: "Autre" },
                    ]}
                    onChange={this.handleInputChange}
                    required
                  />
                </Col>
                {this.state.interlocutor === "Autre" ? (
                  <Col xs={12}>
                    <FormInput
                      name="otherInterlocutor"
                      value={this.state.otherInterlocutor}
                      className="mb-10"
                      title="Interlocuteur"
                      onChange={this.handleInputChange}
                      required
                    />
                  </Col>
                ) : null}
                <Col xs={12} className="h-100">
                  <FormTextarea
                    name="detail"
                    value={this.state.detail}
                    className="mb-10"
                    title="Détail de l'appel"
                    rows={12}
                    onChange={this.handleInputChange}
                    required
                    noResize
                  />
                </Col>
              </Row>
              <div className="d-flex mt-auto">
                <Button
                  className="w-auto ml-auto "
                  text={this.state.callId ? "Mettre à jour" : "Valider"}
                  onClick={
                    this.state.callId
                      ? this.updateFolderCall
                      : this.insertFolderCall
                  }
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </div>
            </Col>
            <Col xs={12} md={8} className="p-40">
              <h2 className="mb-20 mt-0">Historique des appels</h2>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Heure</Th>
                    <Th>Type d'appel</Th>
                    <Th>Interlocuteur</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {this.props.calls.map((call, idx) => (
                    <Tr key={idx}>
                      <Td>{call.date}</Td>
                      <Td>{call.hour}</Td>
                      <Td>{call.type ? "Émis" : "Reçu"}</Td>
                      <Td>
                        {`${this.context.parties[call.interlocutor].kind} ${
                          this.context.parties[call.interlocutor].name
                        }`}
                      </Td>
                      <Td>
                        <ButtonIcon
                          className="blue"
                          small
                          onClick={() =>
                            this.setState({
                              callId: call.id,
                              date: moment(call.date, "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                              ),
                              hour: call.hour,
                              type: call.type,
                              interlocutor: this.checkIfExist(call.interlocutor)
                                ? call.interlocutor
                                : "Autre",
                              otherInterlocutor: this.checkIfExist(
                                call.interlocutor
                              )
                                ? ""
                                : call.interlocutor,
                              detail: call.detail,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </ButtonIcon>
                        <ButtonIcon
                          className="red"
                          small
                          onClick={() => this.deleteFolderCall(call.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ButtonIcon>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Col>
          </Row>
        </FullModal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
