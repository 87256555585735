import React from "react";

import CardEditor from "../CardEditor";


export default class Resolution extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "Resolution";
  }

  render() {
    const defaultData = `<p>Détailler les modalités de résolution et coûts</p>`;

    return (
      <CardEditor
        {...this.props}
        name={this.displayName}
        title="Modalités de résolution et coûts"
        dataName="resolution"
        default={defaultData}
      />
    );
  }
}
