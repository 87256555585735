import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { FormInput } from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";


export default class End extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "End";
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = value;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("informations", tmpData);
    } else {
      this.context.updateDataReport("informations", tmpData);
    }
  };

  render() {
    const { data } = this.props;
    return (
      <CustomCard title="Fin d'expertise" name={this.displayName}>
        <Row>
          <Col xs={12} md={3}>
            <FormInput
              className="mt-10"
              type="date"
              title="Date fin d'expertise"
              name="dateEnd"
              value={data.dateEnd}
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              className="mt-10"
              type="time"
              title="Heure fin d'expertise"
              name="hourEnd"
              value={data.hourEnd}
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>
      </CustomCard>
    );
  }
}
