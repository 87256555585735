const handleSortClick = (sortRef, desc, column) => {
  sortRef.forEach((elem, idx) => {
    if (sortRef[idx] && sortRef[idx].props.nameSort !== column) {
      sortRef[idx].setState({ desc: false, sort: false });
    }
    return null;
  });

  let order = { name: column, desc };

  return order;
};

export default handleSortClick;
