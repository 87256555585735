import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { ButtonIcon, FormCheckbox, FormInput } from "ui-kit-ck-consultant";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CustomCard from "../../../../components/folder/general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class NatureDispute extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "NatureDispute";
  }

  static contextType = AuthContext;

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    let tmpData = this.props.data;
    tmpData.answers[name] = checked;

    this.setData(tmpData);
  };

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.initData();
    }
  }

  initData = () => {
    if (!this.props.data.hasOwnProperty("objects")) {
      let objects = [];

      let answers = {};

      const tmpData = {
        objects,
        answers,
      };

      this.setData(tmpData);
    }
  };

  setData = (tmpData) => {
    if (this.context.updateDataReport) {
      this.context.updateDataReport("natureDispute", tmpData);
    }
  };

  render() {
    return (
      <CustomCard title="Nature du litige" name={this.displayName}>
        <Row>
          {this.props.data && this.props.data.hasOwnProperty("objects")
            ? this.props.data.objects.map((object, idx) => (
                <Col xs={12} key={idx}>
                  <div className="d-flex mb-10">
                    <FormCheckbox
                      className="mt-auto mb-auto"
                      text={object.isEdit ? "" : object.text}
                      name={idx}
                      checked={!!this.props.data.answers[idx]}
                      onChange={this.handleCheckboxChange}
                    />
                    {object.isEdit ? (
                      <>
                        <FormInput
                          className="ml-5 w-100 mt-auto mb-auto"
                          value={object.text}
                          onChange={({ target }) => {
                            let tmpData = this.props.data;
                            tmpData.objects[idx].text = target.value;
                            this.setData(tmpData);
                          }}
                        />
                        <ButtonIcon
                          className="red mt-auto mb-auto"
                          onClick={() => {
                            window.confirmCustom(
                              "Êtes-vous sûr de vouloir supprimet cet objet ?",
                              (result) => {
                                if (result) {
                                  let tmpData = this.props.data;
                                  delete tmpData.answers[idx];
                                  tmpData.objects.splice(idx, 1);
                                  this.setData(tmpData);
                                }
                              }
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ButtonIcon>
                      </>
                    ) : null}
                  </div>
                </Col>
              ))
            : null}
        </Row>
        <div className="d-flex">
          <ButtonIcon
            isBackground
            className="ml-auto"
            onClick={() => {
              let tmpData = this.props.data;
              tmpData.objects.push({ text: "", isEdit: true });
              this.setData(tmpData);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </ButtonIcon>
        </div>
      </CustomCard>
    );
  }
}
