import React, { createRef } from "react";
import {
  FormCheckbox,
  Signature,
  Modal,
  Button,
  FormInput,
} from "ui-kit-ck-consultant";
import { Col } from "react-flexbox-grid";
import SignatureCanvas from "react-signature-canvas";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AuthContext from "../../../context/AuthContext";

export default class PartyBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalSignature: false,
      height: 200,
      width: 200,
      errorMessage: { currentIdx: null, message: "" },
    };

    this.signatureRef = createRef();
  }

  static contextType = AuthContext;

  setSizeSignature = () => {
    if (
      this.signatureRef.current &&
      this.state.width !== this.signatureRef.current.getCanvas().offsetWidth
    ) {
      this.setState({
        width: this.signatureRef.current.getCanvas().offsetWidth,
      });
    }
  };

  handleClearSignatureClick = () => {
    this.signatureRef.current.clear();
  };

  handleValidSignatureClick = () => {
    let image = this.signatureRef.current.toDataURL("image/png");

    this.setState(
      {
        isModalSignature: false,
      },
      () => {
        this.updateSignature(image);
      }
    );
  };

  updateSignature = (signature) => {
    let party = this.props.parties[this.props.idx];
    party["signature"] = signature;
    this.props.onChange(party);
  };

  addCachetImg = (e) => {
    let party = this.props.parties[this.props.idx];
    const file = e.target.files[0];
    if (file) {
      const maxImgSize = 50 * 1024; // 50 KB
      if (file.size > maxImgSize) {
        this.setState({
          errorMessage: {
            currentIdx: this.props.idx,
            message: "L'image a une taille trop importante",
          },
        });
        return;
      }

      if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
        this.setState({
          errorMessage: {
            currentIdx: this.props.idx,
            message: "Ce type de fichier n'est pas autorisé",
          },
        });
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        party.cachetImg = base64String;
        this.props.onChange(party);
      };
      reader.readAsDataURL(file);
    }
  };

  render() {
    const parties = this.context.parties;
    const token = this.props.parties[this.props.idx].token;

    const agreed = this.props.parties[this.props.idx].agreed;
    const goodForTransaction =
      this.props.parties[this.props.idx].goodForTransaction;
    const signature = this.props.parties[this.props.idx].signature;

    if (!token) {
      return null;
    }

    let party = {};
    if (token && parties[token]) {
      party = parties[token];
    }

    return (
      <>
        <Col xs={12} md={6}>
          <h3>
            {party.party} - {party.kind} {party.name}
          </h3>
          <p>
            {party.address} {party.address2},<br />
            {party.zipCode} - {party.city}
          </p>
          <FormCheckbox
            className="mb-10"
            text="Lu et approuvé."
            checked={agreed}
            onChange={(e) => {
              let party = this.props.parties[this.props.idx];
              party["agreed"] = e.target.checked;
              this.props.onChange(party);
            }}
          />
          <br />
          <FormCheckbox
            className="mb-10"
            text="Bon pour transaction et désistement"
            checked={goodForTransaction}
            onChange={(e) => {
              let party = this.props.parties[this.props.idx];
              party["goodForTransaction"] = e.target.checked;
              this.props.onChange(party);
            }}
          />
          <Signature
            onAdd={() => {
              this.setState(
                {
                  isModalSignature: true,
                },
                () => {
                  this.setSizeSignature();
                }
              );
            }}
            onRemove={() => {
              this.updateSignature("");
            }}
            signature={signature}
          />
          {!this.props.parties[this.props.idx].hasOwnProperty("cachetImg") ? (
            <div
              className="mt-10"
              style={{
                position: "relative",
                border: `dashed 1px ${
                  this.state.errorMessage.currentIdx !== null &&
                  this.state.errorMessage.currentIdx === this.props.idx
                    ? "red"
                    : "var(--gray)"
                }`,
              }}
              onClick={() => {
                if (this.state.currentIdx !== null) {
                  this.setState({
                    errorMessage: {
                      currentIdx: null,
                      message: "",
                    },
                  });
                }
              }}
            >
              <FormInput
                styleInput={{ opacity: 0, zIndex: 1 }}
                name="date"
                value={this.state.date}
                className="mb-10 "
                type="file"
                onChange={(e) => this.addCachetImg(e)}
              />
              <span
                style={{
                  position: "absolute",
                  color: `${
                    this.state.errorMessage.currentIdx !== null &&
                    this.state.errorMessage.currentIdx === this.props.idx
                      ? "red"
                      : "var(--gray)"
                  }`,
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  zIndex: 0,
                  pointerEvents: "none",
                }}
              >
                {this.state.errorMessage.currentIdx !== null
                  ? this.state.errorMessage.message
                  : "Cliquer pour ajouter un cachet"}
              </span>
            </div>
          ) : (
            <div
              className="mb-10 mt-10 opacity"
              style={{
                display: "flex",
                position: "relative",
                borderRadius: "15px",
                border: "dashed 1px var(--gray)",
                minHeight: "100px",
                width: "100%",
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              <div
                className="opacity"
                style={{
                  display: "inline-flex",
                  position: "absolute",
                  right: 0,
                  top: 0,
                  width: "32px",
                  height: "32px",
                  backgroundColor: "var(--gray)",
                  borderBottomLeftRadius: "15px",
                  cursor: "pointer",
                  zIndex: 14,
                }}
                onClick={(e) => {
                  let party = this.props.parties[this.props.idx];
                  delete party.cachetImg;

                  this.props.onChange(party);
                  e.stopPropagation();
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  className="red"
                  style={{ margin: "auto" }}
                />
              </div>
              <img
                style={{
                  zIndex: 10,
                  maxWidth: "10%",
                  margin: "auto",
                }}
                src={`data:image/png;base64,${
                  this.props.parties[this.props.idx].cachetImg
                }`}
                alt="cachet"
              />
            </div>
          )}
        </Col>
        <Modal
          closeMessage="Êtes-vous sûr de vouloir fermer cette fenêtre ?"
          show={this.state.isModalSignature}
          onClose={() => {
            this.setState({
              isModalSignature: false,
            });
          }}
          isMove
        >
          <SignatureCanvas
            ref={this.signatureRef}
            penColor="black"
            canvasProps={{
              height: this.state.height,
              width: this.state.width,
              style: {
                border: "var(--gray) dashed 2px",
                borderRadius: "15px",
              },
              className: "sigCanvas mb-10",
            }}
          />
          <Button
            text="Rénitialiser"
            className="m-0 mb-10 outline"
            onClick={this.handleClearSignatureClick}
          />
          <Button
            text="Valider"
            className="m-0"
            onClick={this.handleValidSignatureClick}
          />
        </Modal>
      </>
    );
  }
}
