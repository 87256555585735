import React from "react";

import CardEditor from "../CardEditor";

export default class CasualLink extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "CasualLink";
  }

  render() {
    const defaultData = `<div><p>Lien de causalité entre la faute et les désordres constatés :</p></br>
    <p>Il convient de ne pas se prononcer sur la responsabilité, ni sur la prise en charge du sinistre, mais de préciser si le lien de causalité est établi.</p></br>
    <p>Eu égard à la multiplicité de Dispositions Générales, l’appréciation de la garantie est du ressort de la Compagnie.<br/></br>
    <p>Certains contrats ne couvrent, en ce qui vise les dommages subis par le véhicule, ni les conséquences d’une non façon, ni celles d’un vice caché. D’autres ne couvrent les conséquences de vices cachés que dans des circonstances particulières.</p></br>
    <p>Recherche de la faute éventuelle d’un tiers et si une mise en cause d’un sous-traitant, d’un fournisseur, d’un constructeur, d’un autre réparateur, d’un tiers, d’un assureur est nécessaire, le principe contradictoire doit être respecté afin que la partie concernée, convoquée régulièrement par vos soins, puisse saisir son assureur, qui lui-même dépêchera un expert.</p></br>
    <p>La prise en charge directement par le responsable est alors à tenter.</p></br>
    <p>Vous devez préserver toutes nos chances de recours du fait d’un règlement qui s’imposerait à nous en raison du lien contractuel entre notre assuré et son client.</p></br>
    <p>Rédiger un Procès Verbal de Constatations qui sera signé des participants (document annexé).</p>
    </div>`;

    return (
      <CardEditor
        {...this.props}
        name={this.displayName}
        title="Lien de causalité"
        dataName="casualLink"
        default={defaultData}
      />
    );
  }
}
