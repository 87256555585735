import React from "react";
import {
  Loader,
  TabsEdit,
  TabsEditHeader,
  TabsEditBody,
  TabsEditMenu,
  TabsEditItem,
  ButtonIcon,
  Button,
  FormInput,
} from "ui-kit-ck-consultant";
import {
  faRotate,
  faTrash,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-flexbox-grid";

import CustomCard from "../../general/CustomCard";

import { getPieces } from "../../../../requests/folder";

import AuthContext from "../../../../context/AuthContext";

export default class DefaultDamageAssessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsId: 0,
    };
  }

  static contextType = AuthContext;

  getPieces = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        getPieces(
          {
            reference:
              this.context.dataAdministrative.dataAdministrativeInformation
                .mission.reference,
          },
          (result) => {
            if (result.success) {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  let tmpData = this.props.data;
                  tmpData[this.state.tabsId].pieces = result.dataPieces;
                  tmpData[this.state.tabsId].hours = result.dataHours;
                  tmpData[this.state.tabsId].general = result.dataGeneral;

                  this.props.setData(tmpData);
                }
              );
            } else {
              this.setState(
                {
                  isLoad: false,
                },
                () => window.alert("Une erreur est survenue")
              );
            }
          }
        );
      }
    );
  };

  addItem = () => {
    let tmpData = this.props.data;
    tmpData.push({});
    this.props.setData(tmpData);
  };

  render() {
    return (
      <>
        <TabsEdit className="mt-10 mt-20-xl mb-0">
          <TabsEditHeader onClickAdd={this.addItem} className="mb-0">
            {this.props.data.map((element, idx) => {
              return (
                <TabsEditMenu
                  key={idx}
                  title={`Chiffrage n°${idx + 1}`}
                  active={this.state.tabsId === idx}
                  disabled={this.context.isLock}
                  onClick={() =>
                    this.setState({ tabsId: idx }, () =>
                      this.props.onTabsChange(idx)
                    )
                  }
                />
              );
            })}
          </TabsEditHeader>
          <TabsEditBody noCard>
            <TabsEditItem active={true}>
              <CustomCard
                title={"Évaluation des dommages"}
                name={this.props.displayName}
                action={
                  <>
                    <ButtonIcon
                      className="blue p-0 mr-10"
                      small
                      disabled={this.context.isDisconnected}
                      onClick={(e) => {
                        e.preventDefault();
                        this.getPieces();
                      }}
                      info="Synchroniser Alpha Expert"
                    >
                      <FontAwesomeIcon icon={faRotate} />
                    </ButtonIcon>
                    {!this.props.isLight ? (
                      <ButtonIcon
                        className="blue p-0 mr-10"
                        small
                        onClick={(e) => {
                          e.preventDefault();
                          let tmpData = this.props.data;

                          if (this.props.isRc) {
                            tmpData =
                              this.props.dataEvaluation.RCdamageAssessment;
                          } else {
                            tmpData =
                              this.props.dataEvaluation.damageAssessment;
                          }

                          this.props.setData(tmpData);
                        }}
                        info="Synchroniser Chiffrage"
                      >
                        <FontAwesomeIcon icon={faRotate} />
                      </ButtonIcon>
                    ) : null}
                  </>
                }
              >
                {this.props.data.length ? (
                  <>
                    <Row>
                      {this.state.tabsId > 0 ? (
                        <Col xs={12} md={6} lg={3} className="d-flex">
                          <Button
                            orange
                            outline
                            className="mb-10"
                            text="Supprimer"
                            onClick={() => {
                              window.confirmCustom(
                                "Êtes-vous sûr de vouloir supprimer ce chiffrage ?",
                                (result) => {
                                  let tmpData = this.props.data;
                                  const tabsId = this.state.tabsId;

                                  if (tabsId === tmpData.length - 1 && result) {
                                    this.setState(
                                      {
                                        tabsId: tabsId - 1,
                                      },
                                      () => {
                                        this.props.onTabsChange(tabsId - 1);
                                        tmpData.splice(tabsId, 1);
                                        this.props.setData(tmpData);
                                      }
                                    );
                                  }
                                }
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </Col>
                      ) : null}
                      {this.context.isExpertise || this.context.isReport ? (
                        <Col xs={12} md={6} lg={3} className="d-flex">
                          <Button
                            outline
                            className="mb-10"
                            orange
                            text={
                              this.props.data[this.state.tabsId].isHide
                                ? "Afficher le chiffrage"
                                : "Masquer le chiffrage"
                            }
                            onClick={() => {
                              let tmpData = this.props.data;

                              tmpData[this.state.tabsId].isHide =
                                !tmpData[this.state.tabsId].isHide;

                              this.props.setData(tmpData);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={
                                this.props.data[this.state.tabsId].isHide
                                  ? faEye
                                  : faEyeSlash
                              }
                            />
                          </Button>
                        </Col>
                      ) : null}
                      <Col xs={12} lg={9}>
                        <FormInput
                          placeholder="Libellé chiffrage"
                          value={this.props.data[this.state.tabsId].title || ""}
                          onChange={(e) => {
                            let tmpData = this.props.data;

                            tmpData[this.state.tabsId].title = e.target.value;

                            this.props.setData(tmpData);
                          }}
                        />
                      </Col>
                    </Row>
                    {this.props.children}
                  </>
                ) : null}
              </CustomCard>
            </TabsEditItem>
          </TabsEditBody>
        </TabsEdit>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
