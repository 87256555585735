import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  FormInput,
  FormSelect,
  FormCheckbox,
  Loader,
} from "ui-kit-ck-consultant";
import {
  faPlus,
  faTrash,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CardEditor from "../CardEditor";
import DefaultDamageAssessment from "./DefaultDamageAssessment";

import AuthContext from "../../../../context/AuthContext";

import { getPrice } from "../../../../utils/general";

export default class DamageAssessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      tabsId: 0,
    };
    this.displayName = "DamageAssessment";
  }

  static contextType = AuthContext;

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState(
      {
        [name]: checked,
      },
      this.setData
    );
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("damageAssessment", tmpData);
    } else if (this.context.updateDataReport) {
      this.context.updateDataReport("damageAssessment", tmpData);
    }

    let tmpDataEvaluation = this.props.dataEvaluation;
    tmpDataEvaluation["damageAssessment"] = tmpData;
    this.context.updateDataFolder("dataEvaluation", tmpDataEvaluation);
  };

  handleInputChange = (type, name, value, idx = null, isForce = true) => {
    let tmpData = this.props.data;

    if (!tmpData[this.state.tabsId].hasOwnProperty(type)) {
      tmpData[this.state.tabsId][type] = {};
    }

    if (isForce && typeof value === "string") {
      value = value.replace(",", ".");
    }

    if (
      name === "hours" &&
      tmpData[this.state.tabsId][type][idx].type === "P1"
    ) {
      let idxIng = null;
      tmpData[this.state.tabsId][type].forEach((element, idx) => {
        if (element.type === "Ing. 1") {
          idxIng = idx;
        }
      });

      tmpData[this.state.tabsId][type][idxIng][name] = value;
    } else if (
      name === "hours" &&
      tmpData[this.state.tabsId][type][idx].type === "P2"
    ) {
      let idxIng = null;
      tmpData[this.state.tabsId][type].forEach((element, idx) => {
        if (element.type === "Ing. 2") {
          idxIng = idx;
        }
      });

      tmpData[this.state.tabsId][type][idxIng][name] = value;
    }

    if (type) {
      if (idx !== null) {
        tmpData[this.state.tabsId][type][idx][name] = value;
      } else {
        tmpData[this.state.tabsId][type][name] = value;
      }
    } else {
      tmpData[this.state.tabsId][name] = value;
    }

    this.setData(tmpData);
  };

  onAddLineClick = (type) => {
    let tmpData = this.props.data;

    if (!tmpData[this.state.tabsId].hasOwnProperty(type)) {
      tmpData[this.state.tabsId][type] = [];
    }

    tmpData[this.state.tabsId][type].push({ price: 0, quantity: 1, vat: 20 });
    this.setData(tmpData);
  };

  getValue = (quantity, price, dilapidated = 0, discount = 0, vat = 0) => {
    let total = 0;

    let priceDilapidated = 0;
    let priceDiscount = 0;
    let priceVat = 0;

    if (price) {
      price = price * quantity;

      if (dilapidated) {
        priceDilapidated = (price * dilapidated) / 100;
      }

      if (discount) {
        priceDiscount = (price * discount) / 100;
      }

      total = price - priceDilapidated - priceDiscount;

      if (vat) {
        priceVat = (total * vat) / 100;
      }
    }

    return total + priceVat;
  };

  getTotalValue = (data, isVat = false) => {
    let total = 0;
    if (data) {
      data.forEach((element) => {
        total += this.getValue(
          element.quantity,
          element.price,
          element.dilapidated,
          element.discount,
          isVat ? element.vat : 0
        );
      });
    }
    return total;
  };

  changePosition = (key, idx) => {
    let tmpData = this.props.data;
    let currentData = tmpData[this.state.tabsId] || {};

    return (
      <Td>
        <div
          className="d-flex"
          style={{
            flexDirection: "column",
          }}
        >
          {idx !== 0 ? (
            <ButtonIcon
              small
              className="blue m-auto mb-0 p-0"
              onClick={() => {
                let tmpCurrentData = currentData;

                const oldLine = tmpCurrentData[key][idx - 1];

                tmpCurrentData[key][idx - 1] = tmpCurrentData[key][idx];

                tmpCurrentData[key][idx] = oldLine;

                this.setData(tmpCurrentData);
              }}
            >
              <FontAwesomeIcon icon={faCaretUp} />
            </ButtonIcon>
          ) : null}
          {idx !== currentData[key].length - 1 ? (
            <ButtonIcon
              small
              className="blue m-auto mt-0 p-0"
              onClick={() => {
                let tmpCurrentData = currentData;

                const oldLine = tmpCurrentData[key][idx + 1];

                tmpCurrentData[key][idx + 1] = tmpCurrentData[key][idx];

                tmpCurrentData[key][idx] = oldLine;

                this.setData(tmpCurrentData);
              }}
            >
              <FontAwesomeIcon icon={faCaretDown} />
            </ButtonIcon>
          ) : null}
        </div>
      </Td>
    );
  };

  getData = () => {
    let tmpData = this.props.data;

    let currentData = tmpData[this.state.tabsId] || {};

    const tmpDataPieces = currentData.pieces || [];

    const tmpDataHours = currentData.hours || [];

    const tmpDataGeneral = currentData.general || {};

    let { pourcRemisePiece, pourcRemiseMo, discount } = tmpDataGeneral;

    pourcRemisePiece = pourcRemisePiece || 0;
    pourcRemiseMo = pourcRemiseMo || 0;
    discount = discount || 0;

    const totalPieces = this.getTotalValue(tmpDataPieces);
    const totalPiecesVAT = this.getTotalValue(tmpDataPieces, true);
    const totalPiecesHT = totalPieces - totalPieces * (pourcRemisePiece / 100);
    const totalPiecesWithDiscount =
      totalPiecesVAT - totalPiecesVAT * (pourcRemisePiece / 100);

    const totalHours = this.getTotalValue(tmpDataHours);
    const totalHoursVAT = this.getTotalValue(tmpDataHours, true);
    const totalHoursHT = totalHours - totalHours * (pourcRemiseMo / 100);
    const totalMoWithDiscount =
      totalHoursVAT - totalHoursVAT * (pourcRemiseMo / 100);

    const totalBeforeDiscount = totalPiecesHT + totalHoursHT;

    const totalDiscountTTC = totalPiecesWithDiscount + totalMoWithDiscount;

    const totalDiscountHT =
      (totalBeforeDiscount -
        (totalBeforeDiscount - totalBeforeDiscount * (discount / 100))) *
      -1;

    const discountTTC = totalDiscountTTC - totalDiscountTTC * (discount / 100);

    // Round up to the nearest cent
    const discountTva =
      (Math.ceil(
        Math.abs(
          totalDiscountTTC -
            discountTTC -
            (totalBeforeDiscount -
              (totalBeforeDiscount - totalBeforeDiscount * (discount / 100)))
        ) * 100
      ) /
        100) *
      -1;

    // Round up to the nearest cent
    const totalHT =
      Math.ceil((totalPiecesHT + totalHoursHT + totalDiscountHT) * 100) / 100;

    const totalTVA = discountTTC - totalHT;

    return (
      <>
        <Table className="mb-20">
          <Thead>
            <Tr>
              <Th colSpan="13">Détail des pièces</Th>
            </Tr>
            <Tr>
              <Th></Th>
              <Th className="nowrap p-10">Nbe</Th>
              <Th className="nowrap p-10">Libellé</Th>
              <Th className="nowrap p-10">Réf. constructeur</Th>
              <Th className="nowrap p-10">Opération</Th>
              <Th className="nowrap p-10">P</Th>
              <Th className="nowrap p-10">Prix unit HT</Th>
              <Th className="nowrap p-10">% vétusté</Th>
              <Th className="nowrap p-10">% remise</Th>
              <Th className="nowrap p-10">% TVA</Th>
              <Th className="nowrap p-10">Total HT</Th>
              <Th className="nowrap p-10">Total TTC</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {tmpDataPieces.map((element, idx) => (
              <Tr key={idx}>
                {this.changePosition("pieces", idx)}
                <Td className="p-10">
                  <FormInput
                    type="number"
                    styleInput={{
                      border: "none",
                      padding: "0 10px",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    style={{
                      width: "50px",
                    }}
                    value={element["quantity"]}
                    onChange={({ target }) =>
                      this.handleInputChange(
                        "pieces",
                        "quantity",
                        target.value,
                        idx
                      )
                    }
                  />
                </Td>
                <Td className="p-10 w-100">
                  <FormInput
                    styleInput={{
                      border: "none",
                      padding: "0 10px",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    value={element["name"]}
                    onChange={({ target }) =>
                      this.handleInputChange(
                        "pieces",
                        "name",
                        target.value,
                        idx
                      )
                    }
                  />
                </Td>
                <Td className="p-10">
                  <FormInput
                    styleInput={{
                      border: "none",
                      padding: "0 10px",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    value={element["reference"]}
                    onChange={({ target }) =>
                      this.handleInputChange(
                        "pieces",
                        "reference",
                        target.value,
                        idx
                      )
                    }
                  />
                </Td>
                <Td className="p-10">
                  <FormSelect
                    ignore={true}
                    options={[
                      {
                        text: "",
                        value: "DEFAULT",
                        disabled: true,
                      },
                      { text: "E", value: "E" },
                      { text: "R", value: "R" },
                      { text: "RP", value: "RP" },
                      { text: "PP", value: "PP" },
                      { text: "S", value: "S" },
                      { text: "EP", value: "EP" },
                      { text: "L", value: "L" },
                      { text: "C", value: "C" },
                      { text: "R", value: "R" },
                      { text: "N", value: "N" },
                      { text: "RM", value: "RM" },
                      { text: "EM", value: "EM" },
                      { text: "CB", value: "CB" },
                      { text: "D", value: "D" },
                      { text: "A", value: "A" },
                    ]}
                    styleInput={{
                      border: "none",
                      padding: "0 10px",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    style={{
                      width: "120px",
                    }}
                    value={element["operation"]}
                    onChange={({ target }) =>
                      this.handleInputChange(
                        "pieces",
                        "operation",
                        target.value,
                        idx
                      )
                    }
                  />
                </Td>
                <Td className="p-10 text-center">
                  <FormCheckbox
                    checked={element.painted}
                    onChange={({ target }) =>
                      this.handleInputChange(
                        "pieces",
                        "painted",
                        target.checked,
                        idx
                      )
                    }
                  />
                </Td>
                <Td className="p-10">
                  <FormInput
                    type="number"
                    styleInput={{
                      border: "none",
                      padding: "0 10px",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    style={{
                      width: "120px",
                    }}
                    value={element["price"]}
                    onChange={({ target }) =>
                      this.handleInputChange(
                        "pieces",
                        "price",
                        target.value,
                        idx
                      )
                    }
                  />
                </Td>
                <Td className="p-10">
                  <FormInput
                    type="number"
                    styleInput={{
                      border: "none",
                      padding: "0 10px",
                      width: "56px",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    value={element["dilapidated"]}
                    onChange={({ target }) =>
                      this.handleInputChange(
                        "pieces",
                        "dilapidated",
                        target.value,
                        idx
                      )
                    }
                  />
                </Td>
                <Td className="p-10">
                  <FormInput
                    type="number"
                    styleInput={{
                      border: "none",
                      padding: "0 10px",
                      width: "56px",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    value={element["discount"]}
                    onChange={({ target }) =>
                      this.handleInputChange(
                        "pieces",
                        "discount",
                        target.value,
                        idx
                      )
                    }
                  />
                </Td>
                <Td className="p-10">
                  <FormInput
                    type="number"
                    styleInput={{
                      border: "none",
                      padding: "0 10px",
                      width: "56px",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    value={element["vat"]}
                    onChange={({ target }) =>
                      this.handleInputChange("pieces", "vat", target.value, idx)
                    }
                  />
                </Td>
                <Th className="p-10">
                  {getPrice(
                    this.getValue(
                      element.quantity,
                      element.price,
                      element.dilapidated,
                      element.discount
                    )
                  )}
                </Th>
                <Th className="p-10">
                  {getPrice(
                    this.getValue(
                      element.quantity,
                      element.price,
                      element.dilapidated,
                      element.discount,
                      element.vat
                    )
                  )}
                </Th>
                <Td className="text-center">
                  <ButtonIcon className="red" small>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => {
                        window.confirmCustom(
                          "Etes vous sûre de vouloir supprimer la ligne ?",
                          (result) => {
                            if (result) {
                              let tmpData = this.props.data;
                              tmpData[this.state.tabsId].pieces.splice(idx, 1);
                              this.setData(tmpData);
                            }
                          }
                        );
                      }}
                    />
                  </ButtonIcon>
                </Td>
              </Tr>
            ))}
            <Tr>
              <Td colSpan="10" hide></Td>
              <Th radiusBottomLeft>{getPrice(totalPieces)}</Th>
              <Th>{getPrice(totalPiecesVAT)}</Th>
              <Th className="text-center">
                <ButtonIcon
                  small
                  className="blue"
                  onClick={(e) => {
                    e.preventDefault();
                    this.onAddLineClick("pieces");
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </ButtonIcon>
              </Th>
            </Tr>
          </Tbody>
        </Table>
        <Table className="mb-20">
          <Thead>
            <Tr>
              <Th colSpan="8">Détail des opérations</Th>
            </Tr>
            <Tr>
              <Th></Th>
              <Th>Type</Th>
              <Th>Sous-type</Th>
              <Th>Heure</Th>
              <Th>Tarif horaire</Th>
              <Th>% TVA</Th>
              <Th>Montant HT</Th>
              <Th>Montant TTC</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {tmpDataHours.map((element, idx) => (
              <Tr key={idx}>
                {this.changePosition("hours", idx)}
                <Td>
                  <FormSelect
                    ignore={true}
                    options={[
                      {
                        text: "",
                        value: "DEFAULT",
                        disabled: true,
                      },
                      { text: "T1", value: "T1" },
                      { text: "T2", value: "T2" },
                      { text: "T3", value: "T3" },
                      { text: "M1", value: "M1" },
                      { text: "M2", value: "M2" },
                      { text: "M3", value: "M3" },
                      { text: "P1", value: "P1" },
                      { text: "P2", value: "P2" },
                      { text: "DSP", value: "DSP" },
                      { text: "Ing. 1", value: "Ing. 1" },
                      { text: "Ing. 2", value: "Ing. 2" },
                    ]}
                    styleInput={{
                      border: "none",
                      fontSize: "13px",
                      height: "36px",
                      width: "80px",
                    }}
                    value={element["type"]}
                    onChange={({ target }) =>
                      this.handleInputChange("hours", "type", target.value, idx)
                    }
                  />
                </Td>
                <Td>
                  {element["type"] && element["type"].includes("Ing.") ? (
                    <FormSelect
                      ignore={true}
                      options={[
                        {
                          text: "",
                          value: "DEFAULT",
                          disabled: true,
                        },
                        { text: "Opaque", value: "Opaque" },
                        { text: "Opaque Vernis", value: "Opaque Vernis" },
                        { text: "Métal", value: "Métal" },
                        { text: "Métal vernis", value: "Métal vernis" },
                        { text: "Nacré vernis", value: "Nacré vernis" },
                        { text: "Autre", value: "Autre" },
                      ]}
                      styleInput={{
                        border: "none",
                        fontSize: "13px",
                        height: "36px",
                        width: "120px",
                      }}
                      value={element["subType"]}
                      onChange={({ target }) =>
                        this.handleInputChange(
                          "hours",
                          "subType",
                          target.value,
                          idx
                        )
                      }
                    />
                  ) : null}
                </Td>
                <Td>
                  <FormInput
                    type="number"
                    styleInput={{
                      border: "none",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    value={element["quantity"]}
                    onChange={({ target }) =>
                      this.handleInputChange(
                        "hours",
                        "quantity",
                        target.value,
                        idx
                      )
                    }
                  />
                </Td>
                <Td>
                  <FormInput
                    type="number"
                    styleInput={{
                      border: "none",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    value={element["price"]}
                    onChange={({ target }) =>
                      this.handleInputChange(
                        "hours",
                        "price",
                        target.value,
                        idx
                      )
                    }
                  />
                </Td>
                <Td>
                  <FormInput
                    type="number"
                    styleInput={{
                      border: "none",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    value={element["vat"]}
                    onChange={({ target }) =>
                      this.handleInputChange("hours", "vat", target.value, idx)
                    }
                  />
                </Td>
                <Th>
                  {getPrice(this.getValue(element.quantity, element.price))}
                </Th>
                <Th>
                  {getPrice(
                    this.getValue(
                      element.quantity,
                      element.price,
                      0,
                      0,
                      element.vat
                    )
                  )}
                </Th>
                <Td className="text-center">
                  <ButtonIcon className="red" small>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => {
                        window.confirmCustom(
                          "Ëtes vous sûre de vouloir supprimer cette ligne ?",
                          (result) => {
                            if (result) {
                              let tmpData = this.props.data;
                              tmpData[this.state.tabsId].hours.splice(idx, 1);
                              this.setData(tmpData);
                            }
                          }
                        );
                      }}
                    />
                  </ButtonIcon>
                </Td>
              </Tr>
            ))}
            <Tr>
              <Td colSpan="6" hide></Td>
              <Th radiusBottomLeft>{getPrice(totalHours)}</Th>
              <Th>{getPrice(totalHoursVAT)}</Th>
              <Th className="text-center">
                <ButtonIcon
                  small
                  className="blue"
                  onClick={(e) => {
                    e.preventDefault();
                    this.onAddLineClick("hours");
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </ButtonIcon>
              </Th>
            </Tr>
          </Tbody>
        </Table>

        <Table className="mb-20">
          <Thead>
            <Tr>
              <Th colSpan="6">Total général</Th>
            </Tr>
            <Tr>
              <Th>Libellé</Th>
              <Th>Montant HT avant remise</Th>
              <Th>% REMISE</Th>
              <Th>Montant HT</Th>
              <Th>Montant TVA</Th>
              <Th>Montant TTC</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td className="w-100">Pièces</Td>
              <Td>{getPrice(totalPieces)}</Td>
              <Td>
                <FormInput
                  type="number"
                  styleInput={{
                    border: "none",
                    padding: "0 10px",
                    width: "56px",
                    fontSize: "13px",
                    height: "36px",
                  }}
                  value={pourcRemisePiece || 0}
                  onChange={({ target }) =>
                    this.handleInputChange(
                      "general",
                      "pourcRemisePiece",
                      target.value
                    )
                  }
                />
              </Td>
              <Td>{getPrice(totalPiecesHT)}</Td>
              <Td>{getPrice(totalPiecesWithDiscount - totalPiecesHT)}</Td>
              <Td>{getPrice(totalPiecesWithDiscount)}</Td>
            </Tr>
            <Tr>
              <Td className="w-100">Main d'œuvre </Td>
              <Td>{getPrice(totalHours)}</Td>
              <Td>
                <FormInput
                  type="number"
                  styleInput={{
                    border: "none",
                    padding: "0 10px",
                    width: "56px",
                    fontSize: "13px",
                    height: "36px",
                  }}
                  value={pourcRemiseMo || 0}
                  onChange={({ target }) =>
                    this.handleInputChange(
                      "general",
                      "pourcRemiseMo",
                      target.value
                    )
                  }
                />
              </Td>
              <Td>{getPrice(totalHoursHT)}</Td>
              <Td>{getPrice(totalMoWithDiscount - totalHoursHT)}</Td>
              <Td>{getPrice(totalMoWithDiscount)}</Td>
            </Tr>
            <Tr>
              <Td className="w-100">Remise/Total </Td>
              <Td>{getPrice(totalPiecesHT + totalHoursHT)}</Td>
              <Td>
                <FormInput
                  type="number"
                  styleInput={{
                    border: "none",
                    padding: "0 10px",
                    width: "56px",
                    fontSize: "13px",
                    height: "36px",
                  }}
                  value={discount}
                  onChange={({ target }) =>
                    this.handleInputChange("general", "discount", target.value)
                  }
                />
              </Td>
              <Td>{getPrice(totalDiscountHT)}</Td>
              <Td>{getPrice(discountTva)}</Td>
              <Td>{getPrice((totalDiscountTTC - discountTTC) * -1)}</Td>
            </Tr>
            <Tr className="w-100">
              <Th>Total</Th>
              <Th></Th>
              <Th></Th>
              <Th>{getPrice(totalHT)}</Th>
              <Th>{getPrice(totalTVA)}</Th>
              <Th>{getPrice(discountTTC)}</Th>
            </Tr>
          </Tbody>
        </Table>
      </>
    );
  };

  render() {
    return (
      <>
        {!this.props.isLight ? (
          <CardEditor
            title="Évaluation des dommages - observation"
            name={`${this.displayName}_comment`}
            dataName="damageAssessmentComment"
            data={this.props.dataComment}
          />
        ) : null}
        <DefaultDamageAssessment
          {...this.props}
          setData={this.setData}
          displayName={this.displayName}
          onTabsChange={(tabsId) => {
            this.setState({ tabsId });
          }}
        >
          {this.getData()}
        </DefaultDamageAssessment>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
