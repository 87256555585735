import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? window.location.origin.includes("development")
        ? "https://development.redaxion.fr/api"
        : "https://redaxion.fr/api"
      : "/api",
  transformRequest: [
    (data) => {
      return {
        ...{
          tokenAuth: window.localStorage.getItem("token"),
        },
        ...data,
      };
    },
    ...axios.defaults.transformRequest,
  ],
});

instance.defaults.method = "POST";
instance.defaults.withCredentials = true;
instance.defaults.responseType = "json";

export default instance;
