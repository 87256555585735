import React from "react";

import CardEditor from "../CardEditor";

export default class Origines extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "Consequences";
  }

  render() {
    return (
      <CardEditor
        {...this.props}
        name={this.displayName}
        title="Conséquences"
        dataName="consequences"
        default={""}
      />
    );
  }
}
