import React from "react";
import { FormInput, Modal, Button } from "ui-kit-ck-consultant";

export default class ModalUpdateGaugeTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
    };
  }

  componentDidMount() {
    this.setState({
      title: this.props.data.text,
    });
  }

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { title } = this.state;
    const { show } = this.props;

    return (
      <Modal
        closeMessage="Êtes-vous sûr de vouloir fermer cette fenêtre ?"
        title={`Changement de l'appellation ${title}`}
        show={show}
        onClose={this.props.onClose}
        isMove
      >
        <FormInput
          type="text"
          className="mb-10"
          name={"title"}
          value={title}
          title="Nom de la gauge"
          onChange={this.handleInputChange}
        />
        <div className="d-flex">
          <Button
            text={"Ajouter"}
            onClick={() => {
              this.props.onUpdate(this.state.title);
            }}
          />
        </div>
      </Modal>
    );
  }
}
