import axiosConfig from "../utils/axiosConfig";

const getAll = (callback) => {
  axiosConfig("folder/maileva/get-all")
    .then((res) => {
      callback(res.data);
    })
    .catch((e) => callback({ success: false }));
};

const sendMaileva = (data, callback) => {
  axiosConfig("folder/maileva/send", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch((e) => callback({ success: false }));
};

export { getAll, sendMaileva };
