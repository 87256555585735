import React from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  FormInput,
  FormSelect,
  Modal,
  Loader,
} from "ui-kit-ck-consultant";

import { addFolder } from "../../requests/home";

import AuthContext from "../../context/AuthContext";

export default class ModalAddFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reference: "",
      type: "DEFAULT",
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  addFolder = () => {
    if (this.state.reference && this.state.type !== "DEFAULT") {
      const folders = this.props.folders.filter(
        (element) => element.reference === this.state.reference
      );

      if (!folders.length) {
        this.setState(
          {
            isLoad: true,
          },
          () => {
            addFolder(
              {
                reference: this.state.reference,
                type: this.state.type,
              },
              (result) => {
                if (result.success) {
                  this.setState(
                    {
                      isLoad: false,
                      reference: "",
                      type: "DEFAULT",
                    },
                    () => {
                      if (result.isExist) {
                        window.alert("Le dossier est déjà créé");
                      }
                      document.location.hash = `/folder/${result.token}`;
                    }
                  );
                } else {
                  this.setState(
                    {
                      isLoad: false,
                    },
                    () => window.alert("Une erreur est survenue")
                  );
                }
              }
            );
          }
        );
      } else {
        document.location.hash = `/folder/${folders[0].token}`;
      }
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  render() {
    return (
      <>
        <Modal
          title="Nouveau dossier"
          show={this.props.show}
          onClose={this.props.onClose}
          isMove
        >
          <FormInput
            className="mb-10"
            name="reference"
            value={this.state.reference}
            title="N° dossier ou Immatriculation"
            onChange={this.handleInputChange}
          />
          <FormSelect
            className="mb-10"
            name="type"
            value={this.state.type}
            init="Veuillez sélectionner un type de dossier"
            options={[...this.context.types]}
            title="Type de dossier"
            onChange={this.handleInputChange}
          />
          <Button
            className="mb-10 w-auto ml-auto"
            text="Créer"
            onClick={this.addFolder}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
          <p className="text-center">Pas de N° dossier ?</p>
          <a
            href={this._handleClick}
            onClick={this.props.onOpenModalAddBlankFolder}
          >
            Créer un dossier vierge
          </a>
        </Modal>

        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
