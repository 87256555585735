import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faCamera } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  ButtonIcon,
  Card,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "ui-kit-ck-consultant";

import ViewMailBox from "./ViewMailBox";

import { getParty } from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";

export default class HistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
      modalIdx: null,
    };
  }

  static contextType = AuthContext;

  getPartyWithId = (key) => {
    if (this.context.parties.hasOwnProperty(key)) {
      return this.context.parties[key];
    } else {
      return {};
    }
  };

  getTo = (recipient, isOther = false, isEmail = false) => {
    const data = this.getPartyWithId(recipient);

    if (isEmail) {
      return <>&nbsp;/&nbsp;{recipient}</>;
    } else if (isOther) {
      return (
        <>
          &nbsp;/&nbsp;{data.kind} {data.name}
        </>
      );
    } else {
      return (
        <>
          {data.kind} {data.name}
        </>
      );
    }
  };

  render() {
    const expert = getParty(this.context.parties, "Expert");

    return (
      <Card className="mt-10 mt-20-xl" title="Historiques des envois">
        <Table>
          <Thead>
            <Tr>
              <Th>Date d'envoi</Th>
              <Th>Destinataires</Th>
              <Th>Objet</Th>
              <Th>Pièces jointes</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {[...this.props.data].reverse().map((element, idx) => (
              <Tr key={idx}>
                <Td className="nowrap">
                  {moment(element.dateInsert).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {element.recipient
                    ? this.getTo(element.recipient)
                    : element.email}
                  {element.recipients
                    ? element.recipients.map((recipient) => (
                        <Fragment key={recipient}>
                          {this.getTo(recipient, true)}
                        </Fragment>
                      ))
                    : null}
                  {element.emailRecipients
                    ? element.emailRecipients.map((recipient) => (
                        <Fragment key={recipient}>
                          {this.getTo(recipient, true, true)}
                        </Fragment>
                      ))
                    : null}
                </Td>
                <Td>{element.object}</Td>
                <Td>{element.files ? element.files.length + 1 : 1}</Td>
                <Td className="d-flex">
                  <ButtonIcon
                    small
                    className="blue"
                    onClick={() => {
                      let token = "";
                      if (this.context.isSoftware) {
                        token = `token/${window.localStorage.getItem(
                          "token"
                        )}/`;
                      }

                      const url = `${this.context.domain}/api/folder/get-document/${token}${element.filename}`;
                      window.open(url, "_blank");

                      if (element.files && element.files.length) {
                        element.files.forEach((file) => {
                          const downloadLink = document.createElement("a");
                          downloadLink.href = file.base64;
                          downloadLink.download = file.name;
                          downloadLink.click();
                        });
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </ButtonIcon>
                  <ButtonIcon
                    className="blue"
                    onClick={() => {
                      this.setState({
                        isModal: true,
                        modalIdx: this.props.data.length - 1 - idx,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faCamera} />
                  </ButtonIcon>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {this.state.isModal ? (
          <ViewMailBox
            sender={expert}
            object={this.props.data[this.state.modalIdx].object}
            date={this.props.data[this.state.modalIdx].dateInsert}
            files={[
              { name: this.props.data[this.state.modalIdx].filename },
              ...this.props.data[this.state.modalIdx].files,
            ]}
            recipient={this.props.data[this.state.modalIdx].recipient}
            email={this.props.data[this.state.modalIdx].email}
            recipients={this.props.data[this.state.modalIdx].recipients}
            message={this.props.data[this.state.modalIdx].messageHTML}
            show={this.state.isModal}
            onClose={() =>
              this.setState({
                isModal: false,
                modalIdx: null,
              })
            }
          />
        ) : null}
      </Card>
    );
  }
}
