import React from "react";
import { CardColor } from "ui-kit-ck-consultant";
import { Col, Row } from "react-flexbox-grid";

export default class Header extends React.Component {
  getCount = (keys) => {
    let count = 0;

    // keys.forEach((key) => {
    //   if (this.props.countFolders[key]) {
    //     count += this.props.countFolders[key];
    //   }
    // });

    return count;
  };

  getColor = (keys) => {
    if (this.getCount(keys) > 5) {
      return "bg-red";
    } else if (this.getCount(keys) > 0) {
      return "bg-orange";
    } else {
      return "bg-blue";
    }
  };

  render() {
    return (
      <Row>
        <Col xs={12} md={4} className="mt-10">
          <CardColor
            count={this.getCount([2])}
            title="Expertises à réaliser"
            subtitle=""
            className={this.getColor([2])}
            onClick={() => this.props.updateStateFilter(2)}
          />
        </Col>
        <Col xs={12} md={4} className="mt-10">
          <CardColor
            count={this.getCount([5])}
            title="Rapports à rédiger"
            subtitle=""
            className={this.getColor([5])}
            onClick={() => this.props.updateStateFilter(5)}
          />
        </Col>
        <Col xs={12} md={4} className="mt-10">
          <CardColor
            count={this.getCount([4])}
            title="Dossiers en attente"
            subtitle=""
            className={this.getColor([4])}
            onClick={() => this.props.updateStateFilter(4)}
          />
        </Col>
      </Row>
    );
  }
}
