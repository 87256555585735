import React from "react";
import { ButtonIcon, Loader } from "ui-kit-ck-consultant";
import {
  faArrowLeft,
  faCalendar,
  faImages,
  faLock,
  faUnlock,
  faPhone,
  faUser,
  faSave,
  faFileContract,
  faBook,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Save from "./Save";

import { getParty } from "../../utils/general";

import AuthContext from "../../context/AuthContext";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isFixed: false,
      currentFile: 0,
    };
  }

  static contextType = AuthContext;

  handleScroll = () => {
    const position = window.pageYOffset;
    if (position >= 125 && !this.state.isFixed) {
      this.setState({ isFixed: true });
    } else if (position < 125 && this.state.isFixed) {
      this.setState({ isFixed: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const lese = getParty(this.context.parties, "Lésé");
    const adverse = getParty(this.context.parties, "Adverse");

    return (
      <>
        {this.state.isFixed ? (
          <div
            style={{
              width: "100%",
              height: "125px",
            }}
          />
        ) : null}
        <div
          style={{
            position: this.state.isFixed ? "fixed" : "initial",
            top: 0,
            display: "flex",
            width: "100%",
            height: this.state.isFixed ? "80px" : "125px",
            paddingBottom: this.state.isFixed ? 0 : "45px",
            background: this.context.isDisconnected
              ? "linear-gradient(to left, var(--primary-color), var(--red-light))"
              : "linear-gradient(to left, var(--primary-color), var(--secondary-color))",
            zIndex: this.state.isFixed ? 15 : 0,
          }}
        >
          <div
            style={{ marginLeft: "15px" }}
            className="mr-auto mt-auto mb-auto d-inline-flex"
          >
            <ButtonIcon
              isBackground
              className="shadow mt-auto mb-auto"
              onClick={() => {
                if (this.props.save === 1) {
                  alert("Sauvegarde en cours");
                } else {
                  this.props.history.push("/home");
                }
              }}
              info="Retour"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </ButtonIcon>
            <ButtonIcon
              isBackground
              className="shadow ml-10 mt-auto mb-auto"
              onClick={() =>
                this.context.updateFolderState(
                  this.context.stateId !== 8
                    ? 8
                    : localStorage.getItem(`${this.context.reference}-stateId`)
                )
              }
              info="Clôturer / Ouvrir"
              disabled={this.context.isDisconnected}
            >
              <FontAwesomeIcon
                icon={this.context.stateId !== 8 ? faLock : faUnlock}
              />
            </ButtonIcon>
            <ButtonIcon
              isBackground
              className="shadow ml-10 mt-auto mb-auto"
              onClick={this.props.openModalBackupStatus}
              info="Sauvegarder"
              disabled={this.context.isDisconnected}
            >
              <FontAwesomeIcon icon={faSave} />
            </ButtonIcon>
            <div className="d-flex flex-column">
              <div
                className="ml-10 m-auto shadow d-inline-flex"
                style={{
                  padding: "4px 10px",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white)",
                  fontSize: "12px",
                  borderRadius: "8px",
                }}
              >
                <span className="m-auto">Dossier n°{this.props.reference}</span>
              </div>
              <div
                className="ml-10 mt-5 m-auto shadow d-inline-flex"
                style={{
                  padding: "4px 10px",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white)",
                  fontSize: "13px",
                  borderRadius: "8px",
                }}
              >
                <span className="m-auto">
                  {lese.kind} {lese.name} / {adverse.kind} {adverse.name}
                </span>
              </div>
            </div>
            <Save save={this.props.save} />
            {this.context.isLock ? (
              <div
                className={"m-auto ml-10 d-flex red bg-red-light"}
                style={{
                  transition: "1s",
                  padding: "0.5rem",
                  borderRadius: "10px",
                }}
              >
                <FontAwesomeIcon icon={faUser} style={{ fontSize: "16px" }} />
                <span
                  className="pl-10 m-auto nowrap"
                  style={{ fontSize: "12px" }}
                >
                  Dossier vérouillé par{" "}
                  {
                    (
                      this.context.users.find(
                        (user) => user.id === this.context.lockUser
                      ) || {}
                    ).name
                  }
                </span>
              </div>
            ) : null}
          </div>
          <div
            style={{ marginRight: "15px" }}
            className="ml-auto mt-auto mb-auto d-inline-flex"
          >
            <ButtonIcon
              onClick={this.props.openModalHistoryPostal}
              isBackground
              className="mr-10 shadow ignore-flex"
              info="Historique des couriers"
              disabled={this.context.isDisconnected}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </ButtonIcon>
            <ButtonIcon
              onClick={this.props.openModalLibraryGLA}
              isBackground
              className="mr-10 shadow ignore-flex"
              info="Biblio'tech"
              disabled={this.context.isDisconnected}
            >
              <FontAwesomeIcon icon={faBook} />
            </ButtonIcon>
            <ButtonIcon
              onClick={this.props.openModalTechnicalAdvice}
              isBackground
              className="mr-10 shadow ignore-flex"
              info="Avis techniques"
              disabled={this.context.isDisconnected}
            >
              <FontAwesomeIcon icon={faFileContract} />
            </ButtonIcon>
            <ButtonIcon
              onClick={this.props.openModalCall}
              isBackground
              className="mr-10 shadow ignore-flex"
              info="Historique des appels"
              disabled={this.context.isDisconnected}
            >
              <FontAwesomeIcon icon={faPhone} />
            </ButtonIcon>
            <ButtonIcon
              onClick={this.props.openModalAppointment}
              isBackground
              className="mr-10 shadow ignore-flex"
              info="Historique des RDV"
              disabled={this.context.isDisconnected}
            >
              <FontAwesomeIcon icon={faCalendar} />
            </ButtonIcon>
            <ButtonIcon
              onClick={() => this.context.openModalLibrary()}
              isBackground
              className="mr-10 shadow ignore-flex"
              info="Photos / Documents"
              disabled={this.context.isDisconnected}
            >
              <FontAwesomeIcon icon={faImages} />
            </ButtonIcon>
          </div>
        </div>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
