import React from "react";

import AuthContext from "../../../../context/AuthContext";

import CustomCard from "../../../../components/folder/general/CustomCard";

import Lexical from "../../../../lexical/Lexical";

export default class MaintenancePlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: "",
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setTemplate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentVehicleId !== this.props.currentVehicleId) {
      this.setTemplate();
    }
  }

  getDefautlTemplate = () => `
  - Vidange moteur tous les kms ou tous les ans</br>
  - Remplacement filtre à huile tous les kms ou tous les ans </br>
  - Remplacement filtre à carburant tous les kms ou tous les ans</br>
  - Remplacement filtre à air tous les kms ou tous les ans</br>
  - Remplacement kit distribution/pompe à eau tous les kms ou tous les ans`;

  setTemplate = () => {
    if (this.props.data) {
      this.setState({ editorState: this.props.data });
    } else {
      this.setState({ editorState: this.getDefautlTemplate() });
    }
  };

  render() {
    const { name } = this.props;
    return (
      <CustomCard
        className="mt-10 mt-20-xl"
        title="Plan d'entretien constructeur"
        name={name}
        disabled={this.context.isLock}
      >
        <Lexical
          value={this.state.editorState}
          onChange={(editorState) =>
            this.setState({ editorState }, () => {
              let tmpData =
                this.context.dataAdministrative.dataVehicleIdentification[
                  this.props.currentVehicleId
                ];
              tmpData.maintenancePlan = editorState;
              this.props.setData(tmpData);
            })
          }
        />
      </CustomCard>
    );
  }
}
