import React from "react";

export default class DashboardFolderLayout extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
