import React from "react";
import {
  faEye,
  faRotateLeft,
  faRotateRight,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Status,
  ListingVertical,
  ButtonIcon,
  Button,
  Loader,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";

import { getParties, getTitleHistory } from "../../utils/general";

import AuthContext from "../../context/AuthContext";

export default class LeftBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
    };
  }

  static contextType = AuthContext;

  getDataActions = () => {
    let actions = [];

    const parties = getParties(this.context.dataAdministrative);

    // 1;2;3;4;5;6;9;11 - general
    // 10;15 - protocol
    // 7;12;13;14 - expertise/report

    this.props.actions.forEach((element) => {
      let replaceKind = "";
      let replaceIdx = "";
      let replaceDate = "";
      let replaceHour = "";
      let replaceType = "";
      let replaceUser = "";
      let replaceRecipients = "";
      let subtitle = element.name;

      if ([1, 2, 3, 4, 5, 6, 9, 11].includes(element.typeId)) {
        // OK
      } else if ([10, 15].includes(element.typeId)) {
        const protocol = this.props.dataProtocolComplaints.dataProtocol;
        if (!protocol) {
          return null;
        } else {
          let indexProtocol = protocol.histories.findIndex(
            (data) => data.token === element.keyData
          );
          replaceIdx = `n°${
            protocol.histories.filter((elem, idx) => indexProtocol >= idx)
              .length
          } `;

          let recipients = [];
          if (element.typeId === 15) {
            const idxSend = this.props.actions.filter(
              (elem) =>
                element.keyData === elem.keyData &&
                elem.id <= element.id &&
                elem.typeId === 15
            ).length;
            if (
              protocol.histories[indexProtocol] &&
              protocol.histories[indexProtocol].recipients.hasOwnProperty(
                idxSend - 1
              )
            ) {
              protocol.histories[indexProtocol].recipients[idxSend - 1].forEach(
                (recipient) => {
                  recipients.push({
                    ...parties[recipient],
                  });
                }
              );
            }
          }

          if (protocol.histories[indexProtocol]) {
            replaceDate = moment(
              protocol.histories[indexProtocol].informations.date
            ).format("DD/MM/YYYY");
            replaceHour = protocol.histories[indexProtocol].informations.hour;
            replaceRecipients = recipients.map(
              (recipient) => `${recipient.kind} ${recipient.name}`
            );
          }
        }
      } else if ([7, 12, 13, 14].includes(element.typeId)) {
        const expertisesReports = [
          ...this.props.dataExpertise,
          ...this.props.dataReports,
        ];
        let indexExpertiseReport = expertisesReports.findIndex(
          (data) => data.token === element.keyData
        );
        const expertiseReport = expertisesReports[indexExpertiseReport];
        if (!expertiseReport) {
          return null;
        } else {
          replaceIdx = `n°${
            expertisesReports.filter(
              (elem, idx) =>
                elem.name === expertiseReport.name &&
                indexExpertiseReport >= idx
            ).length
          } `;

          if (expertiseReport.name.includes("EXPERTISE")) {
            replaceKind = "Expertise";
          } else if (expertiseReport.name.includes("Rapport")) {
            replaceKind = "Rapport";
            subtitle = subtitle.replace("Clôturée", "Clôturé");
            subtitle = subtitle.replace("Ré-ouverte", "Ré-ouvert");
          }

          let recipients = [];
          if (element.typeId === 14) {
            const idxSend = this.props.actions.filter(
              (elem) =>
                element.keyData === elem.keyData &&
                elem.id <= element.id &&
                elem.typeId === 14
            ).length;
            if (expertiseReport.recipients.hasOwnProperty(idxSend - 1)) {
              expertiseReport.recipients[idxSend - 1].forEach((recipient) => {
                recipients.push({
                  ...parties[recipient],
                });
              });
            }
          }

          replaceDate = moment().format("DD/MM/YYYY"); // date action
          replaceHour = moment().format("HH:mm"); // date action
          replaceType = expertiseReport.informations.type
            ? expertiseReport.informations.type
            : "";
          replaceRecipients = recipients.map(
            (recipient) => `${recipient.kind} ${recipient.name}`
          );
        }
      } else {
        return null;
      }

      subtitle = subtitle.replace("[kind]", replaceKind);
      subtitle = subtitle.replace("[idx]", replaceIdx);
      subtitle = subtitle.replace("[date]", replaceDate);
      subtitle = subtitle.replace("[hour]", replaceHour);
      subtitle = subtitle.replace("[type]", replaceType);
      subtitle = subtitle.replace("[user]", replaceUser);
      subtitle = subtitle.replace("[recipients]", replaceRecipients);

      actions.push({
        backgroundColor: element.color,
        title: moment(element.date).format("DD/MM/YYYY HH:mm"),
        subtitle,
      });
    });

    return actions;
  };

  backHistory = () => {
    const currentPosition = this.props.currentPosition - 1;

    const history =
      this.props.histories[this.props.histories.length - 1 - currentPosition];

    this.setState({ isLoad: true }, () => {
      this.context.updateDataFolder(
        history.name,
        JSON.parse(history.data),
        () => this.setState({ isLoad: false }),
        null,
        null,
        currentPosition
      );
    });
  };

  nextHistory = () => {
    const currentPosition = this.props.currentPosition + 1;

    const history =
      this.props.histories[this.props.histories.length - 1 - currentPosition];

    this.setState({ isLoad: true }, () => {
      this.context.updateDataFolder(
        history.name,
        JSON.parse(history.data),
        () => this.setState({ isLoad: false }),
        null,
        null,
        currentPosition
      );
    });
  };

  render() {
    return (
      <>
        {!this.props.isActions ? (
          <div
            onClick={this.props.updateActions}
            style={{
              position: "fixed",
              fontSize: "13px",
              top: "140px",
              left: 0,
              writingMode: "vertical-lr",
              backgroundColor: "var(--orange)",
              padding: "20px 3px",
              color: "#ffffff",
              zIndex: 1,
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            Suivi du dossier
          </div>
        ) : null}
        {!this.props.isHistory ? (
          <div
            onClick={this.props.updateHistory}
            style={{
              position: "fixed",
              fontSize: "13px",
              top: "290px",
              left: 0,
              writingMode: "vertical-lr",
              backgroundColor: "var(--orange)",
              padding: "20px 3px",
              color: "#ffffff",
              zIndex: 1,
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            Historique des modifications
          </div>
        ) : null}
        {this.props.isActions || this.props.isHistory ? (
          <Col xs={12} lg={3}>
            <div
              className={
                window.innerWidth >= 992
                  ? "col-xs-12 col-lg-3 w-100 overflow-y-auto"
                  : "w-100 overflow-y-auto"
              }
              style={
                window.innerWidth >= 992
                  ? {
                      position: "fixed",
                      flexBasis: "calc(25% - 16px)",
                      maxWidth: "calc(25% - 16px)",
                      height: "calc(100vh - 125px)",
                    }
                  : {}
              }
            >
              {this.props.isActions ? (
                <Card
                  title="Suivi du dossier"
                  className="mb-10 mb-20-xl"
                  action={
                    <ButtonIcon className="blue p-0" small>
                      <FontAwesomeIcon icon={faEye} />
                    </ButtonIcon>
                  }
                  onClick={this.props.updateActions}
                >
                  <Status
                    className={`mb-20 w-100 bg-${this.props.stateColor}-light ${this.props.stateColor}`}
                    text={this.props.stateName}
                  />
                  {this.context.isDisconnected ? (
                    <div className="text-center red p-20">
                      <FontAwesomeIcon
                        style={{ fontSize: "26px" }}
                        icon={faTriangleExclamation}
                      />
                      <p>
                        le suivi du dossier n'est pas dispobile en mode hors
                        connexion
                      </p>
                    </div>
                  ) : (
                    <ListingVertical data={this.getDataActions()} />
                  )}
                </Card>
              ) : null}
              {this.props.isHistory ? (
                <Card
                  title="Historique des modifications"
                  className="mb-10 mb-20-xl"
                  action={
                    <ButtonIcon className="blue p-0" small>
                      <FontAwesomeIcon icon={faEye} />
                    </ButtonIcon>
                  }
                  onClick={this.props.updateHistory}
                >
                  <Row>
                    <Col xs={12} md={6}>
                      <Button
                        text="Annuler"
                        disabled={
                          !this.props.histories.length ||
                          this.props.currentPosition + 1 >=
                            this.props.histories.length
                        }
                        onClick={this.nextHistory}
                      >
                        <FontAwesomeIcon icon={faRotateLeft} />
                      </Button>
                    </Col>
                    <Col xs={12} md={6}>
                      <Button
                        text="Rétablir"
                        disabled={
                          !this.props.histories.length ||
                          !this.props.currentPosition
                        }
                        onClick={this.backHistory}
                      >
                        <FontAwesomeIcon icon={faRotateRight} />
                      </Button>
                    </Col>
                  </Row>
                  {this.props.histories.reverse().map((history, idx) => (
                    <div key={idx}>
                      <hr className="solid" />
                      <h4 className="mt-0 mb-0">
                        {getTitleHistory(history.name)}
                      </h4>
                      <p className="mt-0 ft-14">
                        Il y a{" "}
                        {moment
                          .duration(moment().diff(moment(history.date)))
                          .seconds()}{" "}
                        secondes
                      </p>
                    </div>
                  ))}
                </Card>
              ) : null}
            </div>
          </Col>
        ) : null}
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
