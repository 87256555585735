import React from "react";
import moment from "moment";
import { ButtonIcon, Button, Card, Loader } from "ui-kit-ck-consultant";
import {
  faEnvelope,
  faFilePdf,
  faUnlock,
  faLock,
  faTrash,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ADMINISTRATIVE componennts
import PurposeMission from "../../../views/dashboard/folder/administrative/PurposeMission";
import IdentificationParties from "../../../views/dashboard/folder/administrative/IdentificationParties";
import RecallFacts from "../../../views/dashboard/folder/RecallFacts";
import VehicleIdentification from "../../../views/dashboard/folder/administrative/VehicleIdentification";

// EXPERTISE components
import Attendees from "./expertise/Attendees";
import ExpertiseConditions from "./expertise/ExpertiseConditions";
import Findings from "./expertise/Findings";
import PositionKnowParties from "./expertise/PositionKnowParties";
import Vehicle from "./expertise/Vehicle";
import Informations from "./expertise/Informations";
import Attachments from "./expertise/Attachments";
import Fees from "./expertise/Fees";
import DeclarationParties from "./expertise/DeclarationParties";

// REPORT components
import NatureOfMission from "./report/NatureOfMission";
import AmicableSolution from "./report/AmicableSolution";
import NatureDispute from "./report/NatureDispute";
import AdditionalInformation from "./report/AdditionalInformation";
import DamageAssessment from "./report/DamageAssessment";
import TechnicalAnalysis from "./report/TechnicalAnalysis";
import Responsibilities from "./report/Responsibilities";
import AdditionalDamage from "./report/AdditionalDamage";
import Conclusion from "./report/Conclusion";
import Observations from "./report/Observations";
import ContradictoryExamination from "./report/ContradictoryExamination";
import ExpertSignature from "./report/ExpertSignature";
import VehicleQuality from "./report/VehicleQuality";
import QualificationOfTheSinister from "./report/QualificationOfTheSinister";
import Origines from "./report/Origines";
import Consequences from "./report/Consequences";
import CasualLink from "./report/CasualLink";
import FinancialStake from "./report/FinancialStake";
import ContinuedFileState from "./report/ContinuedFileState";
import InsuredActivity from "./report/InsuredActivity";
import RCadditionalDamage from "./report/RCadditionalDamage";
import Beneficiary from "./report/Beneficiary";
import Conditions from "./report/Conditions";
import RCdamageAssessment from "./report/RCdamageAssessment";
import Resolution from "./report/Resolution";
import Information from "./report/Information";

import HistoryTable from "../file-preparation/HistoryTable";
import ModalSendPdf from "../general/ModalSendPdf";

import { getData, getParties, getPartiesOptions } from "../../../utils/general";

import { documentToPdfRequest } from "../../../requests/mail";
import {
  getFolderDocument,
  insertFolderTechnicalAdvice,
} from "../../../requests/folder";

import AuthContext from "../../../context/AuthContext";

export default class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isModalMail: false,
      mailBase64: "",
      mailFiles: [],
      dataFolderDocument: [],
      fees: [],
    };
  }
  static contextType = AuthContext;

  componentDidMount() {
    this.getFolderDocument();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.token !== prevProps.data.token) {
      this.getFolderDocument();
    }
  }

  getFolderDocument = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        getFolderDocument(
          {
            folderId: this.context.folderId,
            token: this.props.data.token,
            type: `Rapport ${this.props.data.informations.type}`,
          },
          (result) => {
            if (result.success) {
              this.setState({
                isLoad: false,
                dataFolderDocument: result.data,
              });
            } else {
              this.setState({
                isLoad: false,
              });
            }
          }
        );
      }
    );
  };

  downloadPDF = (data) => {
    const type = this.props.data.informations.type;

    let name = "";
    const reference =
      this.context.dataAdministrative.dataAdministrativeInformation.mission
        .reference;
    this.documentToPdf(data, (base64) => {
      if (type.includes("final")) {
        name = "rapport-d-expertise";
      } else if (type === "information") {
        name = "rapport-d-information";
      } else if (type === "simplified" && this.props.data.company === "macif") {
        name = "rapport-simplifie-macif";
      } else if (type === "simplified" && this.props.data.company === "covea") {
        name = "rapport-simplifie-covea";
      }

      name = `${name}-${reference}-du-${moment().format(
        "YYYY-MM-DD_HH-mm-ss"
      )}.pdf`;

      this.context.handleOpenModalPDF(base64, name);
    });
  };

  documentToPdf = (data, callback, isWebservice = false) => {
    data = {
      ...data,
      folderId: this.context.folderId,
      folderType: this.context.shortType,
      isWebservice,
      expert:
        this.context.users.filter((expert) => {
          return (
            expert.id ===
            parseInt(
              this.context.dataAdministrative.dataAdministrativeInformation
                .mission.expert
            )
          );
        })[0] || {},
    };
    this.setState(
      {
        isLoad: true,
      },
      () => {
        documentToPdfRequest(data, (result) => {
          if (result.success) {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                callback(result.data, result.files);
              }
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                window.alert("Une erreur est survenue");
              }
            );
          }
        });
      }
    );
  };

  getStatusCard = (name) => {
    const statusCard = getData(this.props.data, "statusCard")[name];
    return statusCard === undefined ? true : statusCard;
  };

  updateStatusCard = (name) => {
    let tmpData = this.props.data;

    tmpData.statusCard[name] =
      tmpData.statusCard[name] !== undefined
        ? !tmpData.statusCard[name]
        : false;

    this.props.updateReport(tmpData);
  };

  getJustifyCard = (name) => {
    const justifyCard = getData(this.props.data, "justifyCard")[name];
    return justifyCard === undefined ? false : justifyCard;
  };

  updateJustifyCard = (name) => {
    let tmpData = this.props.data;

    tmpData.justifyCard = tmpData.justifyCard || {};

    tmpData.justifyCard[name] =
      tmpData.justifyCard[name] !== undefined
        ? !tmpData.justifyCard[name]
        : true;

    this.props.updateReport(tmpData);
  };

  getJumpCard = (name) => {
    const jumpCard = getData(this.props.data, "jumpCard")[name];
    return !!jumpCard;
  };

  updateJumpCard = (name) => {
    let tmpData = this.props.data;

    if (!tmpData.jumpCard) {
      tmpData.jumpCard = {};
    }

    tmpData.jumpCard[name] =
      tmpData.jumpCard[name] !== undefined ? !tmpData.jumpCard[name] : false;

    this.props.updateReport(tmpData);
  };

  updateStatusCardExpertise = (idx, name) => {
    let tmpData = this.props.data.expertises;

    tmpData[idx].statusCard[name] = !tmpData[idx].statusCard[name];
    this.updateDataReport("expertises", tmpData);
  };

  updateDataReport = (name, data, callback = () => {}) => {
    let tmpData = this.props.data;

    tmpData[name] = data;

    if (["RCdamageAssessment", "damageAssessment"].includes(name)) {
      let tmpDataEvaluation = this.props.dataEvaluation;
      tmpDataEvaluation[name] = data;

      this.context.updateDataFolder("dataEvaluation", tmpDataEvaluation);
    }

    this.props.updateReport(tmpData, callback);
  };

  updateDataExpertise = (idx, name, data, callback) => {
    let tmpData = this.props.data.expertises;

    tmpData[idx][name] = data;

    this.updateDataReport("expertises", tmpData, callback);
  };

  getHideCardExpertise = (title, idx, name) => {
    return (
      <div className="d-flex w-100">
        <h2>{title}</h2>
        <div className="ml-auto">
          <ButtonIcon
            className="black"
            onClick={() => this.updateStatusCardExpertise(idx, name)}
          >
            <FontAwesomeIcon
              icon={
                this.props.data.expertises[idx].statusCard[name] !== false
                  ? faEye
                  : faEyeSlash
              }
            />
          </ButtonIcon>
        </div>
      </div>
    );
  };

  getDefault = () => {
    const { data, dataRecallFacts } = this.props;
    const { dataVehicleIdentification } = this.context.dataAdministrative;
    const { dataAdministrative } = this.context;

    const type = data.informations.type;

    return (
      <>
        {["informationRc", "finalRc"].includes(type) ? (
          <NatureOfMission data={getData(data, "natureOfMission", "string")} />
        ) : null}

        {["simplified"].includes(type) &&
        this.props.data.company === "macif" ? (
          <Information data={getData(data, "information")} />
        ) : null}

        {["information", "final", "finalRc"].includes(type) ||
        this.props.data.company === "covea" ? (
          <PurposeMission data={getData(data, "purposeMission")} />
        ) : null}

        {["information", "final", "finalRc"].includes(type) ? (
          <IdentificationParties
            data={this.props.dataAdministrative.dataIdentificationParties}
            dataParties={getData(data, "partiesReport")}
            setData={(name, data) => {
              let tmpData = this.props.dataAdministrative;
              tmpData[name] = data;
              this.context.updateDataFolder(
                "dataAdministrative",
                tmpData,
                () => {}
              );
            }}
          />
        ) : null}

        {["information", "final", "informationRc", "finalRc"].includes(type) ||
        this.props.data.company === "covea" ? (
          <VehicleIdentification
            dataVehicleIdentification={dataVehicleIdentification}
            data={getData(data, "vehicleIdentification")}
          />
        ) : null}

        {["informationRc", "finalRc", "final"].includes(type) ? (
          <>
            <Vehicle
              dataExpertiseConditions={getData(data, "expertiseConditions")}
              data={getData(data, "vehicle")}
              statusCardLevelsWear={this.getStatusCard("LevelsWear")}
              updateStatusCardLevelsWear={() =>
                this.updateStatusCard("LevelsWear")
              }
              isForceCard={true}
            />
          </>
        ) : null}

        {["informationRc", "finalRc"].includes(type) ? (
          <VehicleQuality data={getData(data, "vehicleQuality")} />
        ) : null}

        {["informationRc", "finalRc"].includes(type) ? (
          <QualificationOfTheSinister
            data={getData(data, "qualificationOfTheSinister")}
            administrative={
              this.props.dataAdministrative.dataAdministrativeInformation
            }
          />
        ) : null}

        {["finalRc"].includes(type) ? (
          <InsuredActivity data={getData(data, "insuredActivity", "string")} />
        ) : null}

        {["information", "final", "informationRc", "finalRc"].includes(type) ? (
          <RecallFacts
            dataRecallFacts={dataRecallFacts}
            date={data.informations.date}
            dataExpertiseConditions={getData(data, "expertiseConditions")}
            data={getData(data, "recallFacts")}
          />
        ) : null}

        {data.expertises.map((expertise, idx) => (
          <AuthContext.Provider
            key={idx}
            value={{
              ...this.context,
              updateStatusCard: this.updateStatusCard,
              getStatusCard: this.getStatusCard,
              updateJustifyCard: this.updateJustifyCard,
              getJustifyCard: this.getJustifyCard,
              updateJumpCard: this.updateJumpCard,
              getJumpCard: this.getJumpCard,
              isLock: this.context.isLock || data.isLock,
              isReport: true,
              updateDataReport: this.updateDataReport,
              updateDataExpertise: (name, tmpData, callback) =>
                this.updateDataExpertise(idx, name, tmpData, callback),
            }}
          >
            <Card
              key={idx}
              className="mt-10 mt-20-xl"
              title={`Expertise ${expertise.type ? expertise.type : ""} n°${
                idx + 1
              } du ${moment(expertise["informations"].date).format(
                "DD/MM/YYYY"
              )}`.toUpperCase()}
              border={"#0f66b0"}
              disabled={data.isLock}
              action={
                <ButtonIcon
                  className="red p-0"
                  small
                  onClick={() => {
                    window.confirmCustom(
                      "Êtes-vous sûr de vouloir supprimer cette expertise ?",
                      (result) => {
                        if (result) {
                          let tmpData = this.props.data.expertises;

                          tmpData.splice(idx, 1);

                          this.updateDataReport("expertises", tmpData);
                        }
                      }
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ButtonIcon>
              }
              disableStyleAction
            >
              {["informationRc", "finalRc"].includes(type) ||
              (["final"].includes(type) &&
                this.props.data.company === "macif") ? (
                <>
                  {expertise.statusCard.Informations === false ? null : (
                    <Informations data={getData(expertise, "informations")} />
                  )}
                </>
              ) : null}
              {[
                "information",
                "final",
                "simplified",
                "informationRc",
                "finalRc",
              ].includes(type) ? (
                <>
                  {this.getHideCardExpertise(
                    "Personnes présentes",
                    idx,
                    "Attendees"
                  )}
                  {expertise.statusCard.Attendees === false ? null : (
                    <Attendees
                      data={getData(expertise, "attendees")}
                      dataAdministrative={getData(
                        dataAdministrative,
                        "dataIdentificationParties"
                      )}
                      updateDataAdministrative={(name, data) => {
                        let tmpData = this.props.dataAdministrative;
                        tmpData[name] = data;
                        this.context.updateDataFolder(
                          "dataAdministrative",
                          tmpData,
                          () => {}
                        );
                      }}
                    />
                  )}
                </>
              ) : null}

              {["final", "finalRc"].includes(type) ? (
                <>
                  {this.getHideCardExpertise(
                    "Déclaration des parties",
                    idx,
                    "DeclarationParties"
                  )}
                  {expertise.statusCard.DeclarationParties === false ? null : (
                    <DeclarationParties
                      ref={(ref) => {
                        this.refDeclarationParties = ref;
                      }}
                      data={getData(expertise, "declarationParties", "array")}
                      partiesOptions={this.context.partiesOptions.filter(
                        (element) =>
                          getData(data, "attendees")[element.value] === 1
                      )}
                    />
                  )}
                </>
              ) : null}

              {["information", "final", "informationRc", "finalRc"].includes(
                type
              ) || this.props.data.company === "covea" ? (
                <>
                  {this.getHideCardExpertise(
                    "Conditions d'expertise",
                    idx,
                    "ExpertiseConditions"
                  )}
                  {expertise.statusCard.ExpertiseConditions === false ? null : (
                    <ExpertiseConditions
                      data={getData(expertise, "expertiseConditions")}
                    />
                  )}
                </>
              ) : null}

              {["information"].includes(type) ? (
                <>
                  {this.getHideCardExpertise("Véhicule", idx, "Vehicle")}{" "}
                  {expertise.statusCard.Vehicle === false ? null : (
                    <Vehicle
                      dataExpertiseConditions={getData(
                        expertise,
                        "expertiseConditions"
                      )}
                      data={getData(expertise, "vehicle")}
                      statusCard={expertise.statusCard.Vehicle}
                      statusCardLevelsWear={expertise.statusCard.LevelsWear}
                      updateStatusCardLevelsWear={() =>
                        this.updateStatusCardExpertise(idx, "LevelsWear")
                      }
                    />
                  )}
                </>
              ) : null}

              {[
                "information",
                "final",
                "simplified",
                "informationRc",
                "finalRc",
              ].includes(type) ? (
                <>
                  {this.getHideCardExpertise("Constatations", idx, "Findings")}
                  {expertise.statusCard.Findings === false ? null : (
                    <Findings
                      dataAttendees={getData(expertise, "attendees")}
                      data={getData(expertise, "findings", "array")}
                      isReport={true}
                    />
                  )}
                </>
              ) : null}
              <>
                {this.getHideCardExpertise("Frais annexes", idx, "Fees")}
                {expertise.statusCard.Fees === false ? null : (
                  <Fees data={getData(data, "expertiseConditions")} />
                )}
              </>

              {["information", "final"].includes(type) ||
              (["simplified"].includes(type) &&
                this.props.data.company === "macif") ? (
                <>
                  {this.getHideCardExpertise(
                    "Position connue des parties",
                    idx,
                    "PositionKnowParties"
                  )}
                  {expertise.statusCard.PositionKnowParties === false ? null : (
                    <PositionKnowParties
                      ref={(ref) => {
                        this.refPositionKnowParties = ref;
                      }}
                      data={getData(expertise, "positionKnowParties", "array")}
                      dataComment={getData(
                        expertise,
                        "positionKnowPartiesComment",
                        "string"
                      )}
                      partiesOptions={this.context.partiesOptions.filter(
                        (element) =>
                          getData(data, "attendees")[element.value] === 1
                      )}
                    />
                  )}
                </>
              ) : null}
            </Card>
          </AuthContext.Provider>
        ))}

        {["information", "final"].includes(type) ||
        this.props.data.company === "covea" ? (
          <TechnicalAnalysis
            data={getData(data, "technicalAnalysis", "array")}
          />
        ) : null}

        {["final"].includes(type) ||
        (["simplified"].includes(type) &&
          this.props.data.company === "macif") ? (
          <Responsibilities
            data={getData(data, "responsibilities", "string")}
          />
        ) : null}

        {["simplified"].includes(type) &&
        this.props.data.company === "macif" ? (
          <Resolution data={getData(data, "resolution", "string")} />
        ) : null}

        {["final"].includes(type) ? (
          <AdditionalDamage data={getData(data, "additionalDamage", "array")} />
        ) : null}

        {["information"].includes(type) ||
        this.props.data.company === "covea" ? (
          <Observations data={getData(data, "observations")} />
        ) : null}

        {["information"].includes(type) ? (
          <ContradictoryExamination
            data={getData(data, "contradictoryExamination", "string")}
          />
        ) : null}

        {["informationRc", "finalRc"].includes(type) ? (
          <h2>Appréciation des circonstances, causes et conséquences</h2>
        ) : null}

        {["informationRc", "finalRc"].includes(type) ? (
          <Origines data={getData(data, "origines", "string")} />
        ) : null}

        {["informationRc", "finalRc"].includes(type) ? (
          <Consequences data={getData(data, "consequences", "string")} />
        ) : null}

        {["informationRc", "finalRc"].includes(type) ? (
          <CasualLink data={getData(data, "casualLink", "string")} />
        ) : null}

        {["informationRc", "finalRc"].includes(type) ? (
          <h2>Évaluation et frais annexes</h2>
        ) : null}
        {["final"].includes(type) ? (
          <DamageAssessment
            type={type}
            data={getData(data, "damageAssessment", "array")}
            dataComment={getData(data, "damageAssessmentComment", "string")}
            dataEvaluation={this.props.dataEvaluation}
          />
        ) : null}

        {["finalRc"].includes(type) ? (
          <RCdamageAssessment
            data={getData(data, "RCdamageAssessment", "array")}
            dataEvaluation={this.props.dataEvaluation}
          />
        ) : null}

        {["finalRc"].includes(type) ? (
          <RCadditionalDamage data={getData(data, "rcAdditionalDamage")} />
        ) : null}

        {["finalRc"].includes(type) ? (
          <Beneficiary data={getData(data, "beneficiary")} />
        ) : null}

        {["finalRc"].includes(type) ? (
          <Conditions data={getData(data, "conditions")} />
        ) : null}

        {["finalRc"].includes(type) ? (
          <PositionKnowParties
            ref={(ref) => {
              this.refPositionKnowParties = ref;
            }}
            data={getData(data, "positionKnowParties", "array")}
            dataComment={getData(data, "positionKnowPartiesComment", "string")}
            partiesOptions={this.context.partiesOptions.filter(
              (element) => getData(data, "attendees")[element.value] === 1
            )}
            isCard
          />
        ) : null}
        {["final", "simplified", "finalRc"].includes(type) &&
        (type !== "simplified" || this.props.data.company !== "macif") ? (
          <Conclusion
            data={getData(
              data["conclusion"] && typeof data["conclusion"] === "string"
                ? { text: data }
                : data,
              "conclusion",
              {}
            )}
            type={type}
            isLimit={
              ["finalRc"].includes(type) ||
              this.context.dataAdministrative.dataAdministrativeInformation
                .mission.typeMission === "7"
            }
          />
        ) : null}
        {["informationRc"].includes(type) ? (
          <FinancialStake data={getData(data, "financialStake", "string")} />
        ) : null}

        {["informationRc"].includes(type) ? (
          <ContinuedFileState data={getData(data, "continuedFileState")} />
        ) : null}
        <Attachments data={getData(data, "attachments", "array")} />
        {[
          "information",
          "final",
          "simplified",
          "informationRc",
          "finalRc",
        ].includes(type) ? (
          <ExpertSignature data={getData(data, "expertSignature")} />
        ) : null}
      </>
    );
  };

  getFinalMacif = () => {
    const { data, dataRecallFacts } = this.props;
    const { dataAdministrative } = this.context;

    const type = data.informations.type;

    return (
      <>
        {["final"].includes(type) && this.props.data.company === "macif" ? (
          <AmicableSolution data={getData(data, "amicableSolution")} />
        ) : null}

        {["final"].includes(type) && this.props.data.company === "macif" ? (
          <NatureDispute data={getData(data, "natureDispute")} />
        ) : null}

        {["information", "final", "finalRc"].includes(type) ||
        this.props.data.company === "covea" ? (
          <PurposeMission data={getData(data, "purposeMission")} />
        ) : null}

        {["information", "final", "finalRc"].includes(type) ? (
          <IdentificationParties
            data={this.props.dataAdministrative.dataIdentificationParties}
            dataParties={getData(data, "parties")}
            setData={(name, data) => {
              let tmpData = this.props.dataAdministrative;
              tmpData[name] = data;
              this.context.updateDataFolder(
                "dataAdministrative",
                tmpData,
                () => {}
              );
            }}
          />
        ) : null}

        {["information", "final", "informationRc", "finalRc"].includes(type) ||
        this.props.data.company === "covea" ? (
          <VehicleIdentification
            data={getData(data, "vehicleIdentification")}
          />
        ) : null}

        {["informationRc", "finalRc", "final"].includes(type) ? (
          <>
            <Vehicle
              dataExpertiseConditions={getData(data, "expertiseConditions")}
              data={getData(data, "vehicle")}
              statusCardLevelsWear={this.getStatusCard("LevelsWear")}
              updateStatusCardLevelsWear={() =>
                this.updateStatusCard("LevelsWear")
              }
              isForceCard={true}
            />
          </>
        ) : null}

        {["information", "final", "informationRc", "finalRc"].includes(type) ? (
          <RecallFacts
            dataRecallFacts={dataRecallFacts}
            date={data.informations.date}
            dataExpertiseConditions={getData(data, "expertiseConditions")}
            data={getData(data, "recallFacts")}
          />
        ) : null}

        {data.expertises.map((expertise, idx) => (
          <AuthContext.Provider
            key={idx}
            value={{
              ...this.context,
              updateStatusCard: this.updateStatusCard,
              getStatusCard: this.getStatusCard,
              updateJustifyCard: this.updateJustifyCard,
              getJustifyCard: this.getJustifyCard,
              updateJumpCard: this.updateJumpCard,
              getJumpCard: this.getJumpCard,
              isLock: this.context.isLock || data.isLock,
              isReport: true,
              updateDataReport: this.updateDataReport,
              updateDataExpertise: (name, tmpData, callback) =>
                this.updateDataExpertise(idx, name, tmpData, callback),
            }}
          >
            <Card
              key={idx}
              className="mt-10 mt-20-xl"
              title={`Expertise ${expertise.type ? expertise.type : ""} n°${
                idx + 1
              } du ${moment(expertise["informations"].date).format(
                "DD/MM/YYYY"
              )}`.toUpperCase()}
              border={"#0f66b0"}
              disabled={data.isLock}
              action={
                <ButtonIcon
                  className="red p-0"
                  small
                  onClick={() => {
                    window.confirmCustom(
                      "Êtes-vous sûr de vouloir supprimer cette expertise ?",
                      (result) => {
                        if (result) {
                          let tmpData = this.props.data.expertises;

                          tmpData.splice(idx, 1);

                          this.updateDataReport("expertises", tmpData);
                        }
                      }
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ButtonIcon>
              }
              disableStyleAction
            >
              {["informationRc", "finalRc"].includes(type) ||
              (["final"].includes(type) &&
                this.props.data.company === "macif") ? (
                <>
                  {expertise.statusCard.Informations === false ? null : (
                    <Informations data={getData(expertise, "informations")} />
                  )}
                </>
              ) : null}
              {[
                "information",
                "final",
                "simplified",
                "informationRc",
                "finalRc",
              ].includes(type) ? (
                <>
                  {this.getHideCardExpertise(
                    "Personnes présentes",
                    idx,
                    "Attendees"
                  )}
                  {expertise.statusCard.Attendees === false ? null : (
                    <Attendees
                      data={getData(expertise, "attendees")}
                      dataAdministrative={getData(
                        dataAdministrative,
                        "dataIdentificationParties"
                      )}
                      updateDataAdministrative={(name, data) => {
                        let tmpData = this.props.dataAdministrative;
                        tmpData[name] = data;
                        this.context.updateDataFolder(
                          "dataAdministrative",
                          tmpData,
                          () => {}
                        );
                      }}
                    />
                  )}
                </>
              ) : null}

              {["information", "final", "informationRc", "finalRc"].includes(
                type
              ) || this.props.data.company === "covea" ? (
                <>
                  {this.getHideCardExpertise(
                    "Conditions d'expertise",
                    idx,
                    "ExpertiseConditions"
                  )}
                  {expertise.statusCard.ExpertiseConditions === false ? null : (
                    <ExpertiseConditions
                      data={getData(expertise, "expertiseConditions")}
                    />
                  )}
                </>
              ) : null}

              {[
                "information",
                "final",
                "simplified",
                "informationRc",
                "finalRc",
              ].includes(type) ? (
                <>
                  {this.getHideCardExpertise("Constatations", idx, "Findings")}
                  {expertise.statusCard.Findings === false ? null : (
                    <Findings
                      dataAttendees={getData(expertise, "attendees")}
                      data={getData(expertise, "findings", "array")}
                      isReport={true}
                    />
                  )}
                </>
              ) : null}

              {["information", "final"].includes(type) ? (
                <>
                  {this.getHideCardExpertise(
                    "Position connue des parties",
                    idx,
                    "PositionKnowParties"
                  )}
                  {expertise.statusCard.PositionKnowParties === false ? null : (
                    <PositionKnowParties
                      ref={(ref) => {
                        this.refPositionKnowParties = ref;
                      }}
                      data={getData(expertise, "positionKnowParties", "array")}
                      dataComment={getData(
                        expertise,
                        "positionKnowPartiesComment",
                        "string"
                      )}
                      partiesOptions={this.context.partiesOptions.filter(
                        (element) =>
                          getData(data, "attendees")[element.value] === 1
                      )}
                    />
                  )}
                </>
              ) : null}
            </Card>
          </AuthContext.Provider>
        ))}

        {["final"].includes(type) && this.props.data.company === "macif" ? (
          <AdditionalInformation
            data={getData(data, "additionalInformation")}
          />
        ) : null}

        {["information", "final"].includes(type) ||
        this.props.data.company === "covea" ? (
          <TechnicalAnalysis
            data={getData(data, "technicalAnalysis", "array")}
          />
        ) : null}

        {["final"].includes(type) ? (
          <DamageAssessment
            type={type}
            data={getData(data, "damageAssessment")}
            dataComment={getData(data, "damageAssessmentComment", "string")}
            dataEvaluation={this.props.dataEvaluation}
          />
        ) : null}

        {["final"].includes(type) ? (
          <AdditionalDamage data={getData(data, "additionalDamage", "array")} />
        ) : null}

        {["final"].includes(type) ||
        (["simplified"].includes(type) &&
          this.props.data.company === "macif") ? (
          <Responsibilities
            data={getData(data, "responsibilities", "string")}
          />
        ) : null}

        {["final", "simplified", "finalRc"].includes(type) &&
        (type !== "simplified" || this.props.data.company !== "macif") ? (
          <Conclusion
            data={getData(
              data["conclusion"] && typeof data["conclusion"] === "string"
                ? { text: data }
                : data,
              "conclusion",
              {}
            )}
            type={type}
            isLimit
          />
        ) : null}

        <Attachments data={getData(data, "attachments", "array")} />
        {[
          "information",
          "final",
          "simplified",
          "informationRc",
          "finalRc",
        ].includes(type) ? (
          <ExpertSignature data={getData(data, "expertSignature")} />
        ) : null}
      </>
    );
  };

  render() {
    const { data, dataRecallFacts } = this.props;
    const { dataAdministrative } = this.context;
    const parties = getParties(this.state.dataAdministrative);
    const partiesOptions = getPartiesOptions(this.state.dataAdministrative);

    const type = data.informations.type;

    return (
      <AuthContext.Provider
        value={{
          ...this.context,
          updateStatusCard: this.updateStatusCard,
          getStatusCard: this.getStatusCard,
          updateJustifyCard: this.updateJustifyCard,
          getJustifyCard: this.getJustifyCard,
          updateJumpCard: this.updateJumpCard,
          getJumpCard: this.getJumpCard,
          isLock: this.context.isLock || data.isLock,
          isReport: true,
          updateDataReport: this.updateDataReport,
          data,
        }}
      >
        {["final"].includes(type) && this.props.data.company === "macif"
          ? this.getFinalMacif()
          : this.getDefault()}
        {this.context.isLock ? null : (
          <div className="d-flex w-100 mt-20">
            <ButtonIcon
              isBackground
              className={"ml-auto bg-red"}
              disabled={this.context.isDisconnected}
              onClick={this.props.onClickDelete}
            >
              <FontAwesomeIcon icon={faTrash} />
            </ButtonIcon>
            <ButtonIcon
              isBackground
              className="white ml-10"
              disabled={this.context.isDisconnected}
              onClick={() =>
                this.downloadPDF({
                  administrative: dataAdministrative,
                  data: data,
                  recallFacts: dataRecallFacts,
                  type: `Report ${type}`,
                })
              }
            >
              <FontAwesomeIcon icon={faFilePdf} />
            </ButtonIcon>
            <ButtonIcon
              isBackground
              className="white ml-10"
              disabled={this.context.isDisconnected}
              onClick={() => {
                this.documentToPdf(
                  {
                    administrative: dataAdministrative,
                    data: data,
                    recallFacts: dataRecallFacts,
                    type: `Report ${type}`,
                  },
                  (base64, files) => {
                    console.log(files);
                    this.setState({
                      isModalMail: true,
                      mailBase64: base64,
                      mailFiles: files,
                    });
                  }
                );
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </ButtonIcon>
            <Button
              className="ml-10 w-auto"
              text={data.isLock ? "Réouvrir" : "Clôturer + Exporter"}
              disabled={this.context.isDisconnected}
              onClick={() => {
                window.confirmCustom(
                  data.isLock
                    ? "Êtes-vous sûr de vouloir réouvrir ce rapport ?"
                    : "Êtes-vous sûr de vouloir clôturer ce rapport ?",
                  (result) => {
                    if (result) {
                      if (
                        !data.isLock &&
                        (data.technicalAnalysis || data.origines)
                      ) {
                        insertFolderTechnicalAdvice(
                          {
                            folder: this.context.folderId,
                            reference: this.context.reference,
                            date: moment().format("YYYY-MM-DD"),
                            brand:
                              this.context.dataAdministrative
                                .dataVehicleIdentification[0].brand,
                            model:
                              this.context.dataAdministrative
                                .dataVehicleIdentification[0].model,
                            motor:
                              this.context.dataAdministrative
                                .dataVehicleIdentification[0].motorType,
                            text: data.technicalAnalysis || data.origines,
                          },
                          () => {}
                        );
                      }

                      let tmpData = this.props.data;
                      tmpData.isLock = !tmpData.isLock;

                      tmpData.isLock
                        ? this.props.updateReport(
                            tmpData,
                            () => {
                              if (tmpData.name.includes("Rapport final")) {
                                this.context.updateFolderState(6);
                              } else if (
                                tmpData.name.includes("Rapport d'information")
                              ) {
                                this.context.updateFolderState(5);
                              }

                              this.documentToPdf(
                                {
                                  administrative: dataAdministrative,
                                  data: data,
                                  recallFacts: dataRecallFacts,
                                  type: `Report ${type}`,
                                },
                                () => {},
                                true
                              );
                            },
                            12,
                            tmpData.token
                          )
                        : this.props.updateReport(
                            tmpData,
                            () => {
                              if (tmpData.name.includes("Rapport final")) {
                                this.context.updateFolderState(5);
                              }
                            },
                            13,
                            tmpData.token
                          );
                    }
                  }
                );
              }}
            >
              {data.isLock ? (
                <FontAwesomeIcon icon={faUnlock} />
              ) : (
                <FontAwesomeIcon icon={faLock} />
              )}
            </Button>
            {!data.isLock && (
              <Button
                className="ml-10 w-auto"
                text={data.isLock ? "Réouvrir" : "Clôturer"}
                disabled={this.context.isDisconnected}
                onClick={() => {
                  window.confirmCustom(
                    data.isLock
                      ? "Êtes-vous sûr de vouloir réouvrir ce rapport ?"
                      : "Êtes-vous sûr de vouloir clôturer ce rapport ?",
                    (result) => {
                      if (result) {
                        let tmpData = this.props.data;
                        tmpData.isLock = !tmpData.isLock;

                        tmpData.isLock
                          ? this.props.updateReport(
                              tmpData,
                              () => {
                                if (tmpData.name.includes("Rapport final")) {
                                  this.context.updateFolderState(6);
                                } else if (
                                  tmpData.name.includes("Rapport d'information")
                                ) {
                                  this.context.updateFolderState(5);
                                }

                                this.documentToPdf(
                                  {
                                    administrative: dataAdministrative,
                                    data: data,
                                    recallFacts: dataRecallFacts,
                                    type: `Report ${type}`,
                                  },
                                  () => {},
                                  false
                                );
                              },
                              12,
                              tmpData.token
                            )
                          : this.props.updateReport(
                              tmpData,
                              () => {
                                if (tmpData.name.includes("Rapport final")) {
                                  this.context.updateFolderState(5);
                                }
                              },
                              13,
                              tmpData.token
                            );
                      }
                    }
                  );
                }}
              >
                <FontAwesomeIcon icon={faLock} />
              </Button>
            )}
          </div>
        )}
        <ModalSendPdf
          token={data.token}
          dataAdministrative={dataAdministrative}
          informations={data.informations}
          data={this.state.mailBase64}
          files={this.state.mailFiles}
          show={this.state.isModalMail}
          type={`Rapport ${type.replace("simplified", "simplifié")}`}
          messageType={`le rapport ${type.replace("simplified", "simplifié")}`}
          partiesOption={partiesOptions}
          parties={parties}
          onClose={(isReload = false) =>
            this.setState(
              { isModalMail: false, mailBase64: "", mailFiles: [] },
              () => {
                if (isReload) {
                  this.getFolderDocument();
                }
              }
            )
          }
          date={this.props.data.informations.date}
          updateReport={this.props.updateReport}
          dataReport={this.props.data}
        />
        <Loader show={this.state.isLoad} />
        <HistoryTable data={this.state.dataFolderDocument} />
      </AuthContext.Provider>
    );
  }
}
