import React from "react";

import CustomCard from "../general/CustomCard";

import AuthContext from "../../../context/AuthContext";

import Lexical from "../../../lexical/Lexical";

export default class CardEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: "",
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState(
      {
        editorState:
          (this.props.subDataName
            ? this.props.data[this.props.subDataName]
            : this.props.data) || this.props.default,
      },
      () => {
        if (
          (this.props.subDataName
            ? !this.props.data[this.props.subDataName]
            : !this.props.data) &&
          this.props.default
        ) {
          this.handleEditorChange();
        }
      }
    );
  }

  updateStateEditor = (value, callback) => {
    this.setState(
      { editorState: `${this.state.editorState}\n${value}` },
      callback
    );
  };

  handleEditorChange = () => {
    let tmpData = this.props.data;

    if (this.props.subDataName) {
      tmpData[this.props.subDataName] = this.state.editorState;
    } else {
      tmpData = this.state.editorState;
    }

    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise(this.props.dataName, tmpData);
    } else if (this.context.updateDataReport) {
      this.context.updateDataReport(this.props.dataName, tmpData);
    }
  };

  getData = () => {
    return (
      <Lexical
        value={this.state.editorState}
        onChange={(editorState) =>
          this.setState({ editorState }, this.handleEditorChange)
        }
      />
    );
  };

  render() {
    if (this.props.noCard) {
      return (
        <>
          {this.props.title ? <h3>{this.props.title}</h3> : null}
          {this.getData()}
        </>
      );
    }

    return (
      <CustomCard title={this.props.title} name={this.props.name}>
        {this.props.before ? (
          <div
            className="d-flex"
            style={{
              flexDirection: "column",
            }}
          >
            {this.props.before}
          </div>
        ) : null}
        {this.getData()}
        {this.props.after ? (
          <div
            className="d-flex"
            style={{
              flexDirection: "column",
            }}
          >
            {this.props.after}
          </div>
        ) : null}
      </CustomCard>
    );
  }
}
