import React from "react";
import {
  faCamera,
  faCheck,
  faList,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormInput,
  FormSelect,
  Modal,
  FullModal,
  Button,
  ButtonIcon,
  FormTextarea,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import moment from "moment";

import AuthContext from "../../../context/AuthContext";
import { generateString } from "../../../utils/general";

export default class ModalRecallFacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      dateEnd: "",
      mileage: "",
      description: "",
      speaker: "",
      speakerName: "",
      file: null,
      token: "",
      isDateEnd: false,
      isModalList: false,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.show && this.props.idx !== prevProps.idx) {
      this.setState({
        date: this.props.data.date,
        dateEnd: this.props.data.dateEnd,
        isDateEnd: this.props.data.isDateEnd,
        mileage: this.props.data.mileage,
        description: this.props.data.description,
        speaker: this.props.data.speaker,
        speakerName: this.props.data.speakerName,
        file: this.props.data.file,
        token: this.props.data.token,
      });
    }

    if (this.props.show && this.props.show !== prevProps.show) {
      let modalRecallFactsSpeaker = localStorage.getItem(
        "ModalRecallFactsSpeaker"
      );
      modalRecallFactsSpeaker = modalRecallFactsSpeaker
        ? JSON.parse(modalRecallFactsSpeaker)
        : {};

      this.setState({
        speaker:
          this.props.data.speaker ||
          modalRecallFactsSpeaker[this.props.reference] ||
          "",
      });
    }
  }

  addRecallFacts = () => {
    if (this.state.date && this.state.description) {
      localStorage.setItem(
        "ModalRecallFactsSpeaker",
        JSON.stringify({
          ...JSON.parse(
            localStorage.getItem("ModalRecallFactsSpeaker") || "{}"
          ),
          [this.context.reference]: this.state.speaker,
        })
      );
      this.props.addRecallFacts(
        {
          date: this.state.date,
          dateEnd: this.state.dateEnd,
          isDateEnd: this.state.isDateEnd,
          mileage: this.state.mileage,
          description: this.state.description,
          speaker: this.state.speaker,
          speakerName: this.state.speakerName,
          file: this.state.file,
          status: false,
          token: generateString(),
        },
        () => {
          this.setState({
            date: "",
            dateEnd: "",
            isDateEnd: false,
            mileage: "",
            description: "",
            speakerName: "",
            file: null,
            token: "",
          });
        }
      );
    } else {
      window.alert("Veuillez remplir tous les champs obligatoires");
    }
  };

  updateRecallFacts = () => {
    if (this.state.date && this.state.description) {
      localStorage.setItem(
        "ModalRecallFactsSpeaker",
        JSON.stringify({
          ...JSON.parse(
            localStorage.getItem("ModalRecallFactsSpeaker" || "{}")
          ),
          [this.context.reference]: this.state.speaker,
        })
      );
      this.props.updateRecallFacts(
        {
          date: this.state.date,
          dateEnd: this.state.dateEnd,
          isDateEnd: this.state.isDateEnd,
          mileage: this.state.mileage,
          description: this.state.description,
          speaker: this.state.speaker,
          speakerName: this.state.speakerName,
          file: this.state.file,
          status: false,
          token: this.state.token,
        },
        () => {
          this.setState({
            date: "",
            dateEnd: "",
            isDateEnd: false,
            mileage: "",
            description: "",
            speakerName: "",
            file: null,
            token: "",
          });
        }
      );
    } else {
      window.alert("Veuillez remplir tous les champs obligatoires");
    }
  };

  setFiles = (files) => {
    files = files.filter((file) =>
      ["png", "jpeg", "jpg"].includes(file.extension)
    );

    if (files.length) {
      this.setState({ file: files[0] });
    } else {
      window.alert("Aucun fichier compatible");
    }
  };

  render() {
    const list = [
      "Mise en circulation du véhicule",
      "Carte grise établie au profit de ",
      "Révision/entretien du véhicule réalisé aux établissements selon facture N°",
      "Acquisition du véhicule d'occasion pour la somme de",
      "Acquisition du véhicule neuf pour la somme de",
      "Le véhicule est affecté d'un dysfonctionnement. Il est confié aux établissements",
      "Le diagnostic du technicien des Ets révèle :",
      "M. se rapproche de son assureur et établit une déclaration de sinistre.",
      "Nous engageons une démarche préliminaire aux Ets",
      "Nous invitons par lettre recommandée avec AR les parties à la réunion d'expertise amiable et contradictoire organisée le ",
      "Selon la source Histovec : Changement de titulaire",
      "Selon la source Histovec : Achat ou reprise par un professionnel",
      "Selon la source Histovec : Cession (vente)",
      "Modification des caractéristiques techniques",
      "Conversion au nouveau format d’immatriculation.",
      "Mutation du certificat d’immatriculation au nom de : ",
      "Selon la source Histovec : Visite technique périodique favorable",
      "Selon la source Histovec : Visite technique périodique défavorable pour défaillances majeures.",
      "Selon la source Histovec : Visite technique périodique défavorable pour défaillances critiques.",
      "Selon la source Histovec : Contre visite favorable",
      `- Contrôle technique réglementaire réalisé au centre... selon procès-verbal n°...
Il en ressort :
Défaillances critiques :
- 
Défaillances majeures :
- 
Défaillances mineures :`,
      "Facture n°  émanant du Garage    à l'adresse de Madame, Monsieur   , pour un montant de  € TTC, précisant :",
      "La facture mentionne ou ne mentionne aucun autre commentaire particulier.",
      `- Madame/Monsieur  confie son véhicule par la route/ou par remorquage auprès du Garage  pour diagnostic, en raison d’un dysfonctionnement de : 
Aucun ordre de réparation n'est établi /ou un ordre de réparation est établi sous n°...`,
      `- Cession du véhicule entre ... et   
En amont de la transaction, il est procédé à un essai routier du véhicule sur une distance de ... kms, sur un parcours urbain/rural ou les deux.
Durant, l’essai, aucune anomalie de fonction n’est décelée ou citer les anomalies si évoquées lors de l’achat.`,
    ];

    return (
      <>
        <FullModal
          closeMessage="Êtes-vous sûr de vouloir fermer cette fenêtre ?"
          title="Rappel des faits"
          show={this.props.show}
          onClose={() =>
            this.setState(
              {
                date: "",
                dateEnd: "",
                isDateEnd: false,
                mileage: "",
                description: "",
                speakerName: "",
                file: null,
                token: "",
              },
              this.props.onClose
            )
          }
        >
          <FormInput
            autoFocus={this.props.idx >= 0 ? false : true}
            type="date"
            className="mb-10"
            name="date"
            value={this.state.date}
            title="Date"
            onChange={this.handleInputChange}
            max={moment().format("YYYY-MM-DD")}
            required
          />
          {this.state.isDateEnd ? (
            <FormInput
              type="date"
              className="mb-10"
              name="dateEnd"
              value={this.state.dateEnd}
              title="Date de fin"
              onChange={this.handleInputChange}
              max={moment().format("YYYY-MM-DD")}
            />
          ) : null}
          <FormCheckbox
            text="Date de début / fin"
            className="mb-10"
            name="isDateEnd"
            checked={this.state.isDateEnd}
            onChange={({ target }) =>
              this.setState({
                isDateEnd: target.checked,
              })
            }
          />
          <FormInput
            className="mb-10"
            name="mileage"
            value={this.state.mileage}
            title="Kms / Hrs"
            onChange={this.handleInputChange}
          />
          <FormTextarea
            className="mb-10"
            name="description"
            value={this.state.description}
            title="Fait"
            onChange={this.handleInputChange}
            required
            rows={10}
          />
          <Button
            text="Phrase type"
            onClick={() => this.setState({ isModalList: true })}
          >
            <FontAwesomeIcon icon={faList} />
          </Button>
          <FormSelect
            className="mb-10"
            name="speaker"
            value={this.state.speaker}
            title="Intervenant"
            ignore={true}
            init="Veuillez sélectionner un intervenant"
            options={[
              { value: "", text: "Rénitialiser" },
              ...this.context.partiesOptions,
              { value: "Autre", text: "Autre" },
            ]}
            onChange={this.handleInputChange}
          />
          {this.state.speaker === "Autre" ? (
            <FormInput
              className="mb-10"
              name="speakerName"
              value={this.state.speakerName}
              title="Nom intervenant"
              onChange={this.handleInputChange}
              required
            />
          ) : null}
          {this.state.file ? (
            <div
              className="mt-10 mb-10"
              style={{
                width: "auto",
                marginRight: "auto",
                display: "inline-flex",
                borderRadius: "6px",
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
                padding: "10px",
              }}
            >
              <span style={{ fontSize: "12px" }}>
                {this.state.file.filename}
              </span>
              <div
                className="m-auto ml-5 cursor-pointer"
                onClick={() =>
                  this.setState({
                    file: null,
                  })
                }
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          ) : (
            <ButtonIcon
              isBackground
              onClick={() => this.context.openModalLibrary(this.setFiles)}
            >
              <FontAwesomeIcon icon={faCamera} />
            </ButtonIcon>
          )}
          <div className="d-flex">
            <Button
              className="w-auto ml-auto"
              text={this.props.idx !== null ? "Modifier" : "Valider"}
              onClick={() =>
                this.props.idx !== null
                  ? this.updateRecallFacts()
                  : this.addRecallFacts()
              }
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </div>
        </FullModal>
        <Modal
          closeMessage="Êtes-vous sûr de vouloir fermer cette fenêtre ?"
          title="Phrase type"
          show={this.state.isModalList}
          onClose={() => this.setState({ isModalList: false })}
        >
          {list.map((element, idx) => (
            <div
              key={idx}
              className="d-flex mb-5 pb-5"
              style={{
                borderBottom: "solid 1px black",
              }}
            >
              <span className="mt-auto mb-auto mr-auto">{element}</span>
              <ButtonIcon
                className="pt-0 pb-0 mt-auto mb-auto"
                small
                style={{
                  flexShrink: 0,
                }}
                onClick={() => {
                  this.setState({ description: element, isModalList: false });
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </ButtonIcon>
            </div>
          ))}
        </Modal>
      </>
    );
  }
}
