import React from "react";
import { Switch } from "ui-kit-ck-consultant";

import CustomCard from "../../../../components/folder/general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class AmicableSolution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.displayName = "AmicableSolution";
  }

  static contextType = AuthContext;

  setData = (tmpData) => {
    this.context.updateDataReport("amicableSolution", tmpData);
  };

  render() {
    return (
      <CustomCard title="Solution amiable" name={this.displayName}>
        <div className="d-flex mb-20 mt-auto">
          <p className="mt-auto mb-auto mr-10 nowrap">
            Présence d’un protocole d’accord
          </p>
          <Switch
            className="mt-auto mb-auto"
            value1="Non"
            value2="Oui"
            color1="#f2711c"
            color2="#026fc2"
            idxActive={
              this.props.data.hasOwnProperty(`switch_protocol`)
                ? this.props.data[`switch_protocol`]
                : 0
            }
            onChange={(type) => {
              let tmpData = this.props.data;
              tmpData[`switch_protocol`] = type;
              this.setData(tmpData);
            }}
          />
        </div>
        <div className="d-flex mb-20 mt-auto">
          <p className="mt-auto mb-auto mr-10 nowrap">
            Accord entre les parties hors protocole d’accord
          </p>
          <Switch
            className="mt-auto mb-auto"
            value1="Non"
            value2="Oui"
            color1="#f2711c"
            color2="#026fc2"
            idxActive={
              this.props.data.hasOwnProperty(`switch_agreement`)
                ? this.props.data[`switch_agreement`]
                : 0
            }
            onChange={(type) => {
              let tmpData = this.props.data;
              tmpData[`switch_agreement`] = type;
              this.setData(tmpData);
            }}
          />
        </div>
      </CustomCard>
    );
  }
}
