import React from "react";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-flexbox-grid";
import {
  ButtonIcon,
  FormInput,
  FormTextarea,
  Switch,
  Table,
  Tbody,
  Td,
  Tr,
} from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class Fees extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "Fees";
  }
  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = value;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("expertiseConditions", tmpData);
    } else {
      this.context.updateDataReport("expertiseConditions", tmpData);
    }
  };

  getSwitch = (text, name, textInput = null, textDate = null) => (
    <>
      <div className="d-flex mb-20 mt-auto">
        <p className="mt-auto mb-auto mr-10 nowrap">{text}</p>
        <Switch
          className="mt-auto mb-auto"
          value1="Non"
          value2="Oui"
          color1="#f2711c"
          color2="#026fc2"
          idxActive={
            this.props.data.hasOwnProperty(`switch_${name}`)
              ? this.props.data[`switch_${name}`]
              : 0
          }
          onChange={(type) => {
            let tmpData = this.props.data;
            tmpData[`switch_${name}`] = type;
            this.setData(tmpData);
          }}
        />
        {textInput &&
        this.props.data.hasOwnProperty(`switch_${name}`) &&
        this.props.data[`switch_${name}`] ? (
          <div className="d-flex">
            <FormInput
              className="mt-auto mb-auto ml-10"
              placeholder={textInput}
              name={`text_${name}`}
              value={this.props.data[`text_${name}`] || ""}
              onChange={this.handleInputChange}
            />
            <span className="nowrap mt-auto mb-auto ml-10">
              € HT /{" "}
              {(parseFloat(this.props.data[`text_${name}`] || 0) * 1.2).toFixed(
                2
              )}{" "}
              € TTC
            </span>
          </div>
        ) : null}
      </div>
      <div className="d-flex mb-20 mt-auto">
        {textDate &&
        this.props.data.hasOwnProperty(`switch_${name}`) &&
        this.props.data[`switch_${name}`] ? (
          <div className="d-flex">
            <FormInput
              type="date"
              className="mt-auto mb-auto ml-10"
              title={textDate}
              name={`text2_${name}`}
              value={this.props.data[`text2_${name}`] || ""}
              onChange={this.handleInputChange}
            />
          </div>
        ) : null}
      </div>
    </>
  );

  getData = () => {
    return (
      <>
        <Table>
          <Tbody>
            {this.props.data.hasOwnProperty("fees")
              ? this.props.data.fees.map((element, idx) => (
                  <Tr key={idx}>
                    <Td className="p-10 w-100">
                      <FormInput
                        styleInput={{ border: "none" }}
                        placeholder="Motif"
                        value={element.value ? element.value : ""}
                        onChange={({ target }) => {
                          let tmpData = this.props.data;
                          tmpData.fees[idx].value = target.value;

                          this.setData(tmpData);
                        }}
                      />
                    </Td>
                    <Td className="p-10">
                      <div className="d-flex">
                        <FormInput
                          className="w-100"
                          styleInput={{
                            border: "none",
                            minWidth: "180px",
                            textAlign: "right",
                          }}
                          placeholder="Montant"
                          type="number"
                          value={element.amount ? element.amount : ""}
                          onChange={({ target }) => {
                            let tmpData = this.props.data;
                            tmpData.fees[idx].amount = target.value;
                            this.setData(tmpData);
                          }}
                        />
                        <span className="mt-auto mb-auto nowrap ml-10">
                          € HT /{" "}
                          {(element.amount ? element.amount * 1.2 : 0).toFixed(
                            2
                          )}{" "}
                          € TTC
                        </span>
                      </div>
                    </Td>
                    <Td className="p-10">
                      <ButtonIcon
                        className="red"
                        small
                        onClick={() => {
                          let tmpData = this.props.data;
                          tmpData.fees.splice(idx, 1);
                          this.setData(tmpData);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </ButtonIcon>
                    </Td>
                  </Tr>
                ))
              : null}
          </Tbody>
        </Table>
        <div className="d-flex">
          <ButtonIcon
            isBackground
            className="mt-10 ml-auto bg-blue"
            onClick={() => {
              let tmpData = this.props.data;
              if (!tmpData.hasOwnProperty("fees")) {
                tmpData.fees = [];
              }

              tmpData.fees.push({});

              this.setData(tmpData);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </ButtonIcon>
        </div>
        <Row>
          <Col xs={12} md={8} className="d-flex">
            {this.getSwitch(
              "Masquer les frais de gardiennage : ",
              "hideStorageCosts"
            )}
          </Col>
          {!this.props.data.switch_hideStorageCosts ? (
            <>
              <Col xs={12} md={8} className="d-flex">
                {this.getSwitch(
                  "Frais de gardiennage : ",
                  "storageCosts",
                  "0",
                  "À partir du",
                  true
                )}
              </Col>
              <Col xs={12}>
                <FormTextarea
                  className="mb-10"
                  title="Commentaire"
                  name="commentFees"
                  value={this.props.data.commentFees}
                  onChange={this.handleInputChange}
                  rows={8}
                />
              </Col>{" "}
            </>
          ) : null}
        </Row>
      </>
    );
  };

  render() {
    if (this.context.isReport) {
      return this.getData();
    }

    return (
      <CustomCard title="Frais annexes" name={this.displayName}>
        {this.getData()}
      </CustomCard>
    );
  }
}
