import React from "react";

import {
  Card,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  ButtonIcon,
} from "ui-kit-ck-consultant";

import AuthContext from "../../../context/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEye } from "@fortawesome/free-solid-svg-icons";

import PostalIcon from "../../icons/PostalIcon";

export const STATUS = {
  DRAFT: "Brouillon, non validé ",
  PENDING: "En cours de traitement",
  BLOCKED: " En attente d'une autorisation financière",
  ACCEPTED: "Accepté",
  REJECTED: "Refusé",
  CANCELED: " Annulé",
  PREPARING: "En cours de production",
  PROCESSED: "Traité",
  PROCESSED_WITH_ERRORS:
    "Traité mais certains destinataires n’étaient pas valides",
  // MAILEVA AR
  LP00: "Code inconnu",
  LP01: "Pris en charge",
  LP02: "Courrier imprimé et prêt à expédier",
  LP03: "Tri départ",
  LP04: "Tri arrivée",
  LP05: "Preuve de dépôt numérique disponible",
  LP06: "Preuve de contenu générée",
  LP07: "Preuve de contenu envoyée",
  LP08: "Pli mis à disposition en bureau de poste",
  LP09: "Destinataire absent avisé",
  LP10: "Destinataire absent nouveau rendez-vous",
  LP11: "Remis en distribution",
  LP12: "Distribution différée pour force majeure",
  LP13: "Distribution différée pour raison interne",
  LP14: "Arrivée au site de distribution",
  LP15: "Distribué",
  LP16: "Distribué (avec remarques du destinataire)",
  LP17: "Distribué à l'expéditeur (suite au retour du pli)",
  LP18: "Avis de réception numérique disponible",
  LP19: "Retour à l'expéditeur pour cause de délai d'instance expiré",
  LP20: "Retour à l'expéditeur pour cause d'adresse incorrecte/incomplète",
  LP21: "Retour à l'expéditeur pour cause de destinataire inconnu à l'adresse",
  LP22: "Retour à l'expéditeur pour cause de pli refusé",
  LP23: "Renvoyé par le Service Client au destinataire",
  LP24: "Renvoi du Service Client vers l'expéditeur après enquête",
  LP25: "Renvoi du Service Client vers l'expéditeur - Délai de garde expiré",
  LP26: "Réexpédition/Dérivation",
};

export default class HistoryPostalTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = AuthContext;

  render() {
    const { mailevaData } = this.props;

    return (
      <Card className="mt-10 mt-20-xl" title="Historiques des courriers">
        <Table>
          <Thead>
            <Tr>
              <Th>Date d'envoi</Th>
              <Th>Destinataires</Th>
              <Th>Adresse</Th>
              <Th>Statut</Th>
              <Th>Type d'envoi</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {mailevaData.map((el, idx) => (
              <Tr key={idx}>
                <Td>{el.insertDate}</Td>
                <Td>{el.recipient}</Td>
                <Td>{el.address}</Td>
                <Td>{STATUS[el.status]}</Td>
                <Td className="text-center">
                  <PostalIcon type={el.typeSend} />
                </Td>
                <Td>
                  <ButtonIcon
                    small
                    className="blue"
                    onClick={() => {
                      let token = "";
                      if (this.context.isSoftware) {
                        token = `token/${window.localStorage.getItem(
                          "token"
                        )}/`;
                      }

                      const url = `${this.context.domain}/api/folder/get-document/${token}${el.filename}`;
                      window.open(url, "_blank");
                    }}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </ButtonIcon>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    );
  }
}
