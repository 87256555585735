import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { FormInput, FormSelect, Switch } from "ui-kit-ck-consultant";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getFileShorcut } from "../../../../utils/general";

import CustomCard from "../../general/CustomCard";
import CardEditor from "../CardEditor";

import AuthContext from "../../../../context/AuthContext";

export default class ExpertiseConditions extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "ExpertiseConditions";
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = value;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("expertiseConditions", tmpData);
    } else {
      this.context.updateDataReport("expertiseConditions", tmpData);
    }
  };

  getSwitch = (text, name, textInput = null, textDate = null) => (
    <>
      <div className="d-flex mb-20 mt-auto">
        <p className="mt-auto mb-auto mr-10 nowrap">{text}</p>
        <Switch
          className="mt-auto mb-auto"
          value1="Non"
          value2="Oui"
          color1="#f2711c"
          color2="#026fc2"
          idxActive={
            this.props.data.hasOwnProperty(`switch_${name}`)
              ? this.props.data[`switch_${name}`]
              : 0
          }
          onChange={(type) => {
            let tmpData = this.props.data;
            tmpData[`switch_${name}`] = type;
            this.setData(tmpData);
          }}
        />
        {textInput &&
        this.props.data.hasOwnProperty(`switch_${name}`) &&
        this.props.data[`switch_${name}`] ? (
          <div className="d-flex">
            <FormInput
              className="mt-auto mb-auto ml-10"
              placeholder={textInput}
              name={`text_${name}`}
              value={this.props.data[`text_${name}`] || ""}
              onChange={this.handleInputChange}
            />
            <span className="nowrap mt-auto mb-auto ml-10">
              € HT /{" "}
              {(parseFloat(this.props.data[`text_${name}`] || 0) * 1.2).toFixed(
                2
              )}{" "}
              € TTC
            </span>
          </div>
        ) : null}
      </div>
      <div className="d-flex mb-20 mt-auto">
        {textDate &&
        this.props.data.hasOwnProperty(`switch_${name}`) &&
        this.props.data[`switch_${name}`] ? (
          <div className="d-flex">
            <FormInput
              type="date"
              className="mt-auto mb-auto ml-10"
              title={textDate}
              name={`text2_${name}`}
              value={this.props.data[`text2_${name}`] || ""}
              onChange={this.handleInputChange}
            />
          </div>
        ) : null}
      </div>
    </>
  );

  getPicture = (name) => {
    let path = "";
    if (this.props.data[name]) {
      const file = this.props.data[name];
      path = getFileShorcut(this.context, file);
    }

    return (
      <div
        className="mb-10 opacity"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          position: "relative",
          borderRadius: "15px",
          border: "dashed 1px var(--gray)",
          height: "200px",
          width: "100%",
          cursor: "pointer",
        }}
        onClick={() =>
          this.context.openModalLibrary((files) => {
            if (files.length) {
              let tmpData = this.props.data;
              tmpData[name] = files[0];

              this.setData(tmpData);
            }
          })
        }
      >
        <div
          className="opacity"
          style={{
            display: "inline-flex",
            position: "absolute",
            right: 0,
            top: 0,
            width: "32px",
            height: "32px",
            backgroundColor: "var(--gray)",
            borderBottomLeftRadius: "15px",
            cursor: "pointer",
            zIndex: 10,
          }}
          onClick={(e) => {
            let tmpData = this.props.data;
            delete tmpData[name];

            this.setData(tmpData);
            e.stopPropagation();
          }}
        >
          <FontAwesomeIcon
            icon={faTrash}
            className="red"
            style={{ margin: "auto" }}
          />
        </div>
        <span
          style={{
            position: "absolute",
            color: "var(--gray)",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          Cliquer pour ajouter une photo
        </span>
        {path ? (
          <img
            style={{
              zIndex: 9,
              flexShrink: 0,
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
            src={path}
            alt={name}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  getData = () => {
    return (
      <>
        <Row>
          <Col xs={12} md={9}>
            <Row>
              <Col xs={12} md={3}>
                <FormSelect
                  className="mb-10"
                  title="Quand"
                  name="when"
                  value={this.props.data.when}
                  options={[
                    { value: "Avant travaux", text: "Avant travaux" },
                    { value: "Pendant travaux", text: "Pendant travaux" },
                    { value: "Après travaux", text: "Après travaux" },
                    { value: "Sans objet", text: "Sans objet" },
                  ]}
                  ignore={true}
                  init="Veuillez sélectionner une option"
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col xs={12} md={3}>
                <FormSelect
                  className="mb-10"
                  title="Conditions"
                  name="where"
                  value={this.props.data.where}
                  options={[
                    {
                      value: "Expertise réalisée au sol",
                      text: "Expertise réalisée au sol",
                    },
                    {
                      value: "Expertise réalisée sur pont élévateur",
                      text: "Expertise réalisée sur pont élévateur",
                    },
                    {
                      value: "Expertise réalisée sur fosse",
                      text: "Expertise réalisée sur fosse",
                    },
                    {
                      value: "Expertise réalisée au sol et sur pont élévateur",
                      text: "Expertise réalisée au sol et sur pont élévateur",
                    },
                    {
                      value: "Expertise réalisée au sol et sur fosse",
                      text: "Expertise réalisée au sol et sur fosse",
                    },
                  ]}
                  ignore={true}
                  init="Veuillez sélectionner une option"
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col xs={12} md={3}>
                <FormSelect
                  className="mb-10"
                  title="État du véhicule"
                  name="state"
                  value={this.props.data.state}
                  options={[
                    { value: "Véhicule roulant", text: "Véhicule roulant" },
                    {
                      value: "Véhicule immobilisé non démonté",
                      text: "Véhicule immobilisé non démonté",
                    },
                    {
                      value: "Véhicule immobilisé partiellement démonté",
                      text: "Véhicule immobilisé partiellement démonté",
                    },
                    { value: "Véhicule réparé", text: "Véhicule réparé" },
                    { value: "Examen sur pièces", text: "Examen sur pièces" },
                    {
                      value: "Véhicule partiellement incendié",
                      text: "Véhicule partiellement incendié",
                    },
                    {
                      value: "Véhicule totalement incendié",
                      text: "Véhicule totalement incendié",
                    },
                  ]}
                  ignore={true}
                  init="Veuillez sélectionner une option"
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col xs={12} md={3}>
                <Switch
                  className="mb-10"
                  title="Véhicule arrivé sur dépanneuse"
                  value1="Non"
                  value2="Oui"
                  color1="#f2711c"
                  color2="#026fc2"
                  idxActive={
                    this.props.data.hasOwnProperty("arrivedTruck")
                      ? this.props.data["arrivedTruck"]
                      : 0
                  }
                  onChange={(type) => {
                    let tmpData = this.props.data;
                    tmpData["arrivedTruck"] = type;
                    this.setData(tmpData);
                  }}
                />
              </Col>
              <Col xs={12} md={3}>
                <FormInput
                  className="mb-10"
                  type="date"
                  title="Date de dépôt au garage"
                  name="depositDate"
                  value={this.props.data.depositDate}
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={3}>
            {this.getPicture("pictureExpertiseConditions")}
          </Col>
          <Col xs={12}>
            <CardEditor
              noCard
              {...this.props}
              name={this.displayName}
              title="Commentaire"
              dataName="expertiseConditions"
              subDataName="comment"
            />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    if (this.context.isReport) {
      return this.getData();
    }

    return (
      <CustomCard title="Conditions d'expertise" name={this.displayName}>
        {this.getData()}
      </CustomCard>
    );
  }
}
