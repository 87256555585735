import React from "react";
import { ButtonIcon, Card } from "ui-kit-ck-consultant";
import {
  faEye,
  faEyeSlash,
  faFileCirclePlus,
  faFileCircleMinus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AuthContext from "../../../context/AuthContext";

export default class CustomCard extends React.Component {
  static contextType = AuthContext;

  render() {
    return (
      <Card
        className="mt-10 mt-20-xl"
        title={this.props.title}
        disabled={this.context.isLock}
        action={
          <>
            {this.props.action}
            {this.context.getJumpCard ? (
              <ButtonIcon
                className="black p-0 mr-10"
                small
                onClick={() => {
                  if (this.context.updateJumpCard) {
                    this.context.updateJumpCard(this.props.name);
                  }
                }}
                info="Forcer le saut de page"
              >
                <FontAwesomeIcon
                  icon={
                    this.context.getJumpCard(this.props.name)
                      ? faFileCircleMinus
                      : faFileCirclePlus
                  }
                />
              </ButtonIcon>
            ) : null}
            {this.context.getStatusCard ? (
              <ButtonIcon
                className="black p-0"
                small
                onClick={() => {
                  if (this.context.updateStatusCard) {
                    this.context.updateStatusCard(this.props.name);
                  }
                }}
                info="Cacher le bloc"
              >
                <FontAwesomeIcon
                  icon={
                    this.context.getStatusCard(this.props.name)
                      ? faEye
                      : faEyeSlash
                  }
                />
              </ButtonIcon>
            ) : null}
          </>
        }
        disableStyleAction
      >
        {!this.context.getStatusCard ||
        this.context.getStatusCard(this.props.name)
          ? this.props.children
          : null}
      </Card>
    );
  }
}
