import React, { createRef } from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonIcon } from "ui-kit-ck-consultant";

import CardEditor from "../CardEditor";

import AuthContext from "../../../../context/AuthContext";

export default class Origines extends React.Component {
  constructor(props) {
    super(props);
    this.refCardEditor = createRef();
    this.displayName = "Origines";
  }

  static contextType = AuthContext;

  render() {
    const defaultData = `<p>Avis technique de l'expert</p>`;
    return (
      <CardEditor
        {...this.props}
        name={this.displayName}
        ref={this.refCardEditor}
        title="Origines"
        dataName="origines"
        default={defaultData}
        after={
          <ButtonIcon
            isBackground
            onClick={() =>
              this.context.openModalTechnicalAdvice(true, (value) => {
                this.refCardEditor.current.updateStateEditor(value, () => {
                  this.context.updateDataReport("origines", value);
                });
              })
            }
            className="mt-10 ml-auto bg-blue"
          >
            <FontAwesomeIcon icon={faPlus} />
          </ButtonIcon>
        }
      />
    );
  }
}
