import React from "react";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ButtonIcon,
  FormSelect,
  FormTextarea,
  Table,
  Tbody,
  Td,
  Tr,
} from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class PositionKnowParties extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "PositionKnowParties";
  }
  static contextType = AuthContext;

  onAddLineClick = (key = null) => {
    let tmpData = [...this.props.data];

    if (key) {
      const exists = tmpData.filter((element) => element.user === key);
      if (!exists.length) {
        tmpData.push({ user: key });
      }
    } else {
      tmpData.push({});
    }
    this.setData(tmpData);
  };

  onRemoveLineClick = (key) => {
    let tmpData = [...this.props.data];

    let idxSplice = [];

    tmpData.forEach((element, idx) => {
      if (element.user === key) {
        idxSplice.push(idx);
      }
    });

    if (idxSplice.length) {
      idxSplice.reverse().forEach((element) => {
        tmpData.splice(element, 1);
      });
      this.setData(tmpData);
    }
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("positionKnowParties", tmpData);
    } else {
      this.context.updateDataReport("positionKnowParties", tmpData);
    }
  };

  getData = () => {
    const { data } = this.props;

    const newData = [...data];

    return (
      <>
        <Table>
          <Tbody>
            {newData.map((element, idx) => (
              <Tr key={idx}>
                <Td className="p-10 w-50">
                  <FormSelect
                    styleInput={{ border: "none" }}
                    ignore={true}
                    init="Veuillez sélectionner un intervenant"
                    options={this.props.partiesOptions}
                    value={element.user ? element.user : ""}
                    onChange={({ target }) => {
                      let tmpData = [...this.props.data];
                      tmpData[idx].user = target.value;
                      this.setData(tmpData);
                    }}
                  />
                </Td>
                <Td className="p-10 w-50">
                  <FormTextarea
                    style={{ border: "none" }}
                    className="w-100"
                    value={element.user ? element.comment : ""}
                    onChange={({ target }) => {
                      let tmpData = [...this.props.data];
                      tmpData[idx].comment = target.value;
                      this.setData(tmpData);
                    }}
                    disabled={!element.user ? true : false}
                  />
                </Td>
                <Td className="p-10">
                  <ButtonIcon
                    className="red"
                    small
                    onClick={() => {
                      let tmpData = [...this.props.data];
                      tmpData.splice(idx, 1);
                      this.setData(tmpData);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </ButtonIcon>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <div className="d-flex">
          <ButtonIcon
            isBackground
            className="mt-10 ml-auto bg-blue"
            onClick={() => this.onAddLineClick()}
          >
            <FontAwesomeIcon icon={faPlus} />
          </ButtonIcon>
        </div>
        <FormTextarea
          title="Observations"
          value={this.props.dataComment}
          onChange={({ target }) => {
            let tmpData = this.props.dataComent;
            tmpData = target.value;
            if (this.context.updateDataExpertise) {
              this.context.updateDataExpertise("positionKnowPartiesComment", tmpData);
            } else {
              this.context.updateDataReport("positionKnowPartiesComment", tmpData);
            }
          }}
        />
      </>
    );
  };

  render() {
    if (this.context.isReport && !this.props.isCard) {
      return this.getData();
    }

    return (
      <CustomCard title="Position connue des parties" name={this.displayName}>
        {this.getData()}
      </CustomCard>
    );
  }
}
