import React from "react";
import { Row, Col } from "react-flexbox-grid";
import {
  FormCheckbox,
  FormInput,
  FormTextarea,
  Switch,
} from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class FolderOrientation extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "FolderOrientation";
  }
  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = value;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("folderOrientation", tmpData);
    } else {
      this.context.updateDataReport("folderOrientation", tmpData);
    }
  };

  getInput = (title, name) => {
    return (
      <Col xs={12} md={4} className="mb-10">
        <div
          className="p-10 h-100"
          style={{ borderRadius: "10px", backgroundColor: "#eeeeee" }}
        >
          <Switch
            className="mb-10"
            title={title}
            value1="Non"
            value2="Oui"
            color1="#f2711c"
            color2="#026fc2"
            idxActive={
              this.props.data.hasOwnProperty(name) ? this.props.data[name] : 0
            }
            onChange={(type) => {
              let tmpData = this.props.data;
              tmpData[name] = type;
              this.setData(tmpData);
            }}
          />
          <FormTextarea
            title="Observations"
            name={`text_${name}`}
            value={this.props.data[`text_${name}`]}
            onChange={this.handleInputChange}
          />
        </div>
      </Col>
    );
  };

  render() {
    return (
      <CustomCard title="Orientation du dossier" name={this.displayName}>
        <Row>
          <Col xs={12} md={4} className="mb-10">
            <div
              className="p-10 h-100"
              style={{ borderRadius: "10px", backgroundColor: "#eeeeee" }}
            >
              <Switch
                className="mb-10 d-flex"
                title="Nouvelle réunion d'expertise prévue ?"
                value1="Non"
                value2="Oui"
                color1="#f2711c"
                color2="#026fc2"
                idxActive={
                  this.props.data.hasOwnProperty("newMeeting")
                    ? this.props.data["newMeeting"]
                    : 0
                }
                onChange={(type) => {
                  let tmpData = this.props.data;
                  tmpData["newMeeting"] = type;
                  this.setData(tmpData);
                }}
              />
              {this.props.data["newMeeting"] ? (
                <FormCheckbox
                  className="mb-10 mt-10"
                  name={"isDateNewMeeting"}
                  checked={this.props.data.isDateNewMeeting}
                  text={"À définir"}
                  onChange={(e) => {
                    const { checked } = e.target;

                    let tmpData = this.props.data;
                    tmpData["isDateNewMeeting"] = checked;
                    if (checked) {
                      tmpData["dateNewMeeting"] = "";
                    }

                    this.setData(tmpData);
                  }}
                />
              ) : null}
              {this.props.data["newMeeting"] &&
              !this.props.data.isDateNewMeeting ? (
                <FormInput
                  type="date"
                  className="mt-10"
                  title="Date de nouvelle réunion"
                  name="dateNewMeeting"
                  value={this.props.data.dateNewMeeting}
                  onChange={this.handleInputChange}
                />
              ) : null}
              <FormTextarea
                title="Observations"
                name="text_dateNewMeeting"
                value={this.props.data.text_dateNewMeeting}
                onChange={this.handleInputChange}
              />
            </div>
          </Col>
          <Col xs={12} md={8}>
            <Row>
              {this.getInput(
                "Démontage complémentaire nécessaire ?",
                "disassembly"
              )}
              {this.getInput("Nouvelle partie convoquée ?", "newPartyCalled")}
              {this.getInput("Demande de devis et remise en état ?", "request")}
              {this.getInput(
                "Mesure conservatoire nécessaire ?",
                "conservativeMeasureRequired"
              )}
              <Col xs={12} md={8} className="mb-10">
                <div
                  className="p-10 h-100"
                  style={{ borderRadius: "10px", backgroundColor: "#eeeeee" }}
                >
                  <FormTextarea
                    title="Observations"
                    name="textFolderOrientation"
                    value={this.props.data.textFolderOrientation}
                    onChange={this.handleInputChange}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomCard>
    );
  }
}
