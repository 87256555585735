import React from "react";
import {
  Modal,
  FormInput,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Pagination,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AuthContext from "../../../context/AuthContext";

import { deleteParty } from "../../../requests/folder";

export default class ModalDatabaseIdentificationParties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      activePage: 1,
      pageLength: 10,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  deleteParty = (id, code) => {
    deleteParty({ id, code }, (result) => {
      if (result.success) {
        this.context.updatePartiesServer(
          this.context.partiesServer.filter((party) => party.id !== id)
        );
      }
    });
  };

  render() {
    const parties = this.state.search
      ? this.context.partiesServer.filter((party) => {
          return (
            party.name
              .toUpperCase()
              .includes(this.state.search.toUpperCase()) ||
            party.code.toUpperCase().includes(this.state.search.toUpperCase())
          );
        })
      : this.context.partiesServer;

    return (
      <Modal
        show={this.props.show}
        large
        title="Base intervenant"
        onClose={this.props.onClose}
      >
        <Row>
          <Col xs={12} md={6}>
            <FormInput
              name="search"
              value={this.state.search}
              className="mb-10"
              title="Recherche"
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Code</Th>
              <Th>Nom</Th>
              <Th>Qualité</Th>
              <Th>Partie représentée</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {parties
              .slice(
                (this.state.activePage - 1) * this.state.pageLength,
                this.state.activePage * this.state.pageLength
              )
              .map((party, idx) => (
                <Tr
                  className="cursor-pointer"
                  onClick={() => this.props.onValid(party)}
                  key={idx}
                >
                  <Th>
                    {idx +
                      1 +
                      (this.state.activePage - 1) * this.state.pageLength}
                  </Th>
                  <Td>{party.code}</Td>
                  <Td>{party.name}</Td>
                  <Td>{party.quality}</Td>
                  <Td>{party.party}</Td>
                  <Td>
                    <ButtonIcon
                      className="ml-10 p-0"
                      small
                      onClick={() => {
                        window.confirmCustom(
                          "Êtes-vous sûr de vouloir supprimer cette intervenant ?",
                          (result) => {
                            if (result) {
                              this.deleteParty(party.id, party.code);
                            }
                          }
                        );
                      }}
                    >
                      <FontAwesomeIcon className="red" icon={faTrash} />
                    </ButtonIcon>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.pageLength}
          totalItemsCount={this.context.partiesServer.length}
          pageRangeDisplayed={6}
          onChange={(activePage) => {
            this.setState({ activePage: activePage });
          }}
          firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
          lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
          prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
          nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
        />
      </Modal>
    );
  }
}
