import React from "react";
import { Modal, ButtonIcon } from "ui-kit-ck-consultant";

import { getTitleHistory } from "../../utils/general";

import AuthContext from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

export default class Folder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = AuthContext;

  render() {
    return (
      <Modal
        title="État des sauvegardes"
        show={this.props.show}
        onClose={this.props.onClose}
        isMove
      >
        {this.props.dataParts.map((part, idx) => (
          <div key={idx} className="d-flex">
            <span style={{ fontSize: "14px" }} className="mt-auto mb-auto">
              {getTitleHistory(part)}
            </span>
            <span
              className="ml-5 mr-5  mt-auto mb-auto"
              style={{
                fontSize: "14px",
                fontStyle: "italic",
              }}
            >
              {this.props.dataOther[`lastDate_${part}`] ||
                "Pas de sauvegarde récente"}
            </span>
            <ButtonIcon
              className={`blue mt-auto mb-auto ${
                this.state[`isLoad_${part}`] ? "rotate" : ""
              }`}
              isSmall
              onClick={() =>
                this.setState(
                  {
                    [`isLoad_${part}`]: true,
                  },
                  () =>
                    this.props.updateDataFolder(part, this.props[part], () =>
                      this.setState({
                        [`isLoad_${part}`]: false,
                      })
                    )
                )
              }
            >
              <FontAwesomeIcon icon={faSync} />
            </ButtonIcon>
          </div>
        ))}
      </Modal>
    );
  }
}
