import React, { Fragment } from "react";
import moment from "moment";
import { faCheck, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-flexbox-grid";
import {
  Button,
  FormInput,
  FormSelect,
  FullModal,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Loader,
  ButtonIcon,
  FormCheckbox,
} from "ui-kit-ck-consultant";

import {
  insertFolderAppointment,
  updateFolderAppointment,
  deleteFolderAppointment,
} from "../../requests/folder";

import AuthContext from "../../context/AuthContext";

export default class ModalAppointment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      appointmentId: null,
      date: "",
      hour: "",
      type: "",
      location: "",
      name: "",
      address: "",
      zipCode: "",
      city: "",
      guests: [],
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "location") {
          // reset form input location if form select location change
          if (value === "Autre") {
            this.setState({
              name: "",
              address: "",
              zipCode: "",
              city: "",
            });
          } else {
            const party = this.context.parties[value];

            this.setState({
              name: "",
              address: party.address,
              zipCode: party.zipCode,
              city: party.city,
            });
          }
        }
      }
    );
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    let guests = this.state.guests;

    if (guests.includes(name) && !checked) {
      guests.splice(guests.indexOf(name), 1);
    } else if (!guests.includes(name) && checked) {
      guests.push(name);
    }

    this.setState({
      guests,
    });
  };

  insertFolderAppointment = () => {
    if (
      this.state.date &&
      this.state.hour &&
      this.state.type &&
      this.state.location &&
      this.state.guests.length
    ) {
      this.setState({ isLoad: true }, () => {
        insertFolderAppointment(
          {
            folder: this.context.folderId,
            date: `${this.state.date} ${this.state.hour}`,
            type: this.state.type,
            location: this.state.location,
            guest: this.state.guests.join(","),
            name: this.state.name,
            address: this.state.address,
            zipCode: this.state.zipCode,
            city: this.state.city,
          },
          (result) => {
            if (result.success) {
              this.setState(
                {
                  isLoad: false,
                  date: "",
                  hour: "",
                  type: "",
                  location: "",
                  guests: [],
                  name: "",
                  address: "",
                  zipCode: "",
                  city: "",
                },
                () => {
                  this.props.getFolderActions();
                  this.props.getFolderAppointments();
                  this.context.updateFolderState(2);
                }
              );
            } else {
              this.setState({ isLoad: false }, () => {
                window.alert("Une erreur est survenue");
              });
            }
          }
        );
      });
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  updateFolderAppointment = () => {
    if (
      this.state.date &&
      this.state.hour &&
      this.state.type &&
      this.state.location &&
      this.state.guests.length
    ) {
      this.setState({ isLoad: true }, () => {
        updateFolderAppointment(
          {
            folder: this.context.folderId,
            appointment: this.state.appointmentId,
            date: `${this.state.date} ${this.state.hour}`,
            type: this.state.type,
            location: this.state.location,
            guest: this.state.guests.join(","),
            name: this.state.name,
            address: this.state.address,
            zipCode: this.state.zipCode,
            city: this.state.city,
          },
          (result) => {
            if (result.success) {
              this.setState(
                {
                  isLoad: false,
                  appointmentId: null,
                  date: "",
                  hour: "",
                  type: "",
                  location: "",
                  guests: [],
                  name: "",
                  address: "",
                  zipCode: "",
                  city: "",
                },
                () => {
                  this.props.getFolderActions();
                  this.props.getFolderAppointments();
                }
              );
            } else {
              this.setState({ isLoad: false }, () => {
                window.alert("Une erreur est survenue");
              });
            }
          }
        );
      });
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  deleteFolderAppointment = (appointment) => {
    window.confirmCustom(
      "Êtes-vous sûr de vouloir supprimer ce RDV ?",
      (result) => {
        if (result) {
          this.setState(
            {
              isLoad: true,
            },
            () => {
              deleteFolderAppointment(
                {
                  appointment,
                },
                (result) => {
                  if (result) {
                    this.setState({ isLoad: false }, () => {
                      this.props.getFolderActions();
                      this.props.getFolderAppointments();
                    });
                  } else {
                    this.setState({ isLoad: false }, () => {
                      window.alert("Une erreur est survenue");
                    });
                  }
                }
              );
            }
          );
        }
      }
    );
  };

  render() {
    this.context.partiesOptions.forEach((element) => {
      if (element.quality === "Autre") {
        element.quality = this.context.parties[element.value].otherQuality;
        element.text = `${this.context.parties[element.value].kind} ${
          this.context.parties[element.value].name
        } - ${element.quality}`;
      }
    });

    return (
      <>
        <FullModal
          show={this.props.show}
          onClose={() => {
            this.setState(
              {
                isLoad: false,
                appointmentId: null,
                date: "",
                hour: "",
                type: "",
                location: "",
                guests: [],
                name: "",
                address: "",
                zipCode: "",
                city: "",
              },
              this.props.onClose
            );
          }}
          noPadding
        >
          <Row className="h-100 w-100">
            <Col
              xs={12}
              md={5}
              lg={4}
              className="h-100 d-flex flex-column bg-blue-extra-light p-40 overflow-y-auto"
            >
              <h2 className="mb-20 mt-0">Programmer un RDV rapide</h2>
              <FormInput
                name="date"
                value={this.state.date}
                className="mb-10"
                type="date"
                title="Date de RDV"
                onChange={this.handleInputChange}
                required
              />
              <FormInput
                name="hour"
                value={this.state.hour}
                className="mb-10"
                type="time"
                title="Heure de RDV"
                onChange={this.handleInputChange}
                required
              />
              <FormSelect
                name="type"
                value={this.state.type}
                className="mb-10"
                title="Type de RDV"
                init="Veuillez sélectionner un type de RDV"
                options={this.context.appointmentTypes}
                onChange={this.handleInputChange}
                required
              />
              <span className="mb-10">
                <b>Personne(s) conviée(s)</b>
              </span>
              {this.context.partiesOptions.map((element, idx) => (
                <FormCheckbox
                  key={idx}
                  className="mb-10"
                  name={element.value}
                  checked={this.state.guests.includes(element.value)}
                  text={element.text}
                  onChange={this.handleCheckboxChange}
                />
              ))}
              <FormSelect
                name="location"
                value={this.state.location}
                className="mb-10"
                title="Lieu de RDV"
                ignore={true}
                init="Veuillez sélectionner un lieu de RDV"
                options={[
                  ...this.context.partiesOptions,
                  { value: "Autre", text: "Autre" },
                ]}
                onChange={this.handleInputChange}
                required
              />
              {this.state.location === "Autre" ? (
                <>
                  <FormInput
                    name="name"
                    value={this.state.name}
                    className="mb-10"
                    title="Nom"
                    onChange={this.handleInputChange}
                  />
                  <FormInput
                    name="address"
                    value={this.state.address}
                    className="mb-10"
                    title="Adresse"
                    onChange={this.handleInputChange}
                  />
                  <FormInput
                    name="zipCode"
                    value={this.state.zipCode}
                    className="mb-10"
                    title="Code postal"
                    onChange={this.handleInputChange}
                  />
                  <FormInput
                    name="city"
                    value={this.state.city}
                    className="mb-10"
                    title="Ville"
                    onChange={this.handleInputChange}
                  />
                </>
              ) : (
                <>
                  <p className="mb-0 mt-0">{this.state.address}</p>
                  <p className="mb-0 mt-0">{this.state.zipCode}</p>
                  <p className="mb-0 mt-0">{this.state.city}</p>
                </>
              )}
              <div className="d-flex mt-auto">
                <Button
                  className="w-auto ml-auto "
                  text={this.state.appointmentId ? "Mettre à jour" : "Valider"}
                  onClick={
                    this.state.appointmentId
                      ? this.updateFolderAppointment
                      : this.insertFolderAppointment
                  }
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </div>
            </Col>
            <Col xs={12} md={7} lg={8} className="p-40">
              <h2 className="mb-20 mt-0">Historique des RDV</h2>
              <Table>
                <Thead>
                  <Tr>
                    <Th className="ml-10 mr-10">Date</Th>
                    <Th className="ml-10 mr-10">Heure</Th>
                    <Th className="ml-10 mr-10">Type</Th>
                    <Th className="ml-10 mr-10">Personnes conviées</Th>
                    <Th className="ml-10 mr-10">Lieu</Th>
                    <Th className="ml-10 mr-10">Adresse</Th>
                    <Th className="ml-10 mr-10">Code postal</Th>
                    <Th className="ml-10 mr-10">Ville</Th>
                    <Th className="ml-10 mr-10">Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {this.props.appointments.map((appointment, idx) => (
                    <Tr key={idx}>
                      <Td className="ml-10 mr-10">{appointment.date}</Td>
                      <Td className="ml-10 mr-10">{appointment.hour}</Td>
                      <Td className="ml-10 mr-10">{appointment.typeName}</Td>
                      <Td className="ml-10 mr-10">
                        {appointment.guest.split(",").map((element, idx) => (
                          <Fragment key={idx}>
                            {`${this.context.parties[element].kind} ${this.context.parties[element].name}`}
                          </Fragment>
                        ))}
                      </Td>
                      <Td className="ml-10 mr-10">
                        {appointment.location === "Autre"
                          ? appointment.name
                          : this.context.parties.quality}
                      </Td>
                      <Td className="ml-10 mr-10">{appointment.address}</Td>
                      <Td className="ml-10 mr-10">{appointment.zipCode}</Td>
                      <Td className="ml-10 mr-10">{appointment.city}</Td>
                      <Td className="ml-10 mr-10 nowrap">
                        <ButtonIcon
                          className="blue"
                          small
                          onClick={() =>
                            this.setState({
                              appointmentId: appointment.id,
                              date: moment(
                                appointment.date,
                                "DD/MM/YYYY"
                              ).format("YYYY-MM-DD"),
                              hour: appointment.hour,
                              type: appointment.typeId,
                              guests: appointment.guest.split(","),
                              location: appointment.location,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </ButtonIcon>
                        <ButtonIcon
                          className="red"
                          small
                          onClick={() =>
                            this.deleteFolderAppointment(appointment.id)
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ButtonIcon>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Col>
          </Row>
        </FullModal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
