import React from "react";

export default class PostalIcon extends React.Component {
  render() {
    return (
      <svg
        id="Icone"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 171.02 171.54"
        width={20}
        height={20}
      >
        <path
          fill="#3c3c3c"
          d="M169.49,63.67c-.97,1.39-2.52,2.14-4.1,2.14-.99,0-1.99-.29-2.86-.9l-2.37-1.65v24.31c0,2.76-2.24,5-5,5s-5-2.24-5-5v-31.27L85.19,11.09,19.51,56.8v109.74c0,2.76-2.24,5-5,5s-5-2.24-5-5V63.76l-1.65,1.15c-.87.61-1.87.9-2.86.9-1.58,0-3.13-.75-4.1-2.14-1.58-2.27-1.02-5.39,1.24-6.96l9.19-6.38.15-.11.08-.06.25-.18L82.31.92s.02-.02.03-.02c.11-.08.22-.15.33-.21.1-.06.21-.12.31-.17.02,0,.04-.02.06-.03.11-.05.22-.1.33-.14.28-.12.57-.2.87-.26.15-.03.29-.05.44-.06.16-.02.33-.03.5-.03h.02c.99,0,1.96.29,2.79.85l69.95,48.68.04.03,10.27,7.15c2.26,1.57,2.82,4.69,1.24,6.96Z"
        />
        <path
          fill="#3c3c3c"
          d="M169.26,112.06c-.53-1.05-1.17-2.05-1.92-2.99-3.17-3.94-7.89-6.19-12.95-6.19h-46.31c-7.4,0-13.79,4.75-15.93,11.83l-12.08,40.1c-1.2,3.97-.47,8.16,2.01,11.49,2.47,3.33,6.27,5.24,10.42,5.24h54.18c7.77,0,14.6-5.51,16.25-13.09l7.71-35.41c.82-3.77.31-7.62-1.38-10.98ZM108.08,112.88h46.31c.96,0,1.89.21,2.74.6l-32.49,22.66-18.85-22.85c.71-.27,1.49-.41,2.29-.41ZM153.16,156.32c-.66,3.02-3.38,5.22-6.48,5.22h-54.18c-1.3,0-2.06-.75-2.39-1.2-.34-.45-.84-1.4-.47-2.65l10.66-35.34,18.86,22.86c1.24,1.48,3,2.25,4.78,2.25,1.24,0,2.49-.38,3.58-1.14l32.8-22.88-7.16,32.88Z"
        />
        <path
          fill={this.props.type === "simple" ? "black" : "#f45b47"}
          d="M157.13,113.48l-32.49,22.66-18.85-22.85c.71-.27,1.49-.41,2.29-.41h46.31c.96,0,1.89.21,2.74.6Z"
        />
        <path
          fill={this.props.type === "simple" ? "black" : "#f45b47"}
          d="M160.32,123.44l-7.16,32.88c-.66,3.02-3.38,5.22-6.48,5.22h-54.18c-1.3,0-2.06-.75-2.39-1.2-.34-.45-.84-1.4-.47-2.65l10.66-35.34,18.86,22.86c1.24,1.48,3,2.25,4.78,2.25,1.24,0,2.49-.38,3.58-1.14l32.8-22.88Z"
        />
        <path
          fill="#3c3c3c"
          d="M75.74,126.49h-38.24c-2.76,0-5-2.24-5-5s2.24-5,5-5h38.24c2.76,0,5,2.24,5,5s-2.24,5-5,5Z"
        />
        <path
          fill="#3c3c3c"
          d="M59.37,148.52h-21.88c-2.76,0-5-2.24-5-5s2.24-5,5-5h21.88c2.76,0,5,2.24,5,5s-2.24,5-5,5Z"
        />
        <path
          fill="#3c3c3c"
          d="M48.43,170.56h-10.94c-2.76,0-5-2.24-5-5s2.24-5,5-5h10.94c2.76,0,5,2.24,5,5s-2.24,5-5,5Z"
        />
      </svg>
    );
  }
}
