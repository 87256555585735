import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalIdentificationPartiesUser from "./ModalIdentificationPartiesUser";
import { Button } from "ui-kit-ck-consultant";

export default class AddIdentificationPartiesUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
    };
  }

  render() {
    return (
      <>
        <Button
          text="Autre intervenant"
          className="w-auto"
          onClick={() =>
            this.setState({
              isModal: true,
            })
          }
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <ModalIdentificationPartiesUser
          show={this.state.isModal}
          setData={this.props.setData}
          idx={null}
          data={this.props.data}
          onClose={() => this.setState({ isModal: false })}
        />
      </>
    );
  }
}
