import React from "react";
import moment from "moment";
import {
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  ButtonIcon,
  Loader,
  Pagination,
} from "ui-kit-ck-consultant";
import {
  faTrash,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faLock,
  faLockOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { deleteFolder, lockFolder, unlockFolder } from "../../requests/home";

import handleSortClick from "../../utils/handleSortClick";

import AuthContext from "../../context/AuthContext";

export default class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isLoadGlobal: 0,
      activePage: 1,
      pageLength: 10,
      order: null,
    };
  }

  static contextType = AuthContext;

  openFolder(e, folder) {
    if (
      !e.target.matches(".not-click") &&
      !e.target.parentNode.matches(".not-click") &&
      !e.target.parentNode.parentNode.matches(".not-click")
    ) {
      if (
        this.context.isDisconnected &&
        (!folder.isOffline || folder.isServer)
      ) {
        window.alert("Le dossier n'est pas accessible sans connexion");
      } else {
        document.location.hash = `/folder/${folder.token}`;
      }
    }
  }

  deleteFolder = (id) => {
    window.confirmCustom(
      "Êtes-vous sûr de vouloir supprimer ce dossier ?",
      (result) => {
        if (result) {
          this.setState({ isLoad: true }, () => {
            deleteFolder({ id }, (result) => {
              if (result.success) {
                this.setState(
                  {
                    isLoad: false,
                  },
                  () => this.props.getFolders()
                );
              } else {
                this.setState({ isLoad: false }, () => {
                  window.alert("Une erreur est survenue");
                });
              }
            });
          });
        }
      }
    );
  };

  lockFolder = (id) => {
    window.confirmCustom(
      "Êtes-vous sûr de vouloir verrouiller ce dossier ?",
      (result) => {
        if (result) {
          this.setState({ isLoad: true }, () => {
            lockFolder({ id }, (result) => {
              if (result.success) {
                this.setState(
                  {
                    isLoad: false,
                  },
                  () => this.props.getFolders()
                );
              } else {
                this.setState({ isLoad: false }, () => {
                  window.alert("Une erreur est survenue");
                });
              }
            });
          });
        }
      }
    );
  };

  unlockFolder = (id) => {
    window.confirmCustom(
      "Êtes-vous sûr de vouloir déverrouiller ce dossier ?",
      (result) => {
        if (result) {
          this.setState({ isLoad: true }, () => {
            unlockFolder({ id }, (result) => {
              if (result.success) {
                this.setState(
                  {
                    isLoad: false,
                  },
                  () => this.props.getFolders()
                );
              } else {
                this.setState({ isLoad: false }, () => {
                  window.alert("Une erreur est survenue");
                });
              }
            });
          });
        }
      }
    );
  };

  handleSortClick = (desc, column) => {
    this.setState({ order: handleSortClick(this.sortRef, desc, column) });
  };

  getStatus = (folder) => {
    if (folder.lockUser && folder.lockUser !== this.context.id) {
      return "bg-red-light";
    } else if (folder.isServer) {
      return "bg-purple-light";
    } else if (folder.isLocal) {
      return "bg-blue-light";
    } else if (folder.isOffline) {
      return "bg-green-light";
    } else {
      return "";
    }
  };

  render() {
    this.sortRef = [];

    let folders = this.props.folders.filter((folder) =>
      this.props.states.includes(folder.stateId)
    );

    const order = this.state.order;
    if (order) {
      folders.sort((a, b) => {
        let aX = a[order.name] || "";
        let bX = b[order.name] || "";

        if (
          (Number.isInteger(aX) && Number.isInteger(bX)) ||
          order.name === "reference"
        ) {
          return order.desc ? aX - bX : bX - aX;
        } else {
          aX = aX.toString();
          bX = bX.toString();
          return order.desc
            ? aX.normalize().localeCompare(bX.normalize())
            : bX.normalize().localeCompare(aX.normalize());
        }
      });
    }

    return (
      <>
        <Table>
          <Thead>
            <Tr>
              <Th
                ref={(ref) => this.sortRef.push(ref)}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, "reference")}
                className="pl-5 pr-5"
                nameSort="reference"
              >
                N° dossier
              </Th>
              <Th
                ref={(ref) => this.sortRef.push(ref)}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, "expertInitial")}
                className="pl-5 pr-5"
                nameSort="expertInitial"
              >
                Exp.
              </Th>
              <Th
                ref={(ref) => this.sortRef.push(ref)}
                sort={true}
                onSort={(desc) =>
                  this.handleSortClick(desc, "assistantInitial")
                }
                nameSort="assistantInitial"
                className="pl-5 pr-5"
              >
                Ass.
              </Th>
              <Th
                ref={(ref) => this.sortRef.push(ref)}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, "nameClient")}
                className="pl-5 pr-5"
                nameSort="nameClient"
              >
                Nom assuré
              </Th>
              <Th
                ref={(ref) => this.sortRef.push(ref)}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, "dateAppointment")}
                className="pl-5 pr-5"
                nameSort="dateAppointment"
              >
                RDV
              </Th>
              <Th
                ref={(ref) => this.sortRef.push(ref)}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, "typeShort")}
                className="pl-5 pr-5"
                nameSort="typeShort"
              >
                Type
              </Th>
              <Th
                ref={(ref) => this.sortRef.push(ref)}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, "delay")}
                className="pl-5 pr-5"
                nameSort="delay"
              >
                Délai
              </Th>
              <Th
                ref={(ref) => this.sortRef.push(ref)}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, "company")}
                className="pl-5 pr-5"
                nameSort="company"
              >
                Compagnie
              </Th>
              <Th
                ref={(ref) => this.sortRef.push(ref)}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, "stateName")}
                className="pl-5 pr-5"
                nameSort="stateName"
              >
                Statut
              </Th>
              <Th className="pl-5 pr-5">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {folders
              .slice(
                (this.state.activePage - 1) * this.state.pageLength,
                this.state.activePage * this.state.pageLength
              )
              .map((folder, idx) => (
                <Tr
                  key={idx}
                  className="cursor-pointer"
                  onClick={(e) => this.openFolder(e, folder)}
                >
                  <Th
                    className={`nowrap pl-10 pr-10 ${this.getStatus(folder)}`}
                  >
                    <div className="d-flex">
                      <span
                        style={{ display: "inline-block" }}
                        className="mt-auto mb-auto"
                      >
                        {folder.reference}
                      </span>
                    </div>
                  </Th>
                  <Td className="nowrap pl-10 pr-10">{folder.expertInitial}</Td>
                  <Td className="nowrap pl-10 pr-10">
                    {folder.assistantInitial}
                  </Td>
                  <Td className="nowrap pl-10 pr-10">{folder.nameClient}</Td>
                  <Td className="nowrap pl-10 pr-10">
                    {folder.dateAppointment
                      ? moment(folder.dateAppointment).format("DD/MM/YYYY")
                      : ""}{" "}
                    {folder.hourAppointment}
                  </Td>
                  <Td className="nowrap pl-10 pr-10">{folder.typeShort}</Td>
                  <Td className="nowrap pl-10 pr-10">{folder.delay}</Td>
                  <Td className="nowrap pl-10 pr-10">{folder.company}</Td>
                  <Td
                    className={`text-center nowrap pl-10 pr-10 bg-${folder.stateColor}-light ${folder.stateColor}`}
                  >
                    {folder.stateName}
                  </Td>
                  <Td className="nowrap pl-0 pr-0 text-center">
                    <div className="text-center">
                      {this.context.isDisconnected ||
                      folder.userId !== this.context.id ? null : (
                        <>
                          {!folder.lockUser ||
                          folder.lockUser === this.context.id ? (
                            <ButtonIcon
                              className="red not-click pt-5 pb-5"
                              small
                              onClick={() => this.deleteFolder(folder.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </ButtonIcon>
                          ) : null}
                        </>
                      )}
                      {this.context.isDisconnected ? null : (
                        <>
                          {!folder.lockUser ||
                          folder.lockUser === this.context.id ? (
                            <ButtonIcon
                              className="blue not-click pt-5 pb-5"
                              small
                              onClick={() =>
                                !folder.lockUser
                                  ? this.lockFolder(
                                      folder.id,
                                      folder.token,
                                      folder.dateUpdate
                                    )
                                  : this.unlockFolder(
                                      folder.id,
                                      folder.token,
                                      folder.dateUpdate
                                    )
                              }
                              info={
                                !folder.lockUser
                                  ? "Verrouiller le dossier"
                                  : "Déverrouiller le dossier"
                              }
                            >
                              <FontAwesomeIcon
                                icon={!folder.lockUser ? faLock : faLockOpen}
                              />
                            </ButtonIcon>
                          ) : null}
                        </>
                      )}
                    </div>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.pageLength}
          totalItemsCount={folders.length}
          pageRangeDisplayed={4}
          onChange={(activePage) => {
            this.setState({ activePage: activePage });
          }}
          firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
          lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
          prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
          nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
        />
        <Loader show={this.state.isLoad || this.state.isLoadGlobal} />
      </>
    );
  }
}
