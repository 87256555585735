import React from "react";
import {
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Loader,
} from "ui-kit-ck-consultant";

import moment from "moment";

import Template from "./file-preparation/Template";
import ModalMaileva from "./file-preparation/ModalMaileva";

import { getTemplateMail, sendMail } from "../../../requests/mail";
import { sendMaileva } from "../../../requests/maileva";

import AuthContext from "../../../context/AuthContext";

export default class FilePreparation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      showModalMailEva: false,
      partiesData: [],
      templateData: null,
      callbackOnValidate: null,
      tabsIdArray: [
        { title: "Accusé de réception" },
        { title: "Demande de documents" },
        { title: "Convocation" },
        { title: "Saisie libre" },
      ],
      tabsId: 0,
    };
  }

  static contextType = AuthContext;

  setData = (name, data, callback = () => {}, action = null, keyData) => {
    let tmpData = this.props.dataFilePreparation;

    tmpData[name] = data;
    this.context.updateDataFolder(
      "dataFilePreparation",
      tmpData,
      callback,
      action,
      keyData
    );
  };

  getTemplateMail = (typeDocument, typeParty, data, callback) => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        getTemplateMail(typeDocument, typeParty, data, (result) => {
          this.setState({ isLoad: false }, () => callback(result));
        });
      }
    );
  };

  getData = (key) => {
    return this.props.dataFilePreparation.hasOwnProperty(key)
      ? this.props.dataFilePreparation[key]
      : [];
  };

  addMessageMail = (
    recipient,
    recipients,
    emailRecipients,
    object,
    message,
    body,
    files,
    name,
    actionType,
    keyData,
    isPreview = false,
    callback
  ) => {
    if (recipient && object && message) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          const parties = this.context.parties;

          let recipientsEmail = recipients.map((key) => parties[key].email);
          emailRecipients = emailRecipients.split(";");
          if (!emailRecipients.length) {
            emailRecipients = [];
          }

          sendMail(
            {
              quality: parties[recipient].quality,
              folder: this.context.folderId,
              to: [
                parties[recipient].email,
                ...recipientsEmail,
                ...emailRecipients,
              ],
              subject: object,
              message,
              body,
              files,
              type: actionType,
              dataTemplate: this.context.getDataTemplate(recipient),
              isPreview,
            },
            (result) => {
              this.setState({ isLoad: false }, () => {
                if (result.success) {
                  if (!isPreview) {
                    let tmpData = this.getData(name);
                    tmpData.push({
                      dateInsert: moment().format("YYYY-MM-DD HH:mm"),
                      recipient,
                      recipients,
                      emailRecipients,
                      object,
                      message,
                      files,
                      filename: result.data.filename,
                      messageHTML: result.data.messageHTML,
                      keyData,
                    });
                    this.setData(
                      name,
                      tmpData,
                      () => {
                        callback({ success: true });
                      },
                      actionType,
                      keyData
                    );
                  } else {
                    callback(result);
                  }
                } else {
                  window.alert("Une erreur est survenue");
                  callback({ success: false });
                }
              });
            }
          );
        }
      );
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  addMessagePostal = (
    recipient,
    recipients,
    object,
    message,
    body,
    files,
    name,
    actionType,
    keyData,
    isPreview = false,
    callback
  ) => {
    const parties = this.context.parties;

    let recipientsEmail = recipients.map((key) => parties[key]);

    if (!isPreview) {
      this.setState({
        showModalMailEva: true,
        partiesData: [parties[recipient], ...recipientsEmail],
        templateData: {
          object,
          message,
          body,
          files,
          name,
          actionType,
          keyData,
          isPreview,
          data: this.context.getDataTemplate(recipient),
        },
        callbackOnValidate: callback,
      });
    } else {
      this.setState({ isLoad: true }, () => {
        const { folderId } = this.context;

        sendMaileva(
          {
            to: [],
            templateData: {
              object,
              message,
              body,
              files,
              name,
              actionType,
              keyData,
              isPreview,
              data: this.context.getDataTemplate(recipient),
            },
            folderId,
          },
          (res) => {
            this.setState({ isLoad: false }, () => {
              if (res.success) {
                callback(res);
              }
            });
          }
        );
      });
    }
  };

  onValidate = (to, callback) => {
    const { templateData } = this.state;
    const { folderId } = this.context;
    this.setState({ isLoad: true }, () => {
      sendMaileva({ to, templateData, folderId }, (res) => {
        if (res.success) {
          this.context.updateMailevaData(res.data || [], () => {
            if (this.state.callbackOnValidate) {
              this.state.callbackOnValidate(res);
            }

            this.setState(
              {
                isLoad: false,
                showModalMailEva: false,
                callbackOnValidate: null,
                templateData: null,
                partiesData: [],
              },
              () => {
                callback(res.success);
              }
            );
          });
        } else {
          this.setState({ isLoad: false }, () => {
            window.alert("Une erreur est survenue");
          });
        }
      });
    });
  };

  render() {
    return (
      <>
        <TabsCustom className="mt-10 mt-20-xl">
          <TabsCustomHeader className="m-0">
            {this.state.tabsIdArray.map((element, key) => (
              <TabsCustomMenu
                key={key}
                title={element.title}
                disabled={element.disabled}
                active={this.state.tabsId === key}
                onClick={(e) =>
                  this.setState({
                    tabsId: key,
                  })
                }
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsId === 0}>
              <Template
                title="Accusé de réception"
                historyTitle="Historique des accusés de réception"
                name="dataAcknowledgmentReceipts"
                message="Bonjour, <br/><br/> Vous trouverez ci-joint l'accusé de réception de la mission confiée.<br/><br/> Cordialement,<br/>[replace]"
                data={this.getData("dataAcknowledgmentReceipts")}
                tmpData={this.getData("tmp_dataAcknowledgmentReceipts")}
                getTemplateMail={this.getTemplateMail}
                addMessage={this.addMessageMail}
                addMessagePostal={this.addMessagePostal}
                parties={[
                  { quality: "Lésé" },
                  { quality: "Gestionnaire" },
                  { quality: "Mandant" },
                  { quality: "Assureur" },
                  { quality: "Expert", party: "Adverse" },
                ]}
                setData={this.setData}
              />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsId === 1}>
              <Template
                title="Demande de documents"
                historyTitle="Historique des demandes de documents"
                name="dataRequestedDocuments"
                message="Bonjour, <br/><br/>Vous trouverez ci-joint notre courrier de demande de documents.<br/><br/> Cordialement,<br/>[replace]"
                data={this.getData("dataRequestedDocuments")}
                tmpData={this.getData("tmp_dataRequestedDocuments")}
                getTemplateMail={this.getTemplateMail}
                addMessage={this.addMessageMail}
                addMessagePostal={this.addMessagePostal}
                parties={[{ quality: "Lésé" }, { quality: "Réparateur" }]}
                setData={this.setData}
              />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsId === 2}>
              <Template
                title="Convocation"
                historyTitle="Historique des convocations"
                name="dataSummons"
                message="Bonjour, <br/><br/>Vous trouverez ci-joint notre courrier de convocation transmis ce jour par voie postale.<br/><br/> Cordialement,<br/>[replace]"
                data={this.getData("dataSummons")}
                tmpData={this.getData("tmp_dataSummons")}
                getTemplateMail={this.getTemplateMail}
                addMessage={this.addMessageMail}
                addMessagePostal={this.addMessagePostal}
                parties={[
                  { quality: "Lésé" },
                  { quality: "Dépositaire" },
                  { quality: "Constructeur" },
                  { quality: "Réparateur" },
                  { quality: "Vendeur" },
                  { quality: "Adverse" },
                  { quality: "Avocat" },
                  { quality: "Expert" },
                  { quality: "Secrétaire" },
                  { quality: "Tiers" },
                  { quality: "Assuré" },
                  { quality: "Mandant" },
                  { quality: "Assureur" },
                  { quality: "Gestionnaire" },
                  { quality: "Centre de contrôle technique" },
                  { quality: "Propriétaire" },
                  { quality: "Réceptionnaire" },
                  { quality: "Chef d’atelier" },
                  { quality: "Conseiller" },
                  { quality: "Technicien" },
                  { quality: "Carrossier" },
                  { quality: "Gérant" },
                  { quality: "Dépanneur" },
                  { quality: "Autre" },
                ]}
                isExpertise={true}
                setData={this.setData}
              />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsId === 3}>
              <Template
                title="Saisie librie"
                historyTitle="Historique des saisies libres"
                name="dataFreeEntry"
                message="Bonjour, <br/><br/> Vous trouverez ci-joint un message.<br/><br/> Cordialement,<br/>[replace]"
                data={this.getData("dataFreeEntry")}
                tmpData={this.getData("tmp_dataFreeEntry")}
                getTemplateMail={this.getTemplateMail}
                addMessage={this.addMessageMail}
                addMessagePostal={this.addMessagePostal}
                parties={[]}
                disableTemplate={true}
                setData={this.setData}
              />
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
        {this.state.showModalMailEva && (
          <ModalMaileva
            show={this.state.showModalMailEva}
            partiesData={this.state.partiesData}
            onClose={() => this.setState({ showModalMailEva: false })}
            onValidate={this.onValidate}
          />
        )}
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
