import React from "react";
import moment from "moment";

import ExpertisesReports from "../../../components/folder/expertises-reports/ExpertisesReports";
import Expertise from "../../../components/folder/expertises-reports/Expertise";

import { generateString, getParty } from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";

export default class Expertises extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsId: null,
    };
  }

  static contextType = AuthContext;

  addExpertise = () => {
    const expertParty = getParty(this.context.parties, "Expert", "Lésé");

    if (!expertParty.name) {
      alert("Veuillez ajouter un expert dans les intervenants");
      return false;
    }

    let tmpData = this.props.data;

    const garage = getParty(this.context.parties, "Réparateur", "Lésé");
    const garage2 = getParty(this.context.parties, "Réparateur");
    const depositary = getParty(this.context.parties, "Dépositaire");

    let user = {};
    if (depositary && depositary.hasOwnProperty("id")) {
      user = depositary;
    } else if (
      garage &&
      garage.hasOwnProperty("id") &&
      garage.party === "Lésé"
    ) {
      user = garage;
    } else if (garage && garage.hasOwnProperty("id")) {
      user = garage2;
    }

    let expertiseConditions = undefined;
    if (tmpData.length > 0 && tmpData[tmpData.length - 1].expertiseConditions) {
      expertiseConditions = JSON.parse(
        JSON.stringify(tmpData[tmpData.length - 1].expertiseConditions)
      );
    }

    let vehicle = {
      tyres: [
        {
          key: "tyreFrontLeft",
          text: "Pneu avant gauche",
          value: 0,
          comment: "",
          files: "",
          display: true,
        },
        {
          key: "tyreFrontRight",
          text: "Pneu avant droit",
          value: 0,
          comment: "",
          files: "",
          display: true,
        },
        {
          key: "tyreRearLeft",
          text: "Pneu arrière gauche",
          value: 0,
          comment: "",
          files: "",
          display: true,
        },
        {
          key: "tyreRearRight",
          text: "Pneu arrière droit",
          value: 0,
          comment: "",
          files: "",
          display: true,
        },
      ],
      liquids: [
        {
          key: "motor",
          text: "Huile moteur",
          value: 0,
          comment: "",
          files: "",
          display: true,
        },
        {
          key: "cooling",
          text: "Liquide de refroidissement",
          value: 0,
          comment: "",
          files: "",
          display: true,
        },
        {
          key: "brake",
          text: "Liquide de freins",
          value: 0,
          comment: "",
          files: "",
          display: true,
        },
      ],
    };
    if (tmpData.length > 0) {
      vehicle = JSON.parse(JSON.stringify(tmpData[tmpData.length - 1].vehicle));
    }

    tmpData.push({
      token: generateString(),
      name: `EXPERTISE`,
      isLock: false,
      informations: {
        date: moment().format("YYYY-MM-DD"),
        hour: moment().format("HH:mm"),
        placeOfExpertise: {
          name: user.name,
          address: user.address,
          zipCode: user.zipCode,
          city: user.city,
          phone: user.phone,
        },
        dateEnd: "",
        hourEnd: "",
        type: this.props.data[this.props.data.length - 1]
          ? this.props.data[this.props.data.length - 1].informations.type
          : "",
      },
      recipients: [],
      statusCard: {},
      expertiseConditions,
      RCdamageAssessment: JSON.parse(
        JSON.stringify(
          this.props.dataEvaluation &&
            this.props.dataEvaluation.RCdamageAssessment
            ? this.props.dataEvaluation.RCdamageAssessment
            : [{}]
        )
      ),
      damageAssessment: JSON.parse(
        JSON.stringify(
          this.props.dataEvaluation &&
            this.props.dataEvaluation.damageAssessment
            ? this.props.dataEvaluation.damageAssessment
            : [{}]
        )
      ),
      vehicle,
    });

    this.context.updateDataFolder(
      "dataExpertise",
      tmpData,
      () =>
        this.setState({ tabsId: this.props.data.length - 1 }, () =>
          this.context.updateFolderState(3)
        ),
      7,
      tmpData[tmpData.length - 1].token
    );
  };

  deleteExpertise = () => {
    window.confirmCustom(
      "Êtes-vous sûr de vouloir supprimer cette expertise ?",
      (result) => {
        if (result) {
          let tmpData = this.props.data;

          tmpData.splice(this.state.tabsId, 1);

          this.context.updateDataFolder(
            "dataExpertise",
            tmpData,
            () => this.setState({ tabsId: null }),
            null,
            null
          );
        }
      }
    );
  };

  updateExpertise = (data, callback = () => {}, action, keyData, kind) => {
    let tmpData = this.props.data;
    tmpData[this.state.tabsId] = data;

    this.context.updateDataFolder(
      "dataExpertise",
      tmpData,
      callback,
      action,
      keyData,
      kind
    );
  };

  render() {
    return (
      <>
        <ExpertisesReports
          onClickAdd={this.addExpertise}
          onClick={(tabsId) => this.setState({ tabsId })}
          onClickDelete={this.deleteExpertise}
          data={this.props.data}
          tabsId={this.state.tabsId}
        >
          {this.state.tabsId !== null &&
          this.props.data.hasOwnProperty(this.state.tabsId) ? (
            <Expertise
              key={this.state.tabsId}
              data={this.props.data[this.state.tabsId]}
              dataRecallFacts={this.props.dataRecallFacts}
              dataAdministrative={this.props.dataAdministrative}
              dataEvaluation={this.props.dataEvaluation}
              tabsId={this.state.tabsId}
              type={"Expertise"}
              updateExpertise={this.updateExpertise}
              onClickDelete={this.deleteExpertise}
            />
          ) : (
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0 3px 6px 0 rgb(0 0 0 / 16%)",
              }}
              className="p-20 bg-gray text-center"
            >
              <span>Aucune expertise sélectionnée</span>
            </div>
          )}
        </ExpertisesReports>
      </>
    );
  }
}
