import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Card } from "ui-kit-ck-consultant";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AuthContext from "../../../../context/AuthContext";

export default class vehicleIdentification extends React.Component {
  static contextType = AuthContext;

  getContent = () => {
    return (
      <>
        {this.props.data.map((element, idx) => {
          const {
            body,
            brand,
            licensePlate,
            model,
            year,
            VIN,
            circulationDate,
            certificateDate,
            fuel,
            gearbox,
            color,
            motorType,
            formula,
          } = element;

          return (
            <Row key={idx}>
              <Col xs={12} className="mb-10">
                <h2>Véhicule n°{idx + 1}</h2>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">Immatriculation</h4>
                <p className="mt-0">{licensePlate}</p>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">Numéro de série</h4>
                <p className="mt-0">{VIN}</p>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">Marque</h4>
                <p className="mt-0">{brand}</p>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">Modèle</h4>
                <p className="mt-0">{model}</p>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">Année</h4>
                <p className="mt-0">{year}</p>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">Date de mise en circulation</h4>
                <p className="mt-0">{circulationDate}</p>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">Date certificat</h4>
                <p className="mt-0">{certificateDate}</p>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">Énergie</h4>
                <p className="mt-0">{fuel}</p>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">Boîte de vitesse</h4>
                <p className="mt-0">{gearbox}</p>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">Carrosserie</h4>
                <p className="mt-0">{body}</p>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">Couleur</h4>
                <p className="mt-0">{color}</p>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">Type moteur</h4>
                <p className="mtypet-0">{motorType}</p>
              </Col>
              <Col xs={12} md={3} className="mb-10">
                <h4 className="mb-0">N° de formule</h4>
                <p className="mtypet-0">{formula}</p>
              </Col>
            </Row>
          );
        })}
      </>
    );
  };

  render() {
    if (this.props.isRemoveCard) {
      return this.getContent();
    }

    return (
      <Card
        className="mt-10 mt-20-xl"
        title="Identification du véhicule"
        disabled={this.context.isLock}
        action={
          <FontAwesomeIcon icon={this.props.statusCard ? faEye : faEyeSlash} />
        }
        onClick={this.props.updateStatusCard}
      >
        {this.props.statusCard ? this.getContent() : null}
      </Card>
    );
  }
}
