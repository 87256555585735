import React from "react";
import { FullModal } from "ui-kit-ck-consultant";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export default class ModalPDF extends React.Component {
  downloadPDF = () => {
    const link = document.createElement("a");
    if (!this.props.base64.includes("application/pdf")) {
      link.href = "data:application/pdf;base64," + this.props.base64;
    } else {
      link.href = this.props.base64;
    }
    link.target = "_blank";
    link.download = this.props.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  b64toBlob = (content, contentType = "application/pdf") => {
    contentType = contentType || "";
    const sliceSize = 512;
    // method which converts base64 to binary
    const byteCharacters = window.atob(content);

    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: contentType,
      name: this.props.name,
    }); // statement which creates the blob
    return blob;
  };

  render() {
    let file = "";

    if (this.props.show) {
      file = URL.createObjectURL(
        this.b64toBlob(this.props.base64.split(",").pop())
      );
    }

    return (
      <FullModal
        title={`PDF - ${this.props.name}`}
        show={this.props.show}
        onClose={this.props.onClose}
        onCustom={this.downloadPDF}
        customIcon={faDownload}
      >
        {this.props.show ? (
          <div style={{ height: "100%" }}>
            <embed
              title={this.props.name}
              src={`${file}#toolbar=0`}
              type="application/pdf"
              width="100%"
              style={{ height: "100%" }}
            />
          </div>
        ) : null}
      </FullModal>
    );
  }
}
