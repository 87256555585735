import moment from "moment";

import { getFolder as getLocalFolder } from "../db/folder";

const getPrice = (value) => {
  value = value || 0;
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(value);
};

const getInt = (value) => {
  return new Intl.NumberFormat("fr-FR").format(value);
};

const getParties = (data) => {
  if (
    data &&
    data.dataIdentificationParties &&
    Object.keys(data.dataIdentificationParties).length
  ) {
    return data.dataIdentificationParties;
  } else {
    return {};
  }
};

const getPartiesOptions = (data) => {
  let parties = [];

  if (
    data &&
    data.dataIdentificationParties &&
    Object.keys(data.dataIdentificationParties).length
  ) {
    parties = Object.keys(data.dataIdentificationParties).map((key) => {
      const element = data.dataIdentificationParties[key];
      return {
        value: key,
        text: `${element.kind} ${element.name} - ${element.quality}`,
        quality: element.quality,
        party: element.party,
      };
    });
  }

  return parties;
};

const getParty = (data, type, party = null, option = null) => {
  let user = {};

  let parties = Object.keys(data).filter(
    (key) =>
      type === data[key].quality &&
      (option ? data[key][option.key] === option.value : true)
  );

  if (parties.length) {
    if (party && parties.filter((key) => data[key].party === party).length) {
      parties = parties.filter((key) => data[key].party === party);
    }

    user = data[parties[0]];
    user.id = parties[0];
  }

  return user;
};

const generateString = (length = 8) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `${new Date().getTime()}-${result}`;
};

const resizeImage = (base64, mime, callback = () => {}) => {
  const max = 1280;
  const image = new Image();
  image.src = base64;
  image.onload = () => {
    let initialWidth = image.width;
    let initialHeight = image.height;
    let finalWidth = 0;
    let finalHeight = 0;

    if (initialWidth > initialHeight) {
      if (initialWidth > max) {
        finalWidth = max;
        finalHeight = (finalWidth * initialHeight) / initialWidth;
      } else {
        finalWidth = initialWidth;
        finalHeight = initialHeight;
      }
    } else {
      if (initialHeight > max) {
        finalHeight = max;
        finalWidth = (finalHeight * initialWidth) / initialHeight;
      } else {
        finalWidth = initialWidth;
        finalHeight = initialHeight;
      }
    }

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = finalWidth;
    canvas.height = finalHeight;
    ctx.drawImage(image, 0, 0, finalWidth, finalHeight);
    base64 = canvas.toDataURL(mime, 0.9);

    callback(base64);
  };
  image.onerror = () => {
    callback(base64);
  };
};

const getData = (data, name, type) => {
  if (data.hasOwnProperty(name) && data[name]) {
    if (name === "attachments" && !Array.isArray(data[name])) {
      return [];
    } else if (name === "technicalAnalysis" && typeof data[name] === "string") {
      return [{ value: data[name], files: [] }];
    } else if (
      (name === "RCdamageAssessment" || name === "damageAssessment") &&
      !Array.isArray(data[name])
    ) {
      return [data[name]];
    }
    return data[name];
  } else if (type === "array") {
    if (name === "RCdamageAssessment" || name === "damageAssessment") {
      return [{}];
    } else {
      return [];
    }
  } else if (type === "string") {
    return "";
  } else {
    return {};
  }
};

const getTitleHistory = (name) => {
  switch (name) {
    case "dataAdministrative":
      return "Administratif";
    case "dataFilePreparation":
      return "Prépa. dossier";
    case "dataRecallFacts":
      return "Rappel des faits";
    case "dataExpertise":
      return "Expertises";
    case "dataProtocolComplaints":
      return "Protocole / Réclamation";
    case "dataReports":
      return "Rapports";
    case "dataEvaluation":
      return "Chiffrage";
    default:
      return "Autre";
  }
};

const getFileShorcut = (context, file, size = 900) => {
  const token = `token/${window.localStorage.getItem("token")}/`;
  if (!file.token) {
    return `${context.domain}/api/folder/file/old-get-file/${token}${
      file.path
    }/${file.mime.replace(/\//g, "_")}`;
  }

  return `${context.domain}/api/folder/file/get-file/${token}${file.folderId}/${file.token}/${size}`;
};

const dataParts = [
  "dataAdministrative",
  "dataFilePreparation",
  "dataRecallFacts",
  "dataExpertise",
  "dataProtocolComplaints",
  "dataReports",
  "dataEvaluation",
];

const checkFolderSync = (folder, callback) => {
  let data = {};

  getLocalFolder(folder.token, (resultLocal) => {
    let isServer = false;
    let isLocal = false;

    if (resultLocal.success) {
      dataParts.forEach((key) => {
        if (
          folder.dataOther[`lastDate_${key}`] ===
          resultLocal.data.dataOther[`lastDate_${key}`]
        ) {
          data[key] = "nothing";
        } else if (
          moment(
            folder.dataOther[`lastDate_${key}`],
            "DD/MM/YYYY HH:mm:ss"
          ).isAfter(
            moment(
              resultLocal.data.dataOther[`lastDate_${key}`],
              "DD/MM/YYYY HH:mm:ss"
            )
          ) ||
          !resultLocal.data.dataOther[`lastDate_${key}`]
        ) {
          data[key] = "server";
          isServer = true;
        } else {
          data[key] = "local";
          isLocal = true;
        }
      });
      callback({ success: true, data, isServer, isLocal });
    } else {
      callback({ success: false, data });
    }
  });
};

const getFoldersFilter = (
  folders,
  type,
  company,
  state,
  search,
  client,
  expert,
  agency,
  region,
  isLockFolders
) => {
  return folders.filter((folder) => {
    if (type && parseInt(type) !== folder.typeId) {
      return false;
    }
    if (company && parseInt(company) !== folder.companyId) {
      return false;
    }
    if (state && parseInt(state) !== folder.stateId) {
      return false;
    }
    if (search) {
      const licensePlate = (folder.licensePlate || "")
        .replace(/ /g, "")
        .replace(/-/g, "")
        .toUpperCase();
      const reference = (folder.reference || "").toUpperCase();

      if (!licensePlate.includes(search) && !reference.includes(search)) {
        return false;
      }
    }
    if (client) {
      if (!(folder.nameClient || "").toUpperCase().includes(client)) {
        return false;
      }
    }
    if (expert) {
      if (expert !== folder.expertInitial) {
        return false;
      }
    }

    if (agency && Number(agency) !== folder.agencyId) {
      return false;
    }

    if (region && Number(region) !== folder.regionId) {
      return false;
    }

    if (
      isLockFolders &&
      folder.lockUser &&
      folder.lockUser !== this.context.id
    ) {
      return false;
    }

    return true;
  });
};

export {
  getPrice,
  getInt,
  getParties,
  getPartiesOptions,
  getParty,
  generateString,
  resizeImage,
  getData,
  getTitleHistory,
  getFileShorcut,
  checkFolderSync,
  dataParts,
  getFoldersFilter,
};
