import React from "react";
import moment from "moment";

import {
  Modal,
  FormCheckbox,
  Button,
  Loader,
  FormInput,
} from "ui-kit-ck-consultant";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { sendMails } from "../../../requests/mail";

import AuthContext from "../../../context/AuthContext";

import Lexical from "../../../lexical/Lexical";

export default class ModalSendPdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipients: [],
      others: "",
      isLoad: false,
      subject: "",
      isMessageEditor: false,
      message: "",
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    const { type } = this.props;
    const { dataAdministrative, parties, partiesOptions } = this.context;

    const leseValue = partiesOptions.filter((element) =>
      ["Lésé"].includes(element.quality)
    );

    if (leseValue[0]) {
      const lese = leseValue[0].value;
      this.setState({
        subject: `${type} - N°${dataAdministrative.dataAdministrativeInformation.mission.referenceSinister} - N°${dataAdministrative.dataAdministrativeInformation.mission.reference} - ${parties[lese].name} - ${dataAdministrative.dataVehicleIdentification[0].brand} - ${dataAdministrative.dataVehicleIdentification[0].licensePlate}`,
      });
    }

    this.setState({ message: this.defaultMessage() });
  }

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    let recipients = this.state.recipients;

    if (recipients.includes(name) && !checked) {
      recipients.splice(recipients.indexOf(name), 1);
    } else if (!recipients.includes(name) && checked) {
      recipients.push(name);
    }

    this.setState({
      recipients,
    });
  };

  handleInputChange = (e) => {
    const { value, name } = e.target;

    this.setState({
      [name]: value,
    });
  };

  sendEmails = () => {
    // CONSTANTES
    const { type } = this.props;
    const { subject, message } = this.state;
    const pdf = this.props.data;
    const recipients = this.state.recipients.map((element) => {
      return {
        id: element,
        ...this.context.parties[element],
      };
    });
    const folderId = this.context.folderId;

    this.setState(
      {
        isLoad: true,
      },
      () => {
        if (pdf && recipients && subject && folderId && type && message) {
          sendMails(
            pdf,
            recipients,
            this.state.others,
            subject,
            folderId,
            type,
            message,
            this.props.token,
            (result) => {
              this.setState(
                {
                  isLoad: false,
                  isMessageEditor: false,
                  message: this.defaultMessage(),
                  recipients: [],
                },
                () => {
                  if (result.success) {
                    if (type.includes("Expertise")) {
                      let tmpData = this.props.dataExpertise;
                      tmpData.recipients.push(
                        recipients.map((recipient) => recipient.id)
                      );

                      this.props.updateExpertise(
                        tmpData,
                        () => {},
                        14,
                        this.props.dataExpertise.token
                      );
                      this.props.onClose(true);
                    } else if (type.includes("Rapport")) {
                      let tmpData = this.props.dataReport;
                      tmpData.recipients.push(
                        recipients.map((recipient) => recipient.id)
                      );

                      this.props.updateReport(
                        tmpData,
                        () => {},
                        14,
                        this.props.dataReport.token
                      );
                      this.props.onClose(true);
                    } else if (type === "Protocol") {
                      let tmpData = this.props.dataProtocol;
                      if (!tmpData.recipients) {
                        tmpData.recipients = [];
                      }
                      tmpData.recipients.push(
                        recipients.map((recipient) => recipient.id)
                      );

                      this.props.updateProtocol(
                        tmpData,
                        () => {},
                        15,
                        this.props.dataProtocol.token
                      );

                      this.props.onClose(true);
                    }
                  } else {
                    window.alert("Une erreur est survenue");
                  }
                }
              );
            },
            this.props.files
          );
        } else {
          this.setState(
            {
              isLoad: false,
            },
            () => {
              window.alert("Veuillez remplir tous les champs obligatoires");
            }
          );
        }
      }
    );
  };

  defaultMessage() {
    const { messageType, informations } = this.props;
    let expertValue = this.context.partiesOptions.filter((element) =>
      ["Expert"].includes(element.quality)
    );
    let expert = expertValue[0].value;

    return `Bonjour, <br/> Vous trouverez ci-joint ${messageType} du ${
      informations.date ? moment(informations.date).format("DD/MM/YYYY") : ""
    }.<br/> Cordialement,<br/>${this.context.parties[expert].kind} ${
      this.context.parties[expert].name
    }`;
  }

  render() {
    const { isMessageEditor, message, recipients, others } = this.state;
    return (
      <>
        <Modal
          large
          closeMessage={"Êtes-vous sûr de vouloir fermer cette fenêtre ?"}
          title={
            isMessageEditor
              ? "Éditer le message de l'e-mail"
              : "Choisissez les destinaitaires pour envoyer le PDF par mail"
          }
          show={this.props.show}
          onClose={() => {
            this.setState(
              {
                recipients: [],
                isMessageEditor: false,
                message: this.defaultMessage(),
              },
              () => {
                this.props.onClose();
              }
            );
          }}
          isMove
        >
          {!isMessageEditor ? (
            <>
              {this.context.partiesOptions.map((element, idx) => (
                <FormCheckbox
                  key={idx}
                  text={element.text}
                  className="mb-10"
                  name={element.value}
                  checked={this.state.recipients.includes(element.value)}
                  onChange={this.handleCheckboxChange}
                />
              ))}
              <FormInput
                className="mb-10"
                name="others"
                value={this.state.others}
                title="Saisie libre adresse mail (;)"
                onChange={this.handleInputChange}
              />
              <Button
                disabled={!recipients.length && !others.length}
                className="w-auto ml-auto"
                text={"Suivant"}
                onClick={() => this.setState({ isMessageEditor: true })}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </>
          ) : (
            <>
              <div className="w-100">
                <Lexical
                  value={message}
                  onChange={(e) => {
                    this.setState({ message: e });
                  }}
                />
              </div>
              <div className="d-flex">
                <Button
                  className="w-auto mr-auto"
                  text={"Retour"}
                  onClick={() => this.setState({ isMessageEditor: false })}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
                <Button
                  className="w-auto"
                  text={"Envoyer"}
                  onClick={this.sendEmails}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </>
          )}
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
