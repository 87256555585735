import React, { createRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Signature, Modal, Button, FormInput } from "ui-kit-ck-consultant";
import SignatureCanvas from "react-signature-canvas";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class ExpertSignature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 200,
      width: 200,
      isModalSignature: false,
      modalSignatureKey: null,
      location: "",
    };

    this.signatureRef = createRef();
    this.displayName = "ExpertSignature";
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.isModalSignature &&
      prevState.isModalSignature !== this.state.isModalSignature
    ) {
      this.setSizeSignature();
    }
  }

  setSizeSignature = () => {
    if (
      this.signatureRef.current &&
      this.state.width !== this.signatureRef.current.getCanvas().offsetWidth
    ) {
      this.setState({
        width: this.signatureRef.current.getCanvas().offsetWidth,
      });
    }
  };

  handleClearSignatureClick = () => {
    this.signatureRef.current.clear();
  };

  handleValidSignatureClick = () => {
    let image = this.signatureRef.current.toDataURL("image/png");

    let tmpData = this.props.data;
    tmpData.base64 = image;

    this.setState({ isModalSignature: false }, () => {
      this.setData(tmpData);
    });
  };

  setData = (tmpData) => {
    this.context.updateDataReport("expertSignature", tmpData);
  };

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = value;

    this.setData(tmpData);
  };

  render() {
    let expert =
      this.context.users.filter((expert) => {
        return (
          expert.id ===
          parseInt(
            this.context.dataAdministrative.dataAdministrativeInformation
              .mission.expert
          )
        );
      })[0] || {};

    if (!expert.name) {
      return null;
    }

    return (
      <>
        <CustomCard title="Signature de l'expert" name={this.displayName}>
          <Row>
            <Col xs={12} md={6}>
              <p>{expert.name}</p>
              <p>{expert.codeVE}</p>
              <Signature
                onAdd={() => {
                  let tmpData = this.props.data;
                  this.setState(
                    {
                      isModalSignature: true,
                    },
                    () => {
                      this.setData(tmpData);
                    }
                  );
                }}
                onRemove={() => {
                  let tmpData = this.props.data;

                  tmpData.base64 = "";

                  this.setData(tmpData);
                }}
                signature={this.props.data.base64}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FormInput
                required
                type="text"
                title="Fait à"
                name="location"
                value={this.props.data.location}
                onChange={this.handleInputChange}
              />
              <FormInput
                required
                type="date"
                title="Le"
                name="date"
                value={this.props.data.date}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
        </CustomCard>
        <Modal
          closeMessage="Êtes-vous sûr de vouloir fermer cette fenêtre ?"
          show={this.state.isModalSignature}
          onClose={() => {
            this.setState({
              isModalSignature: false,
              modalSignatureKey: null,
            });
          }}
          isMove
        >
          <SignatureCanvas
            ref={this.signatureRef}
            penColor="black"
            canvasProps={{
              height: this.state.height,
              width: this.state.width,
              style: {
                border: "var(--gray) dashed 2px",
                borderRadius: "15px",
              },
              className: "sigCanvas mb-10",
            }}
          />
          <Button
            text="Rénitialiser"
            className="m-0 mb-10 outline"
            onClick={this.handleClearSignatureClick}
          />
          <Button
            text="Valider"
            className="m-0"
            onClick={this.handleValidSignatureClick}
          />
        </Modal>
      </>
    );
  }
}
