import React from "react";
import moment from "moment";
import {
  Card,
  FormInput,
  FormSelect,
  ButtonIcon,
  Button,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AuthContext from "../../../../context/AuthContext";

export default class AdministrativeInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mission_agencyId: "",
      mission_agency: "",
      mission_dateMission: "",
      mission_dateSinister: "",
      mission_typeMission: "",
      mission_referenceSinister: "",
      mission_reference: "",
      mission_expert: "",
      mission_assistant: "",
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e, isForce = false) => {
    const { value, name } = e.target;

    let mission_agency = this.state.mission_agency;

    if (name === "mission_agencyId") {
      const dataAgency = this.context.agencies.find((element) => {
        return parseInt(element.value) === parseInt(value);
      });
      mission_agency = dataAgency.name;
    }

    this.setState(
      {
        [name]: value,
        mission_agency,
      },
      () => {
        if (isForce) {
          this.setData();
        }
      }
    );
  };

  handleSelectChange = (e) => {
    const { value, name } = e.target;

    this.setState(
      {
        [name]: value,
      },
      this.setData
    );
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.getData();
    }
  }

  getData = () => {
    let tmpState = this.state;

    Object.keys(this.state).forEach((key) => {
      const keySplit = key.split("_");
      if (
        this.props.data.hasOwnProperty(keySplit[0]) &&
        this.props.data[keySplit[0]].hasOwnProperty(keySplit[1])
      ) {
        tmpState[key] = this.props.data[keySplit[0]][keySplit[1]];
      }
    });

    this.setState(tmpState);
  };

  setData = () => {
    let tmpData = this.props.data;

    Object.keys(this.state).forEach((key) => {
      const keySplit = key.split("_");
      if (
        keySplit[1] === "agencyId" ||
        (this.props.data.hasOwnProperty(keySplit[0]) &&
          this.props.data[keySplit[0]].hasOwnProperty(keySplit[1]))
      ) {
        tmpData[keySplit[0]][keySplit[1]] = this.state[key];
      }
    });

    this.props.setData("dataAdministrativeInformation", tmpData);
  };

  render() {
    return (
      <>
        <Card
          className="mt-10 mt-20-xl"
          title="Informations administratives"
          disabled={this.context.isLock}
          action={
            <ButtonIcon
              className="blue ml-10 p-0"
              small
              onClick={() => {
                window.confirmCustom(
                  "Êtes-vous sûr de synchroniser le bloc avec Alpha Expert (risque d'écrasement des données) ?",
                  (result) => {
                    if (result) {
                      this.setState(
                        {
                          mission_dateMission: moment(
                            this.context.interfaceDossier["DateMission_Dossier"]
                          ).format("YYYY-MM-DD"),
                          mission_dateSinister: moment(
                            this.context.interfaceDossier["Date_Sinistre"]
                          ).format("YYYY-MM-DD"),
                          mission_referenceSinister:
                            this.context.interfaceDossier["Numero_Sinistre"],
                          mission_reference:
                            this.context.interfaceDossier["Numero_Dossier"],
                        },
                        this.setData
                      );
                    }
                  }
                );
              }}
            >
              <FontAwesomeIcon icon={faSync} />
            </ButtonIcon>
          }
          disableStyleAction
        >
          <Row>
            <Col xs={12} md={6} lg={4}>
              <FormSelect
                className="mb-10"
                name="mission_typeMission"
                value={this.state.mission_typeMission}
                init="Veuillez sélectionner un type de dossier"
                options={[...this.context.types]}
                title="Type de dossier"
                onChange={(e) => this.handleInputChange(e, true)}
                ignore={true}
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <FormSelect
                className="mb-10"
                name="mission_agencyId"
                value={this.state.mission_agencyId}
                init="Veuillez sélectionner une agence"
                options={[...this.context.agencies]}
                title="Agence"
                onChange={(e) => this.handleInputChange(e, true)}
                ignore={true}
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <FormInput
                className="mb-10"
                name="mission_dateMission"
                value={this.state.mission_dateMission}
                type="date"
                title="Date de mission"
                onChange={this.handleInputChange}
                onBlur={this.setData}
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <FormInput
                className="mb-10"
                name="mission_dateSinister"
                value={this.state.mission_dateSinister}
                type="date"
                title="Date de sinistre"
                onChange={this.handleInputChange}
                onBlur={this.setData}
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <FormInput
                className="mb-10"
                name="mission_referenceSinister"
                value={this.state.mission_referenceSinister}
                title="N° de sinistre compagnie"
                onChange={this.handleInputChange}
                onBlur={this.setData}
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <FormInput
                className="mb-10"
                name="mission_reference"
                value={this.state.mission_reference}
                title="N° dossier Alpha Expert"
                onChange={this.handleInputChange}
                onBlur={this.setData}
              />
            </Col>
            <Col xs={12}>
              <p
                className={
                  this.context.companyId !== this.context.companyId2
                    ? "m-0 mt-10 red"
                    : "m-0 mt-10"
                }
              >
                Compagnie d'assurance : {this.context.companyName}
              </p>
              {!this.context.isDisconnected &&
              this.context.companyId !== this.context.companyId2 ? (
                <Button
                  text="Synchroniser la compagnie"
                  className="w-auto mt-10"
                  onClick={() => this.context.updateCompany()}
                />
              ) : null}
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}
