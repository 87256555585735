import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { FormCheckbox } from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";
import CardEditor from "../CardEditor";

export default class ContinuedFileState extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "ContinuedFileState";
  }

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = checked;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    this.context.updateDataReport("continuedFileState", tmpData);
  };

  getData = () => {
    const defaultData = `<p>Date prévisionnelle du dépôt des conclusions définitives :</p>`;

    return (
      <Row>
        <Col xs={12}>
          <FormCheckbox
            className="mb-10"
            text="La responsabilité de l’assuré est engagée"
            name="q1"
            checked={this.props.data.q1}
            onChange={this.handleCheckboxChange}
          />
        </Col>
        <Col xs={12}>
          <FormCheckbox
            className="mb-10"
            text="Attente nouvelle réunion d’examen"
            name="q2"
            checked={this.props.data.q2}
            onChange={this.handleCheckboxChange}
          />
        </Col>
        <Col xs={12}>
          <FormCheckbox
            className="mb-10"
            text="Attente démontage partiel véhicule"
            name="q3"
            checked={this.props.data.q3}
            onChange={this.handleCheckboxChange}
          />
        </Col>
        <Col xs={12}>
          <FormCheckbox
            className="mb-10"
            text="Attente chiffrage des dommages"
            name="q4"
            checked={this.props.data.q4}
            onChange={this.handleCheckboxChange}
          />
        </Col>
        <Col xs={12}>
          <FormCheckbox
            className="mb-10"
            text="Attente position des parties"
            name="q5"
            checked={this.props.data.q5}
            onChange={this.handleCheckboxChange}
          />
        </Col>
        <Col>
          <CardEditor
            noCard
            {...this.props}
            subDataName="text"
            dataName="continuedFileState"
            default={defaultData}
          />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <CustomCard title="Suite en l'état du dossier" name={this.displayName}>
        {this.getData()}
      </CustomCard>
    );
  }
}
