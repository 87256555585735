import React from "react";
import {
  Button,
  ButtonIcon,
  FormInput,
  FullModal,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  Loader,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { getRecallFacts } from "../../../requests/folder";

import { generateString } from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";

export default class ModalRecallFactsSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      recallFacts: [],
      recallFactsAdded: [],
      reference: "",
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  getRecallFacts() {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        getRecallFacts(
          {
            reference: this.state.reference,
          },
          (result) => {
            if (result.success) {
              this.setState({
                isLoad: false,
                recallFacts: result.data.dataRecallFacts,
              });
            }
          }
        );
      }
    );
  }

  render() {
    const recallFacts = this.state.recallFacts;
    let parties = this.context.parties;

    return (
      <FullModal
        title="Recherche de rappel des faits"
        show={this.props.show}
        large
        onClose={() => {
          this.setState(
            { recallFactsAdded: [], reference: "", recallFacts: [] },
            () => {
              this.props.onClose();
            }
          );
        }}
      >
        <Row>
          <Col md={4} lg={2}>
            <FormInput
              className="mb-10"
              name="reference"
              value={this.state.reference}
              title="Référence dossier"
              onChange={this.handleInputChange}
            />
          </Col>
          <Col md={4} lg={2} className="d-flex">
            <Button
              disabled={this.state.reference.length === 0}
              className="mt-auto mb-10"
              text="Rechercher"
              onClick={() => {
                this.getRecallFacts();
              }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Button>
          </Col>

          <Table>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Kms / Hrs</Th>
                <Th>Fait</Th>
                <Th>Intervenant</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {recallFacts.length > 0
                ? recallFacts.map((recall, idx) => {
                    return (
                      <Tr key={idx}>
                        <Td
                          className={
                            this.state.recallFactsAdded.includes(idx)
                              ? "bg-green-light"
                              : ""
                          }
                        >
                          {moment(recall.date).format("DD/MM/YYYY")}
                        </Td>
                        <Td
                          className={
                            this.state.recallFactsAdded.includes(idx)
                              ? "bg-green-light"
                              : ""
                          }
                        >
                          {recall.mileage}
                        </Td>
                        <Td
                          className={
                            this.state.recallFactsAdded.includes(idx)
                              ? "bg-green-light"
                              : ""
                          }
                        >
                          {recall.description}
                        </Td>
                        <Td
                          className={
                            this.state.recallFactsAdded.includes(idx)
                              ? "bg-green-light"
                              : ""
                          }
                        >
                          {parties[recall.speaker]
                            ? `${
                                parties[recall.speaker].society
                                  ? `${parties[recall.speaker].society} - `
                                  : ""
                              }${parties[recall.speaker].kind} ${
                                parties[recall.speaker].name
                              }`
                            : recall.speaker === "Autre"
                            ? recall.speakerName
                            : ""}
                        </Td>
                        <Td className="m-auto d-flex flex-column">
                          <ButtonIcon
                            className="blue"
                            small
                            onClick={() => {
                              this.setState({ isLoad: true }, () => {
                                this.props.addRecallFacts(
                                  { ...recall, token: generateString() },
                                  () => {
                                    this.setState({
                                      isLoad: false,
                                      reference: "",
                                      recallFactsAdded: [
                                        ...this.state.recallFactsAdded,
                                        idx,
                                      ],
                                    });
                                  }
                                );
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </ButtonIcon>
                        </Td>
                      </Tr>
                    );
                  })
                : null}
            </Tbody>
          </Table>
        </Row>
        <Loader show={this.state.isLoad} />
      </FullModal>
    );
  }
}
