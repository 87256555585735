import React, { Fragment } from "react";
import {
  faCaretDown,
  faCaretUp,
  faPlus,
  faTrash,
  faTimes,
  faPen,
  faCamera,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonIcon, FullModal } from "ui-kit-ck-consultant";
import { Col, Row } from "react-flexbox-grid";

import { getFileShorcut } from "../../../../utils/general";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

import Lexical from "../../../../lexical/Lexical";

export default class TechnicalAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idxFile: null,
      subIdxFile: null,
      isImageModal: false,
      idxImageModal: null,
      editorState: [],
      newFinding: false,
    };
    this.displayName = "TechnicalAnalysis";
  }

  static contextType = AuthContext;

  componentDidMount() {
    let editorState = this.state.editorState;
    this.props.data.forEach((element) => {
      editorState.push(element.value);
    });

    this.setState({
      editorState,
    });
  }

  handleEditorChange = (idx) => {
    let tmpData = this.props.data;
    tmpData[idx].value = this.state.editorState[idx];
    this.setData(tmpData);
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("technicalAnalysis", tmpData);
    } else {
      this.context.updateDataReport("technicalAnalysis", tmpData);
    }
  };

  onAddLineClick = () => {
    let tmpData = this.props.data;
    let editorState = this.state.editorState;

    editorState.push("");

    this.setState(
      {
        editorState: editorState,
        newFinding: true,
      },
      () => {
        tmpData.push({
          value: "",
          files: [],
        });

        this.setData(tmpData);
      }
    );
  };

  setFiles = (files) => {
    files = files.filter((file) =>
      ["png", "jpeg", "jpg"].includes(file.extension)
    );

    if (files) {
      let tmpData = this.props.data;

      files.forEach((file) => {
        tmpData[this.state.idxFile].files.push(file);
      });

      this.setState(
        {
          idxFile: null,
        },
        () => {
          this.setData(tmpData);
        }
      );
    } else {
      window.alert("Aucun fichier compatible");
    }
  };

  replaceFile = (files) => {
    let tmpData = this.props.data;

    tmpData[this.state.idxFile].files[this.state.subIdxFile] = files[0];

    this.setState(
      {
        idxFile: null,
        subIdxFile: null,
      },
      () => {
        this.setData(tmpData);
      }
    );
  };

  getData = () => {
    return (
      <div className="d-flex flex-column">
        {this.props.data.map((element, idx) => (
          <Fragment key={idx}>
            <div
              className="ml-auto mr-auto"
              style={{
                backgroundColor: "#eeeeee",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                padding: "2px 6px",
                display: "inline-flex",
              }}
            >
              <span className="mr-5 mt-auto mb-auto">{idx + 1}</span>
              {idx !== 0 ? (
                <ButtonIcon
                  className="blue"
                  onClick={() => {
                    let tmpData = this.props.data;
                    let tmpEditorState = this.state.editorState;

                    const oldLine = tmpData[idx - 1];
                    tmpData[idx - 1] = tmpData[idx];
                    tmpData[idx] = oldLine;

                    const oldLineEditorState = tmpEditorState[idx - 1];
                    tmpEditorState[idx - 1] = tmpEditorState[idx];
                    tmpEditorState[idx] = oldLineEditorState;

                    this.setState(
                      {
                        editorState: tmpEditorState,
                      },
                      () => {
                        this.setData(tmpData);
                      }
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faCaretUp} />
                </ButtonIcon>
              ) : null}
              {idx !== this.props.data.length - 1 ? (
                <ButtonIcon
                  className="blue"
                  onClick={() => {
                    let tmpData = this.props.data;
                    let tmpEditorState = this.state.editorState;

                    const oldLine = this.props.data[idx + 1];
                    tmpData[idx + 1] = this.props.data[idx];
                    tmpData[idx] = oldLine;

                    const oldLineEditorState = tmpEditorState[idx + 1];
                    tmpEditorState[idx + 1] = tmpEditorState[idx];
                    tmpEditorState[idx] = oldLineEditorState;

                    this.setState(
                      {
                        editorState: tmpEditorState,
                      },
                      () => {
                        this.setData(tmpData);
                      }
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faCaretDown} />
                </ButtonIcon>
              ) : null}
              <ButtonIcon
                small
                className="mt-auto mb-auto red"
                onClick={() => {
                  window.confirmCustom(
                    "Êtes-vous sûr de vouloir supprimer cette constatation ?",
                    (result) => {
                      if (result) {
                        let tmpData = this.props.data;
                        let editorState = this.state.editorState;

                        tmpData.splice(idx, 1);
                        editorState.splice(idx, 1);

                        this.setState(
                          {
                            editorState,
                          },
                          () => {
                            this.setData(tmpData);
                          }
                        );
                      }
                    }
                  );
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ButtonIcon>
            </div>
            <div
              style={{
                padding: "12px",
                backgroundColor: "#eeeeee",
                borderRadius: "15px",
              }}
            >
              <Row key={idx}>
                <Col xs={12} md={12}>
                  <div>
                    <Lexical
                      ref={(ref) => {
                        if (
                          ref &&
                          this.state.newFinding &&
                          idx + 1 === this.state.editorState.length
                        ) {
                          this.setState({ newFinding: false }, () => {
                            ref.focus();
                          });
                        }
                      }}
                      value={this.state.editorState[idx]}
                      onChange={(editorState) => {
                        let editorStateArray = this.state.editorState;

                        editorStateArray[idx] = editorState;
                        this.setState({ editorState: editorStateArray }, () =>
                          this.handleEditorChange(idx)
                        );
                      }}
                    />
                  </div>
                </Col>
                {element.files
                  ? element.files.map((file, idxFile) => {
                      return (
                        <Col
                          xs={12}
                          md={
                            element.files.length % 2 === 0 ||
                            idxFile !== element.files.length - 1
                              ? 6
                              : 12
                          }
                          key={idxFile}
                        >
                          <div
                            className="d-flex  mt-5 mb-5 bg-white"
                            style={{
                              position: "relative",
                              borderRadius: "15px",
                            }}
                          >
                            <img
                              alt="imageFindings"
                              className="m-auto"
                              src={getFileShorcut(this.context, file)}
                              crossorigin="anonymous"
                              style={{ height: "200px", maxWidth: "100%" }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "px",
                                right: "10px",
                              }}
                            >
                              {file.extension ? (
                                <ButtonIcon
                                  small
                                  className="blue"
                                  onClick={() => {
                                    this.setState(
                                      {
                                        idxFile: idx,
                                        subIdxFile: idxFile,
                                      },
                                      () => {
                                        this.context.openModalLibrary(
                                          this.replaceFile,
                                          file.filename,
                                          file.token,
                                        );
                                      }
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPen} />
                                </ButtonIcon>
                              ) : null}
                              <ButtonIcon
                                className="red"
                                onClick={() => {
                                  let tmpData = this.props.data;
                                  element.files.splice(idxFile, 1);

                                  this.setData(tmpData);
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </ButtonIcon>
                            </div>
                          </div>
                        </Col>
                      );
                    })
                  : null}
              </Row>
            </div>
            <div
              className="ml-auto mr-auto"
              style={{
                backgroundColor: "#eeeeee",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
                padding: "2px 6px",
                display: "inline-flex",
                marginBottom: "12px",
              }}
            >
              <ButtonIcon
                className="m-auto blue"
                onClick={() => {
                  this.setState(
                    {
                      idxFile: idx,
                    },
                    () => {
                      this.context.openModalLibrary(this.setFiles);
                    }
                  );
                }}
              >
                <FontAwesomeIcon icon={faCamera} />
              </ButtonIcon>
            </div>
          </Fragment>
        ))}
        <div className="d-flex">
          <ButtonIcon
            isBackground
            className="mt-10 ml-auto bg-blue"
            onClick={() =>
              this.context.openModalTechnicalAdvice(true, (value) => {
                this.refCardEditor.current.updateStateEditor(value, () => {
                  this.context.updateDataReport("technicalAnalysis", value);
                });
              })
            }
            info="Ouvrir la base de données"
          >
            <FontAwesomeIcon icon={faBook} />
          </ButtonIcon>
          <ButtonIcon
            isBackground
            className="mt-10 ml-10 bg-blue"
            onClick={this.onAddLineClick}
            info="Ajouter une ligne"
          >
            <FontAwesomeIcon icon={faPlus} />
          </ButtonIcon>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <CustomCard title="Analyse technique" name={this.displayName}>
          {this.getData()}
        </CustomCard>
        <FullModal
          show={this.state.isImageModal}
          onClose={() =>
            this.setState({ isImageModal: false, idxImageModal: null })
          }
          isBackground={false}
        >
          <div className="h-100 w-100 d-flex">
            <img
              className="m-auto"
              style={{ maxHeight: "100%", maxWidth: "100%" }}
              src={
                this.state.idxImageModal !== null
                  ? this.props.data[this.state.idxImageModal].base64
                  : null
              }
              alt="fullscreen-findings"
            />
          </div>
        </FullModal>
      </>
    );
  }
}
