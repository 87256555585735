import React from "react";
import {
  faCheck,
  faSync,
  faEnvelope,
  faFilePdf,
  faPlus,
  faEyeSlash,
  faSignature,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-flexbox-grid";
import {
  Button,
  ButtonIcon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Loader,
  FormInput,
  FormTextarea,
} from "ui-kit-ck-consultant";
import moment from "moment";

import CustomCard from "../../../../components/folder/general/CustomCard";
import VehicleIdentification from "../../../../components/folder/expertises-reports/report/VehicleIdentification";
import RecallFacts from "../../../../views/dashboard/folder/RecallFacts";

import { getParty, generateString } from "../../../../utils/general";

import {
  documentToPdfRequest,
  electronicSignature,
} from "../../../../requests/mail";
import { getFolderDocument } from "../../../../requests/folder";

import ModalSendPdf from "../../../../components/folder/general/ModalSendPdf";
import HistoryTable from "../../../../components/folder/file-preparation/HistoryTable";

import PartyTop from "../../../../components/folder/protocol/PartyTop";
import LineTransaction from "../../../../components/folder/protocol/LineTransaction";
import PartyBottom from "../../../../components/folder/protocol/PartyBottom";

import Lexical from "../../../../lexical/Lexical";

import { writeLog } from "../../../../requests/general";

import AuthContext from "../../../../context/AuthContext";

export default class Protocol extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      parties: [],
      editorState: "",
      articles: ["", "", "", ""],
      textSignature: "",
      mailBase64: "",
      mailFiles: [],
      isModalMail: false,
      token: "",
      dataFolderDocument: [],
      statusCard: {},
      justifyCard: {},
      jumpCard: {},
      informations: {
        date: "",
        hour: "",
      },
      history: "",
      hides: {},
      recallFacts: {},
      isReady: false,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.updateProtocol()
    );
  };

  componentDidMount() {
    this.getFolderDocument();

    this.setDefaultState(async () => {
      if (
        this.props.data &&
        !this.props.data.hasOwnProperty("technicalFindings")
      ) {
        await this.setFindings();
      }

      this.setState({
        statusCard: this.props.data.statusCard || {},
        justifyCard: this.props.data.justifyCard || {},
        jumpCard: this.props.data.jumpCard || {},
        parties: this.props.data.parties,
        editorState: this.props.data.technicalFindings,
        articles: this.props.data.articles,
        location: this.props.data.location,
        textSignature:
          this.props.data.textSignature || this.state.textSignature,
        informations: {
          date: this.props.data.informations.date,
          hour: this.props.data.informations.hour,
        },
        history: this.props.data.history,
        hides: this.props.data.hides,
        recallFacts: this.props.data.recallFacts,
        isReady: true,
      });
    });
  }

  setDefaultState = (callback) => {
    const lese = getParty(this.context.parties, "Lésé");

    let parties = Object.keys(this.context.parties)
      .filter(
        (key) =>
          this.context.parties[key].quality &&
          ["Lésé", "Adverse"].includes(this.context.parties[key].quality)
      )
      .map((key) => ({
        token: key,
      }));

    this.setState(
      {
        parties,
        articles: [
          `Après échanges, les parties se sont rapprochées et ont convenu ensemble des concessions réciproques suivantes pour mettre un terme amiable à leur différend :<br/><br/>• [Exposé précis des engagements de la Partie 1 + Date butoir d’exécution]<br/>• [Exposé précis des engagements de la Partie 2 + Date butoir d’exécution]`,
          `• [Exposé précis des règlements en chiffres et en lettres avec notion de TVA]<br/>• [Précision des dates butoirs ou échéanciers]<br/><br/>En contrepartie, et sous réserve du bon respect des clauses précitées, ${lese.kind} ${lese.name} se déclare entièrement et intégralement satisfait de tout préjudice matériel ou immatériel ayant justifié sa réclamation.<br/><br/>${lese.kind} ${lese.name} déclare également se désister de toute instance et action, principale ou reconventionnelle, pour les faits litigieux, objets du présent protocole transactionnel.<br/><br/>Les parties acceptent les termes de cette résolution et conservent à leur charge leurs frais irrépétibles et les dépens.`,
          `En cas de manquement de l’une quelconque des parties aux obligations définies au présent protocole, la présente transaction sera résolue de plein droit, 48 heures ouvrées après mise en demeure infructueuse adressée par lettre recommandée avec accusé de réception, à la partie débitrice de l’obligation inexécutée, sans préjudice pour l’autre partie de solliciter le cas échéant des dommages et intérêts complémentaires.`,
          `Les termes du protocole emportent transaction et sont donc soumis aux articles 2044 et suivants du code civil.<br/><br/>Selon l’article 2044 du Code Civil la transaction est un contrat qui doit être écrit par lequel les parties par des concessions réciproques terminent une contestation née ou préviennent d’une contestation à naître.<br/><br/>Conformément à l’article 2052 du Code Civil, la transaction fait obstacle à l’introduction ou à la poursuite entre les parties d’une action en justice ayant le même objet.<br/><br/>Les parties sont donc informées qu’elles renoncent par ce protocole à toute réclamation entre elles à propos des faits qu’elles y énoncent.`,
          `Signature électronique<br/>
Le Présent accord est signé par chacune des Parties au moyen d'un procédé de signature électronique mis en œuvre par un prestataire tiers, YouSign, qui garantit la sécurité et l'intégrité des exemplaires numériques conformément à l’article 1367 du code civil. 
<br/>
Conformément à l’alinéa 4 de l’article 1375 du code civil, l’Accord est établi en un seul exemplaire numérique original, dont une copie sera délivrée à chacune des Parties directement par YouSign, qui est en charge de la mise en œuvre de la solution de signature électronique dans les conditions requises par l’article 1367 du code civil. 
<br/>
Les Parties reconnaissent qu'ils procèdent à la signature électronique de l’accord en toute connaissance de cause de la technologie mise en œuvre et des modalités de celle-ci, et renoncent en conséquence à mettre en cause, dans le cadre de toute réclamation et/ou action en justice, la force probante du procédé technique de la signature électronique.
<br/>
<br/>
Conformément à l’article 2052 du Code Civil, la transaction fait obstacle à l’introduction ou à la poursuite entre les parties d’une action en justice ayant le même objet.
<br/>
<br/>
Les parties sont donc informées qu’elles renoncent par ce protocole à toute réclamation entre elles à propos des faits qu’elles y énoncent. 
<br/>
<br/>
Le présent protocole est établi de façon dématérialisé, paraphé sur chaque page et un original dématérialisé sera remis automatiquement par courriel à chacune des parties après acceptation.
<br/>
<br/>
Ou
<br/>
<br/>
Le présent protocole est établi en deux exemplaires, paraphé sur chaque page et un original est remis à chacune des parties.`,
        ],
        textSignature: "",
        informations: {
          date: moment().format("YYYY-MM-DD"),
          hour: moment().format("HH:mm"),
        },
      },
      callback
    );
  };

  setData = (data, callback = () => {}, type = null, keyData = null) => {
    this.props.setData("dataProtocol", data, callback, type, keyData);
  };

  updateProtocol = (callback = () => {}) => {
    let tmpData = this.props.data;

    tmpData.statusCard = this.state.statusCard || {};
    tmpData.justifyCard = this.state.justifyCard || {};
    tmpData.jumpCard = this.state.jumpCard || {};
    tmpData.parties = this.state.parties;
    tmpData.technicalFindings = this.state.editorState;
    tmpData.articles = this.state.articles;
    tmpData.location = this.state.location;
    tmpData.date = this.state.date;
    tmpData.textSignature = this.state.textSignature;
    tmpData.informations = {
      hour: moment().format("HH:mm"),
      date: moment().format("YYYY-MM-DD"),
    };
    tmpData.hides = this.state.hides;
    tmpData.recallFacts = this.state.recallFacts;
    tmpData.history = this.state.history;

    this.setData(tmpData, callback);
  };

  updateHistories = (tmpData, callback = () => {}) => {
    this.setData(tmpData, callback);
  };

  addProtocol = (isElectronicSignature = false) => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        this.updateProtocol(() => {
          let tmpData = this.props.data;

          if (!tmpData.hasOwnProperty("histories")) {
            tmpData.histories = [];
          }

          const element = {
            token: generateString(),
            name: "Protocol",
            informations: {
              date: moment(this.state.informations.date).format("YYYY-MM-DD"),
              hour: this.state.informations.hour,
            },
            parties: this.state.parties,
            technicalFindings: this.state.editorState,
            articles: this.state.articles,
            location: this.state.location,
            dateSignature: this.state.date,
            textSignature: this.state.textSignature,
            recipients: [],
            history: this.state.history,
            hides: this.state.hides,
            recallFacts: this.state.recallFacts,
            statusCard: this.state.statusCard,
            justifyCard: this.state.justifyCard,
            jumpCard: this.state.jumpCard,
            isElectronicSignature,
          };

          tmpData.histories.push(JSON.parse(JSON.stringify(element)));
          this.setData(
            tmpData,
            () =>
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  if (
                    this.state.parties.filter(
                      (element) => !element.token || element.signature
                    ) === this.state.parties.length
                  ) {
                    this.context.updateFolderState(9);
                  } else {
                    this.context.updateFolderState(7);
                  }

                  this.documentToPdf(
                    {
                      administrative: this.context.dataAdministrative,
                      data: element,
                      recallFacts: this.props.dataRecallFacts,
                      type: "Protocol",
                    },
                    () => {},
                    true
                  );
                }
              ),
            10,
            tmpData.histories[tmpData.histories.length - 1].token
          );
        });
      }
    );
  };

  setFindings = async () => {
    return new Promise((resolve) => {
      let text = "<p>";
      let countExpertise = 0;

      this.props.dataExpertise.forEach((element, idx) => {
        countExpertise++;
        if (idx > 0) {
          text += "</br></br>";
        }
        text += `---------- EXPERTISE n°${countExpertise} ----------`;
        text += "</br></br>";
        text += `Lors des opérations d’expertise amiable et contradictoire le ${moment(
          element.informations.date
        ).format("DD/MM/YYYY")} à ${
          element.informations.hour
        }, il a été constaté :`;

        if (element.hasOwnProperty("findings")) {
          element.findings.forEach((finding) => {
            text += "</br>";
            text += finding.value.replace("<p>", "<p>- ");
          });
        }
      });
      text += "</p>";

      this.setState(
        {
          editorState: text,
        },
        () => {
          resolve();
          this.updateProtocol();
        }
      );
    });
  };

  downloadPDF = (data) => {
    const reference =
      this.context.dataAdministrative.dataAdministrativeInformation.mission
        .reference;
    this.documentToPdf(data, (base64) => {
      const name = `protocole-d-accord-transactionnel-${reference}-${moment().format(
        "YYYY-MM-DD_HH-mm-ss"
      )}.pdf`;

      this.context.handleOpenModalPDF(base64, name);
    });
  };

  electronicSignature = (data) => {
    data = {
      ...data,
      folderId: this.context.folderId,
    };
    this.setState({ isLoad: true }, () => {
      electronicSignature(data, (result) => {
        if (result.success) {
          this.setState({ isLoad: false }, () => {
            let tmpData = this.props.data;

            this.props.data.histories.forEach((element, idx) => {
              if (element.token === data.data.token) {
                tmpData.histories[idx].electronicSignature = result.data;
              }
            });

            this.updateHistories(tmpData);
          });
        } else {
          this.setState({ isLoad: false }, () => {
            alert("Une erreur est survenue");
          });
        }
      });
    });
  };

  documentToPdf = (data, callback, isWebservice = false) => {
    data = {
      ...data,
      folderId: this.context.folderId,
      folderType: this.context.shortType,
      isWebservice,
      expert:
        this.context.users.filter((expert) => {
          return (
            expert.id ===
            parseInt(
              this.context.dataAdministrative.dataAdministrativeInformation
                .mission.expert
            )
          );
        })[0] || {},
    };
    this.setState(
      {
        isLoad: true,
      },
      () => {
        documentToPdfRequest(data, (result) => {
          if (result.success) {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                callback(result.data, result.files);
              }
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                window.alert("Une erreur est survenue");
              }
            );
          }
        });
      }
    );
  };

  getFolderDocument = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        getFolderDocument(
          {
            folderId: this.context.folderId,
            type: this.props.type,
            token: null,
          },
          (result) => {
            if (result.success) {
              this.setState({
                isLoad: false,
                dataFolderDocument: result.data,
              });
            } else {
              this.setState({
                isLoad: false,
              });
            }
          }
        );
      }
    );
  };

  onChangeLineTransaction = (articles) => {
    this.setState(
      {
        articles,
      },
      () => this.updateProtocol()
    );
  };

  getPartiesText = (dataProtocol, party, excludeParty = []) => {
    const { parties } = this.context;

    let data = dataProtocol.parties;

    data = data
      .filter((element) => element.token && parties[element.token])
      .map((element) => ({
        ...parties[element.token],
        id: element.token,
      }))
      .filter(
        (element) => element.party === party && !excludeParty.includes(element)
      )
      .map((element) => {
        return (
          <span
            className={
              dataProtocol.electronicSignature &&
              dataProtocol.electronicSignature.signatureId
                ? !this.getStatusElectronicSignature(
                    element.id,
                    dataProtocol.electronicSignature.signers
                  )
                  ? "red"
                  : "green"
                : ""
            }
          >
            {element.name}
          </span>
        );
      });

    if (!data.length) {
      return null;
    }

    return data.reduce((prev, curr) => [prev, ", ", curr]);
  };

  getStatusElectronicSignature = (token, data) => {
    let result = false;

    data.forEach((signer) => {
      if (signer.token === token) {
        result = signer.status === "signed";
      }
    });

    return result;
  };

  getStatusCard = (name) => {
    const statusCard = this.state.statusCard[name];
    return statusCard === undefined ? true : statusCard;
  };

  updateStatusCard = (name) => {
    let statusCard = this.state.statusCard;

    statusCard[name] =
      statusCard[name] !== undefined ? !statusCard[name] : false;

    this.setState({ statusCard });
  };

  getJustifyCard = (name) => {
    const justifyCard = this.state.justifyCard[name];
    return justifyCard === undefined ? false : justifyCard;
  };

  updateJustifyCard = (name) => {
    let justifyCard = this.state.justifyCard;

    justifyCard = justifyCard || {};

    justifyCard[name] =
      justifyCard[name] !== undefined ? !justifyCard[name] : true;

    this.setState({ justifyCard });
  };

  getJumpCard = (name) => {
    const jumpCard = this.state.jumpCard[name];
    return !!jumpCard;
  };

  updateJumpCard = (name) => {
    let jumpCard = this.state.jumpCard;

    jumpCard[name] = jumpCard[name] !== undefined ? !jumpCard[name] : false;

    this.setState({
      jumpCard,
    });
  };

  render() {
    const { dataVehicleIdentification } = this.context.dataAdministrative;
    const { dataRecallFacts } = this.props;
    const { dataAdministrative, parties, partiesOptions } = this.context;

    let currentDataPrintProtocol = {};
    let currentIdxPrintProtocol = {};
    if (this.state.token && this.props.data && this.props.data.histories) {
      this.props.data.histories.forEach((element, idx) => {
        if (element.token === this.state.token) {
          currentDataPrintProtocol = element;
          currentIdxPrintProtocol = idx;
        }
      });
    }

    return (
      <AuthContext.Provider
        value={{
          ...this.context,
          updateStatusCard: (name) => this.updateStatusCard(name),
          getStatusCard: (name) => this.getStatusCard(name),
          updateJustifyCard: (name) => this.updateJustifyCard(name),
          getJustifyCard: (name) => this.getJustifyCard(name),
          updateJumpCard: (name) => this.updateJumpCard(name),
          getJumpCard: (name) => this.getJumpCard(name),
        }}
      >
        <>
          <CustomCard
            title="Identification des parties"
            name="card1"
            disabled={this.context.isLock}
          >
            <Row>
              {this.state.parties.map((party, idx) => (
                <PartyTop
                  key={idx}
                  parties={this.state.parties}
                  idx={idx}
                  onChange={(token) => {
                    let parties = this.state.parties;
                    parties[idx].token = token;
                    this.setState({ parties }, () => this.updateProtocol());
                  }}
                  onClickDeleteParty={() => {
                    writeLog(this.context.folderId, "delete-party", {
                      userId: this.context.id,
                      party: this.state.parties[idx],
                    });
                    let parties = this.state.parties;
                    parties.splice(idx, 1);
                    this.setState({ parties }, () => this.updateProtocol());
                  }}
                />
              ))}
              <Col xs={12} md={6} lg={4}>
                <div
                  className="d-flex h-100"
                  style={{
                    minHeight: "180px",
                  }}
                >
                  <ButtonIcon
                    isBackground
                    className="m-auto"
                    onClick={() => {
                      let parties = this.state.parties;

                      parties.push({});

                      this.setState(
                        {
                          parties,
                        },
                        () => this.updateProtocol()
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </ButtonIcon>
                </div>
              </Col>
            </Row>
          </CustomCard>
          <CustomCard
            name="card2"
            title="Identification du véhicule"
            disabled={this.context.isLock}
          >
            <VehicleIdentification
              data={dataVehicleIdentification}
              isDisabled={true}
              isRemoveCard={true}
            />
          </CustomCard>
          <CustomCard
            name="card3"
            title="Rappel des faits"
            disabled={this.context.isLock}
            action={
              <ButtonIcon
                className="p-0 blue mr-10"
                small
                onClick={() =>
                  this.setState({ recallFacts: {} }, () =>
                    this.updateProtocol()
                  )
                }
              >
                <FontAwesomeIcon icon={faSync} />
              </ButtonIcon>
            }
            disableStyleAction
          >
            <RecallFacts
              dataRecallFacts={dataRecallFacts}
              isProtocol={true}
              date={moment().format("YYYY-MM-DD")}
              data={this.state.recallFacts || {}}
              setData={(name, value) =>
                this.setState(
                  {
                    recallFacts: value,
                  },
                  () => this.updateProtocol()
                )
              }
            />
          </CustomCard>
          <CustomCard
            name="card8"
            title="Historique"
            disabled={this.context.isLock}
          >
            <Lexical
              value={this.state.history}
              onChange={(history) => {
                this.setState(
                  {
                    history,
                  },
                  () => this.updateProtocol()
                );
              }}
            />
          </CustomCard>
          <CustomCard
            name="card4"
            title="Constats techniques"
            disabled={this.context.isLock}
            action={
              <ButtonIcon
                className="p-0 blue mr-10"
                small
                onClick={this.setFindings}
              >
                <FontAwesomeIcon icon={faSync} />
              </ButtonIcon>
            }
            disableStyleAction
          >
            <Lexical
              value={this.state.editorState}
              onChange={(editorState) =>
                this.setState({ editorState }, () => this.updateProtocol())
              }
            />
          </CustomCard>
          {this.state.isReady ? (
            <CustomCard
              name="card5"
              title="Transaction"
              disabled={this.context.isLock}
            >
              <Table>
                <Tbody>
                  <LineTransaction
                    idx={0}
                    title="Concessions réciproques"
                    articles={this.state.articles}
                    onChange={this.onChangeLineTransaction}
                  />
                  <LineTransaction
                    idx={1}
                    title="Paiement"
                    articles={this.state.articles}
                    onChange={this.onChangeLineTransaction}
                  />
                  <LineTransaction
                    idx={2}
                    title="Clause résolutoire"
                    articles={this.state.articles}
                    onChange={this.onChangeLineTransaction}
                  />
                  <LineTransaction
                    idx={3}
                    title="Autorité de la chose jugée"
                    articles={this.state.articles}
                    onChange={this.onChangeLineTransaction}
                  />
                  <LineTransaction
                    idx={4}
                    title="Signature"
                    articles={this.state.articles}
                    onChange={this.onChangeLineTransaction}
                  />
                </Tbody>
              </Table>
            </CustomCard>
          ) : null}
          <CustomCard
            name="card6"
            title="Signatures"
            disabled={this.context.isLock}
          >
            <FormTextarea
              name="textSignature"
              value={this.state.textSignature}
              className="mb-10 mt-10"
              rows={12}
              onChange={this.handleInputChange}
              required
              noResize
            />
            <Row>
              {this.state.parties.map((party, idx) => (
                <PartyBottom
                  key={idx}
                  parties={this.state.parties}
                  idx={idx}
                  onChange={(data) => {
                    let parties = this.state.parties;
                    parties[idx] = data;
                    this.setState({ parties }, () => this.updateProtocol());
                  }}
                />
              ))}
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <FormInput
                  name="location"
                  value={this.state.location}
                  className="mt-10"
                  type="text"
                  title="Fait à"
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col xs={12} md={4}>
                <FormInput
                  name="date"
                  value={this.state.date}
                  className="mt-10"
                  type="datetime-local"
                  title="Le"
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
            <div className="d-flex">
              <div className="d-flex ml-auto mt-10">
                <Button
                  className="ml-10"
                  text="Valider pour signature électronique"
                  onClick={() => this.addProtocol(true)}
                >
                  <FontAwesomeIcon icon={faSignature} />
                </Button>
                <Button
                  className="ml-10"
                  text="Valider"
                  onClick={() => this.addProtocol()}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </div>
            </div>
          </CustomCard>
          <CustomCard
            name="card7"
            title="Historique des validations"
            disableStyleAction
            action={
              <>
                <ButtonIcon
                  className="blue p-0 mr-10"
                  small
                  info="Afficher les lignes masquées"
                  onClick={() => {
                    this.setState(
                      {
                        hides: {},
                      },
                      () => this.updateProtocol()
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faSync} />
                </ButtonIcon>
              </>
            }
          >
            <Table>
              <Thead>
                <Tr>
                  <Th>Validation</Th>
                  <Th className="w-50">Lésé</Th>
                  <Th className="w-50">Adverse</Th>
                  <Th className="w-50">Autre(s)</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {this.props.data && this.props.data.hasOwnProperty("histories")
                  ? [...this.props.data.histories]
                      .reverse()
                      .map((element, idx) => {
                        if (
                          !this.state.hides ||
                          !this.state.hides[element.token]
                        ) {
                          return (
                            <Tr
                              key={this.props.data.histories.length - 1 - idx}
                            >
                              <Td className="nowrap">
                                {moment(element.informations.date).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                {element.informations.hour}
                              </Td>
                              <Td className="w-50 nowrap">
                                {this.getPartiesText(element, "Lésé")}
                              </Td>
                              <Td className="w-50 nowrap">
                                {this.getPartiesText(element, "Adverse")}
                              </Td>
                              <Td className="w-50 nowrap">
                                {this.getPartiesText(element, [
                                  "Lésé",
                                  "Adverse",
                                ])}
                              </Td>
                              <Td className="nowrap pl-0 pr-0 text-center">
                                <ButtonIcon
                                  className="blue"
                                  small
                                  onClick={() => {
                                    this.downloadPDF({
                                      administrative:
                                        this.context.dataAdministrative,
                                      data: element,
                                      recallFacts: this.props.dataRecallFacts,
                                      type: "Protocol",
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faFilePdf} />
                                </ButtonIcon>
                                {element.isElectronicSignature ? (
                                  <>
                                    {element.electronicSignature &&
                                    element.electronicSignature
                                      .signatureId ? null : (
                                      <ButtonIcon
                                        className="orange"
                                        small
                                        onClick={() =>
                                          window.confirmCustom(
                                            element.parties.filter(
                                              (party) =>
                                                this.context.parties[
                                                  party.token
                                                ].email
                                            ).length !== element.parties.length
                                              ? "Attention une ou plusieurs parties n'ont pas d'adresse mail, êtes-vous sûr de vouloir procéder à l'envoi ?"
                                              : "Êtes-vous sûr de vouloir procéder à l'envoi ?",
                                            (result) => {
                                              if (result) {
                                                this.electronicSignature({
                                                  administrative:
                                                    this.context
                                                      .dataAdministrative,
                                                  data: element,
                                                  recallFacts:
                                                    this.props.dataRecallFacts,
                                                  type: "Protocol",
                                                });
                                              }
                                            }
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faSignature} />
                                      </ButtonIcon>
                                    )}
                                  </>
                                ) : (
                                  <ButtonIcon
                                    className="blue"
                                    small
                                    onClick={() => {
                                      this.documentToPdf(
                                        {
                                          administrative:
                                            this.context.dataAdministrative,
                                          data: element,
                                          recallFacts:
                                            this.props.dataRecallFacts,
                                          type: "Protocol",
                                        },
                                        (base64, files) => {
                                          this.setState({
                                            isModalMail: true,
                                            mailBase64: base64,
                                            mailFiles: files,
                                          });
                                        }
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEnvelope} />
                                  </ButtonIcon>
                                )}
                                <ButtonIcon
                                  className="red"
                                  small
                                  onClick={() => {
                                    let hides = this.state.hides || {};

                                    hides[element.token] =
                                      !hides[element.token];

                                    this.setState(
                                      {
                                        hides: hides,
                                      },
                                      () => this.updateProtocol()
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEyeSlash} />
                                </ButtonIcon>
                              </Td>
                            </Tr>
                          );
                        } else {
                          return null;
                        }
                      })
                  : null}
              </Tbody>
            </Table>
          </CustomCard>
          <ModalSendPdf
            token={this.state.token}
            dataAdministrative={dataAdministrative}
            data={this.state.mailBase64}
            files={this.state.mailFiles}
            show={this.state.isModalMail}
            type={"Protocol"}
            messageType={"le protocole"}
            partiesOptions={partiesOptions}
            parties={parties}
            onClose={() =>
              this.setState({
                isModalMail: false,
                mailBase64: "",
                mailFiles: [],
              })
            }
            updateProtocol={(data, callback, type, keyData) => {
              let tmpData = this.props.data;
              tmpData.histories[currentIdxPrintProtocol] = data;
              this.setData(tmpData, callback, type, keyData);
            }}
            dataProtocol={currentDataPrintProtocol}
            informations={this.state.informations}
          />
          <Loader show={this.state.isLoad} />
          <HistoryTable data={this.state.dataFolderDocument} />
        </>
      </AuthContext.Provider>
    );
  }
}
