import React from "react";

import { Table, Tbody, Tr, Th, Td, FormInput } from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";
import moment from "moment";

import { getParty } from "../../../../utils/general";

export default class Information extends React.Component {
  constructor(props) {
    super(props);
    this.data = {};
    this.displayName = "Information";
  }

  static contextType = AuthContext;

  componentDidMount() {
    const mission =
      this.context.dataAdministrative.dataAdministrativeInformation.mission;

    const vehicle =
      this.context.dataAdministrative.dataVehicleIdentification[0];

    const lese = getParty(this.context.parties, "Lésé");
    const adverse = getParty(this.context.parties, "Adverse", "Adverse", {
      key: "idParty",
      value: 1,
    });
    const expert = getParty(this.context.parties, "Expert", "Lésé");

    this.data = {
      referenceSinister: mission.referenceSinister,
      case: `${lese.kind} ${lese.name} / ${adverse.kind} ${adverse.name}`,
      dateMission: moment(mission.dateMission).format("DD/MM/YYYY"),
      vehicle: `${vehicle.brand} ${vehicle.model}`,
      licensePlate: vehicle.licensePlate,
      dateProtocol: this.props.data.dateProtocol,
      dateEnd: this.props.data.dateEnd,
      dateExpiration: this.props.data.dateExpiration,
      reference: mission.reference,
      expertName: expert.name,
    };

    if (JSON.stringify(this.data) !== JSON.stringify(this.props.data)) {
      let tmpData = this.props.data;
      tmpData = this.data;
      this.setData(tmpData);
    }
  }

  setData = (tmpData) => {
    this.context.updateDataReport("information", tmpData);
  };

  render() {
    return (
      <CustomCard title="Information" name={this.displayName}>
        <Table>
          <Tbody>
            <Tr>
              <Th>Référence compagnie</Th>
              <Td>{this.data.referenceSinister}</Td>
            </Tr>
            <Tr>
              <Th>Affaire</Th>
              <Td>{this.data.case}</Td>
            </Tr>
            <Tr>
              <Th>Date de la mission</Th>
              <Td>{this.data.dateMission}</Td>
            </Tr>
            <Tr>
              <Th>Objet du litige</Th>
              <Td>{this.data.vehicle}</Td>
            </Tr>
            <Tr>
              <Th>Immatriculation</Th>
              <Td>{this.data.licensePlate}</Td>
            </Tr>
            <Tr>
              <Th>Date de signature du protocole d'accord</Th>
              <Td className="p-5">
                <FormInput
                  styleInput={{
                    border: "none",
                    padding: "0 10px",
                    fontSize: "13px",
                    height: "36px",
                  }}
                  value={this.props.data.dateProtocol}
                  onChange={({ target }) => {
                    let tmpData = this.props.data;
                    tmpData.dateProtocol = target.value;
                    this.setData(tmpData);
                  }}
                />
              </Td>
            </Tr>
            <Tr>
              <Th>Date butoir du protocole d'accord</Th>
              <Td className="p-5">
                <FormInput
                  styleInput={{
                    border: "none",
                    padding: "0 10px",
                    fontSize: "13px",
                    height: "36px",
                  }}
                  value={this.props.data.dateEnd}
                  onChange={({ target }) => {
                    let tmpData = this.props.data;
                    tmpData.dateEnd = target.value;
                    this.setData(tmpData);
                  }}
                />
              </Td>
            </Tr>
            <Tr>
              <Th>Date d'expiration des garanties</Th>
              <Td className="p-5">
                <FormInput
                  styleInput={{
                    border: "none",
                    padding: "0 10px",
                    fontSize: "13px",
                    height: "36px",
                  }}
                  value={this.props.data.dateExpiration}
                  onChange={({ target }) => {
                    let tmpData = this.props.data;
                    tmpData.dateExpiration = target.value;
                    this.setData(tmpData);
                  }}
                />
              </Td>
            </Tr>
            <Tr>
              <Th>Références expert</Th>
              <Td>{this.data.reference}</Td>
            </Tr>
            <Tr>
              <Th>Dossier suivi par</Th>
              <Td>{this.data.expertName}</Td>
            </Tr>
          </Tbody>
        </Table>
      </CustomCard>
    );
  }
}
