import React from "react";
import { Redirect } from "react-router-dom";
import { ButtonIcon, Header } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPowerOff,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import redaxionLogo from "../images/redaxion.png";

import { signout } from "../requests/general";

import AuthContext from "../context/AuthContext";

export default class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectSignout: "",
    };
  }
  static contextType = AuthContext;

  render() {
    if (this.state.redirectSignout) {
      window.localStorage.setItem("token", "");
      return (
        <iframe
          title="auth-hubex"
          style={{ display: "none" }}
          src={this.state.redirectSignout}
          onLoad={() => window.location.reload()}
        />
      );
    }

    if (this.props.connected === false) {
      return <Redirect to="/signin" />;
    } else {
      return (
        <div className="Dashboard-container">
          <div className="Dashboard-container-body">
            <Header
              logo={redaxionLogo}
              height="30"
              onClick={() => this.props.history.push("/home")}
            >
              {this.context.isSoftware ? null : (
                <ButtonIcon
                  onClick={() => {
                    window
                      .open(
                        "https://drive.google.com/file/d/1PjRszNK8KV2Au2bVk6X7zQEmO0I6gmTg/view?usp=share_link",
                        "_blank"
                      )
                      .focus();
                  }}
                  info="Télécharger le version portable"
                >
                  <FontAwesomeIcon icon={faDownload} />
                </ButtonIcon>
              )}
              <ButtonIcon
                onClick={() => {
                  window.open(this.context.url, "_blank")
                }}
                info="Mon compte"
              >
                <FontAwesomeIcon icon={faUser} />
              </ButtonIcon>
              <ButtonIcon
                onClick={() => {
                  var url = window.location.href;
                  var arr = url.split("/");

                  signout(arr[0] + "//" + arr[2], (result) => {
                    this.setState({ redirectSignout: result.redirect });
                  });
                }}
                info="Se déconnecter"
              >
                <FontAwesomeIcon icon={faPowerOff} />
              </ButtonIcon>
            </Header>
            <div className={"Dashboard-content"}>{this.props.children}</div>
          </div>
        </div>
      );
    }
  }
}
