import React from "react";
import { Tr, Td } from "ui-kit-ck-consultant";

import Lexical from "../../../lexical/Lexical";

export default class LineTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: "",
    };
  }

  componentDidMount() {
    this.setState({
      editorState: this.props.articles[this.props.idx],
    });
  }

  render() {
    return (
      <Tr>
        <Td className="nowrap text-center">
          <span>Article {this.props.idx + 1}</span>
          <br />
          <span>{this.props.title}</span>
        </Td>
        <Td className="w-100 pt-20 pb-20">
          <Lexical
            value={this.state.editorState}
            onChange={(editorState) => {
              this.setState(
                {
                  editorState,
                },
                () => {
                  let articles = this.props.articles;
                  articles[this.props.idx] = editorState;
                  this.props.onChange(articles);
                }
              );
            }}
          />
        </Td>
      </Tr>
    );
  }
}
