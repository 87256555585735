import React from "react";
import {
  ButtonIcon,
  Card,
  FormInput,
  Modal,
  Button,
  Loader,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  FormRadio,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSearch, faLink } from "@fortawesome/free-solid-svg-icons";

import { getReportHistovec } from "../../../requests/folder";

import { getParty, generateString } from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";
import moment from "moment";

export default class Histovec extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalHistovecType: 0,
      modalHistovecSociety: "",
      modalHistovecSIRET: "",
      modalHistovecName: "",
      modalHistovecFirstname: "",
      modalHistovecLicensePlate: "",
      modalHistovecFormula: "",
      histovecOperations: [],
      histovecTechnicalControl: [],
      histovecReportUrl: "",
      isLoad: false,
      isModalHistovec: false,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e, callback = () => {}) => {
    const { value, name } = e.target;
    this.setState(
      {
        [name]: value,
      },
      callback
    );
  };

  setData = (tmpData) => {
    this.context.updateDataFolder("dataAdministrative", tmpData, () => {});
  };

  setDataRecallFacts = (data, callback = () => {}) => {
    this.context.updateDataFolder("dataRecallFacts", data, callback);
  };

  getReportHistovec = () => {
    if (
      ((this.state.modalHistovecType === 0 &&
        this.state.modalHistovecName &&
        this.state.modalHistovecFirstname) ||
        (this.state.modalHistovecType === 1 &&
          this.state.modalHistovecSociety)) &&
      this.state.modalHistovecLicensePlate &&
      this.state.modalHistovecFormula
    ) {
      this.setState(
        {
          isLoad: true,
          isModalHistovec: false,
        },
        () => {
          getReportHistovec(
            {
              type: this.state.modalHistovecType,
              society: this.state.modalHistovecSociety,
              SIRET: this.state.modalHistovecSIRET,
              name: this.state.modalHistovecName,
              firstname: this.state.modalHistovecFirstname,
              licensePlate: this.state.modalHistovecLicensePlate,
              formula: this.state.modalHistovecFormula,
            },
            (result) => {
              if (result.success) {
                const filtered = this.props.dataRecallFacts.filter(
                  (element) =>
                    !result.data.operations.length ||
                    ![
                      "Date de la première mise en circulation du véhicule",
                      "Date d'achat du véhicule",
                    ].includes(element.description)
                );

                this.setState(
                  {
                    isLoad: false,
                  },
                  () => {
                    let tmpData = this.context.dataAdministrative;
                    tmpData["dataVehicleIdentification"][0].histovecOperations =
                      result.data.operations;
                    tmpData[
                      "dataVehicleIdentification"
                    ][0].histovecTechnicalControl =
                      result.data.technical_control;
                    tmpData["dataVehicleIdentification"][0].histovecReportUrl =
                      result.data.url;

                    this.setData(tmpData);

                    let tmpData2 = filtered;
                    const tmpMerge = [
                      ...result.data.operations,
                      ...result.data.technical_control,
                    ];
                    tmpData2 = tmpData2.filter(
                      (element) => element && !element.isHistovec
                    );

                    tmpMerge.forEach((element) => {
                      let mileage = "";
                      if (element.mileage) {
                        mileage = element.mileage.replace(/\D/g, "");
                      }
                      tmpData2.push({
                        date: moment(element.date, "DD/MM/YYYY").format(
                          "YYYY-MM-DD"
                        ),
                        description:
                          element.text || `${element.nature} ${element.result}`,
                        mileage: element.mileage ? parseInt(mileage) : "",
                        status: false,
                        token: generateString(),
                        isHistovec: true,
                      });
                    });

                    tmpData2.sort((a, b) => {
                      return new Date(a.date) - new Date(b.date);
                    });

                    this.setDataRecallFacts(tmpData2);
                    window.alert("Ok !");
                  }
                );
              } else {
                this.setState(
                  {
                    isLoad: false,
                    isModalHistovec: true,
                  },
                  () => window.alert("Une erreur est survenue")
                );
              }
            }
          );
        }
      );
    } else {
      window.alert("Veuillez remplir tous les champs");
    }
  };

  render() {
    const dataVehicleIdentification =
      this.context.dataAdministrative.dataVehicleIdentification;
    const currentVehicle = dataVehicleIdentification[0];

    currentVehicle.histovecOperations = currentVehicle.histovecOperations || [];
    currentVehicle.histovecTechnicalControl =
      currentVehicle.histovecTechnicalControl || [];

    let name = "";
    let firstname = "";
    const insured = getParty(this.context.parties, "Lésé");
    if (insured && insured.name) {
      name = insured.name.split(" ")[0];
      firstname = insured.name.split(" ")[1];
    }

    return (
      <>
        <Card
          className="mt-10 mt-20-xl"
          title="Rapport Histovec"
          disabled={this.context.isLock}
        >
          <div className="d-flex">
            <div className="mt-auto mb-auto ml-auto d-flex">
              <ButtonIcon
                isBackground
                disabled={
                  !this.context.dataAdministrative.dataVehicleIdentification[0]
                    .histovecReportUrl
                }
                onClick={() => {
                  window
                    .open(
                      this.context.dataAdministrative
                        .dataVehicleIdentification[0].histovecReportUrl,
                      "_blank"
                    )
                    .focus();
                }}
              >
                <FontAwesomeIcon icon={faLink} />
              </ButtonIcon>
              <ButtonIcon
                className="ml-10"
                isBackground
                onClick={() =>
                  this.setState({
                    isModalHistovec: true,
                    modalHistovecName: name,
                    modalHistovecFirstname: firstname,
                    modalHistovecLicensePlate: currentVehicle.licensePlate,
                    modalHistovecFormula: currentVehicle.formula,
                  })
                }
              >
                <FontAwesomeIcon icon={faSearch} />
              </ButtonIcon>
            </div>
          </div>

          <h3>Historique des opérations</h3>
          <Table>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Opération</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentVehicle.histovecOperations.map((element, idx) => (
                <Tr key={idx}>
                  <Td>{element.date}</Td>
                  <Td>{element.text}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <h3>Contrôles techniques</h3>
          <Table>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Nature</Th>
                <Th>Résultat</Th>
                <Th>Kilométrage</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentVehicle.histovecTechnicalControl.map((element, idx) => (
                <Tr key={idx}>
                  <Td>{element.date}</Td>
                  <Td>{element.nature}</Td>
                  <Td>{element.result}</Td>
                  <Td>{element.mileage}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
        <Modal
          show={this.state.isModalHistovec}
          closeMessage="Êtes-vous sûr de vouloir fermer cette fenêtre ?"
          onClose={() => this.setState({ isModalHistovec: false })}
          isMove
        >
          <FormRadio
            text="Personne physique"
            value={"modalHistovecType"}
            className="mb-10"
            checked={this.state.modalHistovecType === 0}
            onChange={() => this.setState({ modalHistovecType: 0 })}
          />
          <FormRadio
            text="Personne morale"
            value={"modalHistovecType"}
            className="mb-10"
            checked={this.state.modalHistovecType === 1}
            onChange={() => this.setState({ modalHistovecType: 1 })}
          />
          {this.state.modalHistovecType === 1 ? (
            <>
              <FormInput
                className="mb-10"
                name="modalHistovecSociety"
                value={this.state.modalHistovecSociety}
                title="Raison sociale"
                onChange={this.handleInputChange}
              />
              <FormInput
                className="mb-10"
                name="modalHistovecSIRET"
                value={this.state.modalHistovecSIRET}
                title="SIRET"
                onChange={this.handleInputChange}
              />{" "}
            </>
          ) : (
            <>
              <FormInput
                className="mb-10"
                name="modalHistovecName"
                value={this.state.modalHistovecName}
                title="Nom"
                onChange={this.handleInputChange}
              />
              <FormInput
                className="mb-10"
                name="modalHistovecFirstname"
                value={this.state.modalHistovecFirstname}
                title="Prénom"
                onChange={this.handleInputChange}
              />
            </>
          )}

          <FormInput
            className="mb-10"
            name="modalHistovecLicensePlate"
            value={this.state.modalHistovecLicensePlate}
            title="Immatriculation"
            onChange={this.handleInputChange}
          />
          <FormInput
            className="mb-10"
            name="modalHistovecFormula"
            value={this.state.modalHistovecFormula}
            title="N° de formule"
            onChange={this.handleInputChange}
          />
          <div className="d-flex">
            <Button
              className="ml-auto w-auto"
              text="Synchroniser"
              onClick={() => this.getReportHistovec()}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </div>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
